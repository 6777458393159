module.exports = {
  es: {
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    chats: "Chats",
    date: "Fecha",
    totalChats: "Total chats recibidos",
    maxDayAverage: "Día(s) con más chats promedio",
    lowestDayAverage: "Día(s) con menos chats promedio",
    maxHourAverage: "Hora(s) con más chats promedio",
    moreBusy: "Más ocupado",
    lessBusy: "Menos ocupado",
    day: "Día",
    csvFilename: "treble-reporte-conversaciones-por-hora",
  },
  en: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    chats: "Chats",
    date: "Date",
    totalChats: "Total chats received",
    maxDayAverage: "Day(s) with more average chats",
    lowestDayAverage: "Day(s) with less average chats",
    maxHourAverage: "Hour(s) with more average chats",
    moreBusy: "More busy",
    lessBusy: "Less busy",
    day: "Day",
    csvFilename: "treble-exports-conversations-per-hour",
  },
  pt: {
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    saturday: "Sábado",
    sunday: "Domingo",
    chats: "Conversas",
    date: "Data",
    totalChats: "Total de conversas recebidos",
    maxDayAverage: "Dia(s) com mais conversas médios",
    lowestDayAverage: "Dia(s) com menos conversas médios",
    maxHourAverage: "Hora(s) com mais conversas médios",
    moreBusy: "Mais ocupado",
    lessBusy: "Menos ocupado",
    day: "Dia",
    csvFilename: "treble-relatorio-conversas-por-hora",
  },
};
