import React, { Component } from "react";
import "./styles.scss";

import Dropdown from "../../../../components/Dropdowns/DropdownV3";
import SimpleDropdown from "../../../../components/Dropdowns/Dropdown";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import TextInput from "../../../../components/TextInput/TextInput";
import Modal from "../../../../components/Modal/ModalV2";
import AlertLoader from "../../../../components/AlertLoader/AlertLoader";
import events from "../../../../utils/events";
import { colorOptions } from "../../../../utils/labels";
import { Popover } from "antd";

import languages from "./languages.js";

import { LABEL_RESPONSE } from "../../../../actions/chatAction.js";

export class EditChatLabelDropdown extends Component {
  constructor(props) {
    super(props);
  }
  render = () => {
    const chatLabel = this.props.chatLabel;
    return (
      <Popover
        content={
          <div
            className="dropdown-item"
            onClick={() => this.props.removeLabelFromChat(chatLabel.id)}
          >
            {this.props.strings.remove}
          </div>
        }
        placement="bottom"
        trigger="click"
        overlayClassName="edit-labels-dropdown"
      >
        <div
          key={chatLabel.id}
          className={`label-item label-${chatLabel.color}`}
        >
          {chatLabel.name}
        </div>
      </Popover>
    );
  };
}

export class AddChatLabelsDropdown extends Component {
  constructor(props) {
    super(props);

    this.strings = languages[props.language];

    this.state = {
      inputValue: "",
      selectedColor: "none",
      option: "created",
      onSearch: "",
      order: {
        text: this.strings.labelOrderByAZ,
        icon: "order-by-AZ",
      },
      selectedLabel: null,
    };
    this.colorOptions = colorOptions;
    this.orderBy = [
      { icon: "order-by-AZ", text: this.strings.labelOrderByAZ },
      {
        icon: "order-by-time-asc",
        text: this.strings.labelOrderByTimeAsc,
      },
      {
        icon: "order-by-time-desc",
        text: this.strings.labelOrderByTimeDesc,
      },
    ];
  }

  filterLabels = (labels) => {
    const { inputValue, selectedColor, order } = this.state;
    labels = labels.filter((label) => {
      let can = true;
      if (inputValue !== "") {
        can &=
          String(label.name)
            .toLowerCase()
            .indexOf(this.state.inputValue.toLowerCase()) !== -1;
      }
      if (selectedColor !== "none") {
        can &= label.color === selectedColor;
      }
      return can;
    });
    labels = labels.sort((a, b) => {
      if (order.text === this.orderBy[0].text) {
        return b.name < a.name ? -1 : 1;
      } else if (order.text === this.orderBy[1].text) {
        return new Date(b.created_at) - new Date(a.created_at);
      } else {
        return new Date(a.created_at) - new Date(b.created_at);
      }
    });
    return labels;
  };

  renderSearchBar = () => {
    return (
      <SearchBar
        placeholder={this.strings.searchChatLabel}
        value={this.state.inputValue}
        onChange={(e) => this.setState({ inputValue: e.target.value })}
        showCancel={true}
        onClick={() => this.setState({ inputValue: "", onSearch: false })}
        trackMessage="Agent filter tag by text"
      />
    );
  };

  renderOrderByOptions = () => {
    return this.orderBy.map((elem) => (
      <div
        className="item"
        onClick={() => {
          events.track("Agent sort tags", { "sort option": elem.icon });
          this.setState({
            order: { text: elem.text, icon: elem.icon },
          });
        }}
      >
        <div className={`icon icon--${elem.icon}`} />
        {elem.text}
      </div>
    ));
  };

  renderFilterOptions = () => {
    const { order } = this.state;
    const title = (
      <div className="item">
        <div className={`icon icon--${order.icon}`} />
        <div className="text">{order.text}</div>
      </div>
    );
    return (
      <>
        <SimpleDropdown title={title} iconRow="dropdown-row" hideOnClick={true}>
          {this.renderOrderByOptions()}
        </SimpleDropdown>
        <div className="bar" />
        <div className="lookup-icon">
          <div
            className="icon icon--search"
            onClick={() => this.setState({ onSearch: true })}
          ></div>
        </div>
      </>
    );
  };

  renderColorOptions = (filter = false) => {
    return this.colorOptions.map((color) => (
      <div key={color} className="color-box">
        <div
          className={`color ${color}
                ${this.state.selectedColor === color ? "selected" : ""}`}
          onClick={() => {
            if (filter)
              events.track("Agent filter tag by color", { color: color });
            this.setState({ selectedColor: color });
          }}
        ></div>
      </div>
    ));
  };

  renderCreatedLabels = () => {
    const labels = this.filterLabels(this.props.chatLabels);
    return (
      <>
        <div className="filter">
          <div className="filter-options">
            {this.state.onSearch
              ? this.renderSearchBar()
              : this.renderFilterOptions()}
          </div>
          <div className="color-options color-options-container">
            <div
              className="color-box"
              onClick={() => this.setState({ selectedColor: "none" })}
            >
              <div className="icon icon--none-icon" />
            </div>
            {this.renderColorOptions(true)}
          </div>
        </div>
        <div className="labels">
          <div className="list-labels">
            {labels.map((chatLabel) => (
              <div key={chatLabel.id} className="label-item">
                <div
                  className={`icon--edit-tag color ${chatLabel.color}`}
                ></div>
                <div className="label-text">{chatLabel.name}</div>

                {this.props.isAdmin && (
                  <>
                    <div
                      className="check-box delete-label"
                      onClick={() => this.deleteLabel(chatLabel.id)}
                    >
                      <div className="icon icon--trash" />
                    </div>
                    <div
                      className="check-box edit-label"
                      onClick={() => this.selectLabel(chatLabel)}
                    >
                      <div className="icon icon--edit-label" />
                    </div>
                  </>
                )}
                <div
                  className={`check-box set-label ${
                    this.props.selectedChatLabels.find(
                      (e) => e.id === chatLabel.id,
                    )
                      ? "show"
                      : "not-show"
                  }`}
                  onClick={() => this.props.toggleChatLabel(chatLabel.id)}
                >
                  <div className="icon icon--sent" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  deleteLabel = (labelId) => {
    if (window.confirm(this.strings.confirmDeleteChatLabel)) {
      this.props.deleteChatLabel(labelId);
    }
  };

  createLabel = () => {
    if (this.state.selectedLabel) {
      this.props.editChatLabel(
        {
          name: this.state.inputValue,
          color: this.state.selectedColor,
        },
        this.state.selectedLabel.id,
      );
    } else {
      this.props.createChatLabel({
        name: this.state.inputValue,
        color: this.state.selectedColor,
      });
    }
    this.backToTags();
  };

  renderCreateLabels = () => {
    let createTag = this.strings.createTag;
    if (this.state.selectedLabel) createTag = this.strings.saveChanges;
    return (
      <div className="create-tag">
        <div className="tag-options">
          <div className="text">{this.strings.tagName}</div>
          <TextInput
            className="input-tag"
            type="text"
            placeholder={this.strings.createTagPlaceHolder}
            value={this.state.inputValue}
            onChange={(e) => this.setState({ inputValue: e.target.value })}
          ></TextInput>
          <div className="text">{this.strings.chooseColor}</div>
          <div className="color-options">{this.renderColorOptions()}</div>
        </div>
        <div className="buttons">
          <div className="button cancel" onClick={() => this.backToTags()}>
            {this.strings.cancel}
          </div>
          <div className="button create" onClick={() => this.createLabel()}>
            {createTag}
          </div>
        </div>
      </div>
    );
  };

  selectLabel = (chatLabel) => {
    this.setState({ selectedLabel: chatLabel });
    this.changeOption("create", chatLabel.name, chatLabel.color);
  };

  backToTags = () => {
    this.setState({ selectedLabel: null });
    this.changeOption("created", "", "none");
  };

  changeOption = (option, inputValue, selectedColor) => {
    if (option !== this.state.option) {
      this.setState({ option, inputValue, selectedColor });
    }
  };

  renderOptions = () => {
    const option = this.state.option;
    if (this.state.selectedLabel) {
      return (
        <div className="options edit-tag" onClick={() => this.backToTags()}>
          <div className="icon icon--arrow-back" />
          <p>{this.strings.backToTags}</p>
          <div className="icon icon--close" />
        </div>
      );
    }
    return (
      <div className="options">
        <div
          className={`option ${option === "created" ? "is-active" : ""}`}
          onClick={() => this.changeOption("created", "", "none")}
        >
          {this.strings.tagsCreated}
        </div>
        {this.props.isAdmin && (
          <div
            className={`option ${option === "create" ? "is-active" : ""}`}
            onClick={() => this.changeOption("create", "", "default")}
          >
            {this.strings.createNewTag}
          </div>
        )}
      </div>
    );
  };

  renderLabelsCreator = () => {
    const { name } = this.props.alertState;
    if (name === LABEL_RESPONSE) {
      return (
        <AlertLoader
          alertState={this.props.alertState}
          loading={this.strings.deleting}
          success={this.strings.deleted}
          error={this.strings.cantDeleted}
          updateAlertState={this.props.updateAlertState}
        />
      );
    }
    return (
      <div className="create-chat-labels">
        {this.renderOptions()}
        {this.state.option === "created"
          ? this.renderCreatedLabels()
          : this.renderCreateLabels()}
      </div>
    );
  };

  renderDropdown = () => {
    const { name } = this.props.alertState;
    return (
      <Dropdown
        handleCloseDropdown={() =>
          this.setState({ inputValue: "", selectedColor: "none" })
        }
        subClassName={`add-labels-dropdown ${
          name === LABEL_RESPONSE ? "on-progress" : ""
        }`}
        triggerComponent={this.props.triggerComponent}
      >
        {this.renderLabelsCreator()}
      </Dropdown>
    );
  };

  renderModal = () => {
    return (
      <Modal
        wrappClass="mobile-modal labels-modal"
        show={this.props.show}
        onClose={this.props.onClose}
        body={this.renderLabelsCreator()}
      />
    );
  };

  render = () => {
    return this.props.type === "modal"
      ? this.renderModal()
      : this.renderDropdown();
  };
}
