import { request } from "../utils/request";
import { push } from "connected-react-router";
import { store } from "../store";
import { changeActivation, clearError } from "./userAction";
import config from "../config";
import { toast } from "react-toastify";
import { emptyHistory } from "./chatAction";

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// CHATS

async function updateChats(dispatch) {
  let moreChats = true;
  let chats = [];
  let next_conversation_id = null;

  const addChats = (success) => {
    chats = chats.concat(success.chats);
    next_conversation_id = success.next_conversation_id
      ? success.next_conversation_id
      : null;
    if (!next_conversation_id) {
      moreChats = false;
    }
  };

  const fetchChats = async (next_conversation_id) => {
    const state = store.getState();
    const user = state.mainReducer.user;

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };

    const response = await fetch(
      `${config.API_ROOT}/agent/conversation?${
        next_conversation_id
          ? `next_conversation_id=${next_conversation_id}`
          : ""
      }`,
      {
        method: "GET",
        headers: headers,
      },
    );

    return response.json();
  };

  while (moreChats) {
    const json_response = await fetchChats(next_conversation_id);
    console.log(json_response);
    addChats(json_response);
  }

  dispatch(receiveChats(chats));

  return chats;
}

export const getChats = () => (dispatch) => {
  dispatch(requestChats());
  updateChats(dispatch);
};

const REQUEST_CHATS = "REQUEST_CHATS";
export const requestChats = () => (dispatch) => {
  dispatch(emptyHistory);
  dispatch({
    type: REQUEST_CHATS,
  });
};

const RECEIVE_CHATS = "RECEIVE_CHATS";
export const receiveChats = (chats) => (dispatch) => {
  dispatch({
    type: RECEIVE_CHATS,
    chats,
  });
};

const AGENT_CHATS_ERROR = "AGENT_CHATS_ERROR";
export const agentChatsError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_CHATS_ERROR,
    error,
  });
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// HSMS

const RECEIVE_HSMS = "RECEIVE_HSMS";
export const receiveHsms = (hsms) => (dispatch) => {
  dispatch({
    type: RECEIVE_HSMS,
    hsms,
  });
};

const AGENT_HSMS_ERROR = "AGENT_HSMS_ERROR";
export const hsmsError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_HSMS_ERROR,
    error,
  });
};

export const getHsms = () => (dispatch) => {
  request(
    "GET",
    "/agent/hsm",
    null,
    true,
    (data) => {
      const hsms = data.hsms;
      return dispatch(receiveHsms(hsms));
    },
    (error) => {
      console.log(error);
      return dispatch(hsmsError("Unable to get Hsms"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// CONTACTS

const CONTACT_PAGINATION_LIMIT = 500;

export const getContacts = (offset, searchValue) => (dispatch) => {
  dispatch(requestContacts());
  const params = {};
  request(
    "GET",
    `/agent/contact/paginated?limit=${CONTACT_PAGINATION_LIMIT}&offset=${offset}&search=${searchValue}`,
    params,
    true,
    (data) => {
      return dispatch(receiveContacts(data));
    },
    (error) => {
      console.log(error);
      dispatch(agentContactsError("Error retrieving contact"));
      return setTimeout(() => dispatch(clearError()), 5000);
    },
    false,
    false,
  );
};

const REQUEST_CONTACTS = "REQUEST_CONTACTS";
export const requestContacts = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONTACTS,
  });
};

const RECEIVE_CONTACTS = "RECEIVE_CONTACTS";
export const receiveContacts = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONTACTS,
    contacts: data.contacts,
    offset: data.contacts.length,
    total: data?.total,
    visible: data?.visible,
  });
};

const CLEAN_CONTACTS = "CLEAN_CONTACTS";
export const cleanContacts = () => (dispatch) => {
  dispatch({ type: CLEAN_CONTACTS });
};

const AGENT_CONTACTS_ERROR = "AGENT_CONTACTS_ERROR";
export const agentContactsError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_CONTACTS_ERROR,
    error,
  });
};

const REQUEST_CREATE_CONTACT = "REQUEST_CREATE_CONTACT";
export const requestCreateContact = () => (dispatch) => {
  dispatch({
    type: REQUEST_CREATE_CONTACT,
  });
};

const RECEIVE_CREATE_CONTACT = "RECEIVE_CREATE_CONTACT";
export const receiveCreateContact = (data) => (dispatch) => {
  toast("¡Contacto agregado!", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  dispatch({
    type: RECEIVE_CREATE_CONTACT,
    contactId: data.id,
    contactName: data.name,
    contactCellphone: data.whole_cellphone,
    contactActiveConversation: data.active_conversation,
  });
};

const CREATE_CONTACT_ERROR = "CREATE_CONTACT_ERROR";
export const createContactError = (error) => (dispatch) => {
  dispatch({
    type: CREATE_CONTACT_ERROR,
    error,
  });
};

const REQUEST_UPDATE_CONTACT = "REQUEST_UPDATE_CONTACT";
export const requestUpdateContact = () => (dispatch) => {
  dispatch({
    type: REQUEST_UPDATE_CONTACT,
  });
};

const RECEIVE_UPDATE_CONTACT = "RECEIVE_UPDATE_CONTACT";
export const receiveUpdateContact = (contactId, name) => (dispatch) => {
  toast("¡Contacto editado!", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  dispatch({
    type: RECEIVE_UPDATE_CONTACT,
    contactId,
    name,
  });
};

const UPDATE_CONTACT_ERROR = "UPDATE_CONTACT_ERROR";
export const updateContactError = (error) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTACT_ERROR,
    error,
  });
};

const REQUEST_DELETE_CONTACT = "REQUEST_DELETE_CONTACT";
export const requestDeleteContact = () => (dispatch) => {
  dispatch({
    type: REQUEST_DELETE_CONTACT,
  });
};

const RECEIVE_DELETE_CONTACT = "RECEIVE_DELETE_CONTACT";
export const receiveDeleteContact = (contactId) => (dispatch) => {
  dispatch({
    type: RECEIVE_DELETE_CONTACT,
    contactId,
  });
};

const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR";
export const deleteContactError = (error) => (dispatch) => {
  dispatch({
    type: DELETE_CONTACT_ERROR,
    error,
  });
};

export const updateContact = (contactId, name) => (dispatch) => {
  dispatch(requestUpdateContact());

  const params = {
    name,
  };
  request(
    "PUT",
    `/agent/contact/${contactId}`,
    params,
    true,
    (data) => {
      return dispatch(receiveUpdateContact(contactId, name));
    },
    (error) => {
      console.log(error);
      dispatch(updateContactError("Error updating contact"));
      return setTimeout(() => dispatch(clearError()), 5000);
    },
    false,
    false,
  );
};

export const deleteContact = (contactId) => (dispatch) => {
  dispatch(requestDeleteContact());

  request(
    "DELETE",
    `/agent/contact/${contactId}`,
    null,
    true,
    (data) => {
      return dispatch(receiveDeleteContact(contactId));
    },
    (error) => {
      console.log(error);
      dispatch(deleteContactError("Error deleting contact"));
      return setTimeout(() => dispatch(clearError()), 5000);
    },
    false,
    false,
  );
};

export const createContact = (name, country_code, cellphone) => (dispatch) => {
  dispatch(requestCreateContact());

  const params = {
    name,
    country_code,
    cellphone,
  };
  request(
    "POST",
    "/agent/contact",
    params,
    true,
    (data) => {
      return dispatch(receiveCreateContact(data));
    },
    (error) => {
      console.log(error);
      dispatch(createContactError("Error creating contact"));
      return setTimeout(() => dispatch(clearError()), 5000);
    },
    false,
    false,
  );
};

async function getAddress(latitude, longitude, key, msgId, callback) {
  const fetchAddress = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`,
      {
        method: "GET",
      },
    );
    return response.json();
  };

  const json_response = await fetchAddress();
  console.log(json_response); // Validates that a json response is given
  let address =
    json_response["results"] && json_response["results"].length > 1
      ? json_response["results"][0]["formatted_address"]
      : "Unable to get address";
  callback(address, msgId);
}

export const getAddressFromCoordinates =
  (latitude, longitude, key, msgId, callback) => (dispatch) => {
    getAddress(latitude, longitude, key, msgId, callback);
  };

export const getChannels = () => (dispatch) => {
  request(
    "GET",
    "/agent/company/channels",
    null,
    true,
    (data) => {
      return dispatch(receiveChannel(data.channels));
    },
    (error) => {
      console.log(error);
    },
  );
};

export const getHelpdesks = () => (dispatch) => {
  request(
    "GET",
    "/agent/company/helpdesks",
    null,
    true,
    (data) => {
      return dispatch(receiveHelpdesks(data.helpdesks));
    },
    (error) => {
      console.log(error);
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// LOGOUT

const AGENT_LOGOUT_ERROR = "AGENT_LOGOUT_ERROR";
export const agentLogoutError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_LOGOUT_ERROR,
    error,
  });
};

const AGENT_LOGOUT = "AGENT_LOGOUT";
const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const agentLogoutSimple =
  (error, forceLogout = true, loginHelpdesk = false) =>
  (dispatch) => {
    if (forceLogout && !loginHelpdesk) {
      dispatch(push("/?error=true"));
      dispatch({ type: AGENT_LOGOUT });
      dispatch({ type: ADMIN_LOGOUT });
    } else {
      window.location.reload();
    }

    dispatch(agentLogoutError(error));

    setTimeout(() => {
      dispatch(clearError());
    }, 10000);

    return;
  };

export const agentLogout = () => (dispatch) => {
  dispatch(changeActivation(false, true));
  dispatch({ type: AGENT_LOGOUT });
  dispatch({ type: ADMIN_LOGOUT });
  return dispatch(push("/"));
};

const RECEIVE_CHANNELS = "RECEIVE_CHANNELS";
export const receiveChannel = (channels) => (dispatch) => {
  dispatch({
    type: RECEIVE_CHANNELS,
    channels,
  });
};

const RECEIVE_HELPDESKS = "RECEIVE_HELPDESKS";
export const receiveHelpdesks = (helpdesks) => (dispatch) => {
  dispatch({
    type: RECEIVE_HELPDESKS,
    helpdesks,
  });
};
