import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";
import { toast } from "react-toastify";

import { formatText } from "../../../utils/WhatsappMarkdown";

import {
  adminGetSnippets,
  adminCreateSnippet,
  adminUpdateSnippet,
  adminDeleteSnippet,
} from "../../../actions/snippetAction";
import { getAllTags, createNewTag } from "../../../actions/statAction";

import TextInput from "../../../components/TextInput/TextInput";
import Dropdown from "../../../components/Dropdowns/Dropdown";
import SnippetModal from "./SnippetModal/SnippetModal";

import "./Snippets.scss";
import languages from "./languages.js";

class Snippets extends Component {
  constructor(props) {
    super(props);
    this.strings = languages[props.language];
    this.state = {
      filter: {
        name: "",
        order: {
          text: this.strings.orderByAZ,
          icon: "order-by-AZ",
        },
        tag: "",
      },
      showCreateModal: false,
      snippet: this.snippetDefault(),
      editSnippe: false,
    };
  }

  snippetDefault = () => {
    return {
      name: "",
      text: "",
      tags: ["DEFAULT"],
    };
  };

  componentDidMount() {
    this.props.adminGetSnippets();
    this.props.getAllTags(this.props.user.company.id);
    this.setFilters();
  }

  setFilters = () => {
    const { filter } = this.state;
    filter.order.text = this.strings.orderByAZ;
    filter.order.icon = "order-by-AZ";
    filter.tag = this.strings.seeAllTags;
    this.setState({ filter });
  };

  changeFilter = (value, key) => {
    const { filter } = this.state;
    filter[key] = value;
    this.setState({ filter });
  };

  showModal = () => {
    const show = this.state.showCreateModal;
    this.setState({
      showCreateModal: !show,
      snippet: this.snippetDefault(),
      editSnippet: false,
    });
  };

  createSnippet = (snippet = this.snippetDefault(), editSnippet = false) => {
    this.showModal();
    this.setState({ snippet, editSnippet });
  };

  getToast = (title) => {
    const snippetToast = () => {
      toast(title, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
    };
    return snippetToast;
  };

  duplicateSnippet = (snippet) => {
    this.props.adminCreateSnippet(
      snippet,
      this.getToast(this.strings.snippetDuplicated),
      true,
    );
  };

  deleteSnippet = (snippet) => {
    this.props.adminDeleteSnippet(
      snippet,
      this.getToast(this.strings.snippetDeleted),
    );
  };

  getDate = (date) => {
    return moment(date).locale(this.props.language).format("DD MMM YYYY");
  };

  getTag = (tags) => {
    let tag = tags.filter((tag) => tag != "DEFAULT");
    if (!tag.length) tag = ["DEFAULT"];
    return tag[0];
  };

  filterSnippets = (snippets) => {
    const { name, order, tag } = this.state.filter;
    snippets = snippets.sort((a, b) => {
      const { text } = order;
      let val = 0;
      if (text === this.strings.orderByAZ) {
        val = a.name < b.name ? -1 : 1;
      }
      if (text === this.strings.orderByTimeAsc) {
        val = new Date(b.updated_at) - new Date(a.updated_at);
      }
      if (text === this.strings.orderByTimeDesc) {
        val = new Date(a.updated_at) - new Date(b.updated_at);
      }
      return val;
    });

    return snippets.filter((snippet) => {
      let ok = true;
      if (name !== "") {
        ok &= snippet.name.toLowerCase().includes(name.toLowerCase());
      }
      if (tag !== "" && tag !== this.strings.seeAllTags) {
        ok &= snippet.tags.includes(tag);
      }
      return ok;
    });
  };

  renderOrderByOptions = () => {
    const orderby = [
      { icon: "order-by-AZ", text: this.strings.orderByAZ },
      { icon: "order-by-time-asc", text: this.strings.orderByTimeAsc },
      { icon: "order-by-time-desc", text: this.strings.orderByTimeDesc },
    ];
    return orderby.map((elem) => (
      <div
        className="item"
        onClick={() =>
          this.changeFilter({ text: elem.text, icon: elem.icon }, "order")
        }
      >
        <div className={`icon icon--${elem.icon}`} />
        {elem.text}
      </div>
    ));
  };

  renderTags = () => {
    const { tags } = this.props;
    const { tag } = this.state.filter;
    const alltags = [{ name: this.strings.seeAllTags }, ...tags];
    return alltags.map((elem) => (
      <div className="item" onClick={() => this.changeFilter(elem.name, "tag")}>
        {elem.name}
        {tag == elem.name ? <div className="icon icon--check"></div> : ""}
      </div>
    ));
  };

  renderFilters = () => {
    const { name, order, tag } = this.state.filter;
    return (
      <div className="filter-options">
        <div className="searchbar">
          <div className="icon icon--snippet-search size-18" />
          <TextInput
            className=""
            type="text"
            placeholder={this.strings.searchByName}
            value={name}
            onChange={(e) => this.changeFilter(e.target.value, "name")}
            trackMessage="Agent search snippet in snippets View"
          />
        </div>
        <div className="order-by">
          <Dropdown
            title={
              <div className="item">
                <div className={`icon icon--${order.icon}`} />
                {order.text}
              </div>
            }
            iconRow={"dropdown-row"}
            hideOnClick={true}
          >
            {this.renderOrderByOptions()}
          </Dropdown>
        </div>
        <div className="order-by order-tag">
          <Dropdown title={tag} iconRow={"dropdown-row"} hideOnClick={true}>
            {this.renderTags()}
          </Dropdown>
        </div>
      </div>
    );
  };

  renderSnippet = (snippet) => {
    const tag = this.getTag(snippet.tags);
    return (
      <div className="snippet">
        <div className="header">
          <div className="text">{snippet.name}</div>
          <div className="options">
            <Dropdown iconRow={"snippet-options"} hideOnClick={true}>
              <div
                className="item"
                onClick={() => this.duplicateSnippet(snippet)}
              >
                <div className="icon icon--snippet-copy" />
                {this.strings.snippetCopy}
              </div>
              <div className="item" onClick={() => this.deleteSnippet(snippet)}>
                <div className="icon icon--snippet-delete" />
                {this.strings.snippetDelete}
              </div>
            </Dropdown>
            <div
              className="icon icon--snippet-edit"
              onClick={() => this.createSnippet(snippet, true)}
            />
          </div>
        </div>
        <div className="body">
          <div className="text">{formatText(snippet.text)}</div>
        </div>
        <div className="footer">
          <div className="time">
            <div className="icon icon--snippet-time" />
            {this.getDate(snippet.updated_at)}
          </div>
          <div className="tags">
            <div className={`tag tag--${tag === "DEFAULT" ? "grey" : "rose"}`}>
              {tag}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSnippets = () => {
    const snippets = this.filterSnippets(this.props.snippets);
    if (snippets.length) {
      return (
        <div className="snippets-grid">
          {snippets.map((snippet) => this.renderSnippet(snippet))}
        </div>
      );
    }
    return (
      <div className="no-results">
        <div className="image" />
        <div className="text">{this.strings.noResults}</div>
      </div>
    );
  };

  renderContent = () => {
    if (!this.props.snippets.length) {
      return (
        <div className="empty-snippets">
          <div className="title">{this.strings.createTemplateTitle}</div>
          <div className="body">{this.strings.createTemplateDescription}</div>
          <div className="footer">
            <button onClick={() => this.createSnippet()}>
              <div className="icon icon--add-highlighted" />
              {this.strings.buttonCreateMessage}
            </button>
          </div>
        </div>
      );
    }
    return (
      <>
        {this.renderFilters()}
        {this.renderSnippets()}
      </>
    );
  };

  snippetModal = () => {
    return (
      <SnippetModal
        show={this.state.showCreateModal}
        onClose={() => this.showModal()}
        snippet={this.state.snippet}
        editSnippet={this.state.editSnippet}
        strings={this.strings}
        tags={this.props.tags}
        user={this.props.user}
        createTag={this.props.createNewTag}
        adminCreateSnippet={this.props.adminCreateSnippet}
        adminUpdateSnippet={this.props.adminUpdateSnippet}
      />
    );
  };

  renderCreateButton = () => {
    return (
      <button onClick={() => this.createSnippet()}>
        <div className="icon icon--add-highlighted" />
        {this.strings.buttonCreateMessage}
      </button>
    );
  };

  render() {
    return (
      <div className="snippet-container">
        <div className="header">
          {this.strings.templateMessage}
          {this.props.snippets.length ? this.renderCreateButton() : ""}
        </div>
        <div className="content">{this.renderContent()}</div>
        {this.snippetModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  snippets: state.mainReducer.snippets,
  isFetching: state.mainReducer.isFetching,
  user: state.mainReducer.user,
  tags: state.adminReducer.allTags,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetSnippets: () => dispatch(adminGetSnippets()),
  adminCreateSnippet: (snippet, snippetToast, duplicate) =>
    dispatch(adminCreateSnippet(snippet, snippetToast, duplicate)),
  adminUpdateSnippet: (snippet, snippetToast, oldSnippet) =>
    dispatch(adminUpdateSnippet(snippet, snippetToast, oldSnippet)),
  adminDeleteSnippet: (snippet, snippetToast) =>
    dispatch(adminDeleteSnippet(snippet, snippetToast)),
  getAllTags: (company_id) => dispatch(getAllTags(company_id)),
  createNewTag: (company_id, tagName) =>
    dispatch(createNewTag(company_id, tagName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snippets);
