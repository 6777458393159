import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/en-gb"; // without this line it didn't work

import TrebleIcon from "../../../components/TrebleIcon/TrebleIcon";
import SelectDropdown from "./Components/SelectDropdown/SelectDropdown";
import MultipleSelectDropdown from "./Components/MultipleSelectDropdown/MultipleSelectDropdown";
import CalendarPicker from "./Components/CalendarPicker/CalendarPicker";

// Graphs
import Loading from "./Components/Graphs/Loading/Loading";
import ConversationCount from "./Components/Graphs/ConversationCount/ConversationCount";
import ResponseTime from "./Components/Graphs/ResponseTime/ResponseTime";
import Satisfaction from "./Components/Graphs/Satisfaction/Satisfaction";
import Heatmap from "./Components/Graphs/Heatmap/Heatmap";

import {
  getFilterData,
  getConversationCountData,
  getResponseTimeData,
  getSatisfactionData,
  getConversationHourData,
  getConversationDayHourData,
  getSatisfactionDateData,
} from "../../../actions/metricsAction";

import { SATISFACTION } from "../../../reducers/metricsReducer";

import events from "../../../utils/events";
import languages from "./languages";
import "./Metrics.scss";
import _ from "lodash";
import {
  CONVERSATION_COUNT,
  RESPONSE_TIME,
} from "../../../reducers/metricsReducer";

const TAGS = "TAGS";
const AGENTS = "AGENTS";
const LABELS = "LABELS";

moment.locale("en-GB");

class Metrics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectFilterType: TAGS,
      objectFilter: [],
      countriesFilter: [],
      dates: { start: "", end: "" },
    };

    this.strings = languages[props.language];
  }

  fetchAllData = (objectFilterType, objectFilter, countriesFilter, dates) => {
    this.props.getConversationCountData(
      objectFilterType,
      objectFilter,
      countriesFilter,
      dates,
      this.props.data[CONVERSATION_COUNT].timeGroup,
    );
    this.props.getResponseTimeData(
      objectFilterType,
      objectFilter,
      countriesFilter,
      dates,
      this.props.data[RESPONSE_TIME].timeGroup,
    );
    this.props.getSatisfactionData(
      objectFilterType,
      objectFilter,
      countriesFilter,
      dates,
      this.props.data[SATISFACTION].timeGroup,
    );
    this.props.getConversationHourData(
      objectFilterType,
      objectFilter,
      countriesFilter,
      dates,
    );
  };

  componentDidMount() {
    this.props.getFilterData(this.props.language);
    this.fetchAllData(
      this.state.objectFilterType,
      this.state.objectFilter,
      this.state.countriesFilter,
      this.state.dates,
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    const selector = document.getElementById("metrics-filters");

    if (scrolled > 0 && selector) {
      selector.classList.add("add-shadow");
    } else if (selector) {
      selector.classList.remove("add-shadow");
    }
  };

  resetFilters = () => {
    this.setState({
      objectFilterType: TAGS,
      countriesFilter: [],
      objectFilter: [],
      dates: { start: "", end: "" },
    });
    this.fetchAllData(TAGS, [], [], { start: "", end: "" });
  };

  renderObjectSubfilter = () => {
    let objectType = this.state.objectFilterType;
    if (objectType === TAGS) {
      return (
        <MultipleSelectDropdown
          searchable
          enableAll
          searchPlaceholder={this.strings.searchByTeam}
          options={this.props.filters.tags}
          placeholder={this.strings.allTeams}
          _key="tags-filter"
          onSelect={(option) => this.setState({ objectFilter: option })}
          value={this.state.objectFilter}
        />
      );
    } else if (objectType === AGENTS) {
      return (
        <SelectDropdown
          enableAll
          searchable
          searchPlaceholder={this.strings.searchByAgentEmail}
          options={this.props.filters.agents}
          placeholder={this.strings.allAgents}
          _key="agents-filter"
          onSelect={(option) => this.setState({ objectFilter: option })}
          value={this.state.objectFilter}
        />
      );
    } else if (objectType === LABELS) {
      return (
        <SelectDropdown
          enableAll
          searchable
          searchPlaceholder={this.strings.searchByLabel}
          options={this.props.filters.labels}
          placeholder={this.strings.allLabels}
          _key="labels-filter"
          onSelect={(option) => this.setState({ objectFilter: option })}
          value={this.state.objectFilter}
        />
      );
    }
  };

  renderCleanFilter = () => {
    if (
      this.state.objectFilterType !== TAGS ||
      this.state.countriesFilter.length ||
      (Array.isArray(this.state.objectFilter) &&
        this.state.objectFilter.length > 0) ||
      (!Array.isArray(this.state.objectFilter) &&
        this.state.objectFilter !== null) ||
      this.state.dates.start !== "" ||
      this.state.dates.end !== ""
    ) {
      return (
        <div className="icon icon--clean-filters" onClick={this.resetFilters} />
      );
    }
  };

  renderFilters = () => {
    return (
      <div className="filters" id="metrics-filters">
        <div className="main-filter">
          <p className="desktop">{this.strings.filterBy}</p>
          <SelectDropdown
            _key="object-filter"
            className="object-filter"
            options={[
              { label: this.strings.teams, value: TAGS },
              { label: this.strings.agents, value: AGENTS },
              { label: this.strings.labels, value: LABELS },
            ]}
            value={this.state.objectFilterType}
            onSelect={(option) =>
              this.setState({
                objectFilterType: option,
                objectFilter: null,
              })
            }
          />
        </div>
        <div className="secondary-filter">
          {this.renderObjectSubfilter()}
          <MultipleSelectDropdown
            enableAll
            searchable
            searchPlaceholder={this.strings.searchByCountry}
            options={this.props.filters.countries}
            placeholder={this.strings.allCountries}
            _key="countries-filter"
            onSelect={(option) => this.setState({ countriesFilter: option })}
            value={this.state.countriesFilter}
          />
          <div className="date-filter">
            <CalendarPicker
              value={this.state.dates}
              onChange={(dates) => {
                this.setState({ dates });
              }}
            />
          </div>
          {this.renderCleanFilter()}
          <div
            className="apply-filter"
            onClick={() => {
              this.fetchAllData(
                this.state.objectFilterType,
                this.state.objectFilter,
                this.state.countriesFilter,
                this.state.dates,
              );
              events.track("Agent changed metrics filter", {
                "Main filter": this.state.objectFilterType,
                "Secondary filter": this.state.objectFilter,
                Countries: this.state.countriesFilter,
                "Start date": this.state.dates.start,
                "End date": this.state.dates.end,
              });
            }}
          >
            {this.strings.applyFilters}
          </div>
        </div>
      </div>
    );
  };

  trackDownload = (metricType) => {
    console.log(metricType);
  };

  renderContent = () => {
    if (this.props.loading) {
      return <Loading text={this.strings.loadingMetrics} />;
    } else {
      return (
        <div className="content">
          {this.renderFilters()}
          <div className="graphs">
            <ConversationCount
              getConversationCountData={(timeGroup) =>
                this.props.getConversationCountData(
                  this.state.objectFilterType,
                  this.state.objectFilter,
                  this.state.countriesFilter,
                  this.state.dates,
                  timeGroup,
                )
              }
            />
            <ResponseTime
              getResponseTimeData={(timeGroup) =>
                this.props.getResponseTimeData(
                  this.state.objectFilterType,
                  this.state.objectFilter,
                  this.state.countriesFilter,
                  this.state.dates,
                  timeGroup,
                )
              }
            />
            <Satisfaction
              getSatisfactionData={(timeGroup) =>
                this.props.getSatisfactionData(
                  this.state.objectFilterType,
                  this.state.objectFilter,
                  this.state.countriesFilter,
                  this.state.dates,
                  timeGroup,
                )
              }
              getSatisfactionDateData={(timeGroup, date) =>
                this.props.getSatisfactionDateData(
                  this.state.objectFilterType,
                  this.state.objectFilter,
                  this.state.countriesFilter,
                  this.state.dates,
                  timeGroup,
                  date,
                )
              }
              objectFilter={this.state.objectFilterType}
              trackDownload={this.trackDownload}
            />
            <Heatmap
              getDayHourData={(dow, hour) =>
                this.props.getConversationDayHourData(
                  this.state.objectFilterType,
                  this.state.objectFilter,
                  this.state.countriesFilter,
                  dow,
                  hour,
                  this.state.dates,
                )
              }
              trackDownload={this.trackDownload}
            />
          </div>
        </div>
      );
    }
  };

  returnToChats = () => {
    window.location.href = `${process.env.PUBLIC_URL}/admin/chats`;
  };

  render() {
    return (
      <div className="metrics">
        <div className="desktop tablet">
          <div className="header">{this.strings.operationSummary}</div>
          {this.renderContent()}
        </div>
        <div className="mobile">
          <div
            className="icon-close icon icon--close"
            onClick={this.returnToChats}
          />
          <TrebleIcon name="no-metrics" />
          <p>{this.strings.mobileNotSupported}</p>
          <div className="return-button" onClick={this.returnToChats}>
            {this.strings.returnToChats}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  filters: state.metricsReducer.filters,
  loading: state.metricsReducer.loading,
  data: state.metricsReducer.data,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterData: (language) => dispatch(getFilterData(language)),
  getConversationCountData: (
    objectType,
    objectFilter,
    countriesFilter,
    dates,
    timeGroup,
  ) =>
    dispatch(
      getConversationCountData(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      ),
    ),
  getResponseTimeData: (
    objectType,
    objectFilter,
    countriesFilter,
    dates,
    timeGroup,
  ) =>
    dispatch(
      getResponseTimeData(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      ),
    ),
  getSatisfactionData: (
    objectType,
    objectFilter,
    countriesFilter,
    dates,
    timeGroup,
  ) =>
    dispatch(
      getSatisfactionData(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      ),
    ),
  getSatisfactionDateData: (
    objectType,
    objectFilter,
    countriesFilter,
    dates,
    timeGroup,
    date,
  ) =>
    dispatch(
      getSatisfactionDateData(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
        date,
      ),
    ),
  getConversationHourData: (objectType, objectFilter, countriesFilter, dates) =>
    dispatch(
      getConversationHourData(objectType, objectFilter, countriesFilter, dates),
    ),
  getConversationDayHourData: (
    objectType,
    objectFilter,
    countriesFilter,
    dow,
    hour,
    dates,
  ) =>
    dispatch(
      getConversationDayHourData(
        objectType,
        objectFilter,
        countriesFilter,
        dow,
        hour,
        dates,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
