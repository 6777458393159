import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import "./MultipleSelectDropdown.scss";

/*
	props:
		- options list(obj): options to be displayed
    - selectedOptions list(obj): selected options
		- noSelectionTitle str: text to be displayed when empty
		- display function(obj) -> str: a map between an object an its display
		- onSelect function([obj]): to be called when an option is clicked, it calls all the clicked objects
*/

class MultipleSelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedOptions:
        (Array.isArray(this.props.selectedOptions) &&
          this.props.selectedOptions) ||
        [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOptions !== this.props.selectedOptions) {
      this.setState({ selectedOptions: this.props.selectedOptions });
    }
  }

  handleClickOutside = (_) => this.setState({ open: false });

  selectOption = (option, trigger) => {
    let selectedOptions = this.state.selectedOptions;

    let optionIndex = this.state.selectedOptions.indexOf(option);
    if (optionIndex === -1) {
      selectedOptions.push(option);
    } else {
      selectedOptions.splice(optionIndex, 1);
    }

    this.setState({
      selectedOptions: selectedOptions,
    });

    trigger(selectedOptions);
  };

  displayTitle = () => {
    const { selectedOptions } = this.state;
    return selectedOptions.length > 0
      ? selectedOptions.map((e) => this.props.display(e)).join(", ")
      : this.props.noSelectionTitle;
  };

  render = () => {
    console.log(this.state.selectedOptions);

    let onSelect = this.props.onSelect;

    if (typeof onSelect !== "function") onSelect = () => {};

    return (
      <div
        className={`dropdown ${
          this.state.open ? "is-active" : ""
        } purple-dropdown multiple-select-dropdown`}
      >
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p>{this.displayTitle()}</p>
            {!this.props.iconRow ? (
              <span className="icon is-small">
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            ) : (
              <div className={`icon icon--${this.props.iconRow}`}></div>
            )}
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {this.props.options.map((e) => {
              const active =
                this.state.selectedOptions.indexOf(e) !== -1 ? "active" : "";

              return (
                <div
                  key={this.props.options.indexOf(e)}
                  className="dropdown-item"
                  onClick={() => this.selectOption(e, onSelect)}
                >
                  <p>{this.props.display(e)}</p>
                  <div className={`ico-check ${active}`} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
}

export default onClickOutside(MultipleSelectDropdown);
