import React, { Component } from "react";
import { Transition } from "react-transition-group";
import "./Panel.scss";

class Panel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  toggle(visible) {
    this.setState({ visible });
    if (
      this.props.onClose !== undefined &&
      typeof this.props.onClose === "function" &&
      visible === false
    ) {
      this.props.onClose();
    }
  }

  grayPanelAreaClick = (e) => {
    if (e.target.id === "panel-container") {
      this.toggle(false);
    }
  };

  render() {
    const duration = 400;
    const panelWidth = 488;

    const defaultStyle = {
      transition: `right ${duration}ms ease-out`,
      right: -panelWidth,
    };

    const transitionStyles = {
      entering: { right: -panelWidth },
      entered: { right: 0 },
      exiting: { right: -panelWidth },
      exited: { right: -panelWidth },
    };

    return (
      <Transition
        in={this.state.visible}
        timeout={{
          appear: 0,
          enter: 0,
          exit: duration,
        }}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className="panel-container"
            id="panel-container"
            onClick={(e) => this.grayPanelAreaClick(e)}
          >
            <div
              className="panel"
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <div className="panel-navbar">
                <i
                  className="chevron-left"
                  onClick={() => this.toggle(false)}
                ></i>
                <h1>{this.props.title}</h1>
              </div>
              {this.props.children}
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default Panel;
