import { request } from "../utils/request";
import { getChats } from "./agentAction";
import { clearError } from "./userAction";
import { toast } from "react-toastify";
import events from "../utils/events";

var mime = require("mime-types");

// Alert response progress
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

// Alert states
export const LABEL_RESPONSE = "LABEL_RESPONSE";

const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const RECEIVE_CLEAN_HISTORY = "RECEIVE_CLEAN_HISTORY";
export const receiveCleanHistory = () => (dispatch) => {
  dispatch({
    type: RECEIVE_CLEAN_HISTORY,
  });
};

export const emptyHistory = () => (dispatch) => {
  dispatch(receiveCleanHistory());
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// SELECT CONVERSATION

export const selectChat =
  (conversationId, contactId, channelId) => (dispatch) => {
    dispatch(requestSelectChat(conversationId));
    if (conversationId !== null) {
      dispatch(sendSeen(conversationId));
    }

    dispatch(getConversationHistory(contactId, channelId, true));
  };

const REQUEST_SELECT_CHAT = "REQUEST_SELECT_CHAT";
export const requestSelectChat = (conversationId) => (dispatch) => {
  dispatch({
    type: REQUEST_SELECT_CHAT,
    conversationId,
  });
};

// UPLOAD FILES TO S3
const REQUEST_UPLOAD_FILE = "REQUEST_UPLOAD_FILE";
export const requestUploadFile = () => (dispatch) => {
  dispatch({
    type: REQUEST_UPLOAD_FILE,
  });
};

export const uploadToS3 =
  (file, onLoad, uploadFinish, sendToTarget) => (dispatch) => {
    const fileName = file.name;
    const contentType = file.type;

    dispatch(requestUploadFile());

    request(
      "GET",
      `/agent/message/signed_url?objectName=${fileName}&contentType=${contentType}`,
      null,
      true,
      (data) => {
        const signedUrl = data.signedUrl;
        onLoad();
        fetch(signedUrl, {
          method: "PUT",
          headers: {
            "x-amz-acl": "public-read",
            "Content-type": contentType,
          },
          body: file,
        })
          .then((res) => {
            uploadFinish();
            sendToTarget(signedUrl);
          })
          .catch((err) => {
            uploadFinish();
            console.log(err);
          });
      },
      (error) => {
        uploadFinish();
        console.log(error);
      },
    );
  };

// SEND REPLY MESSAGE
export const sendReplyMessage =
  (
    conversationId,
    message,
    replyProviderMsgId,
    replyMessage,
    isResent = false,
  ) =>
  (dispatch) => {
    const temporalId = generateGUID();
    dispatch(
      requestSendMessage(conversationId, message, temporalId, replyMessage),
    );
    dispatch(sendSeen(conversationId));
    const params = {
      text: message,
      reply_provider_msg_id: replyProviderMsgId,
    };

    request(
      "POST",
      `/agent/message/send/reply/${conversationId}`,
      params,
      true,
      (data) => {
        if (isResent) events.track("Message re-sent successfully");
        return dispatch(
          sendMessageSuccess(conversationId, data.id, temporalId),
        );
      },
      (error) => {
        console.log(error);
        events.track("Failed to send message");
        dispatch(sendMessageError(conversationId, temporalId, error));
        return setTimeout(() => dispatch(clearError()), 5000);
      },
      false,
      false,
    );
  };

// SEND MESSAGE

const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const sendMessageSuccess =
  (conversationId, providerMsgId, temporalId) => (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_SUCCESS,
      conversationId,
      providerMsgId,
      temporalId,
    });
  };

const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const sendMessageError =
  (conversationId, temporalId, error) => (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_ERROR,
      conversationId,
      temporalId,
      error,
    });
  };

const REQUEST_SEND_MESSAGE = "REQUEST_SEND_MESSAGE";
export const requestSendMessage =
  (conversationId, message, temporalId, replyMessage = null) =>
  (dispatch) => {
    dispatch({
      type: REQUEST_SEND_MESSAGE,
      conversationId,
      message,
      temporalId,
      replyMessage,
    });
  };

export const sendMessage =
  (conversationId, message, isResent = false) =>
  (dispatch) => {
    const temporalId = generateGUID();
    dispatch(requestSendMessage(conversationId, message, temporalId));
    dispatch(sendSeen(conversationId));
    const params = {
      text: message,
    };

    request(
      "POST",
      `/agent/message/send/${conversationId}`,
      params,
      true,
      (data) => {
        if (isResent) events.track("Message re-sent successfully");
        return dispatch(
          sendMessageSuccess(conversationId, data.id, temporalId),
        );
      },
      (error) => {
        console.log(error);
        events.track("Failed to send message");
        dispatch(sendMessageError(conversationId, temporalId, error));
        return setTimeout(() => dispatch(clearError()), 5000);
      },
      false,
      false,
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// SEND MEDIA

const REQUEST_SEND_MEDIA = "REQUEST_SEND_MEDIA";
export const requestSendMedia =
  (conversationId, mediaUrl, mediaType, temporalId) => (dispatch) => {
    dispatch({
      type: REQUEST_SEND_MEDIA,
      conversationId,
      mediaUrl,
      mediaType,
      temporalId,
    });
  };

const SEND_MEDIA_SUCCESS = "SEND_MEDIA_SUCCESS";
export const sendMediaSuccess =
  (conversationId, providerMsgId, temporalId) => (dispatch) => {
    dispatch({
      type: SEND_MEDIA_SUCCESS,
      conversationId,
      providerMsgId,
      temporalId,
    });
  };

const SEND_MEDIA_ERROR = "SEND_MEDIA_ERROR";
export const sendMediaError =
  (conversationId, temporalId, error) => (dispatch) => {
    dispatch({
      type: SEND_MEDIA_ERROR,
      conversationId,
      temporalId,
      error,
    });
  };

const getMediaType = (mediaUrl) => {
  let mediaType;
  try {
    let fileMime = mime.lookup(mediaUrl);
    mediaType = fileMime.split("/")[0];
    if (mediaType === "application" || mediaType === "text") {
      mediaType = "document";
    }
  } catch (error) {
    mediaType = "document";
    console.log(error);
  }
  return mediaType;
};

export const sendMedia =
  (conversationId, mediaUrl, isResent = false, contactId = null) =>
  (dispatch) => {
    let mediaType = getMediaType(mediaUrl);

    const temporalId = generateGUID();
    dispatch(requestSendMedia(conversationId, mediaUrl, mediaType, temporalId));

    dispatch(sendSeen(conversationId));
    const params = {
      conversation_id: conversationId,
      objectName: mediaUrl,
    };

    request(
      "POST",
      `/agent/message/media`,
      params,
      true,
      (data) => {
        if (isResent) events.track("Message re-sent successfully");
        if (contactId) dispatch(getFiles(contactId));
        return dispatch(sendMediaSuccess(conversationId, data.id, temporalId));
      },
      (error) => {
        console.log(error);
        events.track("Failed to send message");
        dispatch(sendMessageError(conversationId, temporalId, error));
        return setTimeout(() => dispatch(clearError()), 5000);
      },
      false,
      false,
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// SEND HSM

const SEND_HSM_SUCCESS = "SEND_HSM_SUCCESS";
export const sendHSMSuccess =
  (conversationId, providerMsgId, temporalId) => (dispatch) => {
    dispatch({
      type: SEND_HSM_SUCCESS,
      conversationId,
      providerMsgId,
      temporalId,
    });
  };

const SEND_HSM_ERROR = "SEND_HSM_ERROR";
export const sendHSMError =
  (conversationId, temporalId, error) => (dispatch) => {
    dispatch({
      type: SEND_HSM_ERROR,
      conversationId,
      temporalId,
      error,
    });
  };

const REQUEST_SEND_HSM = "REQUEST_SEND_HSM";
export const requestSendHSM =
  (conversationId, header, content, footer, buttons, temporalId) =>
  (dispatch) => {
    dispatch({
      type: REQUEST_SEND_HSM,
      conversationId,
      header,
      content,
      footer,
      buttons,
      temporalId,
    });
  };

const REQUEST_SEND_HSM_CONVERSATION = "REQUEST_SEND_HSM_CONVERSATION";
export const requestSendHSMConversation =
  (contact, header, content, footer, buttons) => (dispatch) => {
    dispatch({
      type: REQUEST_SEND_HSM_CONVERSATION,
      contact,
      header,
      content,
      footer,
      buttons,
    });
  };

const SEND_HSM_CONVERSATION_ERROR = "SEND_HSM_CONVERSATION_ERROR";
export const sendHSMConversationError = (contact, error) => (dispatch) => {
  dispatch({
    type: SEND_HSM_CONVERSATION_ERROR,
    contact,
    error,
  });
};

export const sendHSM =
  (conversationId, name, parameters, header, content, footer, buttons) =>
  (dispatch) => {
    const temporalId = generateGUID();
    dispatch(
      requestSendHSM(
        conversationId,
        header,
        content,
        footer,
        buttons,
        temporalId,
      ),
    );
    dispatch(sendSeen(conversationId));
    const params = {
      conversation_id: conversationId,
      hsm: {
        name: name,
        parameters: parameters,
        header: header,
        content: content,
        footer: footer,
        buttons: buttons,
      },
    };

    request(
      "POST",
      `/agent/hsm`,
      params,
      true,
      (data) => {
        return dispatch(sendHSMSuccess(conversationId, data.id, temporalId));
      },
      (error) => {
        console.log(error);
        dispatch(sendHSMError(conversationId, temporalId, error));
        return setTimeout(() => dispatch(clearError()), 5000);
      },
      false,
      false,
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// SEND HSM CONVERSATION

export const sendHSMConversation =
  (contact, poll_id, name, parameters, header, content, footer, buttons) =>
  (dispatch) => {
    const whole_cellphone = contact.whole_cellphone.split(" ");
    const country_code = whole_cellphone[0];
    const cellphone = whole_cellphone[1];
    const params = {
      whole_cellphone: { country_code, cellphone },
      hsm: { poll_id, name, parameters },
    };
    dispatch(
      requestSendHSMConversation(contact, header, content, footer, buttons),
    );
    request(
      "POST",
      `/agent/hsm/conversation`,
      params,
      true,
      (data) => {
        toast("¡Hsm enviado!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
        console.log(data);
      },
      (error) => {
        console.log(error);
        dispatch(sendHSMConversationError(contact, error));
        return setTimeout(() => dispatch(clearError()), 5000);
      },
      false,
      false,
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// SEND SEEN CONVERSATION

const SEND_SEEN_ERROR = "SEND_SEEN_ERROR";
export const sendSeenError = (error) => (dispatch) => {
  dispatch({
    type: SEND_SEEN_ERROR,
    error,
  });
};

const SEND_SEEN_SUCCESS = "SEND_SEEN_SUCCESS";
export const sendSeenSuccess = (conversationId) => (dispatch) => {
  dispatch({
    type: SEND_SEEN_SUCCESS,
    conversationId,
  });
};

export const sendSeen = (conversationId) => (dispatch) => {
  request(
    "PUT",
    `/agent/message/read/${conversationId}`,
    {},
    true,
    (data) => {
      return dispatch(sendSeenSuccess(conversationId));
    },
    (error) => {
      console.log(error);
      return dispatch(sendSeenError("Unable to read"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// END CONVERSATION

const REQUEST_END_CONVERSATION = "REQUEST_END_CONVERSATION";
export const requestEndConversation = () => (dispatch) => {
  dispatch({
    type: REQUEST_END_CONVERSATION,
  });
};

const END_CONVERSATION_ERROR = "END_CONVERSATION_ERROR";
export const endConversationError = (error) => (dispatch) => {
  dispatch({
    type: END_CONVERSATION_ERROR,
    error,
  });
  setTimeout(() => {
    dispatch(clearError());
  }, 3000);
};

const END_CONVERSATION_SUCCESS = "END_CONVERSATION_SUCCESS";
export const endConversationSuccess = () => (dispatch) => {
  dispatch({
    type: END_CONVERSATION_SUCCESS,
  });
};

export const endConversation = (conversationIds, extra) => (dispatch) => {
  dispatch(requestEndConversation());
  request(
    "PUT",
    `/agent/conversation/finish`,
    {
      conversation_ids: conversationIds,
      extra,
    },
    true,
    (data) => {
      return dispatch(endConversationSuccess());
    },
    (error) => {
      console.log(error);
      return dispatch(endConversationError("Unable to finish conversation"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// REQUEST AGENTS

const REQUEST_AGENTS = "REQUEST_AGENTS";
export const requestAgents = () => (dispatch) => {
  dispatch({
    type: REQUEST_AGENTS,
  });
};

const RECEIVE_AGENTS = "RECEIVE_AGENTS";
export const receiveAgents = (agents) => (dispatch) => {
  dispatch({
    type: RECEIVE_AGENTS,
    agents,
  });
};

const REQUEST_AGENTS_ERROR = "REQUEST_AGENTS_ERROR";
export const requestAgentsError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_AGENTS_ERROR,
    error,
  });
};

export const getAgents = () => (dispatch) => {
  dispatch(requestAgents());

  request(
    "GET",
    "/agent/available/all",
    null,
    true,
    (data) => {
      return dispatch(receiveAgents(data));
    },
    (error) => {
      console.log(error);
      return dispatch(requestAgentsError("Unable to get agents"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// TRANSFER CHAT

const REQUEST_CHAT_TRANSFER = "REQUEST_CHAT_TRANSFER";
export const requestChatTransfer = () => (dispatch) => {
  dispatch({
    type: REQUEST_CHAT_TRANSFER,
  });
};

const CHAT_TRANSFER_SUCCESS = "CHAT_TRANSFER_SUCCESS";
export const chatTransferSuccess = () => (dispatch) => {
  dispatch({
    type: CHAT_TRANSFER_SUCCESS,
  });
};

const CHAT_TRANSFER_ERROR = "CHAT_TRANSFER_ERROR";
export const chatTransferError = (error) => (dispatch) => {
  dispatch({
    type: CHAT_TRANSFER_ERROR,
    error,
  });
  setTimeout(() => {
    dispatch(clearError());
  }, 3000);
};

export const transferChats =
  (conversationIds, agentId, categoryId) => (dispatch) => {
    dispatch(requestChatTransfer());
    request(
      "PUT",
      `/agent/conversation/redirect`,
      {
        conversation_ids: conversationIds,
        tag_id: categoryId,
        agent_id: agentId,
      },
      true,
      (data) => {
        dispatch(chatTransferSuccess());
        return dispatch(getChats());
      },
      (error) => {
        console.log(error);
        return dispatch(chatTransferError("Unable to transfer chat"));
      },
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// ASSIGN CHAT

const REQUEST_CHAT_ASSIGN = "REQUEST_CHAT_ASSIGN";
export const requestChatAssign = () => (dispatch) => {
  dispatch({
    type: REQUEST_CHAT_ASSIGN,
  });
};

const CHAT_ASSIGN_SUCCESS = "CHAT_ASSIGN_SUCCESS";
export const chatAssignSuccess = () => (dispatch) => {
  dispatch({
    type: CHAT_ASSIGN_SUCCESS,
  });
};

const CHAT_ASSIGN_ERROR = "CHAT_ASSIGN_ERROR";
export const chatAssignError = (error) => (dispatch) => {
  dispatch({
    type: CHAT_ASSIGN_ERROR,
    error,
  });
  setTimeout(() => {
    dispatch(clearError());
  }, 3000);
};

export const assignChats =
  (conversationIds, agentId, categoryId) => (dispatch) => {
    dispatch(requestChatAssign());
    request(
      "PUT",
      `/agent/conversation/assign`,
      {
        conversation_ids: conversationIds,
        tag_id: categoryId,
        agent_id: agentId,
      },
      true,
      (data) => {
        dispatch(chatAssignSuccess());
        return dispatch(getChats());
      },
      (error) => {
        console.log(error);
        return dispatch(chatAssignError("Unable to assign chat"));
      },
      true,
      false,
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// CREATE NOTE

const NOTE_CREATED_SUCCESS = "NOTE_CREATED_SUCCESS";
export const createNoteSuccess =
  (content, conversationId, noteId, name) => (dispatch) => {
    dispatch({
      type: NOTE_CREATED_SUCCESS,
      content,
      conversationId,
      noteId,
      name,
    });
  };

const CREATE_NOTE_ERROR = "CREATE_NOTE_ERROR";
export const createNoteError = (error) => (dispatch) => {
  dispatch({
    type: CREATE_NOTE_ERROR,
    error,
  });
};

export const createNote = (content, conversationId, name) => (dispatch) => {
  const params = {
    content,
    conversation_id: conversationId,
  };

  request(
    "POST",
    `/agent/note`,
    params,
    true,
    (data) => {
      return dispatch(
        createNoteSuccess(content, conversationId, data.id, name),
      );
    },
    (error) => {
      console.log(error);
      return dispatch(createNoteError("Unable to create note"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// REQUEST CURRENT CONVERSATION HISTORY

const SELECT_CONTACT = "SELECT_CONTACT";
const selectContact = (contactId) => (dispatch) => {
  dispatch({
    type: SELECT_CONTACT,
    contactId,
  });
};

const REQUEST_CONVERSATION_HISTORY_CELLPHONE =
  "REQUEST_CONVERSATION_HISTORY_CELLPHONE";
export const requestConversationHistory = (firstTime) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_HISTORY_CELLPHONE,
    firstTime,
  });
};

const RECEIVE_CONVERSATION_HISTORY_CELLPHONE =
  "RECEIVE_CONVERSATION_HISTORY_CELLPHONE";
export const receiveConversationHistory =
  (history, notes, firstTime, contactId) => (dispatch) => {
    dispatch({
      type: RECEIVE_CONVERSATION_HISTORY_CELLPHONE,
      history,
      notes,
      hasMoreHistory: firstTime,
      contactId,
    });
  };

const REQUEST_CONVERSATION_HISTORY_CELLPHONE_ERROR =
  "REQUEST_CONVERSATION_HISTORY_CELLPHONE_ERROR";
export const requestConversationHistoryError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_HISTORY_CELLPHONE_ERROR,
    error,
  });
};

export const getConversationHistory =
  (contactId, channelId, firstTime) => (dispatch) => {
    dispatch(requestConversationHistory(firstTime));
    dispatch(selectContact(contactId));

    request(
      "GET",
      `/agent/contact/${contactId}/history?first=${firstTime}&channel=${channelId}`,
      null,
      true,
      (data) => {
        return dispatch(
          receiveConversationHistory(
            data.events,
            data.notes,
            firstTime,
            contactId,
          ),
        );
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestConversationHistoryError("Unable to get conversation history"),
        );
      },
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// CHAT LABELS

const RECEIVE_CHAT_LABELS = "RECEIVE_CHAT_LABELS";
export const receiveChatLabels = (chatLabels) => (dispatch) => {
  dispatch({
    type: RECEIVE_CHAT_LABELS,
    chatLabels,
  });
};

const RECEIVE_SELECTED_CHAT_LABELS = "RECEIVE_SELECTED_CHAT_LABELS";
export const receiveSelectedChatLabels =
  (chatLabels, conversationId) => (dispatch) => {
    dispatch({
      type: RECEIVE_SELECTED_CHAT_LABELS,
      chatLabels,
      conversationId,
    });
  };

const REMOVE_LABEL_ALL_CHATS = "REMOVE_LABEL_ALL_CHATS";
export const removeLabelAllChats = (labelId) => (dispatch) => {
  dispatch({
    type: REMOVE_LABEL_ALL_CHATS,
    labelId,
  });
};

const UPDATE_LABEL_ALL_CHATS = "UPDATE_LABEL_ALL_CHATS";
export const updateLabelAllChats = (labelId, labelInfo) => (dispatch) => {
  dispatch({
    type: UPDATE_LABEL_ALL_CHATS,
    labelId,
    labelInfo,
  });
};

const CHAT_LABELS_ERROR = "CHAT_LABELS_ERROR";
export const chatLabelsError = (error) => (dispatch) => {
  dispatch({
    type: CHAT_LABELS_ERROR,
    error,
  });
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// GET CHAT LABEL

export const getChatLabels = () => (dispatch) => {
  request(
    "GET",
    "/agent/conversation/labels",
    null,
    true,
    (data) => {
      const chatLabels = data.labels;
      return dispatch(receiveChatLabels(chatLabels));
    },
    (error) => {
      console.log(error);
      return dispatch(chatLabelsError("Unable to get chat labels"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// CREATE CHAT LABEL

export const createChatLabel = (labelInfo) => (dispatch) => {
  request(
    "POST",
    "/agent/conversation/label",
    labelInfo,
    true,
    (data) => {
      const chatLabels = data.labels;
      return dispatch(receiveChatLabels(chatLabels));
    },
    (error) => {
      console.log(error);
      return dispatch(chatLabelsError("Unable to create chat label"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// update alertState

const UPDATE_ALERT_STATE = "UPDATE_ALERT_STATE";
export const updateAlertState = (name, progress) => (dispatch) => {
  dispatch({
    type: UPDATE_ALERT_STATE,
    name,
    progress,
  });
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// DELETE CHAT LABEL

export const deleteChatLabel = (labelId) => (dispatch) => {
  dispatch(updateAlertState(LABEL_RESPONSE, LOADING));
  request(
    "DELETE",
    `/agent/conversation/label/${labelId}`,
    null,
    true,
    (data) => {
      const chatLabels = data.labels;
      dispatch(receiveChatLabels(chatLabels));
      dispatch(updateAlertState(LABEL_RESPONSE, SUCCESS));
      return dispatch(removeLabelAllChats(labelId));
    },
    (error) => {
      console.log(error);
      dispatch(updateAlertState(LABEL_RESPONSE, ERROR));
      return dispatch(chatLabelsError("Unable to delete chat label"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// EDIT CHAT LABEL

export const editChatLabel = (labelInfo, labelId) => (dispatch) => {
  request(
    "PUT",
    `/agent/conversation/label/${labelId}`,
    labelInfo,
    true,
    (data) => {
      const chatLabels = data.labels;
      dispatch(receiveChatLabels(chatLabels));
      return dispatch(
        updateLabelAllChats(labelId, { id: labelId, ...labelInfo }),
      );
    },
    (error) => {
      console.log(error);
      return dispatch(chatLabelsError("Unable to update chat label"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// TOGGLE CHAT LABEL

export const toggleChatLabel = (labelId, conversationId) => (dispatch) => {
  request(
    "POST",
    `/agent/conversation/${conversationId}/label/${labelId}`,
    null,
    true,
    (data) => {
      const chatLabels = data.labels;
      return dispatch(receiveSelectedChatLabels(chatLabels, conversationId));
    },
    (error) => {
      console.log(error);
      return dispatch(
        chatLabelsError(
          "Unable to add/remove chat label. Please refresh the page",
        ),
      );
    },
    false,
    false,
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// GET FILES AND DOCS

const REQUEST_FILES = "REQUEST_FILES";
const requestFiles = () => (dispatch) => {
  dispatch({
    type: REQUEST_FILES,
  });
};

const FILES_SUCCESS = "FILES_SUCCESS";
const filesSuccess = (files) => (dispatch) => {
  dispatch({
    type: FILES_SUCCESS,
    files,
  });
};

const FILES_ERROR = "FILES_ERROR";
const filesError = (error) => (dispatch) => {
  dispatch({
    type: FILES_ERROR,
    error,
  });
};

export const getFiles = (contactId) => (dispatch) => {
  dispatch(requestFiles());

  request(
    "GET",
    `/agent/contact/${contactId}/files`,
    null,
    true,
    (data) => {
      return dispatch(filesSuccess(data));
    },
    (error) => {
      console.log(error);
      return dispatch(filesError(error));
    },
    true,
    false,
  );
};

// FULL SCREEN

const SET_FULL_SCREEN = "SET_FULL_SCREEN";
export const setFullScreen = (value) => (dispatch) => {
  dispatch({
    type: SET_FULL_SCREEN,
    value,
  });
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// TEAMS

const AGENT_REQUEST_TAGS = "AGENT_REQUEST_TAGS";
export const agentRequestTags = () => (dispatch) => {
  dispatch({
    type: AGENT_REQUEST_TAGS,
  });
};

const AGENT_TAGS_ERROR = "AGENT_TAGS_ERROR";
export const agentTagsError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_TAGS_ERROR,
    error,
  });
};

const AGENT_RECEIVE_TAGS = "AGENT_RECEIVE_TAGS";
export const agentReceiveTags = (tags) => (dispatch) => {
  dispatch({
    type: AGENT_RECEIVE_TAGS,
    tags,
  });
};

export const agentGetTags = (companyId) => (dispatch) => {
  dispatch(agentRequestTags());
  request(
    "GET",
    `/agent/company/${companyId}/tags`,
    null,
    false,
    (data) => {
      return dispatch(agentReceiveTags(data));
    },
    (error) => {
      console.log(error);
      return dispatch(agentTagsError(error));
    },
  );
};
