const loader = {
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 0,
      ty: 4,
      nm: "Shape Layer 1",
      ks: {
        o: { k: 100 },
        r: { k: 0 },
        p: { k: [20.000000000000014, 20.000000000000014, 0] },
        a: { k: [0, 0, 0] },
        s: { k: [16.266666666666676, 16.266666666666676, 100] },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: "el",
          s: { k: [100, 100] },
          p: { k: [0, 0] },
          nm: "Ellipse Path 1",
          mn: "ADBE Vector Shape - Ellipse",
        },
        {
          ty: "tm",
          s: {
            k: [
              {
                i: { x: [0.439], y: [1.016] },
                o: { x: [0.561], y: [0.015] },
                n: ["0p439_1p016_0p561_0p015"],
                t: 5,
                s: [100],
                e: [0],
              },
              { t: 33.0000013441176 },
            ],
            ix: 1,
          },
          e: {
            k: [
              {
                i: { x: [0.439], y: [1.017] },
                o: { x: [0.561], y: [0.016] },
                n: ["0p439_1p017_0p561_0p016"],
                t: 0,
                s: [100],
                e: [0],
              },
              { t: 30.0000012219251 },
            ],
            ix: 2,
          },
          o: { k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
        },
        {
          ty: "st",
          fillEnabled: true,
          c: { k: [0, 0, 0, 1] },
          o: { k: 100 },
          w: { k: 16 },
          lc: 2,
          lj: 1,
          ml: 4,
          nm: "Stroke 1",
          mn: "ADBE Vector Graphic - Stroke",
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
      sr: 1,
    },
  ],
  v: "4.5.3",
  ddd: 0,
  ip: 1.00000004073083,
  op: 30,
  fr: 30,
  w: 40,
  h: 40,
};

export default loader;
