import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

/*
	props:
    - className
    - title str|ReactComponent: title to be displayed
    - iconRow str: icon of arrow
    - hideOnClick bool: Whether to hide body on click
    - alwaysActive bool: never close only if is clicked on button
*/

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.alwaysActive !== this.props.alwaysActive) {
      this.setState({ open: this.props.alwaysActive });
    }
  };

  handleClickOutside = (_) => {
    if (!this.props.alwaysActive) this.setState({ open: false });
  };

  render = () => {
    console.log(this.state.selectedOptions);

    let onSelect = this.props.onSelect;
    let onBodyClick = () => {};

    if (this.props.hideOnClick === true)
      onBodyClick = () => this.setState({ open: !this.state.open });

    if (typeof onSelect !== "function") onSelect = () => {};

    return (
      <div
        className={`dropdown ${this.state.open ? "is-active" : ""} ${
          this.props.className ? this.props.className : ""
        } purple-dropdown`}
      >
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            {this.props.title}
            {!this.props.iconRow ? (
              <span className="icon is-small">
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            ) : (
              <div className={`icon icon--${this.props.iconRow}`}></div>
            )}
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content" onClick={onBodyClick}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  };
}

export default onClickOutside(Dropdown);
