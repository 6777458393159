const SHOW_NOTIFICATION = "NOTIFICATION";

// notification types
export const NOTIFICATION_NEW_CONVERSATION = "NOTIFICATION_NEW_CONVERSATION";
export const NOTIFICATION_NEW_MESSAGE = "NOTIFICATION_NEW_MESSAGE";

const NEW_CONVERSATION_TITLE = "Nueva conversación";

function contact_identifier(conversation) {
  return conversation.contact.name !== null
    ? conversation.contact.name
    : conversation.contact.whole_cellphone;
}

const getPreviewFromMessage = (message) => {
  let message_text = "";
  switch (message.type) {
    case "text":
      message_text = message.text.body;
      break;
    case "hsm":
      message_text = message.hsm.body;
    case "image":
      message_text = "Imagen";
      break;
    case "audio":
      message_text = "Audio";
      break;
    case "video":
      message_text = "Video";
      break;
    default:
      message_text = "Archivo";
  }
  return message_text;
};

export const notifyNewConversation = (conversation) => (dispatch) => {
  dispatch({
    type: SHOW_NOTIFICATION,
    title: NEW_CONVERSATION_TITLE,
    message:
      contact_identifier(conversation) +
      ": " +
      getPreviewFromMessage(conversation.last_message),
  });
};

export const notifyNewMessage = (conversation, message) => (dispatch) => {
  let message_text = getPreviewFromMessage(message);
  dispatch({
    type: SHOW_NOTIFICATION,
    title: contact_identifier(conversation),
    message: message_text,
  });
};
