import React, { Component } from "react";

import "./ModalV2.scss";

export class ModalV2 extends Component {
  /**
   *
   * @param {*} props
   * wrappClass: classname
   * header: str | component
   * onClose: function
   * closeIcon: component (optional)
   * body: str | component
   * footer: str | component
   * show: boolean
   */

  constructor(props) {
    super(props);
  }

  getIcon = () => {
    return this.props.closeIcon ? this.props.closeIcon : "close";
  };

  closeModal = () => {
    this.props.onClose();
  };

  renderModal = () => {
    return (
      <div className={`modalv2 ${this.props.wrappClass}`}>
        <div className="overlay" onClick={() => this.closeModal()} />
        <div className="modal-content">
          <div className="close">
            <div
              className={`icon-close icon icon--${this.getIcon()}`}
              onClick={() => this.closeModal()}
            />
          </div>
          <div className="modal-header">{this.props.header}</div>
          <div className="modal-body">{this.props.body}</div>
          <div className="modal-footer">{this.props.footer}</div>
        </div>
      </div>
    );
  };

  render() {
    return <>{this.props.show ? this.renderModal() : ""}</>;
  }
}

export default ModalV2;
