import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

/*
	props:
		- options list(str): options to be displayed
		= onSelect function: to be called when an option is clicked
		- onSearch function: to be called when typing in searchbox 
*/

class SearchDropdown extends Component {
  constructor(props) {
    super(props);

    let options = this.props.options;

    this.state = {
      open: this.props.open || false,
      options: options,
      selectedOption: options.length ? options[0] : "",
      searchValue: "",
    };
  }

  handleClickOutside = (_) => this.setState({ open: false });

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({ searchValue: this.props.searchValue });
    }
    if (prevProps.selectedOption !== this.props.selectedOption) {
      this.setState({ selectedOption: this.props.selectedOption });
    }
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  selectOption = (option, trigger) => {
    this.setState({
      selectedOption: option,
      open: false,
    });

    trigger(option);
  };

  render = () => {
    let onSearch = this.props.onSearch;
    let onSelect = this.props.onSelect;

    if (typeof onSearch !== "function") onSearch = () => {};
    if (typeof onSelect !== "function") onSelect = () => {};

    return (
      <div className="dropdown-search">
        <div
          className={`${
            this.state.open ? "is-active" : ""
          } dropdown purple-dropdown options-dropdown`}
        >
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <p>{this.state.selectedOption}</p>
              <span className="icon is-small">
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {this.state.options.map((option) => (
                <div
                  className="dropdown-item"
                  onClick={() => this.selectOption(option, onSelect)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        </div>
        <input
          className="input"
          type="text"
          placeholder={this.props.placeholder}
          onChange={(e) => onSearch(e.target.value)}
          value={this.state.searchValue}
        />
      </div>
    );
  };
}

export default onClickOutside(SearchDropdown);
