import React from "react";

export const contentReplyMessage = (message) => {
  let text, icon, img;
  if (message.type == "text") {
    text = message.text.body;
  } else if (message.type == "hsm") {
    text = message.hsm.body;
  } else if (message.type == "document") {
    icon = <div className="icon icon--file-message"></div>;
    text = message.document.filename;
  } else if (message.type == "audio") {
    icon = <div className="icon icon--record"></div>;
  } else if (message.type == "image") {
    icon = <div className="icon icon--image"></div>;
    text = message.image.caption;
    img = <img className="image-reply-box" src={message.image.url} />;
  } else if (message.type == "video") {
    icon = <div className="icon icon--video"></div>;
    text = message.video.caption;
    img = <video src={message.video.url} className="image-reply-box" />;
  } else if (message.type == "contacts") {
    icon = <div className="icon icon--contact-message"></div>;
    text = message.contacts.map((contact) => contact.name).join(", ");
  } else {
    icon = <div className="icon not-found-icon"></div>;
    text = message.text;
  }
  if (!text) text = "";
  if (!icon) icon = "";
  if (!img) img = "";
  return { text, icon, img };
};
