import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import "./HoverableButton.scss";
/*
	props:
		- title str|ReactComponent: title to be displayed
		- hideOnClick bool: Whether to hide body on click
*/

class HoverableButton extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <>
        <ReactTooltip
          id={`tooltip-${this.props.icon}`}
          place={this.props.place ? this.props.place : "bottom"}
        >
          <span>{this.props.tooltip}</span>
        </ReactTooltip>
        <div
          className={`button btn treble-hover-button ${
            this.props.className ? this.props.className : ""
          }`}
          onClick={this.props.onClick}
          data-for={`tooltip-${this.props.icon}`}
          data-tip
        >
          <div
            className={`icon icon--${this.props.icon} size-${
              this.props.iconSize ? this.props.iconSize : "16"
            }`}
          ></div>
        </div>
      </>
    );
  };
}

export default HoverableButton;
