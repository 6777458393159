import React, { Component } from "react";
import Modal from "@bit/treble.components.modal";
import "./RecordModal.scss";

export class RecordModal extends Component {
  /**
   *
   * @param {*} props
   * this modal receives:
   *  - title (type: string) title of modal
   *  - icon Arrow  (type: boolean)
   *  - body  (type: object) body of the modal
   *  - onClose (type: function) function to close modal
   */
  constructor(props) {
    super(props);
  }

  renderTitle = () => {
    return (
      <div className="record-modal-header">
        <div className={this.props.iconArrow}></div>
        <div className="modal-title">{this.props.title}</div>
      </div>
    );
  };

  renderBody = () => {
    return <div className="record-modal-body">{this.props.body}</div>;
  };

  renderButtons = () => {
    return [
      {
        onClick: this.props.onClose,
        body: "OK",
      },
    ];
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        centered
        title={this.renderTitle()}
        body={this.renderBody()}
        buttons={this.renderButtons()}
      />
    );
  }
}

export default RecordModal;
