import React, { Component } from "react";
import "./SnippetModal.scss";

import { toast } from "react-toastify";
import RichTextarea from "../../../../components/RichTextarea/RichTextarea";
import TextInput from "../../../../components/TextInput/TextInput";
import Dropdown from "../../../../components/Dropdowns/Dropdown";
import Modal from "../../../../components/Modal/ModalV2";
import { whatsAppFormat } from "../../../../utils/whatsAppFormat";

const EDITORCHARLIMIT = 4096;

export class SnippetModal extends Component {
  /**
   * @param {*} props
   * this modal receives:
   * - snippet
   */

  constructor(props) {
    super(props);
    this.state = {
      snippet: {
        name: "",
        text: "",
        tags: ["DEFAULT"],
      },
      oldSnippet: null,
      curText: "",
      counter: 0,
      newTagName: "",
    };
    this.strings = this.props.strings;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.snippet !== this.props.snippet) {
      const snippet = {
        id: this.props.snippet.id,
        name: this.props.snippet.name,
        text: this.props.snippet.text,
        tags: this.props.snippet.tags,
      };
      this.setState({
        snippet: snippet,
        oldSnippet: snippet,
        curText: snippet.text,
      });
    }
    if (prevProps.tags !== this.props.tags) {
      const newTagName = this.state.newTagName;
      if (this.props.tags.some((tag) => tag.name == newTagName)) {
        this.changeSnippet(newTagName, "tags");
        this.setState({ newTagName: "" });
      }
    }
  };

  changeSnippet = (value, key) => {
    const { snippet } = this.state;
    snippet[key] = key == "tags" ? [value, "DEFAULT"] : value;
    this.setState({ snippet });
  };

  changeNewTagName = (value) => {
    this.setState({ newTagName: value });
  };

  createTag = () => {
    const newTagName = this.state.newTagName;
    if (newTagName === "") return;
    this.props.createTag(this.props.user.company.id, newTagName);
  };

  validateSnippet = () => {
    const snippet = this.state.snippet;
    return snippet.name.length > 0 && this.state.curText.length > 0;
  };

  handleChangeEditor = (e) => {
    if (e.getPlainText().length > EDITORCHARLIMIT) {
      return;
    }
    this.setState({
      curText: whatsAppFormat(e),
      counter: e.getPlainText().length,
    });
  };

  getToast = (updated = false) => {
    const snippetToast = () => {
      toast(!updated ? this.strings.snippetCreated : this.strings.snippetUpdated, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
    };
    return snippetToast;
  };

  createSnippet = () => {
    if (!this.validateSnippet()) {
      toast.error(this.strings.emptyFields, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }
    const snippet = {
      id: this.state.snippet.id,
      name: this.state.snippet.name,
      text: this.state.curText,
      tags: this.state.snippet.tags,
    };
    if (!this.props.editSnippet) {
      this.props.adminCreateSnippet(snippet, this.getToast());
    } else {
      this.props.adminUpdateSnippet(
        snippet,
        this.getToast(true),
        this.state.oldSnippet,
      );
    }
    this.closeModal();
  };

  getTag = (tags) => {
    let tag = tags.filter((tag) => tag != "DEFAULT");
    if (!tag.length) tag = ["DEFAULT"];
    return tag[0];
  };

  renderTags = () => {
    const { tags } = this.props;
    const { snippet, newTagName } = this.state;
    const tag = this.getTag(snippet.tags);
    return (
      <>
        <div className="create-tag">
          <TextInput
            className=""
            type="text"
            placeholder={this.strings.createNewTeam}
            value={newTagName}
            onChange={(e) => this.changeNewTagName(e.target.value)}
          />
          <div
            className={`button ${newTagName !== "" ? "button--black" : ""}`}
            onClick={() => this.createTag()}
          >
            {this.strings.addTeam}
          </div>
        </div>
        {tags.map((elem) => (
          <div
            className="item"
            onClick={() => this.changeSnippet(elem.name, "tags")}
          >
            {elem.name}
            {tag == elem.name ? <div className="icon icon--check"></div> : ""}
          </div>
        ))}
      </>
    );
  };

  renderHeader = () => {
    return this.props.editSnippet
      ? this.strings.snippetModalEditTitle
      : this.strings.snippetModalTitle;
  };

  renderBody = () => {
    const { name, text, tags } = this.state.snippet;
    const tag = this.getTag(tags);
    return (
      <>
        <div className="name-tag-wrapper">
          <div className="name">
            <p>{this.strings.snippetTitle}</p>
            <TextInput
              className=""
              type="text"
              placeholder={this.strings.snippetPlaceHolder}
              value={name}
              onChange={(e) => this.changeSnippet(e.target.value, "name")}
            />
          </div>
          <div className="tags">
            <p>{this.strings.selectOrCreateType}</p>
            <Dropdown
              title={<div className="item">{tag}</div>}
              iconRow={"dropdown-row"}
              onClose={true}
            >
              {this.renderTags()}
            </Dropdown>
          </div>
        </div>
        <p>{this.strings.writeMessage}</p>
        <RichTextarea
          extras={["Emojis", "BracketVars"]}
          BracketVars={["name", "agent_name"]}
          placeholder={this.strings.placeholderTextarea}
          initValue={text}
          onChange={this.handleChangeEditor}
        />
        <div className="character-counter">
          {`${this.state.counter}/${EDITORCHARLIMIT}`}
        </div>
      </>
    );
  };

  closeModal = () => {
    this.props.onClose();
  };

  renderFooter = () => {
    return (
      <>
        <a onClick={() => this.closeModal()}>{this.strings.snippetCancel}</a>
        <div className="button" onClick={() => this.createSnippet()}>
          {!this.props.editSnippet
            ? this.strings.createMessage
            : this.strings.saveChanges}
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="snippet-modal">
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          header={this.renderHeader()}
          body={this.renderBody()}
          footer={this.renderFooter()}
        />
      </div>
    );
  }
}

export default SnippetModal;
