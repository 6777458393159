import { request } from "./request";
import config from "../config";

export const getHubspotAttributes = (attrs, callback) => {
  let attributes = "";
  attrs.forEach((attr) => {
    attributes += `&attrs=${attr}`;
  });

  request(
    "GET",
    `${config.APP_MAIN_HOST}/crm/hubspot/properties?${attributes}`,
    null,
    true,
    (data) => {
      callback(data);
    },
    (error) => {
      callback(false);
    },
  );
};
