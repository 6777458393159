/*
 src/reducers/adminReducer.js
*/
var _ = require("lodash");

const initialState = {
  chats: [],
  adminAgents: [],
  stats: {
    assigned: 0,
    finished: 0,
    most_used_tag: "-",
    unassigned: 0,
  },
  events: [],
  notes: [],
  agentStats: {
    active_agents: 0,
    avg_rating: "-",
    chats_per_agent_per_hour: "-",
    total_agents: 0,
  },
  isFetching: false,
  teams: [],
  tags: [],
  allTags: [],
  reportsMetrics: {
    total: 0,
    distinct_contacts: 0,
    mean_first_response_time: 0,
    mean_completion_time: 0,
    mean_response_time: 0,
    mean_csat: 0,
  },
};

function receiveNewTag(state, action) {
  const { allTags } = state;
  const { tag } = action;

  const stateCopy = _.cloneDeep(state);
  let allTagsCopy = _.cloneDeep(allTags);

  allTagsCopy.push(tag);
  stateCopy.allTags = allTagsCopy;

  return stateCopy;
}

export default (state = initialState, action) => {
  console.log("ACTION", action);
  switch (action.type) {
    case "ADMIN_LOGOUT":
      return initialState;
    case "RECEIVE_ADMIN_CONVERSATIONS":
      const { resetChats, chats } = action;
      return Object.assign({}, state, {
        chats: resetChats ? chats : state.chats.concat(chats),
      });
    case "RECEIVE_GENERAL_STATS":
      return Object.assign({}, state, {
        stats: action.stats,
      });
    case "REQUEST_CONVERSATION_HISTORY":
      return Object.assign({}, state, {
        isFetching: true,
        events: [],
        notes: [],
      });
    case "RECEIVE_CONVERSATION_HISTORY":
      return Object.assign({}, state, {
        isFetching: false,
        events: action.history.events,
        notes: action.history.notes,
      });

    case "RECEIVE_AGENT_STATS":
      return Object.assign({}, state, {
        agentStats: action.stats,
      });
    case "RECEIVE_ADMIN_AGENTS":
      return Object.assign({}, state, {
        adminAgents: action.agents,
      });
    case "RECEIVE_TEAMS":
      return Object.assign({}, state, {
        teams: action.data,
      });

    // CONVERSATION

    case "REQUEST_CHAT_ASSIGN":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "CHAT_ASSIGN_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
      });
    case "CHAT_ASSIGN_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
        isFetching: false,
      });
    case "RECEIVE_TAGS_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
        isFetching: false,
      });
    case "REQUEST_TAGS":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_TAGS":
      return Object.assign({}, state, {
        tags: action.agents,
        isFetching: false,
      });
    case "REQUEST_ALL_TAGS":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_ALL_TAGS":
      return Object.assign({}, state, {
        allTags: action.data,
        isFetching: false,
      });
    case "RECEIVE_NEW_TAG":
      return receiveNewTag(state, action);
    case "RECEIVE_ASSIGNMENT_METHOD":
      return Object.assign({}, state, {
        assignmentMethod: action.assignmentMethod,
        waitingTimeLimit: action.waitingTimeLimit,
      });
    default:
      return state;
  }
};
