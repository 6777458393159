import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAgentStats,
  getAdminAgents,
  createAgent,
  editAgent,
  deleteAgent,
  getAllTagsByTeam,
  createNewTag,
  getAssignmentMethod,
  editAssignmentMethod,
} from "../../../actions/statAction";
import { getHelpdesks } from "../../../actions/agentAction";
import Modal from "../../../components/Modal/ModalV2";

import moment from "moment-timezone";
import "moment/locale/pt";
import "moment/locale/es";

import "./AgentStats.scss";

import languages from "./languages";

import { CSVLink } from "react-csv";

import MultipleSelectDropdown from "../../../components/Dropdowns/MultipleSelectDropdown";
import SelectDropdown from "../../../components/SelectDropdown/SelectDropdown";
import TextInput from "../../../components/TextInput/TextInput";
import { Tooltip, Table } from "antd";

import TableIconButton from "./TableIconButton";

import events from "../../../utils/events";
import LabelInputText from "./LabelInputText";
import { validateEmail } from "../../../utils/email";

function msToTime(ms) {
  const duration = moment.duration(ms);
  const hours = Math.floor(duration.asHours());
  const remainingMs = ms - hours * 3600000; // subtract the hours in milliseconds
  return hours + moment.utc(remainingMs).format(":mm:ss");
}

function formatTime(timeStr) {
  let [hours, minutes, seconds] = timeStr.split(":");
  return `${hours} h ${minutes} m ${seconds} s`;
}

const RANDOM_ASSIGNMENT = "random";
const LOWEST_FIRST_ASSIGNMENT = "lowest_first";
const ASSIGN_OWNER_IMMEDIATELY = "assign_owner_immediately";
const WAIT_OWNER_AND_ASSIGN = "wait_owner_and_assign";
const WAIT_OWNER_AND_FALLBACK = "wait_owner_and_fallback";
const WAITING_OWNER_TIME_MODAL = "waitingOwnerTimeModal";
const AGENT_INFO_MODAL = "agentInfoModal";
const AGENT_DELETE_MODAL = "agentDeleteModal";

class AgentStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        actor: "customer",
        searchTerm: "",
        tags: [],
        states: [],
      },
      language: props.language,
      agent: null,
      csv: {
        loading: false,
        data: [],
      },
      modal: null,
      addTag: false,
      newTag: "",
    };

    this.strings = languages[props.language];
  }

  fetchAgents = () => {
    this.props.getAgentStats();
    this.props.getAdminAgents();
  };

  componentDidMount() {
    this.fetchAgents();
    this.props.getAllTagsByTeam(this.props.user.company.id);
    this.props.getAssignmentMethod();
    events.page("Agent Stats");
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.setState({ language: this.props.language });
      this.strings = languages[this.props.language];
    }
  }

  changeFilter = (nValue, key) => {
    const { filter } = this.state;
    filter[key] = nValue;
    this.setState({ filter });
  };

  getCSVRepresentation = (event, done) => {
    if (!this.state.csv.loading) {
      this.setState({
        csv: { ...this.state.csv, loading: true },
      });

      const filteredAgents = this.filterAgents(this.props.adminAgents);
      let finalAgents = filteredAgents.map((chat) => ({
        agente: `${chat.first_name} ${chat.last_name} - ${chat.email}`,
        group: chat.tags
          ? chat.tags.map((e) => e.name.toLowerCase()).join(", ")
          : "-",
        response_time:
          chat.response_time === null ? "-" : msToTime(chat.response_time),
        conversations_finished: chat.conversations_finished
          ? chat.conversations_finished
          : "-",
        conversations_assigned: chat.conversations_assigned
          ? chat.conversations_assigned
          : "-",
        rating:
          chat.rating !== "None" ? parseFloat(chat.rating).toFixed(2) : "-",
        active: chat.active ? "active" : "inactive",
      }));
      this.setState({
        csv: {
          data: finalAgents,
          loading: false,
        },
      });
      done(true);
    }
  };

  filterAgents = (agents) => {
    const { filter } = this.state;
    const { tags, states } = filter;

    let filteredAgents = agents || [];

    if (filter.searchTerm !== "") {
      const { searchTerm } = filter;
      filteredAgents = filteredAgents.filter((e) => {
        const name = `${e.first_name} ${e.last_name}`.toLowerCase();
        const email = e.email.toLowerCase();

        return (
          name.indexOf(searchTerm.toLowerCase()) !== -1 ||
          email.indexOf(searchTerm.toLowerCase()) !== -1
        );
      });
    }

    if (tags.length > 0) {
      filteredAgents = filteredAgents.filter((e) => {
        let found = false;
        const agentTags = e.tags;
        for (var t of agentTags) {
          if (tags.find((tag) => tag === t.name)) {
            found = true;
          }
        }
        return found;
      });
    }

    if (states.length > 0) {
      filteredAgents = filteredAgents.filter(
        (e) => states.indexOf(e.active) !== -1,
      );
    }

    return filteredAgents;
  };

  validateAgentInfo() {
    let requiredFields = ["first_name", "last_name", "email", "agent_type"];
    if (this.state.modal.agentState == "create") {
      requiredFields.push("password");
    }

    return (
      requiredFields.every(
        (attr) =>
          this.state.modal.agent[attr] && this.state.modal.agent[attr].length,
      ) && validateEmail(this.state.modal.agent.email ?? "")
    );
  }

  getButtonsForInfoAgentModal() {
    const send = () => {
      if (this.state.modal.agentState == "create") {
        this.props.createAgent(this.state.modal.agent);
      } else {
        this.props.editAgent(this.state.modal.agent);
      }
      this.setState({ modal: null });
    };

    return (
      <div className="agent-info-modal-buttons">
        <button
          onClick={() => this.setState({ modal: null })}
          className="cancel"
        >
          {this.strings.cancel}
        </button>
        <button
          onClick={send}
          className="send"
          disabled={!this.validateAgentInfo()}
        >
          {this.state.modal.agentState == "create"
            ? this.strings.create
            : this.strings.saveChanges}
        </button>
      </div>
    );
  }

  renderAgentInfoModal() {
    const onChange = (attr) => {
      return (value) => {
        const { agent } = this.state.modal;
        agent[attr] = value;
        this.setModalValue("agent", agent);
      };
    };

    return (
      <Modal
        wrappClass="agent-info-modal"
        show={this.state.modal.show}
        onClose={() => {
          this.setState({ modal: null });
        }}
        header={this.strings[this.state.modal.agentState + "Title"]}
        body={
          <>
            <div className="input-container">
              <LabelInputText
                onChange={onChange("first_name")}
                type="text"
                value={this.state.modal.agent.first_name}
                label={this.strings.first_name}
              />
              <LabelInputText
                onChange={onChange("last_name")}
                type="text"
                value={this.state.modal.agent.last_name}
                label={this.strings.last_name}
              />
            </div>
            <LabelInputText
              onChange={onChange("email")}
              type="email"
              value={this.state.modal.agent.email}
              label={this.strings.email}
              invalidLabel={this.strings.invalidEmail}
            />
            <LabelInputText
              onChange={onChange("password")}
              type="password"
              value={this.state.modal.agent.password}
              label={this.strings.password}
            />
            <div className="input-container">
              <div className="title-and-dropdown">
                <p>{this.strings.selectTags}</p>
                <MultipleSelectDropdown
                  options={this.props.tags.map((tag) => tag.name)}
                  selectedOptions={this.state.modal.agent.tags}
                  noSelectionTitle={this.strings.noTags}
                  display={(tag) => tag}
                  onSelect={onChange("tags")}
                />
              </div>
              <div className="title-and-dropdown">
                <p>{this.strings.selectType}</p>
                <SelectDropdown
                  options={["AGENT", "ADMIN"]}
                  value={this.state.modal.agent.agent_type}
                  noSelectionTitle={this.strings.noType}
                  onSelect={onChange("agent_type")}
                  display={(item) => <p>{this.strings[item]}</p>}
                />
              </div>
            </div>
          </>
        }
        footer={this.getButtonsForInfoAgentModal()}
      />
    );
  }

  renderWaitingOwnerTimeModal() {
    return (
      <Modal
        wrappClass="waiting-owner-time-modal"
        show={this.state.modal.show}
        onClose={() => {
          this.setState({ modal: null });
        }}
        header={this.strings.waitOwnerAndFallback}
        body={
          <div className="body-content">
            <p className="description">
              {this.strings.waitingOwnerTimeModalDescription}
            </p>
            <div className="time-settings">
              <p>{this.strings.after}</p>
              <input
                className="button-style"
                placeholder="00"
                value={this.state.modal.time}
                onChange={(e) => {
                  let newTime = e.target.value.replace(/\D/g, "").slice(0, 2);
                  this.setModalValue("time", newTime);
                }}
              ></input>
              {["minutes", "hours"].map((timeUnit) => (
                <div
                  className={`button-style ${
                    this.state.modal.timeUnit == timeUnit ? "selected" : ""
                  }`}
                  onClick={() => this.setModalValue("timeUnit", timeUnit)}
                >
                  {this.strings[timeUnit]}
                </div>
              ))}
              <p>{this.strings.assignConversation}</p>
            </div>
          </div>
        }
        footer={
          <div className="footer-buttons">
            <button
              onClick={() => {
                this.setState({ modal: null });
              }}
            >
              {this.strings.cancel}
            </button>
            <button
              className="primary"
              disabled={!this.state.modal.time || !this.state.modal.timeUnit}
              onClick={() => {
                const waitingTimeLimit =
                  this.state.modal.time *
                  (this.state.modal.timeUnit == "hours" ? 60 : 1);
                this.props.editAssignmentMethod(
                  WAIT_OWNER_AND_FALLBACK,
                  waitingTimeLimit,
                );
                this.setState({ modal: null });
              }}
            >
              {this.strings.apply}
            </button>
          </div>
        }
      />
    );
  }

  renderAgentDeleteModal() {
    return (
      <Modal
        wrappClass="agent-delete-modal"
        show={this.state.modal.show}
        onClose={() => {
          this.setState({ modal: null });
        }}
        body={
          <>
            <p className="title">
              {this.strings.deleteTitle}{" "}
              <span className="agent-name">
                {`${this.state.modal.agent.first_name} ${this.state.modal.agent.last_name}`}
              </span>
              {"?"}
            </p>
            <p className="description">{this.strings.deleteDescription}</p>
          </>
        }
        footer={
          <div className="agent-delete-modal-buttons">
            <button
              onClick={() => this.setState({ modal: null })}
              className="cancel"
            >
              {this.strings.cancel}
            </button>
            <button
              onClick={() => {
                this.props.deleteAgent(this.state.modal.agent);
                this.setState({ modal: null });
              }}
              className="send"
            >
              {this.strings.confirmDelete}
            </button>
          </div>
        }
      />
    );
  }

  setModalValue(keyName, newValue) {
    const newModal = { ...this.state.modal };
    newModal[keyName] = newValue;
    this.setState({
      modal: newModal,
    });
  }

  renderModal() {
    let modal = null;
    if (this.state.modal?.type == AGENT_INFO_MODAL) {
      modal = this.renderAgentInfoModal();
    } else if (this.state.modal?.type == WAITING_OWNER_TIME_MODAL) {
      modal = this.renderWaitingOwnerTimeModal();
    } else if (this.state.modal?.type == AGENT_DELETE_MODAL) {
      modal = this.renderAgentDeleteModal();
    }
    return modal;
  }

  renderAssignmentMethodDropdown() {
    const TITLE_SECTION = "TITLE_SECTION";
    const HUBSPOT_SECTION = "HUBSPOT_SECTION";

    let assignmentMethodOptions = [
      {
        value: TITLE_SECTION,
      },
      {
        value: RANDOM_ASSIGNMENT,
        label: this.strings.randomAssignment,
        description: this.strings.randomAssignmentDescription,
      },
      {
        value: LOWEST_FIRST_ASSIGNMENT,
        label: this.strings.lowestFirstAssignment,
        description: this.strings.lowestFirstAssignmentDescription,
      },
    ];

    const hasHubspot =
      this.props.helpdesks?.filter((h) => h.type === "HUBSPOT").length > 0;
    if (hasHubspot) {
      assignmentMethodOptions = assignmentMethodOptions.concat([
        {
          value: HUBSPOT_SECTION,
        },
        {
          value: ASSIGN_OWNER_IMMEDIATELY,
          label: this.strings.assignOwnerImmediately,
          description: this.strings.assignOwnerImmediatelyDescription,
        },
        {
          value: WAIT_OWNER_AND_ASSIGN,
          label: this.strings.waitOwnerAndAssign,
          description: this.strings.waitOwnerAndAssignDescription,
        },
        {
          value: WAIT_OWNER_AND_FALLBACK,
          label: this.strings.waitOwnerAndFallback,
          description: this.strings.waitOwnerAndFallbackDescription,
        },
      ]);
    }

    const currentAssignmentMethod = assignmentMethodOptions.filter(
      (method) => method.value == this.props.assignmentMethod,
    )[0];

    return (
      <SelectDropdown
        disableCheck={true}
        className={`assignment-method-dropdown ${
          this.state.modal?.type == WAITING_OWNER_TIME_MODAL ? "is-active" : ""
        }`}
        options={assignmentMethodOptions}
        triggerComponent={
          <div className="dropdown-button">
            <p>{this.strings.assignmentMethod}</p>
            <div className="icon icon--double-arrow" />
            <p>{currentAssignmentMethod?.label}</p>
          </div>
        }
        onSelect={(item) => {
          if (item.value != WAIT_OWNER_AND_FALLBACK) {
            this.props.editAssignmentMethod(item.value);
          } else if (
            currentAssignmentMethod?.value != WAIT_OWNER_AND_FALLBACK
          ) {
            this.setState({
              modal: { type: WAITING_OWNER_TIME_MODAL, show: true },
            });
          }
        }}
        display={(item) => {
          let display = null;
          if (item.value == TITLE_SECTION) {
            display = (
              <div className="section section--title">
                <p className="title">{this.strings.assignmentMethod}</p>
                <p className="description">
                  {this.strings.assignmentMethodDescription}
                </p>
              </div>
            );
          } else if (item.value == HUBSPOT_SECTION) {
            display = (
              <div className="section section--hubspot">
                <div className="icon icon--hubspot-logo"></div>
                <p className="title">{this.strings.hubspotAssignmentMethods}</p>
              </div>
            );
          } else {
            display = (
              <Tooltip
                overlayClassName="assignment-method-option-tooltip"
                placement="rightTop"
                title={
                  <>
                    <p className="title">{item.label}</p>
                    <p className="description">{item.description}</p>
                  </>
                }
              >
                <div className="assignment-method-option">
                  <div>
                    <p>{item.label}</p>
                    <div
                      className={`icon icon--check-circle ${
                        item.value == currentAssignmentMethod?.value
                          ? "selected"
                          : ""
                      }`}
                    ></div>
                  </div>
                  {item.value == WAIT_OWNER_AND_FALLBACK &&
                    currentAssignmentMethod?.value ==
                      WAIT_OWNER_AND_FALLBACK && (
                      <div className="waiting-time-limit-edit">
                        <p>
                          {`${this.strings.after} ${
                            this.props.waitingTimeLimit % 60 == 0
                              ? `${
                                  this.props.waitingTimeLimit / 60
                                } ${this.strings.hours.toLowerCase()}`
                              : `${
                                  this.props.waitingTimeLimit
                                } ${this.strings.minutes.toLowerCase()}`
                          }`}
                        </p>
                        <Tooltip
                          overlayClassName="edit-tooltip"
                          placement="top"
                          title={<p>{this.strings.edit}</p>}
                        >
                          <div
                            className="icon icon--edit-label"
                            onClick={() => {
                              this.setState({
                                modal: {
                                  type: WAITING_OWNER_TIME_MODAL,
                                  show: true,
                                },
                              });
                            }}
                          ></div>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </Tooltip>
            );
          }
          return display;
        }}
      />
    );
  }

  renderHeaderRightButtons() {
    return (
      <div className="right-buttons">
        <div className="add-button-container">
          <div
            className="add-tag"
            onClick={(e) => {
              this.setState({ addTag: !this.state.addTag });
            }}
          >
            <div className="icon icon--group"></div>
            <p>{this.strings.addTag}</p>
          </div>
          <div className={`tag-form ${this.state.addTag ? "is-active" : ""}`}>
            <input
              placeholder="Tag"
              onChange={(e) => {
                this.setState({ newTag: e.target.value });
              }}
            />
            <button
              className="add-button"
              onClick={(e) => {
                this.props.createNewTag(
                  this.props.user.company.id,
                  this.state.newTag,
                );
                events.track("Agent stats add tag", {
                  Text: this.state.newTag,
                });
                this.setState({ addTag: false, newTag: "" });
              }}
              disabled={this.state.newTag === ""}
            >
              {this.strings.add}
            </button>
          </div>
        </div>
        <div className="add-button-container">
          <div
            className="add-agent"
            onClick={(e) => {
              this.setState({
                modal: {
                  type: AGENT_INFO_MODAL,
                  show: true,
                  agentState: "create",
                  agent: { company_id: this.props.user.company.id },
                },
              });
              events.track("Agent stats click on create agent", {});
            }}
          >
            <div className="icon icon--salesperson"></div>
            <p>{this.strings.addAgent}</p>
          </div>
        </div>
      </div>
    );
  }

  getTableColumns() {
    return [
      {
        title: this.strings.agent,
        className: "agent-data",
        sorter: (a, b) => {
          const fullNameA = `${a.first_name} ${a.last_name}`;
          const fullNameB = `${b.first_name} ${b.last_name}`;
          return fullNameA.localeCompare(fullNameB);
        },
        render: (agent) => (
          <>
            <p>{`${agent.first_name} ${agent.last_name}`}</p>
            <p>{agent.email}</p>
          </>
        ),
      },
      {
        title: this.strings.tag,
        sorter: (a, b) => {
          const tagsA = a.tags
            ? a.tags.map((e) => e.name.toLowerCase()).join(", ")
            : "-";
          const tagsB = b.tags
            ? b.tags.map((e) => e.name.toLowerCase()).join(", ")
            : "-";
          return tagsA.localeCompare(tagsB);
        },
        render: (agent) => {
          return (
            <>
              {agent.tags
                ? agent.tags.map((e) => e.name.toLowerCase()).join(", ")
                : "-"}
            </>
          );
        },
      },
      {
        title: this.strings.status,
        className: "agent-status",
        sorter: (a, b) => b.active - a.active,
        render: (agent) => {
          const color = agent.active ? "green" : "red";
          return (
            <>
              <div className={`circle ${color}`}></div>
              <p>
                {agent.active ? this.strings.active : this.strings.inactive}
              </p>
            </>
          );
        },
      },
      {
        title: this.strings.avgResponse,
        className: "centered-text",
        sorter: (a, b) => a.response_time - b.response_time,
        render: (agent) => {
          return (
            <>
              {agent.response_time === null
                ? "-"
                : formatTime(msToTime(agent.response_time))}
            </>
          );
        },
      },
      {
        title: this.strings.chatsAttended,
        className: "centered-text",
        sorter: (a, b) => a.conversations_finished - b.conversations_finished,
        render: (agent) => {
          return (
            <>
              {agent.conversations_finished
                ? agent.conversations_finished
                : "-"}
            </>
          );
        },
      },
      {
        title: this.strings.chatsProcess,
        className: "centered-text",
        sorter: (a, b) => a.conversations_assigned - b.conversations_assigned,
        render: (agent) => {
          return (
            <>
              {agent.conversations_assigned
                ? agent.conversations_assigned
                : "-"}
            </>
          );
        },
      },
      {
        title: this.strings.rating,
        className: "centered-text",
        sorter: (a, b) => {
          const ratingA = a.rating !== "None" ? parseFloat(a.rating) : null;
          const ratingB = b.rating !== "None" ? parseFloat(b.rating) : null;
          return ratingA - ratingB;
        },
        render: (agent) => (
          <>
            {agent.rating !== "None"
              ? parseFloat(agent.rating).toFixed(2)
              : "-"}
          </>
        ),
      },
      {
        title: "",
        onCell: (agent, rowIndex) => {
          return {
            onClick: (event) => {
              const selectedAgent = {
                ...agent,
                company_id: this.props.user.company.id,
              };
              selectedAgent["tags"] = agent.tags.map((tag) => tag.name);
              selectedAgent["active"] = true;
              this.setState({
                modal: {
                  type: AGENT_INFO_MODAL,
                  show: true,
                  agentState: "edit",
                  agent: selectedAgent,
                },
              });
              events.track("Agent stats click on edit agent", {});
            },
          };
        },
        render: () => {
          return (
            <TableIconButton
              iconName="edit-label"
              text={this.strings.edit}
              variant="primary"
            />
          );
        },
      },
      {
        title: "",
        onCell: (agent, rowIndex) => {
          return {
            onClick: (event) => {
              const selectedAgent = {
                ...agent,
                company_id: this.props.user.company.id,
              };
              this.setState({
                modal: {
                  type: AGENT_DELETE_MODAL,
                  show: true,
                  agent: selectedAgent,
                },
              });
              events.track("Agent stats click on delete agent", {});
            },
          };
        },
        render: () => {
          return (
            <TableIconButton
              iconName="delete"
              text={this.strings.delete}
              variant="danger"
            />
          );
        },
      },
    ];
  }

  renderEmptyTable() {
    return (
      <div className="empty-table">
        <div className="img-no-results"></div>
        <p>{this.strings.noResults}</p>
      </div>
    );
  }

  render() {
    const filteredAgents = this.filterAgents(this.props.adminAgents);
    const agentStats = this.props.agentStats;
    return (
      <div className="agent-stats">
        <div className="header">{this.strings.salespeopleManager}</div>
        <div className="top-bar-container">
          {this.renderAssignmentMethodDropdown()}
          {this.renderHeaderRightButtons()}
        </div>
        <div className="container is-fluid general-stats-container">
          <div className="columns">
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.agentsConnected}</p>
                <h1>{agentStats.active_agents}</h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.totalAgents}</p>
                <h1>{agentStats.total_agents}</h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.averageRating}</p>
                <h1>
                  {agentStats.avg_rating !== "None"
                    ? parseFloat(agentStats.avg_rating).toFixed(2)
                    : "-"}
                </h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.agentHr}</p>
                <h1>
                  {agentStats.chats_per_agent_per_hour !== "None"
                    ? parseFloat(agentStats.chats_per_agent_per_hour).toFixed(2)
                    : "-"}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          {this.renderTableHeader()}
          <Table
            columns={this.getTableColumns()}
            rowKey={(agent) => agent.id}
            dataSource={filteredAgents}
            locale={{ emptyText: this.renderEmptyTable() }}
            scroll={{ x: "max-content" }}
            showSorterTooltip={false}
          />
        </div>
        {this.renderModal()}
      </div>
    );
  }

  renderTableHeader() {
    const chats = this.filterAgents(this.props.adminAgents).length;
    const filter = this.state.filter;
    const { searchTerm } = filter;

    let nTags = this.props.tags.map((tag) => tag.name);
    let nStates = [this.strings.active, this.strings.inactive];

    return (
      <div className="table-header">
        <h1>
          {this.strings.agents} ({chats})
        </h1>
        <div className="searchbar">
          <div className="icon icon--search" />
          <TextInput
            className="input"
            type="text"
            placeholder={this.strings.search}
            value={searchTerm}
            onChange={(e) => this.changeFilter(e.target.value, "searchTerm")}
            trackMessage="Agent stats search"
          />
          {searchTerm != "" && (
            <div
              className="icon icon--search-cancel-grey"
              onClick={() => this.changeFilter("", "searchTerm")}
            />
          )}
        </div>

        <MultipleSelectDropdown
          iconRow={"dropdown-row"}
          options={nStates}
          noSelectionTitle={this.strings.allStatus}
          display={(status) => status}
          onSelect={(states) => {
            events.track("Agent stats select status", { Statuses: states });
            this.changeFilter(
              states.map((state) => state === this.strings.active),
              "states",
            );
          }}
        />

        <MultipleSelectDropdown
          iconRow={"dropdown-row"}
          options={nTags}
          noSelectionTitle={this.strings.allTags}
          display={(tag) => tag}
          onSelect={(e) => {
            this.changeFilter(e, "tags");
            events.track("Agent stats select tags", { Tags: e });
          }}
        />

        <CSVLink
          data={this.state.csv.data}
          filename={"treble.csv"}
          asyncOnClick={true}
          onClick={this.getCSVRepresentation}
        >
          <button
            className="download-btn"
            onClick={() => {
              events.track("Agent stats download", {
                "Search Text": this.state.filter.searchTerm,
                Tags: this.state.filter.tags,
                Statuses: this.state.filter.states,
              });
            }}
          >
            <div className="icon icon--download-data" />
            {this.strings.download}
          </button>
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminAgents: state.adminReducer.adminAgents,
  agentStats: state.adminReducer.agentStats,
  language: state.mainReducer.language,
  user: state.mainReducer.user,
  isFetching: state.mainReducer.isFetching || state.adminReducer.isFetching,
  errorVisible: state.mainReducer.errorVisible,
  errorMessage: state.mainReducer.errorMessage,
  successMessageVisible: state.mainReducer.successMessageVisible,
  successMessage: state.mainReducer.successMessage,
  tags: state.adminReducer.tags,
  assignmentMethod: state.adminReducer.assignmentMethod,
  waitingTimeLimit: state.adminReducer.waitingTimeLimit,
  helpdesks: state.mainReducer.helpdesks,
});

const mapDispatchToProps = (dispatch) => ({
  getAgentStats: () => dispatch(getAgentStats()),
  getAdminAgents: () => dispatch(getAdminAgents()),
  createAgent: (agent) => dispatch(createAgent(agent)),
  editAgent: (agent) => dispatch(editAgent(agent)),
  deleteAgent: (agent) => dispatch(deleteAgent(agent)),
  getAllTagsByTeam: (company_id) => dispatch(getAllTagsByTeam(company_id)),
  createNewTag: (company_id, tagName) =>
    dispatch(createNewTag(company_id, tagName)),
  getAssignmentMethod: () => dispatch(getAssignmentMethod()),
  editAssignmentMethod: (assignmentMethod, waitingTimeLimit) =>
    dispatch(editAssignmentMethod(assignmentMethod, waitingTimeLimit)),
  getHelpdesks: () => dispatch(getHelpdesks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentStats);
