module.exports = [
  {
    "country-code": "170",
    image: "/flags/co.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "CO",
    "alpha-3": "COL",
    name: "Colombia",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CO",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "57",
  },
  {
    "country-code": "484",
    image: "/flags/mx.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    name: "Mexico",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:MX",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "52",
  },
  {
    "country-code": "032",
    image: "/flags/ar.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    name: "Argentina",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:AR",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "54",
  },
  {
    "country-code": "076",
    image: "/flags/br.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    name: "Brazil",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BR",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "55",
  },
  {
    "country-code": "004",
    image: "/flags/af.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    name: "Afghanistan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:AF",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "93",
  },
  {
    "country-code": "248",
    image: "/flags/ax.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "AX",
    "alpha-3": "ALA",
    name: "Åland Islands",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:AX",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "358-18",
  },
  {
    "country-code": "008",
    image: "/flags/al.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    name: "Albania",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:AL",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "355",
  },
  {
    "country-code": "012",
    image: "/flags/dz.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    name: "Algeria",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:DZ",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "213",
  },
  {
    "country-code": "016",
    image: "/flags/as.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "AS",
    "alpha-3": "ASM",
    name: "American Samoa",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:AS",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "1-684",
  },
  {
    "country-code": "020",
    image: "/flags/ad.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "AD",
    "alpha-3": "AND",
    name: "Andorra",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:AD",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "376",
  },
  {
    "country-code": "024",
    image: "/flags/ao.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    name: "Angola",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:AO",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "244",
  },
  {
    "country-code": "660",
    image: "/flags/ai.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "AI",
    "alpha-3": "AIA",
    name: "Anguilla",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:AI",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-264",
  },
  {
    "country-code": "010",
    image: "/flags/aq.svg",
    "intermediate-region": "",
    "sub-region-code": "",
    "region-code": "",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
    name: "Antarctica",
    region: "",
    "iso_3166-2": "ISO 3166-2:AQ",
    "sub-region": "",
    "intermediate-region-code": "",
    phone: "672",
  },
  {
    "country-code": "028",
    image: "/flags/ag.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    name: "Antigua and Barbuda",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:AG",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-268",
  },
  {
    "country-code": "051",
    image: "/flags/am.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    name: "Armenia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:AM",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "374",
  },
  {
    "country-code": "533",
    image: "/flags/aw.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "AW",
    "alpha-3": "ABW",
    name: "Aruba",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:AW",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "297",
  },
  {
    "country-code": "036",
    image: "/flags/au.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    name: "Australia",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:AU",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: "61",
  },
  {
    "country-code": "040",
    image: "/flags/at.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    name: "Austria",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:AT",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "43",
  },
  {
    "country-code": "031",
    image: "/flags/az.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    name: "Azerbaijan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:AZ",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "994",
  },
  {
    "country-code": "044",
    image: "/flags/bs.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    name: "Bahamas",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BS",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-242",
  },
  {
    "country-code": "048",
    image: "/flags/bh.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    name: "Bahrain",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:BH",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "973",
  },
  {
    "country-code": "050",
    image: "/flags/bd.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    name: "Bangladesh",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:BD",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "880",
  },
  {
    "country-code": "052",
    image: "/flags/bb.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    name: "Barbados",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BB",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-246",
  },
  {
    "country-code": "112",
    image: "/flags/by.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    name: "Belarus",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:BY",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "375",
  },
  {
    "country-code": "056",
    image: "/flags/be.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    name: "Belgium",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:BE",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "32",
  },
  {
    "country-code": "084",
    image: "/flags/bz.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    name: "Belize",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BZ",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "501",
  },
  {
    "country-code": "204",
    image: "/flags/bj.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    name: "Benin",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:BJ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "229",
  },
  {
    "country-code": "060",
    image: "/flags/bm.svg",
    "intermediate-region": "",
    "sub-region-code": "021",
    "region-code": "019",
    "alpha-2": "BM",
    "alpha-3": "BMU",
    name: "Bermuda",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BM",
    "sub-region": "Northern America",
    "intermediate-region-code": "",
    phone: "1-441",
  },
  {
    "country-code": "064",
    image: "/flags/bt.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    name: "Bhutan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:BT",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "975",
  },
  {
    "country-code": "068",
    image: "/flags/bo.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    name: "Bolivia (Plurinational State of)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BO",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "591",
  },
  {
    "country-code": "535",
    image: "/flags/bq.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BQ",
    "alpha-3": "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BQ",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "599",
  },
  {
    "country-code": "070",
    image: "/flags/ba.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    name: "Bosnia and Herzegovina",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:BA",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "387",
  },
  {
    "country-code": "072",
    image: "/flags/bw.svg",
    "intermediate-region": "Southern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    name: "Botswana",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:BW",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "018",
    phone: "267",
  },
  {
    "country-code": "074",
    image: "/flags/bv.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BV",
    "alpha-3": "BVT",
    name: "Bouvet Island",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BV",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "",
  },
  {
    "country-code": "086",
    image: "/flags/io.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "IO",
    "alpha-3": "IOT",
    name: "British Indian Ocean Territory",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:IO",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "246",
  },
  {
    "country-code": "096",
    image: "/flags/bn.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    name: "Brunei Darussalam",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:BN",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "673",
  },
  {
    "country-code": "100",
    image: "/flags/bg.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    name: "Bulgaria",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:BG",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "359",
  },
  {
    "country-code": "854",
    image: "/flags/bf.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    name: "Burkina Faso",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:BF",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "226",
  },
  {
    "country-code": "108",
    image: "/flags/bi.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    name: "Burundi",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:BI",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "257",
  },
  {
    "country-code": "132",
    image: "/flags/cv.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    name: "Cabo Verde",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CV",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "238",
  },
  {
    "country-code": "116",
    image: "/flags/kh.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    name: "Cambodia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KH",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "855",
  },
  {
    "country-code": "120",
    image: "/flags/cm.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    name: "Cameroon",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CM",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "237",
  },
  {
    "country-code": "124",
    image: "/flags/ca.svg",
    "intermediate-region": "",
    "sub-region-code": "021",
    "region-code": "019",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    name: "Canada",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CA",
    "sub-region": "Northern America",
    "intermediate-region-code": "",
    phone: "1",
  },
  {
    "country-code": "136",
    image: "/flags/ky.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "KY",
    "alpha-3": "CYM",
    name: "Cayman Islands",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:KY",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-345",
  },
  {
    "country-code": "140",
    image: "/flags/cf.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    name: "Central African Republic",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CF",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "236",
  },
  {
    "country-code": "148",
    image: "/flags/td.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    name: "Chad",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:TD",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "235",
  },
  {
    "country-code": "152",
    image: "/flags/cl.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    name: "Chile",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CL",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "56",
  },
  {
    "country-code": "156",
    image: "/flags/cn.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    name: "China",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:CN",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "86",
  },
  {
    "country-code": "162",
    image: "/flags/cx.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "CX",
    "alpha-3": "CXR",
    name: "Christmas Island",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:CX",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: "61",
  },
  {
    "country-code": "166",
    image: "/flags/cc.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "CC",
    "alpha-3": "CCK",
    name: "Cocos (Keeling) Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:CC",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: "61",
  },
  {
    "country-code": "174",
    image: "/flags/km.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "KM",
    "alpha-3": "COM",
    name: "Comoros",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:KM",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "269",
  },
  {
    "country-code": "178",
    image: "/flags/cg.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CG",
    "alpha-3": "COG",
    name: "Congo",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CG",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "242",
  },
  {
    "country-code": "180",
    image: "/flags/cd.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CD",
    "alpha-3": "COD",
    name: "Congo (Democratic Republic of the)",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CD",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "243",
  },
  {
    "country-code": "184",
    image: "/flags/ck.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "CK",
    "alpha-3": "COK",
    name: "Cook Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:CK",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "682",
  },
  {
    "country-code": "188",
    image: "/flags/cr.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    name: "Costa Rica",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CR",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "506",
  },
  {
    "country-code": "384",
    image: "/flags/ci.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    name: "Côte d'Ivoire",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:CI",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "225",
  },
  {
    "country-code": "191",
    image: "/flags/hr.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    name: "Croatia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:HR",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "385",
  },
  {
    "country-code": "192",
    image: "/flags/cu.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    name: "Cuba",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CU",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "53",
  },
  {
    "country-code": "531",
    image: "/flags/cw.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "CW",
    "alpha-3": "CUW",
    name: "Curaçao",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:CW",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "599",
  },
  {
    "country-code": "196",
    image: "/flags/cy.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    name: "Cyprus",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:CY",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "357",
  },
  {
    "country-code": "203",
    image: "/flags/cz.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    name: "Czechia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:CZ",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "420",
  },
  {
    "country-code": "208",
    image: "/flags/dk.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    name: "Denmark",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:DK",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "45",
  },
  {
    "country-code": "262",
    image: "/flags/dj.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    name: "Djibouti",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:DJ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "253",
  },
  {
    "country-code": "212",
    image: "/flags/dm.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    name: "Dominica",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:DM",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-767",
  },
  {
    "country-code": "214",
    image: "/flags/do.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    name: "Dominican Republic",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:DO",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-809 and 1-829",
  },
  {
    "country-code": "218",
    image: "/flags/ec.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    name: "Ecuador",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:EC",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "593",
  },
  {
    "country-code": "818",
    image: "/flags/eg.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    name: "Egypt",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:EG",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "20",
  },
  {
    "country-code": "222",
    image: "/flags/sv.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    name: "El Salvador",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:SV",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "503",
  },
  {
    "country-code": "226",
    image: "/flags/gq.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    name: "Equatorial Guinea",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GQ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "240",
  },
  {
    "country-code": "232",
    image: "/flags/er.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    name: "Eritrea",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ER",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "291",
  },
  {
    "country-code": "233",
    image: "/flags/ee.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "EE",
    "alpha-3": "EST",
    name: "Estonia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:EE",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "372",
  },
  {
    "country-code": "748",
    image: "/flags/sz.svg",
    "intermediate-region": "Southern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    name: "Eswatini",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SZ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "018",
    phone: "268",
  },
  {
    "country-code": "231",
    image: "/flags/et.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    name: "Ethiopia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ET",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "251",
  },
  {
    "country-code": "238",
    image: "/flags/fk.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "FK",
    "alpha-3": "FLK",
    name: "Falkland Islands (Malvinas)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:FK",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "500",
  },
  {
    "country-code": "234",
    image: "/flags/fo.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "FO",
    "alpha-3": "FRO",
    name: "Faroe Islands",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:FO",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "298",
  },
  {
    "country-code": "242",
    image: "/flags/fj.svg",
    "intermediate-region": "",
    "sub-region-code": "054",
    "region-code": "009",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    name: "Fiji",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:FJ",
    "sub-region": "Melanesia",
    "intermediate-region-code": "",
    phone: "679",
  },
  {
    "country-code": "246",
    image: "/flags/fi.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    name: "Finland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:FI",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "358",
  },
  {
    "country-code": "250",
    image: "/flags/fr.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    name: "France",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:FR",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "33",
  },
  {
    "country-code": "254",
    image: "/flags/gf.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GF",
    "alpha-3": "GUF",
    name: "French Guiana",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GF",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "594",
  },
  {
    "country-code": "258",
    image: "/flags/pf.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "PF",
    "alpha-3": "PYF",
    name: "French Polynesia",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:PF",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "689",
  },
  {
    "country-code": "260",
    image: "/flags/tf.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "TF",
    "alpha-3": "ATF",
    name: "French Southern Territories",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:TF",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "",
  },
  {
    "country-code": "266",
    image: "/flags/ga.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    name: "Gabon",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GA",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "241",
  },
  {
    "country-code": "270",
    image: "/flags/gm.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    name: "Gambia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GM",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "220",
  },
  {
    "country-code": "268",
    image: "/flags/ge.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    name: "Georgia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:GE",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "995",
  },
  {
    "country-code": "276",
    image: "/flags/de.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    name: "Germany",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:DE",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "49",
  },
  {
    "country-code": "288",
    image: "/flags/gh.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    name: "Ghana",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GH",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "233",
  },
  {
    "country-code": "292",
    image: "/flags/gi.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "GI",
    "alpha-3": "GIB",
    name: "Gibraltar",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:GI",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "350",
  },
  {
    "country-code": "300",
    image: "/flags/gr.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    name: "Greece",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:GR",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "30",
  },
  {
    "country-code": "304",
    image: "/flags/gl.svg",
    "intermediate-region": "",
    "sub-region-code": "021",
    "region-code": "019",
    "alpha-2": "GL",
    "alpha-3": "GRL",
    name: "Greenland",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GL",
    "sub-region": "Northern America",
    "intermediate-region-code": "",
    phone: "299",
  },
  {
    "country-code": "308",
    image: "/flags/gd.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    name: "Grenada",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GD",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-473",
  },
  {
    "country-code": "312",
    image: "/flags/gp.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GP",
    "alpha-3": "GLP",
    name: "Guadeloupe",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GP",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "590",
  },
  {
    "country-code": "316",
    image: "/flags/gu.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "GU",
    "alpha-3": "GUM",
    name: "Guam",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:GU",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "1-671",
  },
  {
    "country-code": "320",
    image: "/flags/gt.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    name: "Guatemala",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GT",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "502",
  },
  {
    "country-code": "831",
    image: "/flags/gg.svg",
    "intermediate-region": "Channel Islands",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "GG",
    "alpha-3": "GGY",
    name: "Guernsey",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:GG",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "830",
    phone: "44-1481",
  },
  {
    "country-code": "324",
    image: "/flags/gn.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    name: "Guinea",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GN",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "224",
  },
  {
    "country-code": "624",
    image: "/flags/gw.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    name: "Guinea-Bissau",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:GW",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "245",
  },
  {
    "country-code": "328",
    image: "/flags/gy.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    name: "Guyana",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GY",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "592",
  },
  {
    "country-code": "332",
    image: "/flags/ht.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    name: "Haiti",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:HT",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "509",
  },
  {
    "country-code": "334",
    image: "/flags/hm.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "HM",
    "alpha-3": "HMD",
    name: "Heard Island and McDonald Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:HM",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: " ",
  },
  {
    "country-code": "336",
    image: "/flags/va.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "VA",
    "alpha-3": "VAT",
    name: "Holy See",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:VA",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "379",
  },
  {
    "country-code": "340",
    image: "/flags/hn.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "HN",
    "alpha-3": "HND",
    name: "Honduras",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:HN",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "504",
  },
  {
    "country-code": "344",
    image: "/flags/hk.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "HK",
    "alpha-3": "HKG",
    name: "Hong Kong",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:HK",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "852",
  },
  {
    "country-code": "348",
    image: "/flags/hu.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    name: "Hungary",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:HU",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "36",
  },
  {
    "country-code": "352",
    image: "/flags/is.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    name: "Iceland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:IS",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "354",
  },
  {
    "country-code": "356",
    image: "/flags/in.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "IN",
    "alpha-3": "IND",
    name: "India",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:IN",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "91",
  },
  {
    "country-code": "360",
    image: "/flags/id.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    name: "Indonesia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:ID",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "62",
  },
  {
    "country-code": "364",
    image: "/flags/ir.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    name: "Iran (Islamic Republic of)",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:IR",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "98",
  },
  {
    "country-code": "368",
    image: "/flags/iq.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    name: "Iraq",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:IQ",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "964",
  },
  {
    "country-code": "372",
    image: "/flags/ie.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    name: "Ireland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:IE",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "353",
  },
  {
    "country-code": "833",
    image: "/flags/im.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "IM",
    "alpha-3": "IMN",
    name: "Isle of Man",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:IM",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "44-1624",
  },
  {
    "country-code": "376",
    image: "/flags/il.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    name: "Israel",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:IL",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "972",
  },
  {
    "country-code": "380",
    image: "/flags/it.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    name: "Italy",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:IT",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "39",
  },
  {
    "country-code": "388",
    image: "/flags/jm.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    name: "Jamaica",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:JM",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-876",
  },
  {
    "country-code": "392",
    image: "/flags/jp.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    name: "Japan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:JP",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "81",
  },
  {
    "country-code": "832",
    image: "/flags/je.svg",
    "intermediate-region": "Channel Islands",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "JE",
    "alpha-3": "JEY",
    name: "Jersey",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:JE",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "830",
    phone: "44-1534",
  },
  {
    "country-code": "400",
    image: "/flags/jo.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    name: "Jordan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:JO",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "962",
  },
  {
    "country-code": "398",
    image: "/flags/kz.svg",
    "intermediate-region": "",
    "sub-region-code": "143",
    "region-code": "142",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    name: "Kazakhstan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KZ",
    "sub-region": "Central Asia",
    "intermediate-region-code": "",
    phone: "7",
  },
  {
    "country-code": "404",
    image: "/flags/ke.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    name: "Kenya",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:KE",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "254",
  },
  {
    "country-code": "296",
    image: "/flags/ki.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    name: "Kiribati",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:KI",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "686",
  },
  {
    "country-code": "408",
    image: "/flags/kp.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    name: "Korea (Democratic People's Republic of)",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KP",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "850",
  },
  {
    "country-code": "410",
    image: "/flags/kr.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    name: "Korea (Republic of)",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KR",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "82",
  },
  {
    "country-code": "414",
    image: "/flags/kw.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    name: "Kuwait",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KW",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "965",
  },
  {
    "country-code": "417",
    image: "/flags/kg.svg",
    "intermediate-region": "",
    "sub-region-code": "143",
    "region-code": "142",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    name: "Kyrgyzstan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:KG",
    "sub-region": "Central Asia",
    "intermediate-region-code": "",
    phone: "996",
  },
  {
    "country-code": "418",
    image: "/flags/la.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    name: "Lao People's Democratic Republic",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:LA",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "856",
  },
  {
    "country-code": "428",
    image: "/flags/lv.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    name: "Latvia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:LV",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "371",
  },
  {
    "country-code": "422",
    image: "/flags/lb.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    name: "Lebanon",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:LB",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "961",
  },
  {
    "country-code": "426",
    image: "/flags/ls.svg",
    "intermediate-region": "Southern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    name: "Lesotho",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:LS",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "018",
    phone: "266",
  },
  {
    "country-code": "430",
    image: "/flags/lr.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    name: "Liberia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:LR",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "231",
  },
  {
    "country-code": "434",
    image: "/flags/ly.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    name: "Libya",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:LY",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "218",
  },
  {
    "country-code": "438",
    image: "/flags/li.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    name: "Liechtenstein",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:LI",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "423",
  },
  {
    "country-code": "440",
    image: "/flags/lt.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    name: "Lithuania",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:LT",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "370",
  },
  {
    "country-code": "442",
    image: "/flags/lu.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    name: "Luxembourg",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:LU",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "352",
  },
  {
    "country-code": "446",
    image: "/flags/mo.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "MO",
    "alpha-3": "MAC",
    name: "Macao",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:MO",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "853",
  },
  {
    "country-code": "807",
    image: "/flags/mk.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    name: "Macedonia (the former Yugoslav Republic of)",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:MK",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "389",
  },
  {
    "country-code": "450",
    image: "/flags/mg.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    name: "Madagascar",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MG",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "261",
  },
  {
    "country-code": "454",
    image: "/flags/mw.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    name: "Malawi",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MW",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "265",
  },
  {
    "country-code": "458",
    image: "/flags/my.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    name: "Malaysia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:MY",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "60",
  },
  {
    "country-code": "462",
    image: "/flags/mv.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    name: "Maldives",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:MV",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "960",
  },
  {
    "country-code": "466",
    image: "/flags/ml.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    name: "Mali",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ML",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "223",
  },
  {
    "country-code": "470",
    image: "/flags/mt.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    name: "Malta",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:MT",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "356",
  },
  {
    "country-code": "584",
    image: "/flags/mh.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    name: "Marshall Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:MH",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "692",
  },
  {
    "country-code": "474",
    image: "/flags/mq.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
    name: "Martinique",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:MQ",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "596",
  },
  {
    "country-code": "478",
    image: "/flags/mr.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    name: "Mauritania",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MR",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "222",
  },
  {
    "country-code": "480",
    image: "/flags/mu.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    name: "Mauritius",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MU",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "230",
  },
  {
    "country-code": "175",
    image: "/flags/yt.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "YT",
    "alpha-3": "MYT",
    name: "Mayotte",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:YT",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "262",
  },
  {
    "country-code": "583",
    image: "/flags/fm.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    name: "Micronesia (Federated States of)",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:FM",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "691",
  },
  {
    "country-code": "498",
    image: "/flags/md.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    name: "Moldova (Republic of)",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:MD",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "373",
  },
  {
    "country-code": "492",
    image: "/flags/mc.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    name: "Monaco",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:MC",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "377",
  },
  {
    "country-code": "496",
    image: "/flags/mn.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    name: "Mongolia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:MN",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "976",
  },
  {
    "country-code": "499",
    image: "/flags/me.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "ME",
    "alpha-3": "MNE",
    name: "Montenegro",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:ME",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "382",
  },
  {
    "country-code": "500",
    image: "/flags/ms.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "MS",
    "alpha-3": "MSR",
    name: "Montserrat",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:MS",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-664",
  },
  {
    "country-code": "504",
    image: "/flags/ma.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    name: "Morocco",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MA",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "212",
  },
  {
    "country-code": "508",
    image: "/flags/mz.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    name: "Mozambique",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:MZ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "258",
  },
  {
    "country-code": "104",
    image: "/flags/mm.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    name: "Myanmar",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:MM",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "95",
  },
  {
    "country-code": "516",
    image: "/flags/na.svg",
    "intermediate-region": "Southern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    name: "Namibia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:NA",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "018",
    phone: "264",
  },
  {
    "country-code": "520",
    image: "/flags/nr.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    name: "Nauru",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:NR",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "674",
  },
  {
    "country-code": "524",
    image: "/flags/np.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    name: "Nepal",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:NP",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "977",
  },
  {
    "country-code": "528",
    image: "/flags/nl.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    name: "Netherlands",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:NL",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "31",
  },
  {
    "country-code": "540",
    image: "/flags/nc.svg",
    "intermediate-region": "",
    "sub-region-code": "054",
    "region-code": "009",
    "alpha-2": "NC",
    "alpha-3": "NCL",
    name: "New Caledonia",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:NC",
    "sub-region": "Melanesia",
    "intermediate-region-code": "",
    phone: "687",
  },
  {
    "country-code": "554",
    image: "/flags/nz.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    name: "New Zealand",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:NZ",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: "64",
  },
  {
    "country-code": "558",
    image: "/flags/ni.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    name: "Nicaragua",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:NI",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "505",
  },
  {
    "country-code": "562",
    image: "/flags/ne.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "NE",
    "alpha-3": "NER",
    name: "Niger",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:NE",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "227",
  },
  {
    "country-code": "566",
    image: "/flags/ng.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    name: "Nigeria",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:NG",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "234",
  },
  {
    "country-code": "570",
    image: "/flags/nu.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "NU",
    "alpha-3": "NIU",
    name: "Niue",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:NU",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "683",
  },
  {
    "country-code": "574",
    image: "/flags/nf.svg",
    "intermediate-region": "",
    "sub-region-code": "053",
    "region-code": "009",
    "alpha-2": "NF",
    "alpha-3": "NFK",
    name: "Norfolk Island",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:NF",
    "sub-region": "Australia and New Zealand",
    "intermediate-region-code": "",
    phone: "672",
  },
  {
    "country-code": "580",
    image: "/flags/mp.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "MP",
    "alpha-3": "MNP",
    name: "Northern Mariana Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:MP",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "1-670",
  },
  {
    "country-code": "578",
    image: "/flags/no.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    name: "Norway",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:NO",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "47",
  },
  {
    "country-code": "512",
    image: "/flags/om.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    name: "Oman",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:OM",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "968",
  },
  {
    "country-code": "586",
    image: "/flags/pk.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    name: "Pakistan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:PK",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "92",
  },
  {
    "country-code": "585",
    image: "/flags/pw.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    name: "Palau",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:PW",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "680",
  },
  {
    "country-code": "275",
    image: "/flags/ps.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    name: "Palestine, State of",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:PS",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "970",
  },
  {
    "country-code": "591",
    image: "/flags/pa.svg",
    "intermediate-region": "Central America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    name: "Panama",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:PA",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "013",
    phone: "507",
  },
  {
    "country-code": "598",
    image: "/flags/pg.svg",
    "intermediate-region": "",
    "sub-region-code": "054",
    "region-code": "009",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    name: "Papua New Guinea",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:PG",
    "sub-region": "Melanesia",
    "intermediate-region-code": "",
    phone: "675",
  },
  {
    "country-code": "600",
    image: "/flags/py.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    name: "Paraguay",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:PY",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "595",
  },
  {
    "country-code": "604",
    image: "/flags/pe.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "PE",
    "alpha-3": "PER",
    name: "Peru",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:PE",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "51",
  },
  {
    "country-code": "608",
    image: "/flags/ph.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    name: "Philippines",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:PH",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "63",
  },
  {
    "country-code": "612",
    image: "/flags/pn.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "PN",
    "alpha-3": "PCN",
    name: "Pitcairn",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:PN",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "870",
  },
  {
    "country-code": "616",
    image: "/flags/pl.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "PL",
    "alpha-3": "POL",
    name: "Poland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:PL",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "48",
  },
  {
    "country-code": "620",
    image: "/flags/pt.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    name: "Portugal",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:PT",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "351",
  },
  {
    "country-code": "630",
    image: "/flags/pr.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "PR",
    "alpha-3": "PRI",
    name: "Puerto Rico",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:PR",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-787 and 1-939",
  },
  {
    "country-code": "634",
    image: "/flags/qa.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    name: "Qatar",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:QA",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "974",
  },
  {
    "country-code": "638",
    image: "/flags/re.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "RE",
    "alpha-3": "REU",
    name: "Réunion",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:RE",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "262",
  },
  {
    "country-code": "642",
    image: "/flags/ro.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    name: "Romania",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:RO",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "40",
  },
  {
    "country-code": "643",
    image: "/flags/ru.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    name: "Russian Federation",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:RU",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "7",
  },
  {
    "country-code": "646",
    image: "/flags/rw.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    name: "Rwanda",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:RW",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "250",
  },
  {
    "country-code": "652",
    image: "/flags/bl.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "BL",
    "alpha-3": "BLM",
    name: "Saint Barthélemy",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:BL",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "590",
  },
  {
    "country-code": "654",
    image: "/flags/sh.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SH",
    "alpha-3": "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SH",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "290",
  },
  {
    "country-code": "659",
    image: "/flags/kn.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    name: "Saint Kitts and Nevis",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:KN",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-869",
  },
  {
    "country-code": "662",
    image: "/flags/lc.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    name: "Saint Lucia",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:LC",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-758",
  },
  {
    "country-code": "663",
    image: "/flags/mf.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "MF",
    "alpha-3": "MAF",
    name: "Saint Martin (French part)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:MF",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "590",
  },
  {
    "country-code": "666",
    image: "/flags/pm.svg",
    "intermediate-region": "",
    "sub-region-code": "021",
    "region-code": "019",
    "alpha-2": "PM",
    "alpha-3": "SPM",
    name: "Saint Pierre and Miquelon",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:PM",
    "sub-region": "Northern America",
    "intermediate-region-code": "",
    phone: "508",
  },
  {
    "country-code": "670",
    image: "/flags/vc.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    name: "Saint Vincent and the Grenadines",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:VC",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-784",
  },
  {
    "country-code": "882",
    image: "/flags/ws.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    name: "Samoa",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:WS",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "685",
  },
  {
    "country-code": "674",
    image: "/flags/sm.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    name: "San Marino",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:SM",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "378",
  },
  {
    "country-code": "678",
    image: "/flags/st.svg",
    "intermediate-region": "Middle Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ST",
    "alpha-3": "STP",
    name: "Sao Tome and Principe",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ST",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "017",
    phone: "239",
  },
  {
    "country-code": "682",
    image: "/flags/sa.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    name: "Saudi Arabia",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:SA",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "966",
  },
  {
    "country-code": "686",
    image: "/flags/sn.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    name: "Senegal",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SN",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "221",
  },
  {
    "country-code": "688",
    image: "/flags/rs.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "RS",
    "alpha-3": "SRB",
    name: "Serbia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:RS",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "381",
  },
  {
    "country-code": "690",
    image: "/flags/sc.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    name: "Seychelles",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SC",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "248",
  },
  {
    "country-code": "694",
    image: "/flags/sl.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    name: "Sierra Leone",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SL",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "232",
  },
  {
    "country-code": "702",
    image: "/flags/sg.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    name: "Singapore",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:SG",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "65",
  },
  {
    "country-code": "534",
    image: "/flags/sx.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "SX",
    "alpha-3": "SXM",
    name: "Sint Maarten (Dutch part)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:SX",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "599",
  },
  {
    "country-code": "703",
    image: "/flags/sk.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    name: "Slovakia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:SK",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "421",
  },
  {
    "country-code": "705",
    image: "/flags/si.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    name: "Slovenia",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:SI",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "386",
  },
  {
    "country-code": "090",
    image: "/flags/sb.svg",
    "intermediate-region": "",
    "sub-region-code": "054",
    "region-code": "009",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    name: "Solomon Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:SB",
    "sub-region": "Melanesia",
    "intermediate-region-code": "",
    phone: "677",
  },
  {
    "country-code": "706",
    image: "/flags/so.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    name: "Somalia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SO",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "252",
  },
  {
    "country-code": "710",
    image: "/flags/za.svg",
    "intermediate-region": "Southern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    name: "South Africa",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ZA",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "018",
    phone: "27",
  },
  {
    "country-code": "239",
    image: "/flags/gs.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "GS",
    "alpha-3": "SGS",
    name: "South Georgia and the South Sandwich Islands",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:GS",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "",
  },
  {
    "country-code": "728",
    image: "/flags/ss.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "SS",
    "alpha-3": "SSD",
    name: "South Sudan",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SS",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "211",
  },
  {
    "country-code": "724",
    image: "/flags/es.svg",
    "intermediate-region": "",
    "sub-region-code": "039",
    "region-code": "150",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    name: "Spain",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:ES",
    "sub-region": "Southern Europe",
    "intermediate-region-code": "",
    phone: "34",
  },
  {
    "country-code": "144",
    image: "/flags/lk.svg",
    "intermediate-region": "",
    "sub-region-code": "034",
    "region-code": "142",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    name: "Sri Lanka",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:LK",
    "sub-region": "Southern Asia",
    "intermediate-region-code": "",
    phone: "94",
  },
  {
    "country-code": "729",
    image: "/flags/sd.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    name: "Sudan",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:SD",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "249",
  },
  {
    "country-code": "740",
    image: "/flags/sr.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    name: "Suriname",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:SR",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "597",
  },
  {
    "country-code": "744",
    image: "/flags/sj.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
    name: "Svalbard and Jan Mayen",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:SJ",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "47",
  },
  {
    "country-code": "752",
    image: "/flags/se.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    name: "Sweden",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:SE",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "46",
  },
  {
    "country-code": "756",
    image: "/flags/ch.svg",
    "intermediate-region": "",
    "sub-region-code": "155",
    "region-code": "150",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    name: "Switzerland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:CH",
    "sub-region": "Western Europe",
    "intermediate-region-code": "",
    phone: "41",
  },
  {
    "country-code": "760",
    image: "/flags/sy.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    name: "Syrian Arab Republic",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:SY",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "963",
  },
  {
    "country-code": "158",
    image: "/flags/tw.svg",
    "intermediate-region": "",
    "sub-region-code": "030",
    "region-code": "142",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    name: "Taiwan, Province of China",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TW",
    "sub-region": "Eastern Asia",
    "intermediate-region-code": "",
    phone: "886",
  },
  {
    "country-code": "762",
    image: "/flags/tj.svg",
    "intermediate-region": "",
    "sub-region-code": "143",
    "region-code": "142",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    name: "Tajikistan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TJ",
    "sub-region": "Central Asia",
    "intermediate-region-code": "",
    phone: "992",
  },
  {
    "country-code": "834",
    image: "/flags/tz.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    name: "Tanzania, United Republic of",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:TZ",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "255",
  },
  {
    "country-code": "764",
    image: "/flags/th.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "TH",
    "alpha-3": "THA",
    name: "Thailand",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TH",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "66",
  },
  {
    "country-code": "626",
    image: "/flags/tl.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    name: "Timor-Leste",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TL",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "670",
  },
  {
    "country-code": "768",
    image: "/flags/tg.svg",
    "intermediate-region": "Western Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    name: "Togo",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:TG",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "011",
    phone: "228",
  },
  {
    "country-code": "772",
    image: "/flags/tk.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "TK",
    "alpha-3": "TKL",
    name: "Tokelau",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:TK",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "690",
  },
  {
    "country-code": "776",
    image: "/flags/to.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "TO",
    "alpha-3": "TON",
    name: "Tonga",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:TO",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "676",
  },
  {
    "country-code": "780",
    image: "/flags/tt.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    name: "Trinidad and Tobago",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:TT",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-868",
  },
  {
    "country-code": "788",
    image: "/flags/tn.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    name: "Tunisia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:TN",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "216",
  },
  {
    "country-code": "792",
    image: "/flags/tr.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    name: "Turkey",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TR",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "90",
  },
  {
    "country-code": "795",
    image: "/flags/tm.svg",
    "intermediate-region": "",
    "sub-region-code": "143",
    "region-code": "142",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    name: "Turkmenistan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:TM",
    "sub-region": "Central Asia",
    "intermediate-region-code": "",
    phone: "993",
  },
  {
    "country-code": "796",
    image: "/flags/tc.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "TC",
    "alpha-3": "TCA",
    name: "Turks and Caicos Islands",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:TC",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-649",
  },
  {
    "country-code": "798",
    image: "/flags/tv.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    name: "Tuvalu",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:TV",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "688",
  },
  {
    "country-code": "800",
    image: "/flags/ug.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    name: "Uganda",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:UG",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "256",
  },
  {
    "country-code": "804",
    image: "/flags/ua.svg",
    "intermediate-region": "",
    "sub-region-code": "151",
    "region-code": "150",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    name: "Ukraine",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:UA",
    "sub-region": "Eastern Europe",
    "intermediate-region-code": "",
    phone: "380",
  },
  {
    "country-code": "784",
    image: "/flags/ae.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    name: "United Arab Emirates",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:AE",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "971",
  },
  {
    "country-code": "826",
    image: "/flags/gb.svg",
    "intermediate-region": "",
    "sub-region-code": "154",
    "region-code": "150",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
    region: "Europe",
    "iso_3166-2": "ISO 3166-2:GB",
    "sub-region": "Northern Europe",
    "intermediate-region-code": "",
    phone: "44",
  },
  {
    "country-code": "840",
    image: "/flags/us.svg",
    "intermediate-region": "",
    "sub-region-code": "021",
    "region-code": "019",
    "alpha-2": "US",
    "alpha-3": "USA",
    name: "United States of America",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:US",
    "sub-region": "Northern America",
    "intermediate-region-code": "",
    phone: "1",
  },
  {
    "country-code": "581",
    image: "/flags/um.svg",
    "intermediate-region": "",
    "sub-region-code": "057",
    "region-code": "009",
    "alpha-2": "UM",
    "alpha-3": "UMI",
    name: "United States Minor Outlying Islands",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:UM",
    "sub-region": "Micronesia",
    "intermediate-region-code": "",
    phone: "1",
  },
  {
    "country-code": "858",
    image: "/flags/uy.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "UY",
    "alpha-3": "URY",
    name: "Uruguay",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:UY",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "598",
  },
  {
    "country-code": "860",
    image: "/flags/uz.svg",
    "intermediate-region": "",
    "sub-region-code": "143",
    "region-code": "142",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    name: "Uzbekistan",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:UZ",
    "sub-region": "Central Asia",
    "intermediate-region-code": "",
    phone: "998",
  },
  {
    "country-code": "548",
    image: "/flags/vu.svg",
    "intermediate-region": "",
    "sub-region-code": "054",
    "region-code": "009",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    name: "Vanuatu",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:VU",
    "sub-region": "Melanesia",
    "intermediate-region-code": "",
    phone: "678",
  },
  {
    "country-code": "862",
    image: "/flags/ve.svg",
    "intermediate-region": "South America",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    name: "Venezuela (Bolivarian Republic of)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:VE",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "005",
    phone: "58",
  },
  {
    "country-code": "704",
    image: "/flags/vn.svg",
    "intermediate-region": "",
    "sub-region-code": "035",
    "region-code": "142",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    name: "Viet Nam",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:VN",
    "sub-region": "South-eastern Asia",
    "intermediate-region-code": "",
    phone: "84",
  },
  {
    "country-code": "092",
    image: "/flags/vg.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "VG",
    "alpha-3": "VGB",
    name: "Virgin Islands (British)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:VG",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-284",
  },
  {
    "country-code": "850",
    image: "/flags/vi.svg",
    "intermediate-region": "Caribbean",
    "sub-region-code": "419",
    "region-code": "019",
    "alpha-2": "VI",
    "alpha-3": "VIR",
    name: "Virgin Islands (U.S.)",
    region: "Americas",
    "iso_3166-2": "ISO 3166-2:VI",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region-code": "029",
    phone: "1-340",
  },
  {
    "country-code": "876",
    image: "/flags/wf.svg",
    "intermediate-region": "",
    "sub-region-code": "061",
    "region-code": "009",
    "alpha-2": "WF",
    "alpha-3": "WLF",
    name: "Wallis and Futuna",
    region: "Oceania",
    "iso_3166-2": "ISO 3166-2:WF",
    "sub-region": "Polynesia",
    "intermediate-region-code": "",
    phone: "681",
  },
  {
    "country-code": "732",
    image: "/flags/eh.svg",
    "intermediate-region": "",
    "sub-region-code": "015",
    "region-code": "002",
    "alpha-2": "EH",
    "alpha-3": "ESH",
    name: "Western Sahara",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:EH",
    "sub-region": "Northern Africa",
    "intermediate-region-code": "",
    phone: "212",
  },
  {
    "country-code": "887",
    image: "/flags/ye.svg",
    "intermediate-region": "",
    "sub-region-code": "145",
    "region-code": "142",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    name: "Yemen",
    region: "Asia",
    "iso_3166-2": "ISO 3166-2:YE",
    "sub-region": "Western Asia",
    "intermediate-region-code": "",
    phone: "967",
  },
  {
    "country-code": "894",
    image: "/flags/zm.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    name: "Zambia",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ZM",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "260",
  },
  {
    "country-code": "716",
    image: "/flags/zw.svg",
    "intermediate-region": "Eastern Africa",
    "sub-region-code": "202",
    "region-code": "002",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    name: "Zimbabwe",
    region: "Africa",
    "iso_3166-2": "ISO 3166-2:ZW",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region-code": "014",
    phone: "263",
  },
];
