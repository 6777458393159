module.exports = {
  es: {
    HSM: "HSM",
    POLL: "Conversación",
    DELIVERED: "enviado",
    READ: "leído",
    RECEIVED: "en proceso",
    FAILURE: "envío fallido",
    SEND: "enviado",
    IN_PROGRESS: "iniciada",
    FINISHED: "finalizada",
    RESPONDED: "contestada",
    extratitle: "Descripción:",
    failureDes: "Motivo de falla:",
    origintitle: "Envío realizado desde:",
    agenttitle: "Enviada por:",
    nametitle: "Nombre:",
    originAGENT: "Plataforma de vendedores",
    originTREBLE_SIMPLE: "App de treble",
    originTREBLE_CSV: "App de treble",
    originHUBSPOT_WORKFLOW: "Workflows - HubSpot",
    originHUBSPOT_CONTACT_LIST: "Tabla de contactos - HubSpot",
    originHUBSPOT_DEAL: "Deal - Hubspot",
    originHUBSPOT_CONTACT: "Contacto - Hubspot",
    originHUBSPOT_TICKET: "Ticket - Hubspot",
    noInfo: "Información no disponible",
    DELIVEREDdes:
      "La conversación ha sido recibida por el destinatario a espera de ser leída.",
    READdes: "La conversación ha sido leída por el destinatario.",
    RECEIVEDdes:
      "La conversación se está procesando para ser enviada. Esto puede demorar entre 15 minutos y 1 hora.",
    SENDdes: "La conversación ha sido enviada a la espera de ser entregada.",
    RESPONDEDdes: "Conversación contestada, aún no llega a vendedores.",
    FINISHEDdes: "Interacción finalizada.",
    FAILURE_BY_HUMAN_HANDOVER:
      "Falla de entrega por una sesión abierta con vendedores.",
    RETRY: "Falla general, se intentará nuevamente en un momento.",
    PUSH_NOTIFICATION: "Notificación.",
    REVOKED: "Eliminado.",
    VALIDATION_ERROR: "Error de validación.",
    INVALID_PHONE: "Número inválido",
    FAILURE_BY_RATE_LIMIT: "Falla por limite de tier.",
    COMPANY_RATE_LIMIT: "Falla por límite mensual de compañía",
    FAILURE_BY_TIER_CAPACITY: "Falla por límite de tier.",
    MISSING_PARAMETER: "Falla por falta de parámetro.",
    PARAMETER_MISMATCH: "Falla por problemas en los parámetros.",
    FAILURE_BY_BLOCKED_CONTACT: "Falla por contacto bloqueado.",
    FAILURE_BY_OPTOUT_CONTACT: "Falla por contacto en exclusión.",
    FAILURE_BY_UNABLE_TO_CONTACT: "Falla por bloqueo o versión",
    INVALID_POLL: "Falla por conversación inválida.",
    CHARACTER_LIMIT_HEADER: "Falla por límite de carácteres en el encabezado.",
    CHARACTER_LIMIT_BODY:
      "Falla por límite de caracteres en el cuerpo del HSM.",
    assignedAgent: "Vendedor asignado",
    transferedAgent: "Conversación transferida",
    conversation: "Conversación",
    close: "Cerrar panel de conversación",
    ADMIN: "Administrador",
    AGENT: "Vendedor",
    loadingEvents: "Los eventos se están cargando...",
    noEventsTitle: "Este contacto no tiene eventos registrados",
    noEventsDes: "Comienza tu primera interacción con este contacto",
    hsmPH: "Buscar una plantilla HSM...",
    sendHsm: "Enviar plantilla HSM",
    eventsHistory: "Historal de interacciones",
    viewConvDet: "Ver historial de la conversación",
    notes: "Notas",
    noResults: "Sin resultados",
    noHsm: "No hay hsm que hagan match con tu búsqueda",
    pausedHsmText:
      "Actualmente esta plantilla HSM ha alcanzado la calificación de calidad más baja (rojo) y se encuentra inhabilitada. Meta pausa de manera automática todas las plantillas que hayan alcanzado esta instancia para proteger la calificación de calidad del número de teléfono. Para mayor información puedes acceder a:",
    metaDoc: "documentación META",
    pausedHsm: "HSM en pausa",
    activeContactConversation:
      "No puedes enviar plantillas HSM actualmente ya que el agente {{AGENT}} tiene una conversación activa con este usuario",
  },
  en: {
    HSM: "HSM",
    POLL: "Conversation",
    DELIVERED: "delivered",
    READ: "read",
    RECEIVED: "in process",
    FAILURE: "send failed",
    SEND: "sent",
    IN_PROGRESS: "started",
    FINISHED: "finished",
    RESPONDED: "answered",
    extratitle: "Description:",
    failureDes: "Failure Reason:",
    origintitle: "Shipped from:",
    agenttitle: "Submitted by:",
    nametitle: "Name:",
    originAGENT: "Sales platform",
    originTREBLE_SIMPLE: "Treble app",
    originTREBLE_CSV: "Treble app",
    originHUBSPOT_WORKFLOW: "Workflows - HubSpot",
    originHUBSPOT_CONTACT_LIST: "Contact Table - HubSpot",
    originHUBSPOT_DEAL: "Deal - Hubspot",
    originHUBSPOT_CONTACT: "Contact - Hubspot",
    originHUBSPOT_TICKET: "Ticket - Hubspot",
    noInfo: "Information not available",
    DELIVEREDdes:
      "The conversation has been received by the recipient waiting to be read.",
    READdes: "The conversation has been read by the recipient.",
    RECEIVEDdes:
      "The conversation is being processed to be sent. This can take between 15 minutes and 1 hour.",
    SENDdes: "The conversation has been sent waiting to be delivered.",
    RESPONDEDdes: "Conversation answered, has not yet reached salespeople.",
    FINISHEDdes: "Interaction finished.",
    FAILURE_BY_HUMAN_HANDOVER:
      "Handover failure due to an open session with salespeople.",
    RETRY: "General failure, will try again in a moment.",
    PUSH_NOTIFICATION: "Notification.",
    REVOKED: "Removed.",
    VALIDATION_ERROR: "Validation error.",
    INVALID_PHONE: "Invalid phone number",
    FAILURE_BY_RATE_LIMIT: "Failure due to tier limit.",
    COMPANY_RATE_LIMIT: "Failure due to monthly company limit",
    FAILURE_BY_TIER_CAPACITY: "Failure due to tier limit.",
    MISSING_PARAMETER: "Failure due to missing parameter.",
    PARAMETER_MISMATCH: "Failure due to parameter problems.",
    FAILURE_BY_BLOCKED_CONTACT: "Failure due to blocked contact.",
    FAILURE_BY_OPTOUT_CONTACT: "Failure by optout contact.",
    FAILURE_BY_UNABLE_TO_CONTACT: "Failure by blocked or version",
    INVALID_POLL: "Failed due to invalid conversation.",
    CHARACTER_LIMIT_HEADER: "Failed due to character limit in header.",
    CHARACTER_LIMIT_BODY:
      "Failure due to character limit in the body of the HSM.",
    assignedAgent: "Assigned salesperson",
    transferedAgent: "Transferred conversation",
    conversation: "Conversation",
    close: "Close chat panel",
    ADMIN: "Administrator",
    AGENT: "Salesperson",
    loadingEvents: "Events are loading...",
    noEventsTitle: "This contact has no registered events",
    noEventsDes: "Begin your first interaction with this contact",
    hsmPH: "Search for an HSM template...",
    sendHsm: "Send HSM Template",
    eventsHistory: "Interaction History",
    viewConvDet: "View conversation history",
    notes: "Notes",
    noResults: "No results",
    noHsm: "There are no hsm matching your search",
    pausedHsmText:
      "Currently this HSM template has reached the lowest quality rating (red) and is disabled. Meta automatically pauses all templates that have reached this instance to protect the phone number quality rating. For more information you can access:",
    metaDoc: "META documentation",
    pausedHsm: "HSM paused",
    activeContactConversation:
      "You cannot send HSM templates currently because the agent {{AGENT}} has an active conversation with this user",
  },
  pt: {
    HSM: "HSM",
    POLL: "Conversa",
    DELIVERED: "entregue",
    READ: "ler",
    RECEIVED: "em andamento",
    FAILURE: "envio falhou",
    SEND: "enviado",
    IN_PROGRESS: "iniciado",
    FINISHED: "concluído",
    RESPONDED: "respondido",
    extratitle: "Descrição:",
    failureDes: "Motivo da falha:",
    origintitle: "Enviado de:",
    agenttitle: "Enviado por:",
    nametitle: "Nome:",
    originAGENT: "Plataforma do Vendedor",
    originTREBLE_SIMPLE: "Aplicativo de agudos",
    originTREBLE_CSV: "Aplicativo de agudos",
    originHUBSPOT_WORKFLOW: "Fluxos de trabalho - HubSpot",
    originHUBSPOT_CONTACT_LIST: "Tabela de contato - HubSpot",
    originHUBSPOT_DEAL: "Negócio - Hubspot",
    originHUBSPOT_CONTACT: "Contato - Hubspot",
    originHUBSPOT_TICKET: "Ticket - Hubspot",
    noInfo: "Informações não disponíveis",
    DELIVEREDdes:
      "A conversa foi recebida pelo destinatário aguardando para ser lida.",
    READdes: "A conversa foi lida pelo destinatário.",
    RECEIVEDdes:
      "A conversa está sendo processada para ser enviada. Isso pode levar entre 15 minutos e 1 hora.",
    SENDdes: "A conversa foi enviada aguardando entrega.",
    RESPONDEDdes: "Conversa respondida, ainda não chegou aos vendedores.",
    FINISHEDdes: "Interação concluída.",
    FAILURE_BY_HUMAN_HANDOVER:
      "Falha de entrega devido a uma sessão aberta com vendedores.",
    RETRY: "Falha geral, tentará novamente em um momento.",
    PUSH_NOTIFICATION: "Notificação.",
    REVOKED: "Removido.",
    VALIDATION_ERROR: "Erro de validação.",
    INVALID_PHONE: "Número de telefone inválido",
    FAILURE_BY_RATE_LIMIT: "Falha devido ao limite de nível.",
    COMPANY_RATE_LIMIT: "Falha devido ao limite mensal da empresa",
    FAILURE_BY_TIER_CAPACITY: "Falha devido ao limite de nível.",
    MISSING_PARAMETER: "Falha devido a parâmetro ausente.",
    PARAMETER_MISMATCH: "Falha devido a problemas de parâmetro.",
    FAILURE_BY_BLOCKED_CONTACT: "Falha devido a contato bloqueado.",
    FAILURE_BY_OPTOUT_CONTACT: "Falha por contato optado.",
    FAILURE_BY_UNABLE_TO_CONTACT: "Falha por bloqueio ou versão",
    INVALID_POLL: "Falha devido a conversa inválida.",
    CHARACTER_LIMIT_HEADER:
      "Falha devido ao limite de caracteres no cabeçalho.",
    CHARACTER_LIMIT_BODY:
      "Falha devido ao limite de caracteres no corpo do HSM.",
    assignedAgent: "Vendedor atribuído",
    transferedAgent: "Conversa transferida",
    conversation: "Conversa",
    close: "Fechar painel de bate-papo",
    ADMIN: "Administrador",
    AGENT: "Vendedor",
    loadingEvents: "Eventos carregando...",
    noEventsTitle: "Este contato não possui eventos registrados",
    noEventsDes: "Comece sua primeira interação com este contato",
    hsmPH: "Procurar um modelo HSM...",
    sendHsm: "Enviar modelo HSM",
    eventsHistory: "Histórico de Interação",
    viewConvDet: "Ver histórico de conversas",
    notes: "Notas",
    noResults: "Nenhum resultado",
    noHsm: "Não há hsm que corresponda à sua pesquisa",
    pausedHsmText:
      "Atualmente, este modelo HSM atingiu a classificação de qualidade mais baixa (vermelho) e está desabilitado. Meta pausa automaticamente todos os modelos que atingiram esta instância para proteger a classificação de qualidade do número de telefone. Para mais informações, você pode acessar:",
    metaDoc: "documentação META",
    pausedHsm: "HSM pausado",
    activeContactConversation:
      "Atualmente, você não pode enviar modelos HSM porque o agente {{AGENT}} tem uma conversa ativa com este usuário",
  },
};
