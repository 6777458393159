import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

/*
	props:
		- title str: dropdown button text
		- hideOnBodyClick bool false: Determine whether to hide the drowpdown when an option is clicked
	
*/

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClickOutside = (evt) => {
    if (this.props.hideOnClickOutside === false) return;
    if (this.props.handleCloseDropdown) this.props.handleCloseDropdown();
    this.setState({ open: false });
  };

  onBodyClick = () => {
    if (this.props.hideOnBodyClick !== true) return;
    this.setState({ open: false });
  };

  toggle = (toggleBoolean) => {
    this.setState({ open: toggleBoolean });
  };

  renderToggle = () => {
    if (this.props.triggerComponent) {
      return (
        <div
          onClick={() => {
            if (this.props.handleCloseDropdown)
              this.props.handleCloseDropdown();
            this.setState({ open: !this.state.open });
          }}
        >
          {this.props.triggerComponent}
        </div>
      );
    } else {
      return (
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p className="r">{this.props.title}</p>
          <span className="icon is-small">
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </button>
      );
    }
  };

  renderMenuHeader = () => {
    if (this.props.menuHeader !== undefined) {
      return (
        <div className="dropdown-menu-header">
          <div
            className="icon icon--chevron-left"
            onClick={() => this.setState({ open: false })}
          />
          <h1>{this.props.menuHeader}</h1>
        </div>
      );
    }
    return;
  };

  render = () => {
    let direction = this.props.direction
      ? "direction-" + this.props.direction
      : "";
    let className = this.props.className ? this.props.className : "";
    let subClassName = this.props.subClassName ? this.props.subClassName : "";
    return (
      <div
        className={`dropdown select-dropdown-main ${
          this.state.open ? "is-active" : ""
        } ${this.props.menuWidth ? `width-${this.props.menuWidth}` : ""} 
        ${
          this.props.buttonWidth ? `button-width-${this.props.buttonWidth}` : ""
        }
        ${className}`}
      >
        <div className={`dropdown-trigger`}>{this.renderToggle()}</div>
        <div
          className={`dropdown-menu ${direction} ${subClassName}`}
          role="menu"
        >
          <div className="dropdown-content-container">
            <div className="dropdown-content" onClick={this.onBodyClick}>
              {this.renderMenuHeader()}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default onClickOutside(Dropdown);
