module.exports = {
  es: {
    tagPlaceholder: "Selecciona un grupo",
    agentPlaceholder: "Selecciona un vendedor",
    missingField: "Por favor completa este campo para continuar",
    searchName: "Buscar por nombre...",
    cancel: "Cancelar",
    transfer: "Transferir",
    transferChat: "Transferir conversación",
    transferToTeam: "Transferir al equipo",
    transferToAgent: "Transferir a un vendedor",
    noResults: "Oops! No hay resultados. 👀 Prueba con otro nombre",
  },
  en: {
    tagPlaceholder: "Select a team tag",
    agentPlaceholder: "Select an salesperson",
    missingField: "Please complete this field to continue",
    searchName: "Search by name...",
    cancel: "Cancel",
    transfer: "Transfer",
    transferChat: "Transfer chat",
    transferToTeam: "Transferir to team",
    transferToAgent: "Transferir to salesperson",
    noResults: "oops! No results. 👀 Try another name",
  },
  pt: {
    tagPlaceholder: "Selecione uma marca de equipe",
    agentPlaceholder: "Selecione um vendedor",
    missingField: "Por favor, preencha este campo para continuar",
    searchName: "Procura por nome ...",
    cancel: "Cancelar",
    transfer: "Transferir",
    transferChat: "Transferir bate-papo",
    transferToTeam: "Transferir para a equipe",
    transferToAgent: "Transferir a um vendedor",
    noResults: "opa! Não há resultados. 👀 Tente outro nome",
  },
};
