import { request } from "../utils/request";

const RECEIVE_CONTACT_EVENTS = "RECEIVE_CONTACT_EVENTS";
export const receiveContactEvents = (events) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONTACT_EVENTS,
    events,
  });
};

const REQUEST_CONTACT_EVENTS = "REQUEST_CONTACT_EVENTS";
export const requestContactEvents = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONTACT_EVENTS,
  });
};

export const getContactEvents = (contact_id) => (dispatch) => {
  dispatch(requestContactEvents());
  request(
    "GET",
    `/agent/contact/${contact_id}/history/deployment`,
    null,
    true,
    (data) => {
      return dispatch(receiveContactEvents(data.deployments));
    },
    (error) => {
      console.log(error);
    },
  );
};

const REQUEST_CONTACT_CONVERSATION_HISTORY =
  "REQUEST_CONTACT_CONVERSATION_HISTORY";
export const requestConversationHistory = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONTACT_CONVERSATION_HISTORY,
  });
};

const RECEIVE_CONTACT_CONVERSATION_HISTORY =
  "RECEIVE_CONTACT_CONVERSATION_HISTORY";
export const receiveConversationHistory = (history) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONTACT_CONVERSATION_HISTORY,
    history,
  });
};

export const getContactConversationHistory = (conversationId) => (dispatch) => {
  dispatch(requestConversationHistory());

  request(
    "GET",
    `/agent/conversation/${conversationId}`,
    null,
    true,
    (data) => {
      return dispatch(receiveConversationHistory(data));
    },
    (error) => {
      console.log(error);
    },
  );
};
