import mixpanel from "mixpanel-browser";

const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL;

mixpanel.init(MIXPANEL_KEY, {
  debug: process.env.REACT_APP_ENV != "prod",
});

export default {
  identify: (userId, role) => {
    mixpanel.identify(userId);
    mixpanel.register({ Role: role });
  },

  track: (eventName, data) => {
    mixpanel.track(eventName, data);
  },

  page: (page_name) => {
    mixpanel.track(`Viewed ${page_name}`, { type: "page view" });
  },

  setPeople: (user, data) => {
    if (localStorage.getItem("set_people") == "false") return;
    if (!data) return;
    let people = {};
    let segment = null;
    let integration = null;
    if (data.active_integrations) {
      integration =
        data.active_integrations.split(";").length == 1
          ? data.active_integrations
          : "Zendesk";
    }
    if (integration) {
      segment = integration;
    }
    if (!segment && data.tier == "Soonicorn") {
      segment = "Soonicorn";
    }
    let tier = null;
    if (data.tier) {
      tier = data.tier == "Soonicorn" ? "Yes" : "No";
    }
    people["Soonicorn"] = tier;
    people["Segment"] = segment ? segment : "Other";
    people["Company_id"] = user.company.id;
    people["Email"] = user.agent.email;
    people["Integration"] = integration;
    people["Hubspot Company ID"] = data.hubspot_company_id;
    mixpanel.people.set(people);
    localStorage.setItem("set_people", "false");
  },
};
