import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Dropdown as AntdDropdown, Menu } from "antd";
import ReactTooltip from "react-tooltip";
import "antd/lib/switch/style/index.css";
import { toast } from "react-toastify";

import Countdown from "react-countdown";
import RichTextarea from "../../../components/RichTextarea/RichTextarea";
import TrebleIcon from "../../../components/TrebleIcon/TrebleIcon";
import Dropdown from "../../../components/Dropdowns/Dropdown";
import Button from "../../../components/Button/Button";

import HubspotCloseModal from "./Components/HubspotCloseModal/HubspotCloseModal";

import HoverableButton from "../../../components/HoverableButton/HoverableButton";
import {
  ChatHistory,
  CHAT_HISTORY,
  NOTE_HISTORY,
} from "../../../components/ChatHistory/ChatHistory";
import ContactModal from "./ContactModal/ContactModal";
import HSMModal from "./HSMModal/HSMModal";
import TransferModal from "../../../components/TransferModal/TransferModal";
import ChatDragWindow from "../../../components/ChatDragWindow/ChatDragWindow";
import Modal from "../../../components/Modal/ModalV2";
import FileModal from "../../../components/FileModal/FileModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import Contact from "./Contact/Contact";

import {
  AddChatLabelsDropdown,
  EditChatLabelDropdown,
} from "./ChatLabelsDropdowns";
import {
  getChats,
  getContacts,
  cleanContacts,
  createContact,
  updateContact,
  deleteContact,
  getHsms,
  getChannels,
  getHelpdesks,
  getAddressFromCoordinates,
} from "../../../actions/agentAction";
import AudioPlayer from "react-h5-audio-player";

import {
  sendMessage,
  selectChat,
  endConversation,
  transferChats,
  assignChats,
  getAgents,
  sendMedia,
  createNote,
  getConversationHistory,
  sendHSM,
  sendHSMConversation,
  getChatLabels,
  createChatLabel,
  deleteChatLabel,
  editChatLabel,
  toggleChatLabel,
  sendReplyMessage,
  uploadToS3,
  emptyHistory,
  getFiles,
  setFullScreen,
  agentGetTags,
  updateAlertState,
} from "../../../actions/chatAction";
import {
  agentGetSnippets,
  agentPushSnippet,
  agentRemoveSnippet,
} from "../../../actions/snippetAction";

import {
  getContactEvents,
  getContactConversationHistory,
} from "../../../actions/contactAction";

import scrollLoader from "../../../assets/icons/scroll-loader.gif";

import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

import { formatText } from "../../../utils/WhatsappMarkdown";
import { colorOptions } from "../../../utils/labels";
import { downloadFile, getFileType } from "../../../utils/file";
import { contentReplyMessage } from "../../../utils/messages";

import "./Chats.scss";

import "antd/dist/antd.css";

import "react-responsive-modal/styles.css";

import languages from "./languages.js";
import events from "../../../utils/events";

import _ from "lodash";

const TAB_CHATS = "TAB_CHATS";
const TAB_CONTACTS = "TAB_CONTACTS";

const CHAT_TAB_CHATS = "CHAT_TAB_CHATS";
const CHAT_TAB_NOTES = "CHAT_TAB_NOTES";

class Chats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: TAB_CHATS,
      selectedChatTab: CHAT_TAB_CHATS,
      selectedContactId: null,
      selectedChatId: null,
      messages: {},
      sendingFile: {},
      searchValue: "",
      showContactModal: false,
      actionContact: "",
      showHSMModal: false,
      showTransferModal: false,
      hsm: null,
      finishedSession: false,
      activeConversationAgent: this.props.user.agent.email,
      language: this.props.language,
      selectedProviderMsgId: null,
      showSidePanel: true,
      selectedOption: 0,
      selectedColorOption: "default",
      snippetFilter: "",
      snippetCopy: null,
      sideBar: {
        optionSelected: null,
        showFiles: "files",
        hubspotPanel: null,
      },
      showContactSearchBar: false,
      showEndChatModal: false,
      showFileModal: false,
      showLabelsModal: false,
      showSnippetsModal: false,
      hubspotPanelModal: {
        show: false,
        option: null,
      },
      selectedFile: {},
      fullContactView: false,
      findReplyMessage: null,
    };

    this.strings = languages[props.language];

    this.orderOptions = [
      this.strings.lastMessageSent,
      this.strings.userSubmittedMessage,
      this.strings.newConversations,
      this.strings.TransferredMessages,
      this.strings.filterByLabels,
    ];

    this.orderDateIndex = [
      "last_message",
      "last_user_message",
      "new_conversation",
      "last_redirected",
    ];

    this.colorLabels = colorOptions;

    this.renderChats = this.renderChats.bind(this);
    this.handleSearchTextChange = _.debounce(this.handleSearchTextChange, 500);
  }

  componentDidMount() {
    this.props.getHsms();
    this.props.getContacts(this.props.contacts.offset, this.state.searchValue);
    this.props.getChats();
    this.props.getChatLabels();
    this.props.getChannels();
    this.props.getHelpdesks();
    this.props.agentGetSnippets();

    let savedOrderOption = localStorage.getItem("orderChatsOption");

    this.setState({
      selectedOption: savedOrderOption ? savedOrderOption : 0,
    });
    events.page("Agent Chats");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language !== prevProps.language) {
      this.setState({ language: this.props.language });
      this.strings = languages[this.props.language];
    }

    const currentPropsLastMessage = this.getLastMessageFromHistory(
      this.props.currentConversationHistory,
    );
    if (currentPropsLastMessage !== null) {
      const prevPropsLastMessage = this.getLastMessageFromHistory(
        prevProps.currentConversationHistory,
      );
      if (!_.isEqual(currentPropsLastMessage, prevPropsLastMessage)) {
        if (
          (this.checkLastMessageIsAgent(currentPropsLastMessage) ||
            this.checkNewConversation(
              currentPropsLastMessage,
              prevPropsLastMessage,
            ) ||
            this.minorScrollAdjustment()) &&
          this.checkDifferentProviderMessage(
            currentPropsLastMessage,
            prevPropsLastMessage,
          )
        ) {
          this.scrollDownChat();
        }
      }
    }

    if (
      this.state.selectedTab === TAB_CHATS &&
      prevState.selectedTab === TAB_CONTACTS &&
      prevState.searchValue !== ""
    ) {
      this.props.getContacts(0, "");
    }

    // Scroll last conversation created into view when loading new history or to reply message if it exist
    if (
      this.props.currentConversationHistory !==
        prevProps.currentConversationHistory &&
      prevProps.isFetchingHistory !== this.props.isFetchingHistory
    ) {
      const conversationsCreated = prevProps.currentConversationHistory.filter(
        (e) => e.event_type === "event" && e.event.type === "CREATED",
      );

      if (this.state.findReplyMessage) {
        this.findReplyMessage(this.state.findReplyMessage);
        return;
      }
      if (conversationsCreated.length > 0) {
        const lastConvCreated =
          conversationsCreated[conversationsCreated.length - 1];

        const lastConvCreatedDate = moment(
          moment.utc(lastConvCreated.event.created_at).toDate(),
        ).format("YYYY-MM-DD");
        let scrollDate = document.getElementById(
          `chat-date-${lastConvCreatedDate}`,
        );
        if (scrollDate) {
          scrollDate.scrollIntoView({});
        }
      }
    }
  }

  loadingScroll = () => {
    return (
      <div className="scroll-loader">
        <img src={scrollLoader} />
      </div>
    );
  };

  getLastMessageFromHistory = (history) => {
    if (history !== null) {
      let messages = history.filter((e) => e.event_type === "message");
      if (messages.length > 0) {
        return messages[messages.length - 1].message;
      }
    }
    return null;
  };

  checkLastMessageIsAgent = (lastMessage) => {
    return lastMessage.sender === "AGENT";
  };

  checkNewConversation = (currentLastMessage, prevLastMessage) => {
    return (
      prevLastMessage === null ||
      (currentLastMessage.conversation_id !== undefined &&
        prevLastMessage.conversation_id !== currentLastMessage.conversation_id)
    );
  };

  checkDifferentProviderMessage = (currentLastMessage, prevLastMessage) => {
    if (prevLastMessage === null) {
      return true;
    }

    return (
      currentLastMessage.provider_msg_id !== prevLastMessage.provider_msg_id
    );
  };

  minorScrollAdjustment = () => {
    const chatWindow = document.getElementsByClassName("chat-main-window")[0];
    if (!chatWindow) return;
    return (
      chatWindow.scrollTop + chatWindow.offsetHeight + 150 >=
      chatWindow.scrollHeight
    );
  };

  scrollDownChat = () => {
    // Responsive views makes several chats exist but only one is seen

    let messagesWithProviderMsgId =
      this.props.currentConversationHistory.filter(
        (e) => e.event_type === "message" && e.message.provider_msg_id !== null,
      );

    if (messagesWithProviderMsgId.length) {
      let providerMsgId =
        messagesWithProviderMsgId[messagesWithProviderMsgId.length - 1].message
          .provider_msg_id;

      let scrollMessage = document.getElementById(providerMsgId);
      if (scrollMessage) {
        scrollMessage.scrollIntoView({});
      }
    }
  };

  getSelectedChat = () => {
    const chats = this.props.chats;

    return chats.find((e) => e.conversation_id === this.state.selectedChatId);
  };

  getSelectedContact = () => {
    const contacts = this.props.contacts.value ? this.props.contacts.value : [];

    return contacts.find((c) => c.id === this.state.selectedContactId);
  };

  selectContact = (contact) => {
    this.setState({
      selectedTab: TAB_CONTACTS,
      selectedChatId: null,
      selectedChatTab: CHAT_TAB_CHATS,
      selectedContactId: contact.id,
      finishedSession: false,
      activeConversationAgent: null,
      sideBar: {
        ...this.state.sideBar,
        hubspotPanel: null,
      },
    });

    this.props.emptyHistory();
  };

  onSelectChat = (chat) => {
    let currentChatId = this.state.selectedChatId;

    if (chat.conversation_id !== currentChatId) {
      this.props.selectChat(
        chat.conversation_id,
        chat.contact.id,
        chat.channel_id,
      );
      this.props.getFiles(chat.contact.id);
    }

    this.setState({
      selectedTab: TAB_CHATS,
      selectedChatId: chat.conversation_id,
      selectedContactId: null,
      finishedSession: false,
      activeConversationAgent: this.props.user.email,
      sideBar: {
        ...this.state.sideBar,
        hubspotPanel: null,
      },
    });
  };

  chatDate = (chatDate) => {
    const date = moment(moment.utc(chatDate).toDate()).local();
    const today = moment().local();
    const yesterday = moment().subtract(1, "days").local();
    const format = "DD/MM/YYYY";

    let formattedDate = "";
    if (today.format(format) === date.format(format)) {
      formattedDate = date.format("HH:mm");
    } else if (yesterday.format(format) === date.format(format)) {
      formattedDate = this.strings.yesterday;
    } else if (date.week() === today.week()) {
      formattedDate = date.locale(this.state.language).format("dddd");
    } else {
      formattedDate = date.format(format);
    }

    return formattedDate;
  };

  renderChat = (chat, selectedChat) => {
    const contact = chat.contact;

    const unreadMessages = chat.unread_messages;
    const hasUnread = unreadMessages > 0 ? "has-unread" : "";
    let active = "";
    if (selectedChat !== undefined) {
      active =
        selectedChat.conversation_id === chat.conversation_id ? "active" : "";
    }

    const lastMessage = chat.last_message;
    const date = this.chatDate(lastMessage.created_at);

    let messageStatus = null;
    let messageError = null;
    if (lastMessage.sender === "AGENT") {
      if (lastMessage.provider_msg_id) {
        const deliveredAt = lastMessage.delivered_at;
        const readAt = lastMessage.read_at;
        if (!deliveredAt && !readAt) {
          messageStatus = <div className="icon icon--sent"></div>;
        }
        if (deliveredAt) {
          messageStatus = <div className="icon icon--delivered"></div>;
        }
        if (readAt) {
          messageStatus = <div className="icon icon--read"></div>;
        }
      } else if (lastMessage.error) {
        messageError = (
          <div className="message-error">
            <div className="icon icon--error"></div>
          </div>
        );
      }
    }

    let messagePreview = "";
    if (lastMessage.type === "text") {
      messagePreview = lastMessage.text.body.substring(0, 40);
    } else if (lastMessage.type === "hsm") {
      messagePreview = lastMessage.hsm.body.substring(0, 40);
    } else {
      messagePreview = lastMessage.type;
    }

    const isRedirected = chat.is_redirected ? (
      <div className="chat-is-redirected">
        <div
          data-tip
          data-for={`redirect-tooltip-${chat.conversation_id}`}
          className="icon icon--is-redirected"
        >
          <ReactTooltip
            id={`redirect-tooltip-${chat.conversation_id}`}
            place="top"
            effect="solid"
            className="tooltip"
          >
            {this.strings.chatTransferred}
          </ReactTooltip>
        </div>
      </div>
    ) : (
      ""
    );

    const countUnreadMessages =
      unreadMessages > 0 ? <div className="unread">{unreadMessages}</div> : "";

    const chatLabels = chat.labels;

    return (
      <div className={`select-container ${active}`}>
        <div
          key={`${chat.conversation_id}`}
          className={`chat ${hasUnread}`}
          onClick={() => this.onSelectChat(chat)}
        >
          <div className="messages">
            <div className="user-name">
              {isRedirected}
              <p>
                {contact.name
                  ? contact.name
                  : `${contact.country_code} ${contact.cellphone}`}
              </p>
              <div className="last-hour">{date}</div>
            </div>
            <div className="user-last-message">
              {messageError}
              <p>{formatText(messagePreview)}</p>
              {countUnreadMessages}
              {messageStatus ? (
                <div className="message-status">{messageStatus}</div>
              ) : (
                ""
              )}
            </div>
            {chatLabels.length ? (
              <div className="chat-labels">
                {chatLabels.map((chatLabel) => (
                  <div
                    key={chatLabel.id}
                    className={`label-item label-${chatLabel.color}`}
                  >
                    {chatLabel.name}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  getMessageDate = (chat, orderType) => {
    let date = chat.created_at;
    if (chat[orderType]) {
      date = chat[orderType].created_at;
    }
    if (chat.is_redirected) {
      const redirectDate = chat.last_redirection.created_at;
      date = new Date(date) < new Date(redirectDate) ? redirectDate : date;
    }
    return date;
  };

  compareChats = (orderType) => {
    return (chatA, chatB) => {
      let dateA, dateB;
      if (orderType === "last_message" || orderType === "last_user_message") {
        dateA = this.getMessageDate(chatA, orderType);
        dateB = this.getMessageDate(chatB, orderType);
      } else if (orderType === "last_redirected") {
        if (!(chatA.is_redirected && chatB.is_redirected)) {
          return chatA.is_redirected ? -1 : 1;
        }
        dateA = this.getMessageDate(chatA, "last_message");
        dateB = this.getMessageDate(chatB, "last_message");
      } else {
        dateA = this.getMessageDate(chatA, "");
        dateB = this.getMessageDate(chatB, "");
        if (dateA == dateB) {
          return chatB.unread_messages - chatA.unread_messages;
        }
      }
      return new Date(dateB) - new Date(dateA);
    };
  };

  sortChats = () => {
    let dateIndex = this.orderDateIndex[this.state.selectedOption];
    return this.props.chats.sort(this.compareChats(dateIndex));
  };

  changeSelectedOption = (option, color = "default") => {
    localStorage.setItem("orderChatsOption", option);
    localStorage.setItem("colorLabelOption", color);

    this.setState({
      selectedOption: option,
      selectedColorOption: color,
    });
  };

  getFilterChats = () => {
    const selectedTab = this.state.selectedTab;
    let chats = this.sortChats();
    chats = [...new Set(chats)];
    if (selectedTab === TAB_CHATS && this.state.searchValue !== "") {
      const searchValue = this.state.searchValue.toLowerCase();
      chats = chats.filter((c) => {
        if (
          (c.contact.name &&
            c.contact.name.toLowerCase().includes(searchValue)) ||
          c.contact.whole_cellphone.includes(searchValue)
        ) {
          return c;
        }
      });
    }
    if (
      selectedTab === TAB_CHATS &&
      this.orderOptions[this.state.selectedOption] ==
        this.strings.filterByLabels
    ) {
      chats = chats.filter((c) => {
        if (
          c.labels.find(
            (label) => label.color === this.state.selectedColorOption,
          )
        ) {
          return c;
        }
      });
    }
    return chats;
  };

  renderSearchNotFound = () => {
    return (
      <div className="no-results">
        <div className="image" />
        <div className="text">{this.strings.noSearchResults}</div>
      </div>
    );
  };

  renderNoConversations = () => {
    if (this.props.chats.length > 0) {
      return this.renderSearchNotFound();
    } else if (this.props.isFetchingChats == true) {
      return (
        <div className="loading-chats-container">
          <div className="custom-treble-loader" />
          <p>{this.strings.loadingChats}</p>
        </div>
      );
    }
    return (
      <div className="no-active-conversations">
        <div className="title">{this.strings.noActiveConversations}</div>
        <div className="body">{this.strings.chatWithClients}</div>
      </div>
    );
  };

  renderChats = (chats) => {
    if (this.state.selectedTab !== TAB_CHATS) {
      return;
    }
    const selectedChat = this.getSelectedChat();
    const paintChats = chats.length
      ? chats.map((chat) => {
          return this.renderChat(chat, selectedChat);
        })
      : this.renderNoConversations();

    return <div className="all-chats">{paintChats}</div>;
  };

  renderContact = (contact) => {
    let active = "";
    if (this.state.selectedContactId !== null) {
      active = this.state.selectedContactId === contact.id ? "active" : "";
    }
    return (
      <div className={`select-container ${active}`}>
        <div
          className="contact"
          onClick={() => this.selectContact(contact)}
          key={contact.id}
        >
          <div className="contact-information">
            <div className="contact-main-info">
              <div className="user-name">{contact.name}</div>
            </div>
            <div className="contact-main-info">
              <div className="user-cellphone">{contact.whole_cellphone}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContacts = () => {
    if (this.state.selectedTab !== TAB_CONTACTS) {
      return;
    }
    let contacts = this.props.contacts.value;
    let namedContacts = contacts.filter((c) => c.name !== null);

    let paintContacts = namedContacts.length
      ? namedContacts.map((c) => {
          return this.renderContact(c);
        })
      : this.renderSearchNotFound();

    if (
      this.props.contacts.isFetching &&
      this.props.contacts.value.length < 1
    ) {
      paintContacts = "";
    }

    return (
      <>
        <div className="all-contacts" id="contacts-scrollable">
          {this.props.contacts.isFetching &&
            this.props.contacts.value.length < 1 &&
            this.loadingScroll()}
          <InfiniteScroll
            dataLength={this.props.contacts.value}
            hasMore={
              this.props.contacts.value.length < this.props.contacts.visible &&
              !this.props.contacts.isFetching
            }
            style={{ overflowX: "hidden" }}
            next={() =>
              this.props.getContacts(
                this.props.contacts.offset,
                this.state.searchValue,
              )
            }
            scrollThreshold={0.95}
            scrollableTarget="contacts-scrollable"
          >
            {paintContacts}
            {this.props.contacts.isFetching &&
              this.props.contacts.value.length < this.props.contacts.visible &&
              this.props.contacts.value.length > 0 &&
              this.loadingScroll()}
          </InfiniteScroll>
        </div>
      </>
    );
  };

  setSendingFile = (conversationId, sendingFileStatus) => {
    const { sendingFile } = this.state;
    sendingFile[conversationId] = sendingFileStatus;
    this.setState({ sendingFile });
  };

  renderContactInfo = () => {
    const selectedChat = this.getSelectedChat();
    if (!selectedChat) return;
    let helpdeskInfo =
      selectedChat.contact.extra &&
      selectedChat.contact.extra["helpdesks_info"];

    const contact = selectedChat.contact;

    let firstName = "T",
      lastName = "B",
      email = "-",
      phone = contact.whole_cellphone,
      linkToContact,
      type = "-",
      hasCustomIntegration = false;

    if (contact.name) {
      const name = contact.name.split(" ");
      firstName = name[0];
      lastName = name[1];
    }

    if (helpdeskInfo && (helpdeskInfo["HUBSPOT"] || helpdeskInfo["hubspot"])) {
      let hubspotInfo = helpdeskInfo["HUBSPOT"];

      firstName = hubspotInfo["properties"]["firstname"];
      lastName = hubspotInfo["properties"]["lastname"];
      email = hubspotInfo["properties"]["email"];
      phone = hubspotInfo["properties"]["phone"];
      linkToContact = `https://app.hubspot.com/contacts/${hubspotInfo["portal_id"]}/contact/${hubspotInfo["contact_id"]}/`;
      type = "hubspot";
      helpdeskInfo = hubspotInfo;
      hasCustomIntegration = true;
    }

    return this.renderHelpdeskMainInformation(
      firstName,
      lastName,
      email,
      phone,
      linkToContact,
      type,
      helpdeskInfo,
      hasCustomIntegration,
    );
  };

  renderHelpdeskMainInformation = (
    firstname,
    lastname,
    email,
    phone,
    linkToContact,
    type,
    helpdeskInfo,
    hasCustomIntegration,
  ) => {
    let fullName = `${firstname ? firstname : ""} ${lastname ? lastname : ""}`;
    const initials = fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

    return (
      <div className="contact-info">
        <div className="contact-header">
          <div className="contact-photo">
            <Avatar size={83}>{initials}</Avatar>
            <div className={`logo logo--${type}-logo`}></div>
          </div>
          <h4>
            <span>{firstname}</span>
            <span>{lastname}</span>
          </h4>
          {hasCustomIntegration ? (
            <div className={`link-helpdesk ${type}`}>
              <a
                href={linkToContact}
                target="_blank"
                onClick={() => {
                  events.track(`Agent chats click on ${type} link`, {});
                }}
              >
                {this.strings["goTo" + type]}
              </a>
              <div className={`external-link ${type}`}></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="contact-body">
          <hr />
          <h5>{this.strings.contactDetails}</h5>
          <hr />
          <h6>{this.strings.information}</h6>
          <p>
            <span>{firstname}</span>
            <span>{lastname}</span>
          </p>
          <p>{phone}</p>
          <p>{email}</p>
        </div>
        {hasCustomIntegration
          ? this.renderCustomHelpdesk(type, helpdeskInfo)
          : ""}
      </div>
    );
  };

  renderCustomHelpdesk(type, helpdeskInfo) {
    if (type == "hubspot") {
      return (
        <>
          {helpdeskInfo["owner"] && (
            <div className="contact-owner">
              <h6>{this.strings.contactOwner}</h6>
              <p>
                {helpdeskInfo["owner"]["firstname"]}{" "}
                {helpdeskInfo["owner"]["lastname"]}
              </p>
              <p>{helpdeskInfo["owner"]["email"]}</p>
            </div>
          )}
          {helpdeskInfo["properties"]["custom"] && (
            <>
              <hr />
              <div className="contact-custom-properties">
                {helpdeskInfo["properties"]["custom"].map((property) => (
                  <p>
                    <b>{property["key"]}</b>
                    <br />
                    <span>{property["value"]}</span>
                  </p>
                ))}
              </div>
            </>
          )}
        </>
      );
    }
  }

  renderHubspotHeader = (isHubspot) => {
    if (!isHubspot) return;

    const menu = (
      <Menu
        items={[
          {
            key: "hs-contact",
            label: (
              <div className="hubspot-panel-category">
                <TrebleIcon name="hubspot-contacts" />
                <p>{this.strings.hubspotContactPanel}</p>
              </div>
            ),
          },
          {
            key: "hs-ticket",
            label: (
              <div className="hubspot-panel-category">
                <TrebleIcon name="hubspot-tickets" />
                <p>{this.strings.hubspotTicketPanel}</p>
              </div>
            ),
            track: "Agent click to open ticket panel",
          },
        ]}
        onClick={(option) => {
          this.updateSideBarState("hubspotPanel", {
            type: option.key,
            open: false,
          });
          events.track("Agent click to open hubspot panel", {
            "Panel type": `${option.key.replace("hs-", "")}`,
          });
        }}
      />
    );

    return (
      <AntdDropdown overlay={menu} overlayClassName="hubspot-options">
        <div className="hubspot-dashboard" onClick={(e) => e.preventDefault()}>
          <div className="icon icon--hubspot-dashboard" />
          {this.strings.openHubspotDashboard}
        </div>
      </AntdDropdown>
    );
  };

  renderScrollInformation = (isHubspot) => {
    if (!isHubspot) return;
    let sidepanelVersion = localStorage.getItem("hubspotSidepanelVersion");
    let showNotification = localStorage.getItem("hubspotScrollInformation");
    if (
      sidepanelVersion === null ||
      sidepanelVersion === "V1" ||
      showNotification === "false" ||
      this.state.showSidePanel === false
    ) {
      return;
    }
    return (
      <div className="scroll-info">
        <p>
          <span className="arrow-emoji">↔️</span>
          {this.strings.horizontalScroll}
        </p>
        <div
          onClick={() => {
            localStorage.setItem("hubspotScrollInformation", "false");
            this.forceUpdate();
          }}
          className={`icon icon--cancel`}
        />
      </div>
    );
  };

  updateSideBarState = (key, value) => {
    const sideBar = this.state.sideBar;
    sideBar[key] = value;
    this.setState({ sideBar: sideBar });
  };

  renderSideOptions = (title, body, option) => {
    const { optionSelected } = this.state.sideBar;
    return (
      <div
        className="option"
        onClick={() => this.updateSideBarState("optionSelected", option)}
      >
        <Dropdown
          title={title}
          iconRow={"dropdown-row"}
          alwaysActive={optionSelected == option}
        >
          {body}
        </Dropdown>
      </div>
    );
  };

  renderInfo = (isHubspot) => {
    const title = (
      <div className="item">
        <div className="icon icon--contact-info" />
        {this.strings.contactInfo}
      </div>
    );

    const body = (
      <>
        {this.renderScrollInformation(isHubspot)}
        {this.renderContactInfo()}
      </>
    );

    return this.renderSideOptions(title, body, "contact");
  };

  handleSnippet = (snippet) => {
    this.setState({ snippetCopy: snippet, showSnippetsModal: false });
  };

  handleSnippetPin = (snippet) => {
    if (!snippet.fixed) this.props.agentPushSnippet(snippet);
    else this.props.agentRemoveSnippet(snippet);
  };

  renderSnippet = (snippet) => {
    return (
      <div
        className={`snippet ${
          this.state.snippetCopy && this.state.snippetCopy.id == snippet.id
            ? "down"
            : ""
        } ${snippet.fixed ? "fixed" : ""}`}
      >
        <div className="header">
          <div className="text">{snippet.name}</div>
          <div
            className="icon icon--pushpin"
            onClick={() => this.handleSnippetPin(snippet)}
            onMouseUp={() => this.handleSnippetPin(snippet)}
          />
        </div>
        <div
          onClick={() => this.handleSnippet(snippet)}
          onMouseUp={() => {
            events.track("Agent snippet clicked", { "Snippet id": snippet.id });
            setTimeout(this.handleSnippet, 100, null);
          }}
        >
          <div className="body">
            <div className="text">{formatText(snippet.text)}</div>
          </div>
          <div className="footer">
            <button>{this.strings.useAnswer}</button>
          </div>
        </div>
      </div>
    );
  };

  filterSnippets = (snippets) => {
    return snippets.filter((snippet) => {
      return snippet.name
        .toLowerCase()
        .includes(this.state.snippetFilter.toLowerCase());
    });
  };

  renderSnippets = () => {
    let snippets;
    if (this.props.agentSnippets.length) {
      const agentSnippets = this.filterSnippets(this.props.agentSnippets);
      snippets = (
        <div className="snippets">
          <SearchBar
            placeholder={this.strings.searchTemplate}
            value={this.state.snippetFilter}
            onChange={(e) => this.setState({ snippetFilter: e.target.value })}
            trackMessage="Agent search snippet in chats View"
            showCancel={false}
            onClick={() => this.setState({ snippetFilter: "" })}
          />
          {agentSnippets.length
            ? agentSnippets.map((snippet) => this.renderSnippet(snippet))
            : this.renderSearchNotFound()}
        </div>
      );
    } else {
      snippets = (
        <div className="snippets">
          <div className="not-created">
            <div className="text">{this.strings.snippetsNotCreated}</div>
            <div className="arrow" />
            <div className="admin">
              <div className="image" />
              <div>
                <div className="text">{this.strings.snippetPermissions}</div>
                <div className="bubble">
                  <div className="tail" />
                  <div className="message">{this.strings.templateBuilder}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return snippets;
  };

  renderSnippetsSidebar = () => {
    if (!this.state.showSidePanel) return;
    const title = (
      <div className="item">
        <div className="icon icon--snippet-templates" />
        {this.strings.messageTemplates}
      </div>
    );
    const body = this.renderSnippets();
    return this.renderSideOptions(title, body, "snippets");
  };

  getFileMonth = (date) => {
    const today = moment(moment().utc().toDate());
    let month;
    if (date.year() === today.year() && date.month() === today.month()) {
      month = this.strings.thisMonth;
    } else {
      month = date.locale(this.state.language).format("MMMM");
      if (date.year() !== today.year()) {
        month += " - " + date.locale(this.state.language).format("YYYY");
      }
    }
    return month;
  };

  groupFiles = (files) => {
    files = files.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    let groups = [],
      block = [],
      lastDate = moment(moment.utc().toDate());
    files.map((file) => {
      const date = moment(moment.utc(file.created_at).toDate());
      if (lastDate.month() != date.month() && block.length) {
        groups.push({
          month: this.getFileMonth(lastDate),
          files: block,
        });
        block = [];
      }
      lastDate = date;
      block.push(file);
    });
    if (block.length) {
      groups.push({
        month: this.getFileMonth(lastDate),
        files: block,
      });
    }
    return groups;
  };

  renderFileModal = () => {
    return (
      <FileModal
        language={this.props.language}
        show={this.state.showFileModal}
        selectedFile={this.state.selectedFile}
        onClose={() => this.setState({ showFileModal: false })}
      />
    );
  };

  showFileModal = (file) => {
    const category = file.category || file.type;
    this.setState({
      showFileModal: true,
      selectedFile: {
        url: file[category].url,
        type: category,
        created_at: file.created_at,
      },
    });
  };

  renderFile = (file) => {
    const category = file.category;

    if (["image", "video"].includes(category)) {
      return (
        <div
          className="image"
          onClick={() => {
            events.track("Agent files panel clicks on file", {
              "file type": getFileType(file[category].url),
              "file date": file.created_at,
            });
            this.showFileModal(file);
          }}
        >
          {category == "image" ? (
            <img src={file.image.url} />
          ) : (
            <video src={file.video.url} />
          )}
        </div>
      );
    }
    if (["audio", "voice"].includes(file.category)) {
      return (
        <>
          <div className="audio">
            <AudioPlayer
              layout="horizontal"
              showSkipControls={false}
              showJumpControls={false}
              src={file[category].url}
              onPlay={(e) =>
                events.track("Agent documents panel plays audio", {
                  "file type": getFileType(file[category].url),
                  "file date": file.created_at,
                })
              }
            />
          </div>
          <div className="download-audio">
            <div
              className="text"
              onClick={() => {
                downloadFile(
                  file[category].url,
                  "Agent documents panel downloads audio",
                  file.created_at,
                );
              }}
            >
              {this.strings.downloadAudio}
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="document">
        <div className="name">{file.document.filename}</div>
        <div
          className="icon icon--download-file"
          onClick={() => {
            downloadFile(
              file.document.url,
              "Agent documents panel downloads a document",
              file.created_at,
            );
          }}
        ></div>
      </div>
    );
  };

  renderGroupFiles = (type) => {
    if (
      this.props.files[type] === undefined ||
      !this.props.files[type].length
    ) {
      return;
    }
    const groupFiles = this.groupFiles(this.props.files[type]);
    return (
      <>
        {groupFiles.map((groupFile) => {
          const month = groupFile.month;
          const files = groupFile.files;
          return (
            <>
              <div className="month">{month}</div>
              <div className={type}>
                {files.map((file) => this.renderFile(file))}
              </div>
            </>
          );
        })}
      </>
    );
  };

  renderBodyFiles = () => {
    const { showFiles } = this.state.sideBar;
    return (
      <div className="files-and-docs">
        <div className="selector">
          <div
            className={`files ${showFiles == "files" ? "selected" : ""}`}
            onClick={() => this.updateSideBarState("showFiles", "files")}
          >
            {this.strings.seeFiles}
          </div>
          <div
            className={`docs ${showFiles == "docs" ? "selected" : ""}`}
            onClick={() => this.updateSideBarState("showFiles", "docs")}
          >
            {this.strings.seeDocs}
          </div>
        </div>
        <div className="month-groups">{this.renderGroupFiles(showFiles)}</div>
      </div>
    );
  };

  renderFiles = () => {
    const title = (
      <div className="item">
        <div className="icon icon--files-and-docs" />
        {this.strings.filesAndDocs}
      </div>
    );
    const body = this.renderBodyFiles();
    return this.renderSideOptions(title, body, "files");
  };

  formatSnippetText = (text, contactName, agentName) => {
    let snippetText = text.replace("{{agent_name}}", agentName);
    if (contactName !== "") {
      snippetText = snippetText.replace("{{name}}", contactName);
    } else {
      snippetText = snippetText.replace(/{{name}} {0,1}/gi, "");
    }
    return snippetText;
  };

  getHubspotMapper = (helpdeskInfo) => {
    const hubspotInfo = helpdeskInfo["HUBSPOT"] || helpdeskInfo["hubspot"];

    if (!hubspotInfo) return null;

    const europeanHubspot = hubspotInfo["location"] == "eu" ? true : false;

    const hubspotMapper = {
      "hs-contact": {
        title: this.strings.hubspotCloseContact,
        url: `https://${
          europeanHubspot ? "app-eu1" : "app"
        }.hubspot.com/contacts/${hubspotInfo["portal_id"]}/contact/${
          hubspotInfo["contact_id"]
        }`,
      },
      "hs-ticket": {
        title: this.strings.hubspotCloseTicket,
        url: hubspotInfo.ticket_id
          ? `https://${
              europeanHubspot ? "app-eu1" : "app"
            }.hubspot.com/contacts/${hubspotInfo["portal_id"]}/ticket/${
              hubspotInfo["ticket_id"]
            }`
          : `https://${
              europeanHubspot ? "app-eu1" : "app"
            }.hubspot.com/contacts/${
              hubspotInfo["portal_id"]
            }/objects/0-5/views/all/list`,
      },
    };

    return hubspotMapper;
  };

  renderHubspotPanel = (helpdeskInfo) => {
    const hubspotMapper = this.getHubspotMapper(helpdeskInfo);

    let hubspotPanel = this.state.sideBar.hubspotPanel;
    let panelValues = hubspotMapper[hubspotPanel.type];

    return (
      <div
        className={`side-panel desktop hubspot-panel ${
          hubspotPanel.open ? "extend" : "collapse"
        }`}
      >
        <div
          className="toggle-side-panel"
          onClick={() => {
            this.updateSideBarState("hubspotPanel", {
              ...hubspotPanel,
              open: !hubspotPanel.open,
            });
            events.track("Agent interacts with hubspot panel size", {
              Action: hubspotPanel.open === true ? "close" : "open",
              "Panel type": hubspotPanel.type.replace("hs-", ""),
            });
          }}
        >
          <TrebleIcon
            name={
              hubspotPanel.open ? "chevron-right-white" : "chevron-left-white"
            }
          />
        </div>

        <div className="sp-header">
          <p>{panelValues.title}</p>
          <TrebleIcon
            name="hubspot-close"
            onClick={() => {
              this.updateSideBarState("hubspotPanel", null);
            }}
          />
        </div>
        <div className="hubspot-iframe">
          <iframe src={panelValues.url} />
        </div>
      </div>
    );
  };

  renderSidePanel = () => {
    const selectedChat = this.getSelectedChat();
    if (!selectedChat) return;
    const helpdeskInfo =
      selectedChat.contact.extra &&
      selectedChat.contact.extra["helpdesks_info"];

    const isHubspot =
      helpdeskInfo && (helpdeskInfo["HUBSPOT"] || helpdeskInfo["hubspot"])
        ? true
        : false;

    if (this.state.sideBar.hubspotPanel !== null) {
      return this.renderHubspotPanel(helpdeskInfo);
    }

    return (
      <div className={`side-panel desktop ${isHubspot ? "has-hubspot" : ""}`}>
        {this.renderHubspotHeader(isHubspot)}
        {this.renderInfo(isHubspot)}
        {this.renderSnippetsSidebar()}
        {this.renderFiles()}
      </div>
    );
  };

  renderHeaderEndChatModal = () => {
    const header = this.strings.headerEndChat.split("--");
    const emojis = header[0];
    const text = header[1];
    return (
      <>
        <div className="emojis">{emojis}</div>
        {text}
      </>
    );
  };

  renderFooterEndChatModal = () => {
    const selectedChat = this.getSelectedChat();
    if (!selectedChat) return;
    const conversationId = selectedChat.conversation_id;

    return (
      <>
        <div
          className="button end-all-chats"
          onClick={() => {
            this.props.endConversation([conversationId]);
            this.setState({
              showEndChatModal: false,
              selectedChatId: null,
              selectedContactId: null,
            });
          }}
        >
          {this.strings.footerEndChat}
        </div>
        <div
          className="button cancel"
          onClick={() => this.setState({ showEndChatModal: false })}
        >
          {this.strings.footerCancelEndChat}
        </div>
      </>
    );
  };

  renderHubspotCloseModal = (conversationId, hubspot) => {
    return (
      <div className="general-modal">
        <div className="end-all-chats-modal hubspot">
          <Modal
            show={this.state.showEndChatModal}
            header={this.renderHeaderEndChatModal()}
            onClose={() => this.setState({ showEndChatModal: false })}
            body={
              <HubspotCloseModal
                strings={this.strings}
                pipelines={hubspot.pipelines}
                close={() => this.setState({ showEndChatModal: false })}
                finishConversation={(pipeline, stage) => {
                  this.props.endConversation([conversationId], {
                    hubspot: { pipeline_id: pipeline, stage_id: stage },
                  });
                  this.setState({
                    showEndChatModal: false,
                    selectedChatId: null,
                    selectedContactId: null,
                  });
                }}
              />
            }
          />
        </div>
      </div>
    );
  };

  renderEndChatModal = () => {
    const selectedChat = this.getSelectedChat();
    if (!selectedChat) return;
    const conversationId = selectedChat.conversation_id;

    const hubspot = this.props.helpdesks.filter((h) => h.type === "HUBSPOT");
    const hubspotInfo = selectedChat.contact.extra.helpdesks_info?.HUBSPOT;
    const hasTicket = hubspotInfo?.ticket_id;

    if (hubspot.length && hubspot[0] !== undefined && hasTicket) {
      return this.renderHubspotCloseModal(conversationId, hubspot[0]);
    }

    return (
      <div className="general-modal">
        <Modal
          wrappClass="end-all-chats-modal"
          show={this.state.showEndChatModal}
          header={this.renderHeaderEndChatModal()}
          onClose={() => this.setState({ showEndChatModal: false })}
          body={this.strings.bodyEndChat}
          footer={this.renderFooterEndChatModal()}
        />
      </div>
    );
  };

  renderNoteOut = () => {
    return (
      <div className="notes-out">
        <div
          className="icon icon--close"
          onClick={() => {
            this.setState({
              selectedChatTab: CHAT_TAB_CHATS,
            });
            setTimeout(this.scrollDownChat, 1000);
          }}
        ></div>
        <span>{this.strings.notesOut}</span>
      </div>
    );
  };

  renderNoteExpire = () => {
    const text = this.strings.whatsappTime.split("WhatsApp");
    return (
      <div className="session-expire">
        <div className="icon icon--whatsapp-expired"></div>
        <div className="text">
          <span>{text[0]}</span>
          <span className="green">WhatsApp</span>
          <span>{text[1]}</span>
        </div>
      </div>
    );
  };

  renderChatTabs = (sessionExpire) => {
    const selectedTab = this.state.selectedChatTab;
    let note;
    if (sessionExpire) {
      note = this.renderNoteExpire();
    } else if (selectedTab === CHAT_TAB_NOTES) {
      note = this.renderNoteOut();
    } else if (
      selectedTab === CHAT_TAB_CHATS &&
      this.state.selectedProviderMsgId
    ) {
      note = this.renderReplyMessage();
    }
    return <div className="chat-tabs-notes">{note}</div>;
  };

  renderLabelsModal = () => {
    const selectedChat = this.getSelectedChat();
    if (!selectedChat) return;
    return (
      <AddChatLabelsDropdown
        type="modal"
        language={this.props.language}
        alertState={this.props.alertState}
        show={this.state.showLabelsModal}
        onClose={() => this.setState({ showLabelsModal: false })}
        toggleChatLabel={(labelId) => {
          this.props.toggleChatLabel(labelId, this.state.selectedChatId);
        }}
        createChatLabel={(newLabel) => this.props.createChatLabel(newLabel)}
        chatLabels={this.props.chatLabels}
        selectedChatLabels={selectedChat.labels}
        deleteChatLabel={this.props.deleteChatLabel}
        editChatLabel={this.props.editChatLabel}
        updateAlertState={this.props.updateAlertState}
        isAdmin={this.props.user.agent.agent_type === "ADMIN"}
      />
    );
  };

  renderChatLabels = (selectedChat) => {
    return (
      <div className="chat-labels chat-labels-container">
        {selectedChat.labels.map((chatLabel) => (
          <EditChatLabelDropdown
            key={chatLabel.id}
            strings={this.strings}
            chatLabel={chatLabel}
            removeLabelFromChat={(labelId) =>
              this.props.toggleChatLabel(labelId, this.state.selectedChatId)
            }
          />
        ))}
      </div>
    );
  };

  getRemainingSession = () => {
    let userMessages = this.props.currentConversationHistory.filter(
      (m) => m.event_type === "message" && m.message.sender === "USER",
    );
    if (userMessages.length === 0 && this.state.finishedSession === false) {
      this.setState({ finishedSession: true });
      return;
    }
    let remaining = Date.now();
    if (userMessages.length >= 1) {
      let lastUserMessage = userMessages.sort((m) => m.message.created_at)[
        userMessages.length - 1
      ];
      remaining = moment
        .utc(lastUserMessage.message.created_at)
        .add(1, "days")
        .subtract(15, "seconds")
        .format("x");
      if (remaining <= Date.now() && this.state.finishedSession === false) {
        this.setState({ finishedSession: true });
      }
      if (remaining > Date.now() && this.state.finishedSession === true) {
        this.setState({ finishedSession: false });
      }
    } else if (this.state.finishedSession === false) {
      console.log("No user messages for timer");
      this.setState({ finishedSession: true });
    }
    let whatappState = this.strings.finishedSession;
    let countDown = "";
    if (!this.state.finishedSession) {
      whatappState = this.strings.whatsappSession;
      countDown = (
        <Countdown
          date={parseInt(remaining)}
          daysInHours={true}
          onComplete={() => this.setState({ finishedSession: true })}
        />
      );
    }
    return (
      <div className="session">
        <div className="icon icon--whatsapp size-16"></div>
        <div className="text">{whatappState}</div>
        {countDown}
      </div>
    );
  };

  renderHeaderCurrentChat = (selectedChat) => {
    const name = selectedChat.contact.name
      ? selectedChat.contact.name
      : `${selectedChat.contact.country_code} ${selectedChat.contact.cellphone}`;

    const helpdesksInfo =
      selectedChat.contact.extra &&
      selectedChat.contact.extra["helpdesks_info"];

    const currentHelpdeskInfo =
      helpdesksInfo && (helpdesksInfo["HUBSPOT"] || helpdesksInfo["hubspot"]);

    const hasLabels = selectedChat.labels.length > 0 ? true : false;

    const mobileOptions = (
      <div className="mobile-chat-options mobile">
        <button
          className="close-conversation"
          onClick={() => this.setState({ showEndChatModal: true })}
        >
          <div className="icon icon--finish-chat" />
        </button>
        <Dropdown title={<div className="icon icon--collapse-options" />}>
          <div
            className="mobile-option"
            onClick={() => this.setState({ showEndChatModal: true })}
          >
            <div className="icon icon--finish-chat" />
            {this.strings.endChat}
          </div>
          <div
            className="mobile-option"
            onClick={() => this.setState({ showLabelsModal: true })}
          >
            <div className="icon icon--edit-tag" />
            {this.strings.addChatLabel}
          </div>
          <div
            className="mobile-option"
            onClick={() =>
              this.setState({
                showContactModal: true,
                actionContact: "edit",
              })
            }
          >
            <div className="icon icon--edit-contact" />
            {this.strings.editContact}
          </div>
          <div
            className="mobile-option"
            onClick={() => {
              this.props.getAgents();
              this.props.agentGetTags(this.props.user.company.id);
              this.setState({ showTransferModal: true });
            }}
          >
            <div className="icon icon--transfer-agent"></div>
            {this.strings.transferChat}
          </div>
        </Dropdown>
      </div>
    );

    return (
      <div
        className={`chat-window-header is-vip ${
          hasLabels ? "" : "bottom-line"
        }`}
      >
        <div className="chat-header">
          <div
            className="back-chats mobile"
            onClick={() =>
              this.setState({ selectedChatId: null, selectedContactId: null })
            }
          >
            <div className="icon icon--arrow-back mobile"></div>
          </div>
          <div className="chat-information">
            <div className="title-chat">
              {currentHelpdeskInfo && (
                <div className="icon-hubspot mobile"></div>
              )}
              <h1
                onClick={() =>
                  this.setState({
                    showContactModal: true,
                    actionContact: "info",
                  })
                }
              >
                {name}
              </h1>
            </div>
            <div className="bar" />
            {this.getRemainingSession()}
          </div>
          <div className="chat-options">
            {selectedChat.conversation_id && (
              <div className="desktop tablet">
                <HoverableButton
                  icon="finish-chat"
                  iconSize="24"
                  tooltip={this.strings.endChat}
                  onClick={() => this.setState({ showEndChatModal: true })}
                />
              </div>
            )}
            {selectedChat.conversation_id && (
              <div className="desktop tablet">
                <HoverableButton
                  icon="edit-contact"
                  iconSize="24"
                  tooltip={this.strings.editContact}
                  onClick={() =>
                    this.setState({
                      showContactModal: true,
                      actionContact: "edit",
                    })
                  }
                />
              </div>
            )}
            {selectedChat.conversation_id && (
              <div className="desktop tablet">
                <AddChatLabelsDropdown
                  language={this.props.language}
                  alertState={this.props.alertState}
                  toggleChatLabel={(labelId) => {
                    this.props.toggleChatLabel(
                      labelId,
                      this.state.selectedChatId,
                    );
                  }}
                  createChatLabel={(newLabel) =>
                    this.props.createChatLabel(newLabel)
                  }
                  chatLabels={this.props.chatLabels}
                  selectedChatLabels={selectedChat.labels}
                  triggerComponent={
                    <HoverableButton
                      icon="edit-tag"
                      iconSize="24"
                      tooltip={this.strings.addChatLabel}
                    />
                  }
                  deleteChatLabel={this.props.deleteChatLabel}
                  editChatLabel={this.props.editChatLabel}
                  updateAlertState={this.props.updateAlertState}
                  isAdmin={this.props.user.agent.agent_type === "ADMIN"}
                />
              </div>
            )}
            {selectedChat.conversation_id && (
              <div className="desktop tablet">
                <HoverableButton
                  icon="transfer-agent"
                  iconSize="24"
                  tooltip={this.strings.transferChat}
                  onClick={() => {
                    this.props.getAgents();
                    this.props.agentGetTags(this.props.user.company.id);
                    this.setState({ showTransferModal: true });
                  }}
                />
              </div>
            )}
            {selectedChat.conversation_id && (
              <div
                className={`desktop tablet ${
                  this.props.fullScreen ? "screen" : ""
                }`}
              >
                {this.props.fullScreen ? (
                  <p
                    onClick={() =>
                      this.props.setFullScreen(!this.props.fullScreen)
                    }
                  >
                    {this.strings.exitFullScreen}
                  </p>
                ) : null}
                <HoverableButton
                  icon={
                    this.props.fullScreen ? "collapse-screen" : "full-screen"
                  }
                  iconSize="24"
                  tooltip={
                    this.props.fullScreen
                      ? this.strings.exitFullScreen
                      : this.strings.fullScreen
                  }
                  onClick={() => {
                    this.props.setFullScreen(!this.props.fullScreen);
                    if (!this.props.fullScreen) {
                      events.track("Agent full screen mode");
                    }
                  }}
                />
              </div>
            )}
          </div>
          {mobileOptions}
        </div>
        {this.renderChatLabels(selectedChat)}
      </div>
    );
  };

  renderScrolldownButton = () => {
    return (
      <div
        className="scroll-down-button"
        onClick={() => {
          const chat = document.getElementById("chat-scrollable");
          chat.scrollTo({
            top: chat.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <div className="icon scroll-down" />
      </div>
    );
  };

  renderContactView(contact) {
    return (
      <Contact
        {...this.props}
        contact={contact}
        onFullScreenChange={(newState) => {
          this.setState({ fullContactView: newState });
        }}
        toggleHSMModal={this.toggleHSMModal}
      />
    );
  }

  scrollToMessage = (message) => {
    const chatContainer = document.getElementById("chat-scrollable");
    if (!chatContainer) return;
    const topPos = message.offsetTop;
    chatContainer.scrollTop = topPos - 50;
    message.classList.add("highlight");
    setTimeout(() => {
      message.classList.remove("highlight");
    }, 4000);
  };

  findReplyMessage = (providerMsgId, retry = false) => {
    const message = document.getElementById(`msg-id-${providerMsgId}`);
    if (!message) {
      if (retry) {
        const selectedChat = this.getSelectedChat();
        this.setState({ findReplyMessage: providerMsgId });
        this.props.getConversationHistory(
          selectedChat.contact.id,
          selectedChat.channel_id,
          false,
        );
      } else {
        toast.error(this.strings.messageNotFound, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } else {
      this.setState({ findReplyMessage: null });
      this.scrollToMessage(message);
    }
  };

  renderCurrentChat(chats) {
    if (!this.state.selectedChatId && !this.state.selectedContactId) {
      return (
        <div className="chat-container">
          <div className="chat-window">
            <div className="chat-window-header is-vip">
              <h1 className="title"></h1>
            </div>
            <div className="cont">
              <div className="chat-main-window no-chat-selected">
                <div className="no-chat-selected-image" />
                <h1 className="no-chat-selected">
                  {this.state.selectedTab === TAB_CHATS
                    ? this.strings.noSelectedF.concat(this.strings.chat)
                    : this.strings.noSelectedM.concat(this.strings.contact)}
                </h1>
              </div>
              <div className="chat-main-window-footer"></div>
            </div>
          </div>
        </div>
      );
    }
    let selectedChat = this.getSelectedChat();
    let selectedContact = this.getSelectedContact();
    if (!selectedChat) {
      if (!selectedContact) {
        return (
          <div className="chat-container">
            <div className="chat-window">
              <div className="chat-window-header is-vip">
                <div
                  className="back-chats mobile"
                  onClick={() =>
                    this.setState({
                      selectedChatId: null,
                      selectedContactId: null,
                    })
                  }
                />
              </div>
              <div className="cont">
                <div className="chat-main-window no-chat-selected">
                  <div className="no-chat-selected-image" />
                  <h1 className="no-chat-selected">{this.strings.noChat}</h1>
                </div>
                <div className="chat-main-window-footer"></div>
              </div>
            </div>
          </div>
        );
      } else {
        return this.renderContactView(selectedContact);
      }
    } else if (
      selectedChat &&
      selectedContact &&
      selectedChat.contact.id == selectedContact.id
    ) {
      return this.renderContactView(selectedContact);
    }

    const readOnlyTextarea =
      this.state.finishedSession === true &&
      this.state.selectedChatTab === CHAT_TAB_CHATS
        ? true
        : false;

    const placeholderTextarea =
      readOnlyTextarea === true
        ? this.strings.noSessionPlaceholder
        : this.state.selectedChatTab === CHAT_TAB_CHATS
        ? this.strings.answerPlaceholder
        : this.strings.notePlaceholder;

    const availableHSMs = this.getSelectedChat()
      ? this.props.hsms.filter((hsm) => {
          if (
            hsm.buttons?.type === "call_to_action" &&
            hsm.buttons.options.some((button) => button.target_url)
          ) {
            return false;
          }
          return true;
        })
      : this.props.hsms.filter((hsm) => hsm.poll_id);

    const allowDrag =
      this.state.selectedChatTab !== CHAT_TAB_NOTES &&
      this.state.finishedSession === false;

    const deniedPasteSnippet =
      this.state.finishedSession === true &&
      this.state.selectedChatTab === CHAT_TAB_CHATS;

    const contactName = selectedChat.contact.name
      ? selectedChat.contact.name
      : "";

    const agentName =
      this.props.user.agent.first_name + " " + this.props.user.agent.last_name;

    const snippetCopyText = this.state.snippetCopy
      ? this.formatSnippetText(
          this.state.snippetCopy.text,
          contactName,
          agentName,
        )
      : null;

    const contactId = selectedChat?.contact.id || selectedContact?.id;

    return (
      <div className="chat-container">
        <div
          className={`chat-window ${
            this.props.fullScreen ? "full-screen" : ""
          }`}
        >
          {this.renderHeaderCurrentChat(selectedChat)}
          <ChatDragWindow
            sendToTarget={this.sendToTarget}
            uploadToS3={this.props.uploadToS3}
            strings={this.strings}
            selectedChat={this.state.selectedChatId}
            setSendingFile={this.setSendingFile}
            scrollDownChat={this.scrollDownChat}
            allowDrag={allowDrag}
            isFetching={this.props.isFetching}
          >
            {this.renderMobileChatTabs(selectedChat)}
            <div className="chat-main-window has-messages" id="chat-scrollable">
              <ChatHistory
                loadMore={() =>
                  this.props.getConversationHistory(
                    selectedChat.contact.id,
                    selectedChat.channel_id,
                    false,
                  )
                }
                language={this.props.language}
                hasMore={this.props.hasMoreHistory}
                isLoading={this.props.isFetchingHistory}
                conversationId={selectedChat.conversation_id}
                deployments={this.props.deployments[contactId]}
                chatEvents={this.props.currentConversationHistory}
                notes={this.props.currentConversationNotes}
                getAddressFromCoordinates={this.props.getAddressFromCoordinates}
                sendingFile={this.state.sendingFile}
                selectProviderMsgId={(providerMsgId) =>
                  this.setState({ selectedProviderMsgId: providerMsgId })
                }
                type={
                  this.state.selectedChatTab === CHAT_TAB_CHATS
                    ? CHAT_HISTORY
                    : NOTE_HISTORY
                }
                showFileModal={this.showFileModal}
                selectedChat={this.state.selectedChatId}
                sendToTarget={this.sendToTarget}
                findReplyMessage={this.findReplyMessage}
              />
            </div>
            {this.renderScrolldownButton()}
            {this.renderChannelSection()}
            {this.renderChatTabs(readOnlyTextarea)}
            <RichTextarea
              chatTab={this.state.selectedChatTab}
              sendToTarget={this.sendToTarget}
              strings={this.strings}
              mobileToolbar={true}
              extras={[
                "Templates",
                "Emojis",
                "Files",
                "Notes",
                "Jumps",
                "Record",
              ]}
              uploadToS3={this.props.uploadToS3}
              hsms={availableHSMs}
              textModifiers={true}
              placeholder={placeholderTextarea}
              user={this.props.user}
              scrollDownChat={this.scrollDownChat}
              setSendingFile={this.setSendingFile}
              toggleHSMModal={this.toggleHSMModal}
              readOnly={readOnlyTextarea}
              selectedChat={this.state.selectedChatId}
              deniedPaste={deniedPasteSnippet}
              copy={snippetCopyText}
              initValue={
                this.state.selectedChatId in this.state.messages
                  ? this.state.messages[this.state.selectedChatId]
                  : ""
              }
              storeCurrentValue={(message, conversationId) => {
                console.log(message);
                const { messages } = this.state;
                messages[conversationId] = message;
                console.log(messages);
                this.setState({ messages });
              }}
              chats={chats}
              onSelectChat={this.onSelectChat}
              selectTabNotes={this.selectTabNotes}
            />
          </ChatDragWindow>
        </div>
        {this.renderSidePanel()}
      </div>
    );
  }

  sendToTarget = (type, message, conversation_id, resendData = null) => {
    const isResent = resendData !== null;
    if (type === "MEDIA" && this.state.selectedChatTab === CHAT_TAB_CHATS) {
      const contactId = this.getSelectedChat().contact.id;
      this.props.sendMedia(conversation_id, message, isResent, contactId);
    } else if (type === "TEXT") {
      if (this.state.selectedChatTab === CHAT_TAB_CHATS) {
        const providerMsgId = resendData
          ? resendData.providerMsgId
          : this.state.selectedProviderMsgId;
        if (providerMsgId) {
          const replyMessage = this.getMessage(providerMsgId);
          this.props.sendReplyMessage(
            conversation_id,
            message,
            providerMsgId,
            replyMessage,
            isResent,
          );
          this.setState({ selectedProviderMsgId: null });
        } else {
          this.props.sendMessage(conversation_id, message, isResent);
        }
      } else if (this.state.selectedChatTab === CHAT_TAB_NOTES) {
        let agent = this.props.user.agent;
        this.props.createNote(
          message,
          conversation_id,
          `${agent.first_name} ${agent.last_name}`,
        );
      }
    }
    return;
  };

  toggleHSMModal = (hsm) => {
    this.setState({ showHSMModal: true, hsm: hsm });
  };

  renderTabItem = (tabType, nameTab, count) => {
    return (
      <div
        className={`text ${
          this.state.selectedTab === tabType ? "is-active" : ""
        }`}
        onClick={() => {
          this.setState({
            selectedTab: tabType,
            selectedChatId: null,
            selectedContactId: null,
            searchValue: "",
          });
        }}
      >
        {nameTab}
        <div className="count"> {count}</div>
      </div>
    );
  };

  renderTabs = (chatsLength) => {
    return (
      <div className="tabs">
        {this.renderTabItem(TAB_CHATS, this.strings.chats, chatsLength)}
        {this.renderTabItem(
          TAB_CONTACTS,
          this.strings.contacts,
          this.props.contacts.visible,
        )}
      </div>
    );
  };

  renderMobileChatTabs = (selectedChat) => {
    const helpdesksInfo =
      selectedChat.contact.extra &&
      selectedChat.contact.extra["helpdesks_info"];

    const currentHelpdeskInfo =
      helpdesksInfo && (helpdesksInfo["HUBSPOT"] || helpdesksInfo["hubspot"]);

    return (
      <div className="chats-mobile-tabs">
        <div
          className="header-item"
          onClick={() => this.setState({ showSnippetsModal: true })}
        >
          <div className="icon icon--snippet-templates" />
          <p>{this.strings.quickTemplates}</p>
        </div>
        {currentHelpdeskInfo && (
          <div
            className="header-item"
            onClick={() =>
              this.setState({ hubspotPanelModal: { show: true, option: null } })
            }
          >
            <div className="icon icon--hubspot-dashboard" />
            <p>{this.strings.hubspotDashboard}</p>
          </div>
        )}
      </div>
    );
  };

  getMessage = (providerMsgId) => {
    let events = this.props.currentConversationHistory;
    let messageEvent = events.find(
      (e) =>
        e.event_type === "message" &&
        e.message.provider_msg_id === providerMsgId,
    );
    if (messageEvent === undefined) {
      return {
        type: "not-found",
        text: this.strings.msgNotFound,
        sender: "USER",
      };
    }
    return messageEvent.message;
  };

  renderReplyMessage = () => {
    const message = this.getMessage(this.state.selectedProviderMsgId);
    const sender = message.sender.toLowerCase();
    let { text, icon, img } = contentReplyMessage(message);
    return (
      <div className="reply-message">
        <div className="cancel">
          <div
            className="icon icon--close"
            onClick={() => this.setState({ selectedProviderMsgId: null })}
          />
        </div>
        <div className={`box-style ${sender}`}>
          <div className="content">
            {icon}
            <div className="text">{formatText(text)}</div>
            {img ? <div className="cut-image">{img}</div> : ""}
          </div>
        </div>
      </div>
    );
  };
  /**
   * getDisplayCellphone takes a string that represents a number
   * if the number is from USA it will be displayed as
   * +1 9852978058 else it will be +51 919170281
   *@retrun{string}
   */
  getDisplayCellphone = (number) => {
    let index = number.charAt(0) === "1" ? 1 : 2;
    return `${number.substring(0, index)} ${number.substring(
      index,
      number.length,
    )}`;
  };

  renderChannelSection = () => {
    if (this.props.channels.length < 2) return;
    let chat = this.getSelectedChat();
    if (chat == undefined) return;
    let channelIdFromChat = chat.channel_id;
    let channel = this.props.channels.filter(
      (channel) => channel.id === channelIdFromChat,
    )[0];
    if (channel == undefined) return;
    return (
      <div className="chat-channel-container">
        <div className="icon icon--whatsapp" />
        <p> {`${channel.name} +${this.getDisplayCellphone(channel.number)}`}</p>
      </div>
    );
  };

  selectTabNotes = () => {
    this.setState({
      selectedChatTab: CHAT_TAB_NOTES,
      notes: true,
    });
    setTimeout(this.scrollDownChat, 1000);
  };

  renderContactModal = () => {
    if (!this.state.showContactModal) {
      return;
    }
    const resetState = () => {
      this.setState({
        showContactModal: false,
        actionContact: "add",
      });
    };

    let currentContact = this.getSelectedContact();
    if (currentContact === undefined) {
      let currentChat = this.getSelectedChat();
      if (currentChat !== undefined) {
        currentContact = currentChat.contact;
      }
    }
    if (currentContact !== undefined && currentContact.name === null) {
      currentContact["exists"] = false;
    }

    return (
      <ContactModal
        strings={this.strings}
        show={this.state.showContactModal}
        resetState={resetState}
        createContact={this.props.createContact}
        deleteContact={this.props.deleteContact}
        updateContact={this.props.updateContact}
        renderContactInfo={this.renderContactInfo}
        renderFiles={this.renderBodyFiles}
        currentContact={currentContact}
        renderChatLabels={() => {
          const chat = this.getSelectedChat();
          if (chat) this.renderChatLabels(chat);
        }}
        action={this.state.actionContact}
        getRemainingSession={this.getRemainingSession}
      />
    );
  };

  renderSnippetsModal = () => {
    return (
      <Modal
        wrappClass="mobile-modal snippets-modal"
        show={this.state.showSnippetsModal}
        body={this.renderSnippets()}
        onClose={() => this.setState({ showSnippetsModal: false })}
      />
    );
  };

  setHubspotOptionPanel = (value) => {
    const hubspotPanelModal = this.state.hubspotPanelModal;
    hubspotPanelModal["option"] = value;
    this.setState({ hubspotPanelModal: hubspotPanelModal });
    console.log("hubspot panel modal", this.state.hubspotPanelModal);
  };

  renderHubspotPanelModalBody = (iframe) => {
    if (iframe) return <iframe src={iframe} />;
    return (
      <div className="hubspot-options">
        <div
          className="hubspot-panel-category"
          onClick={() => this.setHubspotOptionPanel("hs-contact")}
        >
          <TrebleIcon name="hubspot-contacts" />
          <p>{this.strings.hubspotContactPanel}</p>
        </div>
        <div
          className="hubspot-panel-category"
          onClick={() => this.setHubspotOptionPanel("hs-ticket")}
        >
          <TrebleIcon name="hubspot-tickets" />
          <p>{this.strings.hubspotTicketPanel}</p>
        </div>
      </div>
    );
  };

  renderHubspotPanelModal = () => {
    const selectedChat = this.getSelectedChat();
    const helpdeskInfo =
      selectedChat &&
      selectedChat.contact.extra &&
      selectedChat.contact.extra["helpdesks_info"];

    if (!helpdeskInfo) return;

    const hubspotMapper = this.getHubspotMapper(helpdeskInfo);

    let title, body;
    const { show, option } = this.state.hubspotPanelModal;
    if (option) {
      const values = hubspotMapper[option];
      title = values.title;
      body = values.url;
    }

    return (
      <Modal
        wrappClass={`${
          !option ? "mobile-modal" : "option-selected"
        } hubspot-panel-modal`}
        show={show}
        header={title}
        body={this.renderHubspotPanelModalBody(body)}
        onClose={() =>
          this.setState({ hubspotPanelModal: { show: false, option: null } })
        }
      />
    );
  };

  renderHSMModal = () => {
    if (!this.state.showHSMModal || this.state.hsm === null) {
      return;
    }
    const resetState = () => {
      this.setState({
        showHSMModal: false,
        hsm: null,
      });
    };
    const selectedChat = this.getSelectedChat();
    const selectedContact = this.getSelectedContact();
    let isNewConversation = selectedContact && !selectedChat ? true : false;
    const sendHSM = (parameters, header, content, footer, buttons) => {
      const { hsm } = this.state;
      if (selectedChat) {
        console.log("send HSM", selectedChat);
        const { conversation_id } = selectedChat;
        this.props.sendHSM(
          conversation_id,
          hsm.name,
          parameters,
          header,
          content,
          footer,
          buttons,
        );
      } else if (selectedContact) {
        console.log("send HSM New Conversation", selectedContact);
        this.props.sendHSMConversation(
          selectedContact,
          hsm.poll_id,
          hsm.name,
          parameters,
          header,
          content,
          footer,
          buttons,
        );
      } else {
        console.error("Error SendHSM");
        return;
      }
    };
    return (
      <HSMModal
        strings={this.strings}
        show={this.state.showHSMModal}
        hsm={this.state.hsm}
        resetState={resetState}
        sendHSM={sendHSM}
        isNewConversation={isNewConversation}
        channels={this.props.channels}
        displayChannels={this.getDisplayCellphone}
      />
    );
  };

  renderTransferModal = () => {
    const hardResetState = () => {
      this.setState({
        showTransferModal: false,
        selectedChatId: null,
        selectedContactId: null,
        finishedSession: false,
        activeConversationAgent: null,
      });
    };

    const resetState = () => {
      this.setState({
        showTransferModal: false,
      });
    };

    let selectedChat = this.getSelectedChat();

    return (
      <TransferModal
        language={this.props.language}
        chats={[selectedChat]}
        show={this.state.showTransferModal}
        resetState={resetState}
        hardResetState={hardResetState}
        agents={this.props.agents}
        tags={this.props.tags}
        transferChats={this.props.transferChats}
        assignChats={this.props.assignChats}
      />
    );
  };

  handleSearchTextChange = (value) => {
    const tab = this.state.selectedTab;
    const searchedValue = value;
    if (tab === TAB_CONTACTS && searchedValue !== undefined) {
      this.props.cleanContacts();
      this.props.getContacts(
        this.props.contacts.offset,
        this.state.searchValue,
      );
    } else if (tab === TAB_CHATS && searchedValue !== undefined) {
      console.log("Nothing");
    }
  };

  onSearch = ({ target: { value } }) => {
    this.setState({ searchValue: value });
    this.handleSearchTextChange(value);
  };

  renderColorOption = () => {
    const selectedColor = this.state.selectedColorOption;
    return (
      <Dropdown
        className="order-dropdown color-label-dropdown"
        title={
          <div className="color-selector">
            <div className={`color ${selectedColor}`} />
          </div>
        }
        iconRow="dropdown-row"
      >
        {this.colorLabels.map((color) => (
          <div
            className="color-selector"
            onClick={() => {
              this.changeSelectedOption(this.state.selectedOption, color);
            }}
          >
            <div className={`color ${color}`} />
            {selectedColor === color ? (
              <div className="icon icon--sent" />
            ) : null}
          </div>
        ))}
      </Dropdown>
    );
  };

  renderOrderOptions = () => {
    const hasColorOption =
      this.orderOptions[this.state.selectedOption] ===
      this.strings.filterByLabels;
    return (
      <div className="order-options">
        <Dropdown
          className={`order-dropdown ${hasColorOption ? "has-color" : ""}`}
          title={
            <span className="text">
              {this.orderOptions[this.state.selectedOption]}
            </span>
          }
          iconRow="dropdown-row"
        >
          <ul>
            {this.orderOptions.map((option, index) => {
              return (
                <li
                  onClick={() => {
                    events.track("Agent click on sort chat option", {
                      "sort option": this.orderDateIndex[index],
                    });
                    this.changeSelectedOption(index);
                  }}
                  className="dropdown-item"
                >
                  <div className="text-item">{option}</div>
                  {index == this.state.selectedOption && (
                    <div className="icon icon--sent"></div>
                  )}
                </li>
              );
            })}
          </ul>
        </Dropdown>
        {hasColorOption ? this.renderColorOption() : null}
      </div>
    );
  };

  renderCreateContactButton = () => {
    return (
      <div className="contact-button">
        <Button
          text={this.strings.addContact}
          icon="add-contact"
          onClick={() => {
            this.setState({ showContactModal: true, selectedContactId: null });
            events.track("Agents contacts click on add contact", {});
          }}
        />
      </div>
    );
  };

  renderFindOptions = () => {
    return (
      <div className="find-options">
        {this.state.selectedTab === TAB_CHATS
          ? this.renderOrderOptions()
          : this.renderCreateContactButton()}
        <div className="lookup-icon">
          <div
            className="icon icon--search"
            onClick={() => this.setState({ showContactSearchBar: true })}
          />
        </div>
      </div>
    );
  };

  renderSearchBar = () => {
    const placeholder =
      this.state.selectedTab === TAB_CHATS
        ? this.strings.searchConversation
        : this.strings.searchContact;
    return (
      <SearchBar
        placeholder={placeholder}
        onChange={this.onSearch}
        value={this.state.searchValue}
        trackMessage={
          this.state.selectedTab === TAB_CONTACTS
            ? "Agent search contact"
            : "Agent search conversation"
        }
        showCancel={true}
        onClick={() => {
          this.setState({
            showContactSearchBar: false,
            searchValue: "",
          });
          this.handleSearchTextChange("");
        }}
      />
    );
  };

  renderSearchAndOrderBar = () => {
    return (
      <div className="search-filter-conversations">
        {this.state.showContactSearchBar
          ? this.renderSearchBar()
          : this.renderFindOptions()}
      </div>
    );
  };

  renderModals = () => {
    return (
      <>
        {this.renderEndChatModal()}
        {this.renderFileModal()}
        {this.renderContactModal()}
        {this.renderHSMModal()}
        {this.renderTransferModal()}
        {this.renderLabelsModal()}
        {this.renderSnippetsModal()}
        {this.renderHubspotPanelModal()}
      </>
    );
  };

  render() {
    const selectedChat = this.getSelectedChat();
    const selectedContact = this.getSelectedContact();
    let popUpChat = "no-pop-up-chat";
    if (selectedChat !== undefined || selectedContact !== undefined) {
      popUpChat = "pop-up-chat";
    }
    const chats = this.getFilterChats();
    return (
      <div className="columns is-gapless">
        <div
          className={`column chats ${
            this.state.sideBar.hubspotPanel?.open === true ||
            this.props.fullScreen ||
            this.state.fullContactView
              ? "hide"
              : "show"
          }`}
        >
          {this.renderTabs(chats.length)}
          {this.renderSearchAndOrderBar()}
          {this.renderContacts()}
          {this.renderChats(chats)}
        </div>

        <div className={`column desktop tablet ${popUpChat} mobile`}>
          {this.renderCurrentChat(chats)}
        </div>
        {this.renderModals()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chats: state.mainReducer.chats,
    contacts: state.mainReducer.contacts,
    deployments: state.mainReducer.deployments,
    hsms: state.mainReducer.hsms,
    chatLabels: state.mainReducer.chatLabels,
    agents: state.mainReducer.agents,
    tags: state.mainReducer.tags,
    user: state.mainReducer.user,
    isFetching: state.mainReducer.isFetching,
    currentConversationHistory: state.mainReducer.currentConversationHistory,
    hasMoreHistory: state.mainReducer.hasMoreHistory,
    isFetchingHistory: state.mainReducer.isFetchingHistory,
    currentConversationNotes: state.mainReducer.currentConversationNotes,
    language: state.mainReducer.language,
    channels: state.mainReducer.channels,
    helpdesks: state.mainReducer.helpdesks,
    agentSnippets: state.mainReducer.agentSnippets,
    files: state.mainReducer.files,
    fullScreen: state.mainReducer.fullScreen,
    alertState: state.mainReducer.alertState,
    contactEvents: state.mainReducer.contactEvents,
    contactFetching: state.mainReducer.contactFetching,
    events: state.mainReducer.events,
    notes: state.mainReducer.notes,
    isFetchingChats: state.mainReducer.isFetchingChats,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNote: (content, conversationId, name) =>
    dispatch(createNote(content, conversationId, name)),
  endConversation: (conversationId, extra = {}) =>
    dispatch(endConversation(conversationId, extra)),
  getChats: () => dispatch(getChats()),
  getHsms: () => dispatch(getHsms()),
  getContactEvents: (contact_id) => dispatch(getContactEvents(contact_id)),
  getContactConversationHistory: (conversationId) =>
    dispatch(getContactConversationHistory(conversationId)),
  getContacts: (offset, searchvalue) =>
    dispatch(getContacts(offset, searchvalue)),
  cleanContacts: () => dispatch(cleanContacts()),
  getAddressFromCoordinates: (latitude, longitude, key, msgId, callback) =>
    dispatch(
      getAddressFromCoordinates(latitude, longitude, key, msgId, callback),
    ),
  setFullScreen: (value) => dispatch(setFullScreen(value)),
  getFiles: (contactId) => dispatch(getFiles(contactId)),
  getChannels: () => dispatch(getChannels()),
  getHelpdesks: () => dispatch(getHelpdesks()),
  getChatLabels: () => dispatch(getChatLabels()),
  createChatLabel: (label) => dispatch(createChatLabel(label)),
  deleteChatLabel: (id) => dispatch(deleteChatLabel(id)),
  editChatLabel: (label, id) => dispatch(editChatLabel(label, id)),
  toggleChatLabel: (id, chatId) => dispatch(toggleChatLabel(id, chatId)),
  createContact: (name, countryCode, cellphone) =>
    dispatch(createContact(name, countryCode, cellphone)),
  updateContact: (contactId, name) => dispatch(updateContact(contactId, name)),
  deleteContact: (contactId) => dispatch(deleteContact(contactId)),
  sendMedia: (conversationId, mediaUrl, isResent, contactId) =>
    dispatch(sendMedia(conversationId, mediaUrl, isResent, contactId)),
  sendMessage: (customerCellphoneIdMessage, message, isResent) =>
    dispatch(sendMessage(customerCellphoneIdMessage, message, isResent)),
  uploadToS3: (file, onLoad, uploadFinish, sendToTarget) =>
    dispatch(uploadToS3(file, onLoad, uploadFinish, sendToTarget)),
  agentGetSnippets: () => dispatch(agentGetSnippets()),
  agentPushSnippet: (snippet) => dispatch(agentPushSnippet(snippet)),
  agentRemoveSnippet: (snippet) => dispatch(agentRemoveSnippet(snippet)),
  sendReplyMessage: (
    conversationId,
    message,
    replyProviderMsgId,
    replyMessage,
    isResent,
  ) =>
    dispatch(
      sendReplyMessage(
        conversationId,
        message,
        replyProviderMsgId,
        replyMessage,
        isResent,
      ),
    ),
  sendHSM: (
    conversationId,
    name,
    parameters,
    header,
    content,
    footer,
    buttons,
  ) =>
    dispatch(
      sendHSM(
        conversationId,
        name,
        parameters,
        header,
        content,
        footer,
        buttons,
      ),
    ),
  sendHSMConversation: (
    contact,
    hsm_poll_id,
    hsm_name,
    parameters,
    header,
    content,
    footer,
    buttons,
  ) =>
    dispatch(
      sendHSMConversation(
        contact,
        hsm_poll_id,
        hsm_name,
        parameters,
        header,
        content,
        footer,
        buttons,
      ),
    ),
  selectChat: (conversationId, contactId, channelId) =>
    dispatch(selectChat(conversationId, contactId, channelId)),
  transferChats: (conversationIds, agentId, categoryId) =>
    dispatch(transferChats(conversationIds, agentId, categoryId)),
  getAgents: () => dispatch(getAgents()),
  agentGetTags: (companyId) => dispatch(agentGetTags(companyId)),
  assignChats: (conversationIds, agentId, categoryId) =>
    dispatch(assignChats(conversationIds, agentId, categoryId)),
  getConversationHistory: (contactId, channelId, firstTime) =>
    dispatch(getConversationHistory(contactId, channelId, firstTime)),
  updateAlertState: (name, progress) =>
    dispatch(updateAlertState(name, progress)),
  emptyHistory: () => dispatch(emptyHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
