import React, { Component } from "react";
import ReactPlayer from "react-player";
import "./ChatDragWindow.scss";
import events from "../../utils/events";
import { toast } from "react-toastify";

const OneMB = 1000000;
const IMAGE = "IMAGE";
const VIDEO = "VIDEO";
const FILE = "FILE";

export class ChatDragWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onDrag: false,
      fileDropped: false,
    };

    this.strings = this.props.strings;
    this.fileLoaded = null;
    this.eventType = "";

    this.fileTypes = {
      IMAGE: {
        hasTypes: true,
        types: ["png", "jpeg"],
        size: 5 * OneMB,
        typeError: this.strings.typeImageSupport,
        sizeError: this.strings.sizeImageSupport,
      },
      VIDEO: {
        hasTypes: true,
        types: ["mp4", "3gpp"],
        size: 16 * OneMB,
        typeError: this.strings.typeVideoSupport,
        sizeError: this.strings.sizeVideoSupport,
      },
      FILE: {
        hasTypes: false,
        size: 100 * OneMB,
        sizeError: this.strings.sizeFileSupport,
      },
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.selectedChat !== prevProps.selectedChat) {
      this.setState({
        onDrag: false,
        fileDropped: false,
      });
      this.fileLoaded = null;
    }
  };

  resetDrag = () => {
    this.setState({ onDrag: false });
  };

  handleOnPaste = (event) => {
    event.stopPropagation();
    if (this.state.fileDropped || !this.props.allowDrag) return;
    event.persist();
    if (event.clipboardData.files.length) {
      this.fileLoaded = event.clipboardData.files[0];
      this.eventType = "Agent send a file by copy and paste";
      this.setState({ fileDropped: true });
    }
  };

  stopEventPropagation = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  handleDragOver = (event) => {
    this.stopEventPropagation(event);
  };

  handleDragEnter = (event) => {
    this.stopEventPropagation(event);
    if (this.state.fileDropped) return;
    this.setState({ onDrag: true });
  };

  handleDragLeave = (event) => {
    this.stopEventPropagation(event);
    if (this.state.fileDropped) return;
    this.resetDrag();
  };

  handleDragEnd = (event) => {
    this.stopEventPropagation(event);
    this.resetDrag();
  };

  handleOnDrop = (event) => {
    this.stopEventPropagation(event);
    if (this.state.fileDropped) return;
    this.resetDrag();
    event.persist();
    if (event.dataTransfer.files.length) {
      this.fileLoaded = event.dataTransfer.files[0];
      this.eventType = "Agent send a file by drag and drop";
      this.setState({ fileDropped: true });
    }
  };

  renderDropZone = () => {
    return (
      <>
        <div
          className="drop-file-input drop-zone"
          onDragLeave={this.handleDragLeave}
        >
          <div className="drop-file-input--label">
            {this.strings.dragFileHere}
          </div>
        </div>
      </>
    );
  };

  throwFileError = (error) => {
    this.setState({ fileDropped: false });
    toast.error(error, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  sendDropfile = () => {
    const selectedChat = this.props.selectedChat;
    const file = this.fileLoaded;
    const onLoad = () => {
      this.setState({ fileDropped: false });
      this.props.setSendingFile(selectedChat, true);
      setTimeout(this.props.scrollDownChat, 50);
    };
    const uploadFinish = () => {
      events.track(this.eventType, {
        fileExtension: file.type.split("/")[1],
      });
      this.props.setSendingFile(selectedChat, false);
    };
    const sendToTarget = (signedUrl) => {
      const finalUrl = signedUrl.split("?")[0];
      console.log("finalUrl", finalUrl);
      this.props.sendToTarget("MEDIA", finalUrl, selectedChat);
    };
    this.props.uploadToS3(this.fileLoaded, onLoad, uploadFinish, sendToTarget);
  };

  renderContentType = (mimeType) => {
    let content = "";
    const fileUrl = URL.createObjectURL(this.fileLoaded);
    if (mimeType == IMAGE) {
      content = <img src={fileUrl} />;
    } else if (mimeType == VIDEO) {
      content = <ReactPlayer url={fileUrl} width={400} height={280} controls />;
    } else {
      content = (
        <div>
          <div className="center-file">
            <div className="icon icon-file"></div>
          </div>
          <div className="drop-file-name">{this.fileLoaded.name}</div>
        </div>
      );
    }
    return content;
  };

  closeFile = () => {
    events.track("Agent canceled sending file");
    this.setState({ fileDropped: false });
  };

  renderSendButton = () => {
    if (this.props.isFetching) {
      return (
        <button className="button">
          <div className="ico-spinner" />
        </button>
      );
    }
    return (
      <button className="button" onClick={() => this.sendDropfile()}>
        <p>{this.strings.sendFile}</p>
      </button>
    );
  };

  renderDropFile = () => {
    if (this.fileLoaded.type === "") {
      this.throwFileError(this.strings.notValidFile);
      return;
    }

    let mimeType = this.fileLoaded.type.split("/")[0].toUpperCase();
    const fileType = this.fileLoaded.type.split("/")[1];
    const fileSize = this.fileLoaded.size;

    if (mimeType != IMAGE && mimeType != VIDEO) mimeType = FILE;

    if (
      this.fileTypes[mimeType].hasTypes &&
      !this.fileTypes[mimeType].types.includes(fileType)
    ) {
      this.throwFileError(this.fileTypes[mimeType].typeError);
      return;
    }

    if (fileSize > this.fileTypes[mimeType].size) {
      this.throwFileError(this.fileTypes[mimeType].sizeError);
      return;
    }

    return (
      <div className="drop-file-input">
        <div className="previous-file-view">
          <div className="header-content">
            <div
              className="icon icon--cancel-file"
              onClick={() => this.closeFile()}
            />
          </div>
          <div className="content-file">{this.renderContentType(mimeType)}</div>
          {this.renderSendButton()}
        </div>
      </div>
    );
  };

  allowToDrag = () => {
    if (this.props.allowDrag) {
      return (
        <>
          {this.state.onDrag ? this.renderDropZone() : ""}
          {this.state.fileDropped ? this.renderDropFile() : ""}
        </>
      );
    }
  };

  render() {
    return (
      <div
        className="cont show-chat"
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDrop={this.handleOnDrop}
        onPaste={this.handleOnPaste}
        tabIndex="0"
      >
        {this.allowToDrag()}
        {this.props.children}
      </div>
    );
  }
}

export default ChatDragWindow;
