// import 'babel-polyfill'
import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import "react-dates/initialize";
import { Provider } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history, store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import Login from "./views/Login/Login";
import Platform from "./views/Platform/Platform";

import * as serviceWorker from "./serviceWorker";

LogRocket.init("kotvh4/agenttrebleai");

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

const isAuthenticated = () => {
  const state = store.getState();
  return state.mainReducer.user;
};

const isMobile = window.innerWidth <= 768;

const LoggedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <Component {...props} {...rest} />
        )
      }
    />
  );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <LoggedRoute path="/admin:filter?" component={Platform} />
      </ConnectedRouter>
      <ToastContainer />
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (isMobile) {
  navigator.serviceWorker.register(swUrl);
} else {
  serviceWorker.unregister();
}
