module.exports = {
  es: {
    noResults: "Oops! No hay resultados 👀",
  },
  en: {
    noResults: "Oops! There are no results 👀",
  },
  pt: {
    noResults: "Opa! Não há resultados 👀",
  },
};
