import React from "react";
import { connect } from "react-redux";

import TrebleIcon from "../../../../../../components/TrebleIcon/TrebleIcon";

import languages from "./languages";

const Empty = (props) => {
  const strings = languages[props.language];

  return (
    <div className="empty-graph">
      <div className="empty-graph-header">
        <div className="empty-graph-title">
          <TrebleIcon name="warning" size={18} />
          <p>
            {strings.emptyTitle.replace("[GRAPH_KEY]", strings[props.name])}
          </p>
        </div>
        <p>{strings.emptyDescription}</p>
      </div>
      <TrebleIcon name="no-metrics-bw" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Empty);
