import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { HeatMapGrid } from "react-grid-heatmap";
import { CSVLink } from "react-csv";

import TrebleIcon from "../../../../../../components/TrebleIcon/TrebleIcon";
import Empty from "../../../../../../views/Platform/Metrics/Components/Graphs/Empty/Empty";
import Loading from "../../../../../../views/Platform/Metrics/Components/Graphs/Loading/Loading";
import MultipleSelectDropdown from "../../../../../../views/Platform/Metrics/Components/MultipleSelectDropdown/MultipleSelectDropdown";

import {
  CONVERSATION_HOUR,
  CONVERSATION_DAY_HOUR,
} from "../../../../../../reducers/metricsReducer";

import events from "../../../../../../utils/events";
import languages from "./languages";
import "./Heatmap.scss";

const MAX_DOW = "maxDow";
const MIN_DOW = "minDow";
const MAX_HOUR = "maxHour";

const DATE = "DATE";
const COUNT = "COUNT";

const HOUR_LABELS = [...Array(24).keys()].map((i) => {
  if (i < 10) {
    return `0${i}:00`;
  } else {
    return `${i}:00`;
  }
});

class Heatmap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredCell: null,
      extras: [MIN_DOW, MAX_HOUR],
      sidePanel: null,
    };

    this.strings = languages[props.language];

    this.dowDays = {
      1: this.strings.monday,
      2: this.strings.tuesday,
      3: this.strings.wednesday,
      4: this.strings.thursday,
      5: this.strings.friday,
      6: this.strings.saturday,
      7: this.strings.sunday,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        this.props.informationPanel.data,
        prevProps.informationPanel.data,
      )
    ) {
      this.setState({
        sidePanel: {
          ...this.state.sidePanel,
          counts: [
            ...new Set(
              this.props.informationPanel.data
                .sort((a, b) => a.count - b.count)
                .map((data) => data.count.toString()),
            ),
          ],
        },
      });
    }
    if (
      !_.isEqual(this.props.data, prevProps.data) &&
      this.state.sidePanel !== null
    ) {
      this.setState({ sidePanel: null });
    }
  }

  getTotalConversations = () => {
    return this.props.data.reduce(
      (sum, array) => (sum += array.reduce((sum_2, value) => (sum_2 += value))),
      0,
    );
  };

  getUniqueConversationCountOptions = () => {
    let countLabels = [];
    let countArray = [
      ...new Set(
        this.props.informationPanel.data
          .sort((a, b) => a.count - b.count)
          .map((data) => data.count.toString()),
      ),
    ];

    countArray.forEach((count) =>
      countLabels.push({ label: count, value: count }),
    );

    return countLabels;
  };

  changeSidePanelSort = (sortObject) => {
    const sidePanel = this.state.sidePanel;

    if (sortObject === sidePanel.sortObject) {
      this.setState({
        sidePanel: { ...this.state.sidePanel, sortAsc: !sidePanel.sortAsc },
      });
    } else {
      this.setState({
        sidePanel: { ...this.state.sidePanel, sortObject, sortAsc: true },
      });
    }
  };

  sortSidePanel = (a, b) => {
    const sortObject = this.state.sidePanel.sortObject;
    const sortAsc = this.state.sidePanel.sortAsc;

    if (sortObject === DATE) {
      if (sortAsc) {
        return a.date > b.date ? 1 : -1;
      } else {
        return a.date > b.date ? -1 : 1;
      }
    } else if (sortObject === COUNT) {
      return sortAsc === true ? a.count - b.count : b.count - a.count;
    }
    return 0;
  };

  renderSidePanel = () => {
    if (this.state.sidePanel !== null) {
      if (this.props.informationPanel.loading) {
        return (
          <div className="heatmap-side-panel">
            <Loading />
          </div>
        );
      } else {
        return (
          <div className="heatmap-side-panel">
            <div className="heatmap-side-panel-header">
              <div className="dow">
                {this.dowDays[this.state.sidePanel.dow].slice(0, 2)}
              </div>
              <div className="hour">
                {this.state.sidePanel.hour}:00 - {this.state.sidePanel.hour}:59
              </div>
              <div className="total">
                {this.props.informationPanel.data.reduce(
                  (sum, data) => (sum += data.count),
                  0,
                )}{" "}
                {this.strings.chats}
              </div>
              <div
                className="icon icon--close"
                onClick={() => this.setState({ sidePanel: null })}
              />
            </div>
            <div className="heatmap-side-panel-data">
              <div className="heatmap-side-panel-filters">
                <div className="heatmap-side-panel-filter">
                  <p>{this.strings.date}</p>
                  <TrebleIcon
                    name="sort"
                    onClick={() => this.changeSidePanelSort(DATE)}
                  />
                </div>
                <div className="heatmap-side-panel-filter">
                  <p>{this.strings.totalChats}</p>
                  <TrebleIcon
                    name="sort"
                    onClick={() => this.changeSidePanelSort(COUNT)}
                  />
                </div>
                <MultipleSelectDropdown
                  options={this.getUniqueConversationCountOptions()}
                  _key="heatmap-conversation-count"
                  placement="bottomRight"
                  onSelect={(options) =>
                    this.setState({
                      sidePanel: { ...this.state.sidePanel, counts: options },
                    })
                  }
                  value={this.state.sidePanel.counts}
                >
                  <div className="icon icon--filters" />
                </MultipleSelectDropdown>
              </div>
              <div className="heatmap-side-panel-data-display">
                {this.props.informationPanel.data
                  .sort(this.sortSidePanel)
                  .map((data) => {
                    if (
                      this.state.sidePanel.counts.includes(
                        data.count.toString(),
                      )
                    ) {
                      return (
                        <div
                          className="heatmap-side-panel-data-point"
                          key={data.date}
                        >
                          <div className="heatmap-side-panel-date">
                            {data.date}
                          </div>
                          <div className="heatmap-side-panel-count">
                            {data.count}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  getDownloadData = () => {
    const headers = [this.strings.day, ...HOUR_LABELS];
    const data = this.props.data.map((d, i) => [this.dowDays[i + 1], ...d]);
    return [headers, ...data];
  };

  renderActions = () => {
    return (
      <div className="graph-actions">
        <MultipleSelectDropdown
          options={[
            { label: this.strings.lowestDayAverage, value: MIN_DOW },
            { label: this.strings.maxHourAverage, value: MAX_HOUR },
          ]}
          _key="heatmap-filter"
          onSelect={(option) => {
            this.setState({ extras: option });
            events.track("Agent changed displayed metrics", {
              "Metric type": CONVERSATION_HOUR,
              "Selected metrics": option,
            });
          }}
          value={this.state.extras}
        >
          <div className="icon icon--filters" />
        </MultipleSelectDropdown>

        <CSVLink
          data={this.getDownloadData()}
          filename={`${this.strings.csvFilename}-${moment().format(
            "YYYY-MM-DD",
          )}.csv`}
        >
          <div
            className="icon icon--download"
            onClick={() => this.props.trackDownload(CONVERSATION_HOUR)}
          />
        </CSVLink>
      </div>
    );
  };

  renderHeaders = () => {
    const { maxDow, minDow, maxHour } = this.props.extra;

    let minDowText = `${minDow.dows
      .map((dow) => this.dowDays[dow])
      .join(" / ")} - ${minDow.count} Chats`;
    if (_.isEqual(maxDow.dows, minDow.dows) && maxDow.count === minDow.count) {
      minDowText = "-";
    }

    return (
      <div className="heatmap-headers">
        <div className="heatmap-header">
          <p className="heatmap-header-title">{this.strings.maxDayAverage}</p>
          <p className="heatmap-header-data">
            {maxDow.dows.map((dow) => this.dowDays[dow]).join(" / ")} -{" "}
            {maxDow.count} {this.strings.chats}
          </p>
        </div>
        {this.state.extras.includes(MIN_DOW) && (
          <div className="heatmap-header">
            <p className="heatmap-header-title">
              {this.strings.lowestDayAverage}
            </p>
            <p className="heatmap-header-data">{minDowText}</p>
          </div>
        )}
        {this.state.extras.includes(MAX_HOUR) && (
          <div className="heatmap-header">
            <p className="heatmap-header-title">
              {this.strings.maxHourAverage}
            </p>
            <p className="heatmap-header-data">
              {maxHour.hours.map((hour) => `${hour}:00 hrs`).join(" / ")} -{" "}
              {maxHour.count} {this.strings.chats}
            </p>
          </div>
        )}
      </div>
    );
  };

  onCellClick = (dow, hour) => {
    this.setState({
      sidePanel: { dow, hour, counts: [], sortObject: DATE, sortAsc: true },
    });
    events.track("Agent hovered on metrics to get info", {
      "Metric type": CONVERSATION_HOUR,
    });
    this.props.getDayHourData(dow, hour);
  };

  getSizeAndMargin = () => {
    let cellSize = "46px";
    let margin = "7px";
    if (window.innerWidth < 1920) {
      if (window.innerWidth >= 1440) {
        cellSize = "40px";
        margin = "4px";
      } else {
        cellSize = "34px";
        margin = "3px";
      }
    }
    return [cellSize, margin];
  };

  renderHeatmap = () => {
    const [size, margin] = this.getSizeAndMargin();

    return (
      <div className="heatmap-data">
        <HeatMapGrid
          data={this.props.data}
          xLabels={HOUR_LABELS.map((hr) => `${hr} hrs`)}
          yLabels={Object.values(this.dowDays)}
          cellRender={(x, y, value) => (
            <div
              className="heatmap-cell"
              onMouseEnter={() => this.setState({ hoveredCell: { x, y } })}
              onMouseLeave={() =>
                this.setState({
                  hoveredCell: null,
                })
              }
            >
              {value}
            </div>
          )}
          xLabelsStyle={(index) => ({
            fontSize: "12px",
            color: "#393939",
            transform: "rotate(-45deg)",
            width: size,
            margin: margin,
          })}
          yLabelsStyle={() => ({
            fontSize: "12px",
            textTransform: "uppercase",
            color: "#777",
            margin: margin,
          })}
          cellStyle={(x, y, ratio) => {
            let color = "#00003D";
            if (ratio >= 0.4) {
              color = "#FFFFFF";
            }

            let bgColor = `rgba(0, 0, 61, ${ratio})`;
            if (
              this.state.hoveredCell &&
              (this.state.hoveredCell.x !== x || this.state.hoveredCell.y !== y)
            ) {
              bgColor = `rgba(179, 179, 197, ${ratio})`;
            }

            return {
              background: bgColor,
              fontSize: "16px",
              color: color,
              margin: margin,
            };
          }}
          cellHeight={size}
          xLabelsPos="bottom"
          onClick={(y, x) => this.onCellClick(y + 1, x)}
        />
      </div>
    );
  };

  renderLegend = () => {
    return (
      <div className="heatmap-legend">
        <p>{this.strings.moreBusy}</p>
        <div className="heats">
          {[...Array(6).keys()].map((i) => (
            <div className={`heat heat-${i + 1}`} key={`heat-${i}`} />
          ))}
        </div>
        <p>{this.strings.lessBusy}</p>
      </div>
    );
  };

  render() {
    const totalConversations = this.getTotalConversations();

    if (totalConversations === 0 && !this.props.loading) {
      return <Empty name={CONVERSATION_HOUR} />;
    }

    const content = (
      <>
        {this.renderSidePanel()}
        {this.renderActions()}
        <div className="graph-data">
          {this.renderHeaders()}
          {this.renderHeatmap()}
          {this.renderLegend()}
        </div>
      </>
    );

    return (
      <div className="graph" id="heatmap" key={CONVERSATION_HOUR}>
        {this.props.loading ? <Loading /> : content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  data: state.metricsReducer.data[CONVERSATION_HOUR].data,
  extra: state.metricsReducer.data[CONVERSATION_HOUR].extra,
  loading: state.metricsReducer.data[CONVERSATION_HOUR].loading,
  informationPanel: state.metricsReducer.data[CONVERSATION_DAY_HOUR],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);
