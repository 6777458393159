import { push } from "connected-react-router";
import { request } from "../utils/request";
import { clearError } from "./userAction";
import { registerEvent } from "../utils/amplitude";
import getDeviceType from "../utils/getDeviceType";
import events from "../utils/events";

const REQUEST_LOGIN = "REQUEST_LOGIN";
export const requestLogin = (email, password, apiKey) => (dispatch) => {
  dispatch({
    type: REQUEST_LOGIN,
    email,
    password,
    apiKey,
  });
};

const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const receiveLogin = (user) => (dispatch) => {
  dispatch({
    type: RECEIVE_LOGIN,
    user,
  });
};

const RESET_SHOW_MODALS = "RESET_SHOW_MODALS";
export const resetShowModals = () => (dispatch) => {
  dispatch({
    type: RESET_SHOW_MODALS,
  });
};

const LOGIN_ERROR = "LOGIN_ERROR";
export const errorLogin = (error) => (dispatch) => {
  setTimeout(() => {
    dispatch(clearError());
  }, 3000);

  return dispatch({
    type: LOGIN_ERROR,
    error,
  });
};

export const doLogin = (email, password, apiKey) => (dispatch) => {
  dispatch(requestLogin(email, password, apiKey));

  let body = {
    email,
  };
  if (password === null) {
    body["api_key"] = apiKey;
  } else {
    body["password"] = password;
  }

  request(
    "POST",
    "/agent/login",
    body,
    false,
    (data) => {
      dispatch(receiveLogin(data));

      // dispatch(changeActivation(true));
      try {
        registerEvent(`login_${getDeviceType()}`);
        let sidepanelVersion = localStorage.getItem("hubspotSidepanelVersion");
        events.track("Agent contact view settings", {
          "New view":
            sidepanelVersion === null || sidepanelVersion === "V1"
              ? false
              : true,
        });
      } catch (error) {
        console.log("Failed to register event in amplitude");
        console.log(error);
      }
      return dispatch(push("/admin/chats"));
    },
    (error) => {
      console.log(error);
      return dispatch(errorLogin("Unable to login"));
    },
  );
};

const LOGIN_FROM_HELPDESK = "LOGIN_FROM_HELPDESK";
export const loginFromHelpdesk = () => (dispatch) => {
  dispatch({
    type: LOGIN_FROM_HELPDESK,
  });
};
