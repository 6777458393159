module.exports = {
  es: {
    unassignedChats: "Chats sin asignar",
    activeChats: "Chats activos",
    chatsEndedToday: "Chats finalizados hoy",
    topicHighestLoadToday: "Grupo con mayor carga hoy",
    initialDate: "Fecha inicial",
    finalDate: "Fecha Final",
    allCountries: "Todos los países",
    download: "Descargar",
    conversations: "Conversaciones",
    conversationsSelected: "Conversaciones seleccionadas",
    notes: "Notas",
    search: "Buscar",
    allTags: "Todos los grupos",
    allStatus: "Todos los estados",
    allLabels: "Todas las etiquetas",
    client: "Cliente",
    agent: "Vendedor",
    transfers: "Transferencias",
    tag: "Grupo",
    creationDate: "Fecha de creación",
    duration: "Duración",
    rating: "Calificación",
    status: "Estado",
    inProgress: "En proceso",
    finished: "Cerrada",
    unassigned: "Sin asignar",
    finish_type: "Tipo de finalización",
    agent_first_message: "P. respuesta (creación)",
    p_assigned_time: "P. respuesta (asignacion)",
    last_message: "T. inactividad",
    last_message_sender: "Enviado por: ",
    details: "Ver Detalles",
    assign: "Asignar",
    transfer: "Transferir",
    minutes: "minutos",
    transferChat: "Transferir chat",
    agentTeam: "Equipo Vendedor",
    rowsPerPage: "Filas por página",
    cleanFilters: "Limpiar filtros",
    allRows: "Todas",
    noResponseTime: "Tiempo sin respuesta",
    from: "De",
    to: "A",
  },
  en: {
    unassignedChats: "Unassigned chats",
    activeChats: "Active chats",
    chatsEndedToday: "Chats ended today",
    topicHighestLoadToday: "Tag with the highest load today",
    initialDate: "Initial date",
    finalDate: "Final date",
    allCountries: "All the countries",
    download: "Download",
    conversations: "Chats",
    conversationsSelected: "Selected chats",
    notes: "Notes",
    search: "Search",
    allTags: "All the tags",
    allStatus: "All the status",
    allLabels: "All the labels",
    client: "Client",
    agent: "Salesperson",
    transfers: "Transfers",
    tag: "Tag",
    creationDate: "Creation date",
    duration: "Duration",
    rating: "Rating",
    status: "Status",
    inProgress: "In progress",
    finished: "Finished",
    unassigned: "Unassigned",
    finish_type: "Finish type",
    agent_first_message: "F. answer (creation)",
    p_assigned_time: "F. answer (allocation)",
    last_message: "T. inactivity",
    last_message_sender: "Send by: ",
    details: "Details",
    assign: "Assign",
    transfer: "Transfer",
    minutes: "minutes",
    transferChat: "Transfer chat",
    agentTeam: "Salesperson Team",
    rowsPerPage: "Rows per page",
    cleanFilters: "Clean filters",
    allRows: "All",
    noResponseTime: "No Response time",
    from: "From",
    to: "To",
  },
  pt: {
    unassignedChats: "Bate-papos não atribuídos",
    activeChats: "Chats ativos",
    chatsEndedToday: "Os chats terminaram hoje",
    topicHighestLoadToday: "Tópico com maior carga hoje",
    initialDate: "Data inicial",
    finalDate: "Data final",
    allCountries: "Todos os paises",
    download: "Download",
    conversations: "Conversas",
    conversationsSelected: "Conversas selecionadas",
    notes: "Notas",
    search: "Procurar",
    allTags: "Todas as tags",
    allStatus: "Todos os status",
    allLabels: "Todas as etiquetas",
    client: "Cliente",
    agent: "Vendedor",
    transfers: "Transferências",
    tag: "Marcação",
    creationDate: "Data de criação",
    duration: "Duração",
    rating: "Avaliação",
    status: "Status",
    inProgress: "Em andamento",
    finished: "Finalizado",
    unassigned: "Não atribuído",
    finish_type: "Razão de fechamento",
    agent_first_message: "P. resposta (criação)",
    p_assigned_time: "P. resposta (atribução)",
    last_message: "T. inatividade",
    last_message_sender: "Enviado por",
    details: "Detalhes",
    assign: "Atribuir",
    transfer: "Transferir",
    minutes: "minutos",
    transferChat: "Transferir bate-papo",
    agentTeam: "Equipe do Vendedor",
    rowsPerPage: "Linhas por página",
    cleanFilters: "Limpar filtros",
    allRows: "Tudo",
    noResponseTime: "Sem tempo de resposta",
    from: "De",
    to: "Para",
  },
};
