import React, { PureComponent } from "react";
import onClickOutside from "react-onclickoutside";

import SearchBar from "../SearchBar/SearchBar";
import { Popover } from "antd";
import "antd/dist/antd.css";

import "./SearchableDropdown.scss";
/*
	props:
		- options list(obj): options to be displayed
		- title str: dropdown button text
		- searchPlaceholder str: Placeholder of thesearch input
		- displayItem function(obj) -> str|Component: a map between an object an its display
		- toSearchStr function(obj) -> str: A map between an object an its searchable value
		- onSelect function(obj): to be called when an option is clicked, it calls the clicked object
		- triggerComponent Component: component to display on the trigger
    - hideOnOptionClick bool: Determine whether to hide the drowpdown when an option is clicked
    - renderOnNoResults function(obj) -> component to display on no results found
    - onHoverItem function(obj) -> component to display on hover on option
*/

class SearchableDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      hoverOption: null,
      open: false,
    };
  }

  handleClickOutside = (evt) => {
    if (this.props.hideOnClickOutside === false) return;
    this.setState({ open: false });
  };

  displayItem = (item) => {
    if (typeof item === "string") return <p className="r lh-22">{item}</p>;
    else return this.props.displayItem(item);
  };

  onBodyClick = () => {
    if (this.props.hideOnBodyClick !== true) return;
    this.setState({ open: false });
  };

  hoverOption = (option) => {
    this.setState({ hoverOption: option });
  };

  selectOption = (option) => {
    if (typeof this.props.onSelect === "function") {
      this.setState({ searchText: "", open: false });
      this.props.onSelect(option);
    }
  };

  toggle = (toggleBoolean) => {
    this.setState({ open: toggleBoolean });
  };

  renderToggle = () => {
    if (this.props.triggerComponent) {
      return (
        <div
          onClick={() => {
            if (!this.props.disabled) {
              this.setState({ open: !this.state.open });
            }
          }}
        >
          {this.props.triggerComponent}
        </div>
      );
    } else {
      return (
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p className="r">{this.props.title}</p>
          <span className="icon is-small">
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </span>
        </button>
      );
    }
  };

  onChange = ({ target: { value } }) => {
    this.setState({ searchText: value });
  };

  getfilteredOptions = () => {
    let searchText = this.state.searchText.toLowerCase();
    let { options } = this.props;

    // no searching
    if (!searchText) return this.props.options;

    // use toSearchStr function is available, otherwise use a default function
    let toSearchStr = this.props.toSearchStr;
    if (typeof this.props.toSearchStr !== "function") {
      try {
        let defaultToSearchStr = (option) => String(option);
        toSearchStr = defaultToSearchStr;
      } catch (err) {
        throw "'filterBy' property must be provided";
      }
    }

    // filter by str contained
    return options.filter((option) =>
      toSearchStr(option).toLowerCase().includes(searchText),
    );
  };

  renderOptions = () => {
    const filteredOptions = this.getfilteredOptions();

    if (filteredOptions.length > 0) {
      return (
        <div className="searchable-items">
          {this.getfilteredOptions().map((e) => {
            return (
              <Popover
                content={
                  this.props.onHoverItem ? this.props.onHoverItem(e) : ""
                }
                trigger="hover"
                placement="leftBottom"
                overlayClassName="treble-popover"
              >
                <div
                  key={this.props.options.indexOf(e)}
                  className="dropdown-item"
                  onClick={() => {
                    this.selectOption(e);
                  }}
                >
                  {this.displayItem(e)}
                </div>
              </Popover>
            );
          })}
        </div>
      );
    } else {
      return this.props.renderOnNoResults ? (
        this.props.renderOnNoResults()
      ) : (
        <div className="searchable-items">
          <h3>No results found</h3>
        </div>
      );
    }
  };

  renderMenuHeader = () => {
    if (this.props.menuHeader !== undefined) {
      return (
        <div className="dropdown-menu-header">
          <div
            className="icon icon--close"
            onClick={() => this.setState({ open: false })}
          />
          <h1>{this.props.menuHeader}</h1>
        </div>
      );
    }
    return;
  };

  render = () => {
    let direction = this.props.direction
      ? "direction-" + this.props.direction
      : "";
    let className = this.props.className ? this.props.className : "";
    let subClassName = this.props.subClassName ? this.props.subClassName : "";
    return (
      <div
        className={`dropdown select-dropdown-main ${
          this.state.open ? "is-active" : ""
        }
        ${className}`}
      >
        <div className={`dropdown-trigger`}>{this.renderToggle()}</div>
        <div
          className={`dropdown-menu ${direction} ${subClassName}`}
          role="menu"
        >
          <div className="dropdown-content-container">
            <div className="dropdown-content" onClick={this.onBodyClick}>
              {this.renderMenuHeader()}
              <SearchBar
                placeholder={this.props.searchPlaceholder}
                onChange={this.onChange}
                value={this.state.searchText}
                showCancel={false}
                onClick={() => this.setState({ searchText: "" })}
                trackMessage={this.props.trackMessage}
              />
              {this.state.open ? this.renderOptions() : ""}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default onClickOutside(SearchableDropdown);
