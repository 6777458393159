import { store } from "../store";
import config from "../config";
import { push } from "connected-react-router";
import { registerEvent } from "./amplitude";

export const logEventServer = (reason, body) => {
  if (process.env.REACT_APP_ENV !== "prod") return;
  request(
    "POST",
    "/admin/log/" + reason,
    body,
    true,
    (data) => console.log("Response /admin/log", data),
    (error) => console.error("Error /admin/log", error),
    false,
    false,
  );
};

export const request = (
  method,
  url,
  params,
  auth,
  callbackSuccess,
  callbackError,
  retry = true,
  forceLogout = true,
) => {
  const state = store.getState();
  const user = state.mainReducer.user;

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (auth) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  if (!url.startsWith("http")) {
    url = `${config.API_ROOT}${url}`;
  }
  fetch(url, {
    headers: headers,
    method: method,
    body: method !== "GET" ? JSON.stringify(params) : null,
  })
    .then((response) => {
      return response.json().then((data) => {
        return { response: data, code: response.status };
      });
    })
    .then((response) => {
      const statusCode = response.code;
      const responseData = response.response;
      if (statusCode >= 500) {
        callbackError({
          status: statusCode,
          message: responseData.message || "Internal server error",
        });
        if (forceLogout) {
          registerEvent("logout_internal_server_error");
          logEventServer("server_error", { url, params });
          store.dispatch(push("/?error=true"));
        }
        return;
      }
      if (statusCode >= 402) {
        callbackError({
          status: statusCode,
          message: "User error",
          data: response,
        });
        return;
      }
      if (statusCode == 401) {
        registerEvent("logout_session_expired");
        callbackError({
          status: statusCode,
          message: "Your session expired. Please log in again",
        });
        store.dispatch(push("/"));
        return;
      }

      if (responseData !== undefined) {
        callbackSuccess(responseData);
      }
    })
    .catch((error) => {
      if (error.message == "Network request failed" && retry) {
        let backoffTime, nextRetry;
        if (retry === true) {
          backoffTime = 5;
          nextRetry = 1;
        }
        if (retry === 1) {
          backoffTime = 10;
          nextRetry = 2;
        }
        if (retry === 2) {
          backoffTime = 20;
          nextRetry = false;
        }
        setTimeout(() => {
          request(
            method,
            url,
            params,
            auth,
            callbackSuccess,
            callbackError,
            nextRetry,
          );
        }, backoffTime * 1000);
      } else {
        callbackError(error);
        if (forceLogout) {
          registerEvent("logout_catch_request");
          logEventServer("server_error", { url, params });
          store.dispatch(push("/?error=true"));
        }
        return;
      }
    });
};
