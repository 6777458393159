import React from "react";

class TrebleMapPointer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <i className="icon icon--map-pin-blue"></i>
      </div>
    );
  }
}

export default TrebleMapPointer;
