/*
 * src/store.js
 * No initialState
 */

import { createBrowserHistory } from "history";
import thunkMiddleware from "redux-thunk";
import LogRocket from "logrocket";
import { routerMiddleware } from "connected-react-router";
import { createMigrate, persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, combineReducers } from "redux";

import mainReducer from "./reducers/mainReducer";
import adminReducer from "./reducers/adminReducer";

import { connectRouter } from "connected-react-router";

import socketMiddleware from "./socketMiddleware";
import metricsReducer from "./reducers/metricsReducer";

export const history = createBrowserHistory();

const migrations = {
  0: (state) => {
    return {
      state,
    };
  },
};

const mainPersistConfig = {
  key: "main",
  storage: storage,
  blacklist: [
    "contacts",
    "isFetching",
    "isFetchingHistory",
    "hasMoreHistory",
    "currentConversationHistory",
  ],
  stateReconciler: autoMergeLevel2,
};

const routerPersistConfig = {
  key: "root",
  storage,
  blacklist: ["navigation", "router"], // navigation will not be persisted
  version: 0,
  debug: false,
  timeout: null,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
};

const rootPersistedReducer = (history) =>
  combineReducers({
    router: persistReducer(routerPersistConfig, connectRouter(history)),
    mainReducer: persistReducer(mainPersistConfig, mainReducer),
    adminReducer: persistReducer(routerPersistConfig, adminReducer),
    metricsReducer: metricsReducer,
  });

const loggerMiddleware = createLogger();

export const store = createStore(
  rootPersistedReducer(history),
  applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    thunkMiddleware, // lets us dispatch() functions
    loggerMiddleware, // neat middleware that logs actions
    socketMiddleware(),
    LogRocket.reduxMiddleware(),
  ),
);

export const persistor = persistStore(store);
