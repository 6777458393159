import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import "./Calendar.scss";
import languages from "../CalendarPicker/languages";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let weekdayshort = moment.weekdaysShort();
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendarBody: true,
      lastTypeSelected: this.props.lastTypeSelected,
      dateObject: this.props.dates["referenceDate"],
      startDateObject: this.props.dates["init"],
      endDateObject: this.props.dates["end"],
      hoveredDate: null,
    };

    this.strings = languages[props.language];
  }

  componentWillReceiveProps(nextProps) {
    let nextDate = nextProps.dates["referenceDate"];
    let nextState = {};

    if (
      nextDate.format("YYYY-MM-DD") !==
      this.state.dateObject.format("YYYY-MM-DD")
    ) {
      nextState = {
        dateObject: nextDate,
      };
    }

    if (
      moment(nextProps.dates["init"]).format("YYYY-MM-DD") !=
      moment(this.state.startDateObject).format("YYYY-MM-DD")
    ) {
      nextState = {
        ...nextState,
        startDateObject: nextProps.dates["init"],
      };
    }

    if (
      moment(nextProps.dates["end"]).format("YYYY-MM-DD") !=
      moment(this.state.endDateObject).format("YYYY-MM-DD")
    ) {
      nextState = {
        ...nextState,
        endDateObject: nextProps.dates["end"],
      };
    }

    this.setState({
      ...nextState,
      hoveredDate: nextProps.dates["hovered"],
      lastTypeSelected: nextProps.lastTypeSelected,
    });
  }

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {
    return this.state.dateObject.format("Y");
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf("month").format("d");
    return firstDay;
  };
  month = () => {
    return this.state.dateObject.format("M");
  };
  year = () => {
    return this.state.dateObject.format("YYYY");
  };
  getSelectedDateComplete = (d) => {
    return moment(`${d}-${this.month()}-${this.year()}`, "DD-M-YYYY");
  };

  getOnMonthChangeDay = () => {
    if (this.month() == moment().format("M")) {
      return moment().format("D");
    }
    return null;
  };

  onDayClick = (e, newDate, isHover = false) => {
    let dateType = "";

    if (isHover) {
      dateType = "hover";
    } else if (
      this.state.startDateObject == "" ||
      !this.state.startDateObject._isValid ||
      newDate <= moment(this.state.startDateObject)
    ) {
      dateType = "init";
    } else if (
      this.state.endDateObject == "" ||
      !this.state.endDateObject._isValid ||
      newDate >= moment(this.state.endDateObject)
    ) {
      dateType = "end";
    } else if (this.state.lastTypeSelected == "init") {
      dateType = "end";
    } else {
      dateType = "init";
    }

    this.props.onSelect(moment(newDate), dateType);
  };
  renderDayContainer(day) {
    return <span className="day-container">{day}</span>;
  }

  render() {
    let dateObject = Object.assign({}, this.state.dateObject);
    let lastMonthDay = moment(dateObject).subtract(1, "month").daysInMonth();
    let firstDisplayDay = lastMonthDay - this.firstDayOfMonth() + 1;
    let lastMonthInactiveDays = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      lastMonthInactiveDays[i] = firstDisplayDay;
      firstDisplayDay += 1;
    }
    let weekdayshortname = weekdayshort.map((day) => {
      return (
        <div className="calendar-day-name" key={day}>
          <p>
            {day.substring(
              0,
              this.props.dayOfWeekLength ? this.props.dayOfWeekLength : 3,
            )}
          </p>
        </div>
      );
    });
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <div className="calendar-day empty" key={lastMonthInactiveDays[i]}>
          {this.renderDayContainer(lastMonthInactiveDays[i])}
        </div>,
      );
    }
    let daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = "";
      let isInRangeSelected = false;
      let isInHoveredRange = false;
      let selectedDate = this.getSelectedDateComplete(d);

      if (
        this.state.hoveredDate &&
        this.state.endDateObject != selectedDate &&
        this.state.startDateObject != selectedDate
      ) {
        if (
          this.state.startDateObject != "" &&
          this.state.startDateObject._isValid
        ) {
          if (
            this.state.hoveredDate <= selectedDate &&
            selectedDate < this.state.startDateObject
          ) {
            isInHoveredRange = true;
          } else if (
            (this.state.endDateObject == "" ||
              !this.state.endDateObject._isValid) &&
            this.state.startDateObject < selectedDate &&
            selectedDate <= this.state.hoveredDate
          ) {
            isInHoveredRange = true;
          } else if (
            this.state.endDateObject != "" &&
            this.state.endDateObject._isValid
          ) {
            if (
              this.state.endDateObject < selectedDate &&
              selectedDate <= this.state.hoveredDate
            ) {
              isInHoveredRange = true;
            }
          }
        }
      }

      if (this.state.startDateObject != "" && this.state.endDateObject != "") {
        if (
          selectedDate.format("YYYY-MM-DD") ==
            this.state.startDateObject.format("YYYY-MM-DD") ||
          selectedDate.format("YYYY-MM-DD") ==
            this.state.endDateObject.format("YYYY-MM-DD")
        ) {
          currentDay = "today";
          isInHoveredRange = false;
        } else {
          isInRangeSelected =
            selectedDate.format("YYYY-MM-DD") >
              this.state.startDateObject.format("YYYY-MM-DD") &&
            selectedDate.format("YYYY-MM-DD") <
              this.state.endDateObject.format("YYYY-MM-DD");
        }
      } else if (
        this.state.startDateObject != "" &&
        selectedDate.format("YYYY-MM-DD") ==
          this.state.startDateObject.format("YYYY-MM-DD")
      ) {
        currentDay = "today";
        isInRangeSelected = false;
      } else if (
        this.state.endDateObject != "" &&
        selectedDate.format("YYYY-MM-DD") ==
          this.state.endDateObject.format("YYYY-MM-DD")
      ) {
        currentDay = "today";
        isInHoveredRange = false;
      }

      daysInMonth.push(
        <div
          key={d}
          className={`calendar-day ${currentDay} ${
            isInRangeSelected || isInHoveredRange ? "on-selection" : ""
          }`}
          onClick={(e) => {
            this.onDayClick(e, selectedDate);
          }}
          onMouseMove={(e) => {
            if (
              selectedDate != this.state.startDateObject &&
              selectedDate != this.state.endDateObject
            )
              this.onDayClick(e, selectedDate, true);
          }}
        >
          {this.renderDayContainer(d)}
        </div>,
      );
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });
    if (rows[0].length == 0) {
      rows = rows.slice(1);
    }
    let firstDay = 1;
    if (rows[rows.length - 1].length !== 7) {
      let lastRow = rows[rows.length - 1];
      for (let i = lastRow.length; i < 7; i++) {
        lastRow.push(
          <div className="calendar-day empty" key={`empty-${i}`}>
            {this.renderDayContainer(firstDay)}
          </div>,
        );
        firstDay += 1;
      }
    }

    if (rows.length < 6) {
      let lastRow = [];

      for (let i = 0; i < 7; i++) {
        lastRow.push(
          <div className="calendar-day empty" key={`empty-${i}`}>
            {this.renderDayContainer(firstDay)}
          </div>,
        );
        firstDay += 1;
      }

      rows.push(lastRow);
    }

    let daysinmonth = rows.map((d, i) => {
      return (
        <div className="calendar-week" key={`days-month-${d}-${i}`}>
          {d}
        </div>
      );
    });

    return (
      <div className="tail-datetime-calendar">
        <div className="calendar-navi">
          {this.props.type == "init" && (
            <span
              onClick={(e) => {
                this.props.onArrowClick();
              }}
              className="button-prev"
            />
          )}
          <span className={`calendar-label ${this.props.type}`}>
            {`${this.strings[MONTHS[this.month() - 1]]} ${this.year()}`}
          </span>
          {this.props.type == "end" && (
            <span
              onClick={(e) => {
                this.props.onArrowClick();
              }}
              className="button-next"
            />
          )}
        </div>
        {this.state.showCalendarBody && (
          <div className="calendar-date">
            <div className="calendar-days">{weekdayshortname}</div>
            <div className="all-calendar-days">{daysinmonth}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
