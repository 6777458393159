import React, { Component } from "react";

import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

import InfiniteScroll from "react-infinite-scroll-component";
import Linkify from "react-linkify";

import Pill from "../Pill/Pill";
import BubbleMessage from "./BubbleMessage/BubbleMessage";
import scrollLoader from "../../assets/icons/scroll-loader.gif";

import languages from "./languages.js";
import "./ChatHistory.scss";

var parse = require("html-react-parser");

export const CHAT_HISTORY = "CHAT_HISTORY";
export const NOTE_HISTORY = "NOTE_HISTORY";

export class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDropdown: null,
      locationAddresses: [],
    };

    this.strings = languages[props.language];
  }

  handleIncomingAddress = (addresses, data, msgId) => {
    let message = addresses.find((element) => element.id == msgId);
    let index = addresses.indexOf(message);
    if (index != undefined && index !== -1) {
      if (message.address == "") {
        addresses.splice(index, 1);
        addresses.push({ id: msgId, address: data });
        this.setState({ locationAddresses: addresses });
      }
    }
  };

  formatAgentToName = (agent) => {
    const agentFirstName = agent?.first_name ?? "";
    const agentLastName = agent?.last_name ?? "";
    let agentName = "";
    if (agentFirstName === "" && agentLastName === "") {
      agentName = "Deleted agent";
    } else {
      let fullName = `${agentFirstName} ${agentLastName}`.trim();
      agentName = `<a href="mailto:${agent.email}">${fullName}</a>`;
    }
    return agentName;
  };

  loadingScroll = () => {
    return (
      <div className="scroll-loader">
        <img src={scrollLoader} />
      </div>
    );
  };

  removeMessageFromHistory = (idx) => {
    this.props.chatEvents.splice(idx, 1);
  };

  renderEvents = () => {
    const conversationId = this.props.conversationId;
    const dateContainers = [];
    let events = this.props.chatEvents;
    if (this.props.deployments) {
      events = [{ event_type: "message", message: this.props.deployments }];
    }

    events.map((event, id) => {
      const type = event.event_type;
      const eventCreatedAt = event[type].created_at;

      const date = moment(moment.utc(eventCreatedAt).toDate())
        .local()
        .format("hh:mm A");

      let bubble = null;

      const eventDate = moment(moment.utc(eventCreatedAt).toDate());
      const eventDateId = eventDate.format("YYYY-MM-DD");
      let dateContainer = dateContainers.find((c) => c.id === eventDateId);

      if (dateContainers.length === 0 || dateContainer === undefined) {
        let dateStr = eventDate
          .locale(this.props.language)
          .format(`dddd, MMMM D`);
        dateContainers.push({
          id: eventDateId,
          date: dateStr,
          events: [],
        });
      }

      if (type === "message") {
        const message = event.message;
        const messageType = message.type;
        const providerMsgId = message.provider_msg_id;

        if (message[messageType].url === null) {
          return;
        }

        if (messageType === "location") {
          let currentLocationAddresses = this.state.locationAddresses;
          if (
            !currentLocationAddresses.find(
              (element) => element.id == providerMsgId,
            )
          ) {
            currentLocationAddresses.push({ id: providerMsgId, address: "" });
            this.setState({ locationAddresses: currentLocationAddresses });
          }
        }

        bubble = (
          <BubbleMessage
            id={id}
            type="message"
            message={message}
            strings={this.strings}
            sendToTarget={this.props.sendToTarget}
            selectedChat={this.props.selectedChat}
            removeMessageFromHistory={this.removeMessageFromHistory}
            selectProviderMsgId={this.props.selectProviderMsgId}
            showFileModal={this.props.showFileModal}
            reply={!this.props.readOnly}
            locationAddresses={this.state.locationAddresses}
            getAddressFromCoordinates={this.props.getAddressFromCoordinates}
            handleIncomingAddress={this.handleIncomingAddress}
            findReplyMessage={this.props.findReplyMessage}
          />
        );
      } else if (type === "event") {
        const conversationEventType = event.event.type;
        let conversationEventText = null;
        let conversationEventStyle = null;
        if (conversationEventType === "CREATED") {
          let tagName = event.event.tag?.name ?? "Deleted tag";
          conversationEventText = this.strings.conversationEventCreated.replace(
            "{{TAG}}",
            `<span class="conversation-tag">${tagName}</span>`,
          );
        } else if (conversationEventType === "ASSIGNED") {
          const subtype = event.event.subtype;
          let agentName = this.formatAgentToName(event.event.agent);
          if (subtype === "AUTO") {
            conversationEventText =
              this.strings.conversationEventAssignedAuto.replace(
                "{{AGENT}}",
                agentName,
              );
          } else if (subtype === "MANUAL") {
            conversationEventText = this.strings.conversationEventAssignedManual
              .replace(
                "{{OWNER_AGENT}}",
                this.formatAgentToName(event.event.owner_agent),
              )
              .replace("{{AGENT}}", agentName);
          }
        } else if (conversationEventType === "TAG-TRANSFER") {
          const subtype = event.event.subtype;
          let tagName = event.event.tag?.name ?? "Deleted tag";
          const ownerAgent = this.formatAgentToName(event.event.owner_agent);
          if (subtype === "AGENT") {
            conversationEventText =
              this.strings.conversationEventTagTransferAgent
                .replace("{{OWNER_AGENT}}", ownerAgent)
                .replace(
                  "{{TAG}}",
                  `<span class="conversation-tag">${tagName}</span>`,
                );
          } else if (subtype === "ADMIN") {
            let fromAgent = event.event.from_agent;
            if (fromAgent) {
              conversationEventText =
                this.strings.conversationEventTagTransferAdminFrom
                  .replace("{{OWNER_AGENT}}", ownerAgent)
                  .replace("{{FROM_AGENT}}", this.formatAgentToName(fromAgent))
                  .replace(
                    "{{TAG}}",
                    `<span class="conversation-tag">${tagName}</span>`,
                  );
            } else {
              conversationEventText =
                this.strings.conversationEventTagTransferAdmin
                  .replace("{{OWNER_AGENT}}", ownerAgent)
                  .replace(
                    "{{TAG}}",
                    `<span class="conversation-tag">${tagName}</span>`,
                  );
            }
          }
        } else if (conversationEventType === "AGENT-TRANSFER") {
          const subtype = event.event.subtype;
          const fromAgent = this.formatAgentToName(event.event.from_agent);
          const toAgent = this.formatAgentToName(event.event.to_agent);
          if (subtype === "AGENT") {
            conversationEventText = this.strings.conversationEventAgentTransfer
              .replace("{{FROM_AGENT}}", fromAgent)
              .replace("{{TO_AGENT}}", toAgent);
          } else if (subtype === "ADMIN") {
            conversationEventText =
              this.strings.conversationEventAgentTransferAdmin
                .replace(
                  "{{OWNER_AGENT}}",
                  this.formatAgentToName(event.event.owner_agent),
                )
                .replace("{{FROM_AGENT}}", fromAgent)
                .replace("{{TO_AGENT}}", toAgent);
          }
        } else if (conversationEventType === "CLOSED") {
          const subtype = event.event.subtype;
          if (subtype === "MANUAL") {
            conversationEventText =
              this.strings.conversationEventCloseManual.replace(
                "{{AGENT}}",
                this.formatAgentToName(event.event.agent),
              );
          } else if (subtype === "AUTO") {
            conversationEventText = this.strings.conversationEventCloseAuto;
          }
        } else if (conversationEventType === "HUBSPOT-ASSIGNED") {
          conversationEventStyle = "hubspot";
          conversationEventText = this.strings.conversationEventHubspotAssigned;
        } else if (conversationEventType === "HUBSPOT-TIMEOUT") {
          conversationEventStyle = "hubspot";
          conversationEventText = this.strings.conversationEventHubspotTimeout;
        }

        if (conversationEventText !== null) {
          bubble = (
            <div
              key={id}
              className={`conversation-event ${
                conversationEventStyle ? conversationEventStyle : ""
              }`}
            >
              <div className="chat-info">
                <div>
                  {parse(conversationEventText)} - {date}
                </div>
              </div>
            </div>
          );
        }
      }

      dateContainer = dateContainers.find((c) => c.id === eventDateId);
      if (dateContainer) {
        dateContainer.events.push(bubble);
      }
    });

    const uploadingFileBubble = this.props.sendingFile[conversationId] ? (
      <BubbleMessage
        type="message"
        message={{
          type: "upload-file",
          sender: "agent",
          created_at: moment().utc().toDate(),
        }}
      />
    ) : null;

    return (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        <div className="chat-messages">
          {dateContainers.map((dc) => {
            console.log("Date container", dc);
            return (
              <div
                className="date-container"
                key={dc.id}
                id={`chat-date-${dc.id}`}
              >
                <hr className="" />
                <div className="new-date-label">{dc.date}</div>
                {dc.events.map((e) => {
                  return e;
                })}
              </div>
            );
          })}
          {uploadingFileBubble}
        </div>
      </Linkify>
    );
  };

  renderNotes = () => {
    return (
      <div className="notes-container">
        {this.props.notes.map((note) => {
          console.log("Note", note);
          const date = moment(moment.utc(note.date).toDate());
          return (
            <div class="note-container" key={note.id}>
              <h2>{note.name}</h2>
              <p class="note-content">{note.content}</p>
              <p class="note-date">
                {date
                  .locale(this.props.language)
                  .format("dddd, MMMM YYYY, h:mm:ss a")}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const historyType = this.props.type;
    const deployments = this.props.deployments;
    const chatEvents = this.props.chatEvents;
    const notes = this.props.notes;
    if (
      (historyType === NOTE_HISTORY && notes.length === 0) ||
      (historyType === CHAT_HISTORY &&
        chatEvents.length === 0 &&
        deployments === undefined)
    ) {
      return <></>;
    }

    if (this.props.readOnly === true && historyType === CHAT_HISTORY) {
      return <>{this.renderEvents()}</>;
    }

    if (historyType === CHAT_HISTORY) {
      return (
        <InfiniteScroll
          dataLength={chatEvents.length}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          hasMore={this.props.hasMore}
          scrollableTarget="chat-scrollable"
          next={this.props.loadMore}
          inverse={true}
          endMessage={
            <div className="pill-container">
              <Pill text={this.strings.upToDate} styleClass="up-to-date-pill" />
            </div>
          }
        >
          {this.renderEvents()}
          {this.props.isLoading &&
            this.props.hasMore &&
            chatEvents.length > 0 &&
            this.loadingScroll()}
          {this.props.hasMore &&
            !this.props.isLoading &&
            chatEvents.length > 0 && (
              <div className="pill-container">
                <Pill
                  text={this.strings.loadHistory}
                  icon="reload"
                  styleClass="history-pill"
                  onClick={this.props.loadMore}
                />
              </div>
            )}
        </InfiniteScroll>
      );
    } else if (historyType === NOTE_HISTORY) {
      return <div>{this.renderNotes()}</div>;
    }
  }
}

export default ChatHistory;
