import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

const TurndownService = require("turndown").default;

let turndownService = new TurndownService();

turndownService.addRule("strikethrough", {
  filter: ["del", "s", "strike"],
  replacement: function (content) {
    return "~" + content + "~";
  },
});

turndownService.addRule("Whatsapp_italic", {
  filter: ["em"],
  replacement: function (content) {
    return "_" + content + "_";
  },
});

turndownService.addRule("Whatsapp_bold", {
  filter: ["strong"],
  replacement: function (content) {
    return "*" + content + "*";
  },
});

export const whatsAppFormat = (text) => {
  /*
    newHSM has a message that is a special RichTextArea object that needs to be converted to raw plain text. This RichTextArea object, also known as draft,
    can be transformed into HTML. Since we want the plain text with markdown and not a HTML we use turndowService.turndown to pass from HTML to markdown text.
  */
  let markdownMessage = turndownService.turndown(
    draftToHtml(convertToRaw(text)),
  );
  /*
    double backslash (\\) needs to be replaced to empty. This happens when the html text contains an underscore (_) or any markdown character and the turndown replaces it with 
    double backslash as they are the escape character. Since draftToHTML already handles that, turndown should worry about it and thus we replace it with empty
  */
  markdownMessage = markdownMessage.replace(/\\/g, "");
  return markdownMessage;
};
