module.exports = {
  es: {
    templateMessage: "Plantillas de respuestas",
    createTemplateTitle: "¡Comienza a crear plantillas de mensajes rápidos!",
    createTemplateDescription:
      "Podrás acelerar el proceso de respuesta de tus vendedores - brindándoles una opción cómoda para responder a preguntas frecuentes",
    buttonCreateMessage: "Crear nuevo mensaje",
    searchByName: "Busca plantilla por nombre...",
    orderByAZ: "Ordenar por A - Z",
    orderByTimeAsc: "Ordenar por último modificado",
    orderByTimeDesc: "Ordenar por más antiguo",
    seeAllTags: "Ver todos",
    snippetModalTitle: "Nuevo mensaje",
    snippetModalEditTitle: "Editar mensaje",
    snippetTitle: "Título",
    snippetPlaceHolder: "Ej. Planilla 1",
    selectOrCreateType: "Seleccionar o crear nuevo equipo",
    createNewTeam: "Crear nuevo equipo",
    addTeam: "Agregar",
    writeMessage: "Escribir mensaje",
    createMessage: "Crear mensaje",
    snippetCancel: "Cancelar",
    snippetCopy: "Duplicar",
    snippetDelete: "Borrar",
    placeholderTextarea: "Escribe aquí...",
    saveChanges: "Guardar cambios",
    emptyFields: "Por favor verifica que todos los campos esten llenos",
    snippetCreated: "Snippet creado",
    snippetUpdated: "Snippet actualizado",
    snippetDeleted: "Snippet eliminado",
    snippetDuplicated: "Snippet duplicado",
    noResults: "Oops! No hay resultados relacionados a tu búsqueda",
  },
  en: {
    templateMessage: "Answer templates",
    createTemplateTitle: "Start creating quick message templates!",
    createTemplateDescription:
      "You will be able to speed up the response process of your salespeople - giving them a convenient option to answer frequently asked questions",
    buttonCreateMessage: "Create new message",
    searchByName: "Search template by name...",
    orderByAZ: "Sort by A - Z",
    orderByTimeAsc: "Sort by last modified",
    orderByTimeDesc: "Sort by oldest",
    seeAllTags: "See all",
    snippetModalTitle: "New message",
    snippetModalEditTitle: "Edit message",
    snippetTitle: "Name",
    snippetPlaceHolder: "Ex. Template 1",
    selectOrCreateType: "Select or create new team",
    createNewTeam: "Create new team",
    addTeam: "Add",
    writeMessage: "Write a message",
    createMessage: "Create message",
    snippetCancel: "Cancel",
    snippetCopy: "Duplicate",
    snippetDelete: "Delete",
    placeholderTextarea: "Write here...",
    saveChanges: "Save changes",
    emptyFields: "Please verify that all fields are filled",
    snippetCreated: "Snippet created",
    snippetUpdated: "Snippet updated",
    snippetDeleted: "Snippet deleted",
    snippetDuplicated: "Snippet duplicated",
    noResults: "Oops! There are no results related to your search",
  },
  pt: {
    templateMessage: "Modelos de resposta",
    createTemplateTitle: "Comece a criar modelos de mensagens rápidas!",
    createTemplateDescription:
      "Você poderá acelerar o processo de resposta de seus vendedores - dando a eles uma opção conveniente para responder a perguntas frequentes",
    buttonCreateMessage: "Criar nova mensagem",
    searchByName: "Pesquisar modelo por nome...",
    orderByAZ: "Ordenar por A - Z",
    orderByTimeAsc: "Classificar por última modificação",
    orderByTimeDesc: "Classificar por mais antigo",
    seeAllTags: "Ver todos",
    snippetModalTitle: "Nova mensagem",
    snippetModalEditTitle: "Editar mensagem",
    snippetTitle: "Nome",
    snippetPlaceHolder: "Ex. Modelo 1",
    selectOrCreateType: "Selecione ou crie uma nova equipe",
    createNewTeam: "Criar nova equipe",
    addTeam: "Adicionar",
    writeMessage: "Escreve uma mensagem",
    createMessage: "Criar mensagem",
    snippetCancel: "Cancelar",
    snippetCopy: "duplicado",
    snippetDelete: "Apagar",
    placeholderTextarea: "Escreve aqui...",
    saveChanges: "Guardar mudanças",
    emptyFields: "Por favor, verifique se todos os campos estão preenchidos",
    snippetCreated: "Fragmento criado",
    snippetUpdated: "Fragmento atualizado",
    snippetDeleted: "Fragmento excluído",
    snippetDuplicated: "Fragmento duplicado",
    noResults: "Opa! Não há resultados relacionados à sua pesquisa",
  },
};
