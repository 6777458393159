import React, { Component } from "react";
import { Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import TrebleIcon from "../../../../../components/TrebleIcon/TrebleIcon";

import languages from "./languages.js";
import "./SelectDropdown.scss";

const { Option } = Select;

class SelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      value: props.value ?? null,
    };

    this.strings = languages[props.language];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  filterOptions = () => {
    let searchValue = this.state.search.toLowerCase();
    return this.props.options.filter((option) =>
      option.label.toLowerCase().includes(searchValue),
    );
  };

  onSelect = (value) => {
    this.setState({ value });
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  };

  renderSelectAll = () => {
    if (this.props.enableAll) {
      return (
        <div className="select-all" onClick={() => this.onSelect(null)}>
          <div className="label-text">{this.props.placeholder}</div>
          <TrebleIcon
            name={`${
              this.state.value === null ? "checked-radio" : "unchecked-radio"
            }`}
            size={18}
          />
        </div>
      );
    }
  };

  render = () => {
    let options = this.props.options;
    if (this.search !== "") {
      options = this.filterOptions();
    }

    let classNames = `${this.props.className ?? ""} ${
      this.state.value !== null ? "has-value" : ""
    } ${this.state.search !== "" ? "has-search" : ""}`;

    return (
      <Select
        virtual={false}
        key={this.props._key}
        placeholder={this.props.placeholder}
        className={`treble-select ${classNames}`}
        dropdownClassName={`treble-select-dropdown ${classNames}`}
        dropdownRender={(menu) => {
          if (this.props.searchable) {
            return (
              <div>
                <div className="treble-select-searchbar">
                  <Input
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    placeholder={this.props.searchPlaceholder}
                    prefix={<SearchOutlined />}
                    allowClear
                  />
                </div>
                {this.renderSelectAll()}
                {menu}
              </div>
            );
          }
          return (
            <div>
              {this.renderSelectAll()}
              {menu}
            </div>
          );
        }}
        onSelect={this.onSelect}
        value={this.state.value}
        notFoundContent={
          <div className="treble-empty">
            <p>{this.strings.noResults}</p>
          </div>
        }
        suffixIcon={<div className="icon icon--dropdown-arrow" />}
        getPopupContainer={(trigger) => trigger.parentNode}
        placement={this.props.placement ? this.props.placement : "bottomLeft"}
      >
        {options.map((option) => (
          <Option key={option.value}>
            <div className="label-text">{option.label}</div>
            <TrebleIcon
              name={`${
                option.value === this.state.value
                  ? "checked-radio"
                  : "unchecked-radio"
              }`}
              size={18}
            />
          </Option>
        ))}
      </Select>
    );
  };
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDropdown);
