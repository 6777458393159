module.exports = {
  es: {
    literalChats: "Chats",
    chat: "conversación",
    chats: "Conversaciones",
    chatNumber: "CONVERSACIÓN",
    chatsNumber: "CONVERSACIONES",
    contact: "contacto",
    contacts: "Contactos",
    contactNumber: "CONTACTO",
    contactsNumber: "CONTACTOS",
    addContact: "Agregar contacto",
    addContact2: "Añadir contacto",
    cancel: "Cancelar",
    add: "Agregar",
    endChat: "Finalizar chat",
    saveChanges: "Guardar cambios",
    addChatLabel: "Agregar tag",
    editChatLabel: "Editar tag",
    transferChat: "Transferir chat",
    answerPlaceholder: "Escribe un mensaje...",
    notePlaceholder: "Escribir nota...",
    noSessionPlaceholder:
      "Sabemos lo importante que es para ti mantener comunicación con tus clientes. Puedes reactivar tus conversaciones mandando una plantilla HSM 😃 🚀",
    whatsappSession: "Sesión WhatsApp:",
    finishedSession: "No hay sesión activa de WhatsApp",
    name: "Nombre",
    contactName: "Nombre del contacto",
    cellphone: "Celular",
    missingField: "Por favor completa este campo para continuar",
    noHsm:
      "Por favor asegurate de tener la palabra bien escrita o intenta cambiando tu busqueda",
    noResults: "Sin resultados",
    hsmPlaceholder: "Buscar plantillas HSMs...",
    sendHSM: "Envío de HSM",
    variable: "Variable",
    preview: "Previsualización",
    send: "Enviar",
    noSelectedM: "No has seleccionado un ",
    noSelectedF: "No has seleccionado una ",
    noChat: "No tiene un chat",
    otherAgent:
      "Otro vendedor ({{AGENT}}) está teniendo una conversación con esta persona",
    editContact: "Editar contacto",
    remove: "Remover",
    delete: "Eliminar",
    save: "Guardar",
    transferred: "Transferido",
    answer: "Responder",
    notes: "Notas",
    notesOut:
      'Estás en modo "Notas", da clic en la "equis" para volver a responder mensajes',
    searchConversation: "Buscar conversacion...",
    searchContact: "Busca por nombre o número...",
    transfer: "Transferir",
    tagPlaceholder: "Selecciona un grupo...",
    searchName: "Buscar nombre...",
    conversationFinished: "Conversación finalizada",
    assignedTo: "Asignado a: ",
    conversationCreated: "Conversación creada",
    goTohubspot: "Ir a HubSpot",
    contactDetails: "Detalles del contacto",
    information: "Información",
    contactOwner: "Propietario del contacto",
    allowMicrophoneTitle: "Permitir micrófono",
    allowMicrophoneBodyAccept:
      'Para grabar mensajes de voz, haz clic en "Permitir" para que treble.ai pueda acceder al micrófono de tu computadora.',
    allowTrebleMicrophoneAccess:
      "Para grabar mensajes de voz, treble necesita acceso al micrófono de tu computadora. Haz clic en ",
    getTrebleMicrophoneAccess:
      ' en la barra de navegación y elige "Permitir siempre que agent.treble.ai acceda al micrófono."',
    newView: "Nueva vista",
    horizontalScroll: "Shift + scroll para moverte a la derecha",
    deal: "Trato",
    noNumber: "Sin número seleccionado",
    sentFrom: "Envío desde",
    dragFileHere: "Arrastre el archivo aquí",
    sendFile: "Enviar archivo",
    typeImageSupport: "¡Solo son soportadas imagenes de tipo png y jpeg! 🚨",
    sizeImageSupport: "¡Solo son soportadas imagenes de menos de 5MB! 🚨",
    typeVideoSupport: "¡Solo son soportados videos de tipo mp4 y 3gpp! 🚨",
    sizeVideoSupport: "¡Solo son soportados videos de menos de 16MB! 🚨",
    sizeFileSupport: "¡Solo son soportados archivos de menos de 100MB! 🚨",
    notValidFile: "¡Archivo inválido! 🚨",
    lastMessageSent: "Último mensaje enviado",
    TransferredMessages: "Mensajes transferidos",
    filterByLabels: "Ver por etiquetas",
    userSubmittedMessage: "Mensaje enviado por el usuario",
    newConversations: "Conversaciones nuevas",
    contactInfo: "Información de contacto",
    messageTemplates: "Plantillas de respuestas",
    useAnswer: "Usar respuesta",
    searchTemplate: "Busca plantilla por nombre...",
    snippetsNotCreated: "Oops! Aún no hay plantillas creadas",
    snippetPermissions:
      "Sólo un administrador puede crear nuevas plantillas de mensajes",
    templateBuilder: "Creador de plantillas",
    noSearchResults: "Oops! No hay resultados relacionados a tu búsqueda",
    loadingChats: "Estamos cargando tus conversaciones ✨",
    chatTransferred: "Chat transferido",
    headerEndChat: "😀 🚀 ✨--¡Finalizar conversación!",
    bodyEndChat:
      "Estás a punto de finalizar la conversación con este cliente, recuerda que al finalizar una conversación automáticamente se envía la encuesta de satisfacción",
    footerEndChat: "Finalizar conversación",
    footerCancelEndChat: "Continuar en la conversación",
    filesAndDocs: "Archivos y documentos",
    seeFiles: "Archivos",
    seeDocs: "Documentos",
    thisMonth: "Este mes",
    downloadAudio: "Descargar audio",
    openHubspotDashboard: "Abrir panel de Hubspot",
    hubspotDashboard: "Panel de Hubspot",
    snippetNoResults: "Oops! No hay resultados relacionados a tu búsqueda",
    backToAgents: "Volver a vendedores",
    noActiveConversations: "No tienes conversaciones activas 💬",
    chatWithClients:
      "Para chatear con tus clientes puedes crear un contacto nuevo desde el apartado de contactos o hacer un despliegue desde la plataforma de Treble.ai",
    whatsappTime:
      "Después de 24 horas WhatsApp bloquea la comunicación del chat para proteger a tus clientes de spam o mensajes invasivos.",
    whatsappLine: "Línea de WhatsApp",
    hubspotContactPanel: "Abrir panel de contacto",
    hubspotTicketPanel: "Abrir panel de ticket",
    hubspotCloseContact: "Cerrar panel de contacto",
    hubspotCloseTicket: "Cerrar panel de ticket",
    fullScreen: "Pantalla completa",
    exitFullScreen: "Salir de pantalla completa",
    hubspotCloseModalD:
      "Para finalizar la conversación elige el estado de cierre en el ticket de HubSpot",
    open: "Abierto",
    closed: "Cerrado",
    messageNotFound: "Lo sentimos, no pudimos encontrar el mensaje 😓",
    yesterday: "Ayer",
    quickTemplates: "Plantillas rápidas",
    pausedHsmText:
      "Actualmente esta plantilla HSM ha alcanzado la calificación de calidad más baja (rojo) y se encuentra inhabilitada. Meta pausa de manera automática todas las plantillas que hayan alcanzado esta instancia para proteger la calificación de calidad del número de teléfono. Para mayor información puedes acceder a:",
    metaDoc: "documentación META",
    pausedHsm: "HSM en pausa",
  },
  en: {
    literalChats: "Chats",
    chat: "conversation",
    chats: "Chats",
    chatNumber: "CONVERSATION",
    chatsNumber: "CONVERSATIONS",
    contact: "contact",
    contacts: "Contacts",
    contactNumber: "CONTACT",
    contactsNumber: "CONTACTS",
    addContact: "Add contact",
    addContact2: "Add contact",
    cancel: "Cancel",
    add: "Add",
    endChat: "End chat",
    saveChanges: "Save Changes",
    addChatLabel: "Add tag",
    editChatLabel: "Edit tag",
    transferChat: "Transfer chat",
    answerPlaceholder: "Write a message...",
    notePlaceholder: "Write note...",
    noSessionPlaceholder:
      "We know how important it is for you to maintain communication with your clients. You can reactivate your conversations by sending an HSM template 😃 🚀",
    whatsappSession: "WhatsApp Session:",
    finishedSession: "There is no active WhatsApp session",
    name: "Name",
    contactName: "Contact Name",
    cellphone: "Cellphone",
    missingField: "Please complete this field to continue",
    noHsm:
      "Please make sure you have the word well spelled or try changing your search",
    noResults: "Without results",
    hsmPlaceholder: "Search HSMs templates...",
    sendHSM: "Send HSM",
    variable: "Variable",
    preview: "Preview",
    send: "Send",
    noSelectedM: "You have not selected a ",
    noSelectedF: "You have not selected a ",
    noChat: "You don't have chats",
    otherAgent:
      "Another salesperson ({{AGENT}}) is having a conversation with this person",
    editContact: "Edit contact",
    remove: "Remove",
    delete: "Delete",
    save: "Save",
    transferred: "Transferred",
    answer: "Answer",
    notes: "Notes",
    notesOut:
      'You are in "Notes" mode, click on the "x" to reply to messages again',
    searchConversation: "Search conversation...",
    searchContact: "Search by name or number...",
    transfer: "Transfer",
    tagPlaceholder: "Select a team tag...",
    searchName: "Search name...",
    conversationFinished: "Conversation Finished",
    assignedTo: "Assigned to: ",
    conversationCreated: "Conversation created",
    goTohubspot: "Go to HubSpot",
    contactDetails: "Contact details",
    information: "Information",
    contactOwner: "Contact Owner",
    allowMicrophoneTitle: "Allow microphone",
    allowMicrophoneBodyAccept:
      'To record voice messages, click "Allow" so that treble.ai can access your computer\'s microphone.',
    allowTrebleMicrophoneAccess:
      "To record voice messages, treble needs access to your computer's microphone. Click on ",
    getTrebleMicrophoneAccess:
      ' in the navigation bar and choose "Always allow agent.treble.ai to access the microphone."',
    newView: "New view",
    horizontalScroll: "Shift + scroll to move right",
    deal: "Deal",
    noNumber: "Without selected number",
    sentFrom: "Sent from",
    dragFileHere: "Drag file here",
    sendFile: "Send file",
    typeImageSupport: "¡Only png and jpeg images are supported! 🚨",
    sizeImageSupport: "¡Only images under 5MB are supported! 🚨",
    typeVideoSupport: "¡Only mp4 and 3gpp type videos are supported! 🚨",
    sizeVideoSupport: "¡Only videos under 16MB are supported! 🚨",
    sizeFileSupport: "¡Only files under 100MB are supported! 🚨",
    notValidFile: "¡Invalid file! 🚨",
    lastMessageSent: "Last message sent",
    TransferredMessages: "Transferred messages",
    filterByLabels: "View by tags",
    userSubmittedMessage: "User Submitted Message",
    newConversations: "New conversations",
    contactInfo: "Contact information",
    messageTemplates: "Answer templates",
    useAnswer: "Use answer",
    searchTemplate: "Search template by name...",
    snippetsNotCreated: "oops! No templates created yet",
    snippetPermissions:
      "Only an administrator can create new message templates",
    templateBuilder: "Template builder",
    noSearchResults: "Oops! There are no results related to your search",
    loadingChats: "We are loading your conversations ✨",
    chatTransferred: "Chat transferred",
    headerEndChat: "😀 🚀 ✨--¡End conversation!",
    bodyEndChat:
      "You are about to end the conversation with this client, remember that at the end of a conversation the satisfaction survey is automatically sent",
    footerEndChat: "End conversation",
    footerCancelEndChat: "Continue in the conversation",
    filesAndDocs: "Files and Documents",
    seeFiles: "Files",
    seeDocs: "Documents",
    thisMonth: "This month",
    downloadAudio: "Download audio",
    openHubspotDashboard: "Open Hubspot Dashboard",
    hubspotDashboard: "hubspot dashboard",
    snippetNoResults: "Oops! There are no results related to your search",
    backToAgents: "Back to salespeople",
    noActiveConversations: "Don't have active conversations 💬",
    chatWithClients:
      "To chat with your customers, you can create a new contact from the contact section or launch it from the Treble.ai platform",
    whatsappTime:
      "After 24 hours WhatsApp blocks chat communication to protect your customers from spam or invasive messages",
    whatsappLine: "WhatsApp line",
    hubspotContactPanel: "Open contact panel",
    hubspotTicketPanel: "Open ticket panel",
    hubspotCloseContact: "Close contact panel",
    hubspotCloseTicket: "Close ticket panel",
    fullScreen: "Full screen",
    exitFullScreen: "Exit full screen",
    hubspotCloseModalD:
      "To end the conversation choose the closing status for the HubSpot ticket",
    open: "Open",
    closed: "Closed",
    messageNotFound: "Sorry we couldn't find the message 😓",
    yesterday: "Yesterday",
    quickTemplates: "Quick templates",
    pausedHsmText:
      "Currently this HSM template has reached the lowest quality rating (red) and is disabled. Meta automatically pauses all templates that have reached this instance to protect the phone number quality rating. For more information you can access:",
    metaDoc: "META documentation",
    pausedHsm: "HSM paused",
  },
  pt: {
    literalChats: "Chats",
    chat: "conversação",
    chats: "Conversas",
    chatNumber: "CONVERSAÇÃO",
    chatsNumber: "CONVERSAS",
    contact: "Contato",
    contacts: "Contatos",
    contactNumber: "CONTATO",
    contactsNumber: "CONTATOS",
    addContact: "Adicionar contato",
    addContact2: "Adicionar contato",
    cancel: "Cancelar",
    add: "Adicionar",
    endChat: "Fim de papo",
    saveChanges: "Guardar mudanças",
    addChatLabel: "Adicionar tag",
    editChatLabel: "Editar tag",
    transferChat: "Transferir bate-papo",
    answerPlaceholder: "Escreve uma mensagem...",
    notePlaceholder: "Escrever nota...",
    noSessionPlaceholder:
      "Sabemos o quanto é importante para você manter a comunicação com seus clientes. Você pode reativar suas conversas enviando um template HSM 😃 🚀",
    whatsappSession: "Sessão do WhatsApp:",
    finishedSession: "Não há sessão de WhatsApp ativa",
    name: "Nome",
    contactName: "Nome de contato",
    cellphone: "Móvel",
    missingField: "Por favor, preencha este campo para continuar",
    noHsm:
      "Certifique-se de que a palavra está bem escrita ou tente alterar sua pesquisa",
    noResults: "Sem resultados",
    hsmPlaceholder: "Encontre modelos HSMs...",
    sendHSM: "Envio HSM",
    variable: "Variável",
    preview: "Antevisão",
    send: "Mandar",
    noSelectedM: "Você não selecionou nenhuma ",
    noSelectedF: "Você não selecionou nenhuma ",
    noChat: "Você não tem um bate-papo",
    otherAgent: "Outro vendedor ({{AGENT}}) está conversando com esta pessoa",
    editContact: "Editar Contato",
    remove: "Remover",
    delete: "Livrar-se de",
    save: "Manter",
    transferred: "Transferido",
    answer: "Responder",
    notes: "Notas",
    notesOut:
      'Você está no modo "Notas", clique no "x" para responder as mensagens novamente',
    searchConversation: "Pesquisar conversa...",
    searchContact: "Pesquisar por nome ou número...",
    transfer: "Transferir",
    tagPlaceholder: "Selecione uma marca de equipe ...",
    searchName: "Nome da pesquisa ...",
    conversationFinished: "Conversa encerrada",
    assignedTo: "Atribuído a: ",
    conversationCreated: "Conversa criada",
    goTohubspot: "Vá para o HubSpot",
    contactDetails: "Detalhes do contato",
    information: "Informação",
    contactOwner: "Contactar o proprietário",
    allowMicrophoneTitle: "Permitir microfone",
    allowMicrophoneBodyAccept:
      'Para gravar mensagens de voz, clique em "Permitir" para que treble.ai possa acessar ao microfone do seu computador.',
    allowTrebleMicrophoneAccess:
      "Para gravar mensagens de voz, treble.ai precisa ter acesso ao microfone do seu computador. Clique em ",
    getTrebleMicrophoneAccess:
      ' na barra de navegação e escolha "Sempre permitir que agent.treble.ai acesse o microfone."',
    newView: "Nova visão",
    horizontalScroll: "Shift + rolar para mover para a direita",
    deal: "Deal",
    noNumber: "Nenhum número selecionado",
    sentFrom: "Envio de",
    dragFileHere: "Arraste o arquivo aqui",
    sendFile: "Enviar arquivo",
    typeImageSupport: "¡Apenas imagens png e jpeg são suportadas! 🚨",
    sizeImageSupport: "¡Apenas imagens com menos de 5MB são suportadas! 🚨",
    typeVideoSupport: "¡Apenas vídeos do tipo mp4 e 3gpp são suportados! 🚨",
    sizeVideoSupport: "¡Apenas vídeos com menos de 16MB são suportados! 🚨",
    sizeFileSupport: "¡Apenas arquivos com menos de 100MB são suportados! 🚨",
    notValidFile: "¡arquivo inválido! 🚨",
    lastMessageSent: "Última mensagem enviada",
    TransferredMessages: "Mensagens transferidas",
    filterByLabels: "Ver por tags",
    userSubmittedMessage: "Mensagem enviada pelo usuário",
    newConversations: "Novas conversas",
    contactInfo: "Informação de contato",
    messageTemplates: "Modelos de resposta",
    useAnswer: "Usar resposta",
    searchTemplate: "Pesquisar modelo por nome...",
    snippetsNotCreated: "opa! Nenhum modelo criado ainda",
    snippetPermissions:
      "Apenas um administrador pode criar novos modelos de mensagem",
    templateBuilder: "Construtor de modelos",
    noSearchResults: "Opa! Não há resultados relacionados à sua pesquisa",
    loadingChats: "Estamos carregando seus chats ✨",
    chatTransferred: "Chat transferido",
    headerEndChat: "😀 🚀 ✨--¡encerrar conversa!",
    bodyEndChat:
      "Você está prestes a encerrar a conversa com este cliente, lembre-se que ao final de uma conversa a pesquisa de satisfação é enviada automaticamente",
    footerEndChat: "Encerrar conversa",
    footerCancelEndChat: "Continuar na conversa",
    filesAndDocs: "Arquivos e Documentos",
    seeFiles: "Arquivos",
    seeDocs: "Documentos",
    thisMonth: "Este mês",
    downloadAudio: "Baixar áudio",
    openHubspotDashboard: "Abra o painel do Hubspot",
    hubspotDashboard: "Painel hubspot",
    snippetNoResults: "Opa! Não há resultados relacionados à sua pesquisa",
    backToAgents: "Voltar para vendedores",
    noActiveConversations: "No tienes conversaciones ativas 💬",
    chatWithClients:
      "Para chatear con tus clientes puedes criar um novo contato desde o apartado de contatos ou fazer um despliegue desde a plataforma de Treble.ai",
    whatsappTime:
      "Após 24 horas, o WhatsApp bloqueia a comunicação por bate-papo para proteger seus clientes contra spam ou mensagens invasivas",
    whatsappLine: "Linha do WhatsApp",
    hubspotContactPanel: "Abra o painel de contato",
    hubspotTicketPanel: "Abra o painel de bilhete",
    hubspotCloseContact: "Fechar painel de contato",
    hubspotCloseTicket: "Fechar painel de bilhete",
    fullScreen: "Tela completa",
    exitFullScreen: "Sair da tela cheia",
    hubspotCloseModalD:
      "Para encerrar a conversa, escolha o status de fechamento no ticket da HubSpot",
    open: "Abertos",
    closed: "Fechadas",
    messageNotFound: "Desculpe Não encontramos a mensagem 😓",
    yesterday: "Ontem",
    quickTemplates: "Modelos rápidos",
    pausedHsmText:
      "Atualmente, este modelo HSM atingiu a classificação de qualidade mais baixa (vermelho) e está desabilitado. Meta pausa automaticamente todos os modelos que atingiram esta instância para proteger a classificação de qualidade do número de telefone. Para mais informações, você pode acessar:",
    metaDoc: "documentação META",
    pausedHsm: "HSM pausado",
  },
};
