const languages = {
  es: {
    agentsConnected: "Vendedores conectados",
    totalAgents: "Vendedores totales",
    averageRating: "Calificación promedio",
    agentHr: "Chats por vendedor / hr",
    download: "Descargar",
    agents: "Vendedores",
    search: "Buscar por nombre de agente...",
    allTags: "Todos los grupos",
    allStatus: "Todos los estados",
    agent: "Vendedor",
    tag: "Grupo",
    avgResponse: "Respuesta promedio",
    chatsAttended: "Chats atendidos",
    chatsProcess: "Chats en proceso",
    rating: "Calificación",
    status: "Estado de conexión",
    minutes: "minutos",
    active: "Activo",
    inactive: "Inactivo",
    rowsPerPage: "Filas por página",
    editTitle: "Edita el vendedor",
    createTitle: "Crea un vendedor",
    first_name: "Nombres",
    last_name: "Apellidos",
    password: "Contraseña",
    noTags: "Sin tags",
    noType: "Sin tipo",
    ADMIN: "Administrador",
    AGENT: "Vendedor",
    email: "Correo electrónico",
    selectType: "Elige un tipo",
    selectTags: "Elige al menos un tag",
    cancel: "Cancelar",
    edit: "Editar",
    delete: "Eliminar",
    create: "Crear",
    apply: "Aplicar",
    selected: "seleccionados",
    addTag: "Crear nuevo grupo",
    add: "Agregar",
    editAgent: "Editar vendedor",
    addAgent: "Crear nuevo vendedor",
    salespeopleManager: "Administrador de vendedores",
    assignmentMethod: "Método de asignación",
    assignmentMethodDescription:
      "Selecciona un método para que tus agentes de ventas reciban chats de acuerdo a las necesidades de tu operación",
    hubspotAssignmentMethods: "Métodos de asignación en HubSpot",
    randomAssignment: "Asignar a un agente de manera aleatoria",
    lowestFirstAssignment: "Asignar al agente con menos chats",
    assignOwnerImmediately: "Asignar al agente propietario inmediatamente",
    waitOwnerAndAssign: "Asignar al agente propietario solo si está conectado",
    waitOwnerAndFallback: "Asignar o esperar y reintentar con otro método",
    randomAssignmentDescription:
      'Treble.ai toma en cuenta a los agentes de ventas disponibles considerando sus respectivos "Tags". Se selecciona al agente con menos conversaciones activas y se le asigna la nueva conversación.',
    lowestFirstAssignmentDescription:
      'Treble.ai toma en cuenta a los agentes de ventas disponibles considerando sus respectivos "Tags". Se selecciona al agente con menos conversaciones activas y se le asigna la nueva conversación.',
    assignOwnerImmediatelyDescription:
      "Treble.ai asignará la conversación de manera inmediata al agente de ventas propietario en HubSpot incluso si el agente no se encuentra conectado.",
    waitOwnerAndAssignDescription:
      'Treble.ai dejará la conversación sin asignar indefinidamente y solo será asignada cuando el agente propietario de HubSpot se encuentre conectado en la plataforma. A diferencia del método previo, con esta opción el mensaje se enviará a aún en el caso de "Agente no disponible".',
    waitOwnerAndFallbackDescription:
      "Treble.ai verificará que el agente propietario se encuentre conectado para asignar la conversación. Si el agente no se encuentra conectado entonces esperará X tiempo y asignará la conversación utilizando cualquiera de los 2 métodos anteriores.",
    waitingOwnerTimeModalDescription:
      "Define el tiempo que debe esperar Treble.ai para asignar la conversación",
    after: "Después de",
    minutes: "Minutos",
    hours: "horas",
    assignConversation: "asignar conversación...",
    noResults: "Oops! No hay resultados relacionados a tu búsqueda",
    saveChanges: "Guardar cambios",
    invalidEmail: "Por favor ingresa un correo electrónico válido...",
    confirmDelete: "Sí, eliminar",
    deleteTitle: "¿Estás seguro/a que quieres eliminar al agente de ventas:",
    deleteDescription:
      "Recuerda que al eliminar a un agente de ventas toda la información relacionada a este se perderá y ya no podrás deshacer esta acción",
  },
  en: {
    agentsConnected: "Salespeople connected",
    totalAgents: "Total salespeople",
    averageRating: "Average rating",
    agentHr: "Chats by salesperson / hr",
    download: "Download",
    agents: "Salespeople",
    search: "Search by agent name...",
    allTags: "All the tags",
    allStatus: "All the status",
    agent: "Salesperson",
    tag: "Tag",
    avgResponse: "Avg. response",
    chatsAttended: "Chats attended",
    chatsProcess: "Chats in process",
    rating: "Rating",
    status: "Connection status",
    minutes: "minutes",
    active: "Active",
    inactive: "Inactive",
    rowsPerPage: "Rows per page",
    editTitle: "Edit the salesperson",
    createTitle: "Create an salesperson",
    first_name: "Names",
    last_name: "Surname",
    password: "Password",
    noTags: "No tags",
    noType: "No type",
    ADMIN: "Administrator",
    AGENT: "Salesperson",
    email: "Email",
    selectType: "Choose a type",
    selectTags: "Choose at least one tag",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    create: "Create",
    apply: "Apply",
    selected: "selected",
    addTag: "Add new tag",
    add: "Add",
    editAgent: "Edit salesperson",
    addAgent: "Create new salesperson",
    salespeopleManager: "Salespeople Manager",
    assignmentMethod: "Assignment Method",
    assignmentMethodDescription:
      "Select a method for your sales agents to receive chats according to your operation's needs",
    hubspotAssignmentMethods: "HubSpot Assignment Methods",
    randomAssignment: "Assign randomly to an agent",
    lowestFirstAssignment: "Assign to the agent with the fewest chats",
    assignOwnerImmediately: "Assign to the owner agent immediately",
    waitOwnerAndAssign: "Assign to the owner agent only if connected",
    waitOwnerAndFallback: "Assign or wait and retry with another method",
    randomAssignmentDescription:
      'Treble.ai takes into account the available sales agents considering their respective "Tags". The agent with the fewest active conversations is selected and assigned the new conversation.',
    lowestFirstAssignmentDescription:
      'Treble.ai takes into account the available sales agents considering their respective "Tags". The agent with the fewest active conversations is selected and assigned the new conversation.',
    assignOwnerImmediatelyDescription:
      "Treble.ai will immediately assign the conversation to the owner sales agent in HubSpot even if the agent is not connected.",
    waitOwnerAndAssignDescription:
      'Treble.ai will leave the conversation unassigned indefinitely and will only assign it when the owner agent in HubSpot is connected to the platform. Unlike the previous method, with this option, the message will be sent even in the "Agent unavailable" case.',
    waitOwnerAndFallbackDescription:
      "Treble.ai will check if the owner agent is connected to assign the conversation. If the agent is not connected, it will wait for X time and assign the conversation using either of the previous 2 methods.",
    waitingOwnerTimeModalDescription:
      "Set the time Treble.ai should wait to assign the conversation",
    after: "After",
    minutes: "Minutes",
    hours: "Hours",
    assignConversation: "assign conversation...",
    noResults: "Oops! There are no results related to your search",
    saveChanges: "Save changes",
    invalidEmail: "Please enter a valid email address...",
    confirmDelete: "Yes, delete",
    deleteTitle: "Are you sure you want to delete the sales agent:",
    deleteDescription:
      "Remember that when you delete a sales agent, all the related information will be lost and you will not be able to undo this action.",
  },
  pt: {
    agentsConnected: "Vendedores conectados",
    totalAgents: "Total de Vendedores",
    averageRating: "Classificação média",
    agentHr: "Conversas por vendedor / hr",
    download: "Download",
    agents: "Vendedores",
    search: "Procurar pelo nome do agente...",
    allTags: "Todas as tags",
    allStatus: "Todos os status",
    agent: "Vendedor",
    tag: "Marcação",
    avgResponse: "Média resposta",
    chatsAttended: "Bate-papos atendidos",
    chatsProcess: "Chats em andamento",
    rating: "Avaliação",
    status: "Status da conexão",
    minutes: "minutos",
    active: "Ativo",
    inactive: "Inativo",
    rowsPerPage: "Linhas por página",
    editTitle: "Editar o vendedor",
    createTitle: "Criar um vendedor",
    first_name: "Nomes",
    last_name: "Sobrenome",
    password: "Senha",
    noTags: "Sem tags",
    noType: "Sem tipo",
    ADMIN: "Administrador",
    AGENT: "Vendedor",
    email: "Email",
    selectType: "Escolha um tipo",
    selectTags: "Escolha pelo menos uma tag",
    cancel: "Cancelar",
    edit: "Editar",
    delete: "Delete",
    create: "Criar",
    apply: "Aplicar",
    selected: "selecionado",
    addTag: "Adicionar nova tag",
    add: "Adicionar",
    editAgent: "Editar vendedor",
    addAgent: "Criar novo vendedor",
    salespeopleManager: "Gerente de Vendedores",
    assignmentMethod: "Método de Atribuição",
    assignmentMethodDescription:
      "Selecione um método para que seus agentes de vendas recebam chats de acordo com as necessidades de sua operação",
    hubspotAssignmentMethods: "Métodos de Atribuição no HubSpot",
    randomAssignment: "Atribuir aleatoriamente a um agente",
    lowestFirstAssignment: "Atribuir ao agente com menos chats",
    assignOwnerImmediately: "Atribuir imediatamente ao agente proprietário",
    waitOwnerAndAssign:
      "Atribuir ao agente proprietário apenas se estiver conectado",
    waitOwnerAndFallback:
      "Atribuir ou aguardar e tentar novamente com outro método",
    randomAssignmentDescription:
      'A Treble.ai leva em consideração os agentes de vendas disponíveis, levando em conta suas respectivas "Tags". O agente com menos conversas ativas é selecionado e recebe a nova conversa.',
    lowestFirstAssignmentDescription:
      'A Treble.ai leva em consideração os agentes de vendas disponíveis, levando em conta suas respectivas "Tags". O agente com menos conversas ativas é selecionado e recebe a nova conversa.',
    assignOwnerImmediatelyDescription:
      "A Treble.ai atribuirá imediatamente a conversa ao agente de vendas proprietário no HubSpot, mesmo que o agente não esteja conectado.",
    waitOwnerAndAssignDescription:
      'A Treble.ai deixará a conversa não atribuída indefinidamente e só a atribuirá quando o agente proprietário no HubSpot estiver conectado à plataforma. Ao contrário do método anterior, com esta opção, a mensagem será enviada mesmo no caso de "Agente indisponível".',
    waitOwnerAndFallbackDescription:
      "A Treble.ai verificará se o agente proprietário está conectado para atribuir a conversa. Se o agente não estiver conectado, aguardará X tempo e atribuirá a conversa usando um dos dois métodos anteriores.",
    waitingOwnerTimeModalDescription:
      "Defina o tempo que a Treble.ai deve aguardar para atribuir a conversa",
    after: "Depois de",
    minutes: "Minutos",
    hours: "Horas",
    assignConversation: "atribuir conversa...",
    noResults: "Oops! Não há resultados relacionados à sua pesquisa",
    saveChanges: "Salvar alterações",
    invalidEmail: "Por favor, insira um endereço de email válido...",
    confirmDelete: "Sim, excluir",
    deleteTitle: "Você tem certeza de que deseja excluir o agente de vendas:",
    deleteDescription:
      "Lembre-se de que ao excluir um agente de vendas, todas as informações relacionadas a ele serão perdidas e você não poderá desfazer essa ação.",
  },
};

export default languages;
