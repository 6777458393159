import React from "react";
import Modal from "../../../../components/Modal/ModalV2";
import InlineAlert from "../../../../components/InlineAlert/InlineAlert";
import CountriesDropdown from "../../../../components/CountryDropdownV2/CountryDropdown";
import CountriesModule from "../../../../data/Countries";

import events from "../../../../utils/events";

import "./ContactModal.scss";
import TextInput from "../../../../components/TextInput/TextInput";

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    let contact = props.currentContact;

    let name = "";
    let cellphone = "";
    let countryCodeIndex = 0;
    let countryCodeInput = "57";
    let contactId = null;
    let existingContact = true;
    let setCellphone = false;

    if (contact) {
      name = contact.name !== null ? contact.name : "";
      let cellphoneParts = contact.whole_cellphone.split(" ");
      let countryCode = cellphoneParts[0];
      cellphone = cellphoneParts[1];
      countryCodeInput = countryCode.substring(1, countryCode.length);
      countryCodeIndex = CountriesModule.findIndex(
        (c) => c.phone === countryCodeInput,
      );
      contactId = contact.id;
      existingContact = contact.exists === false ? contact.exists : true;
      setCellphone = true;
    }
    this.state = {
      setCellphone: setCellphone,
      existingContact: existingContact,
      contactId: contactId,
      nameInput: name,
      cellphoneInput: cellphone,
      countryCodeIndex: countryCodeIndex,
      countryCodeInput: countryCodeInput,
      sendAttempt: false,
      dropdownIsOpen: false,
    };
  }

  renderAlert = (property, message) => {
    if (!this.state.sendAttempt) {
      return;
    }
    let stateProperty = `${property}Input`;
    if (this.state[stateProperty] === "") {
      return (
        <InlineAlert
          show={this.state.sendAttempt}
          type="error"
          text={message}
        />
      );
    }
    return;
  };

  validate = () => {
    this.setState({ sendAttempt: true });
    if (this.state.nameInput !== "" && this.state.cellphoneInput !== "") {
      return true;
    } else {
      return false;
    }
  };

  renderTitle = () => {
    if (this.props.action == "info") return this.props.getRemainingSession();
    if (this.props.action == "edit") return this.props.strings.editContact;
    if (this.props.action == "add") return this.props.strings.addContact;
  };

  renderBody = () => {
    const missingName =
      this.state.nameInput === "" && this.state.sendAttempt ? "missing" : "";
    const missingCellphone =
      this.state.cellphoneInput === "" && this.state.sendAttempt
        ? "missing"
        : "";
    if (this.props.action === "info") {
      return (
        <>
          {this.props.renderContactInfo()}
          {this.props.renderChatLabels()}
          {this.props.renderFiles()}
        </>
      );
    }
    if (this.props.action === "edit" || this.state.setCellphone) {
      return (
        <>
          <div className="name">
            <h6>{this.props.strings.name}</h6>
            <div className="form-group m-form__group">
              <div className={`input-group ${missingName}`}>
                <TextInput
                  className="name-input"
                  onChange={(e) => this.setState({ nameInput: e.target.value })}
                  value={this.state.nameInput}
                  type="text"
                  placeholder={this.props.strings.contactName}
                />
              </div>
              {this.renderAlert("name", this.props.strings.missingField)}
            </div>
          </div>
          <div className="cellphone">
            <h6>{this.props.strings.cellphone}</h6>
            <div className="contact-phone">
              <div className="flag-span">
                <img
                  src={CountriesModule[this.state.countryCodeIndex]["image"]}
                  className="flag"
                />
              </div>
              <p>
                +{this.state.countryCodeInput} {this.state.cellphoneInput}
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="name">
            <h6>{this.props.strings.name}</h6>
            <div className="form-group m-form__group">
              <div className={`input-group ${missingName}`}>
                <TextInput
                  className="name-input"
                  onChange={(e) => this.setState({ nameInput: e.target.value })}
                  value={this.state.nameInput}
                  type="text"
                  placeholder={this.props.strings.contactName}
                />
              </div>
              {this.renderAlert("name", this.props.strings.missingField)}
            </div>
          </div>
          <div className="cellphone">
            <h6>{this.props.strings.cellphone}</h6>
            <div className="form-group m-form__group">
              <div className={`input-group ${missingCellphone}`}>
                <CountriesDropdown
                  onSelect={(key, cc) => {
                    this.setState({
                      countryCodeIndex: key,
                      countryCodeInput: cc,
                    });
                  }}
                  currentCountry={this.state.countryCodeIndex}
                  toggleDropdown={(show) => {
                    let modalContent =
                      document.getElementsByClassName("modal-body")[0];
                    if (show) {
                      modalContent.style["height"] = "380px";
                    } else {
                      modalContent.style["height"] = "auto";
                    }
                    this.setState({ dropdownIsOpen: show });
                  }}
                />
                <TextInput
                  onChange={(e) => {
                    this.setState({ cellphoneInput: e.target.value });
                  }}
                  value={
                    this.state.cellphoneInput ? this.state.cellphoneInput : ""
                  }
                  type="number"
                  className="cellphone-input"
                  placeholder="000-000-0000"
                />
              </div>
              {this.renderAlert("cellphone", this.props.strings.missingField)}
            </div>
          </div>
        </>
      );
    }
  };

  renderButton = (body, onClick, color = "treble") => {
    return (
      <button className={`button ${color}`} onClick={onClick}>
        {body}
      </button>
    );
  };

  renderFooter = () => {
    if (this.props.action === "info") return [];
    if (this.props.action === "edit") {
      let deleteButton = null;
      if (this.state.existingContact === true) {
        deleteButton = this.renderButton(
          <>
            <div className="icon icon--trash size-16" />
            <p className="delete-text">{this.props.strings.delete}</p>
          </>,
          () => {
            this.props.deleteContact(this.state.contactId);
            this.props.resetState();
          },
          "danger",
        );
      }
      const cancelButton = this.renderButton(
        this.props.strings.cancel,
        this.props.resetState,
        "none",
      );
      const updateButton = this.renderButton(
        this.props.strings.save,
        () => {
          if (this.validate()) {
            events.track("Agent edit contact");
            this.props.updateContact(
              this.state.contactId,
              this.state.nameInput,
            );
            this.props.resetState();
          }
        },
        this.state.nameInput ? "treble" : "grey",
      );
      return (
        <div className="footer-buttons">
          {deleteButton}
          {cancelButton}
          {updateButton}
        </div>
      );
    } else {
      const cancelButton = this.renderButton(
        this.props.strings.cancel,
        this.props.resetState,
        "none",
      );
      const createButton = this.renderButton(
        this.props.strings.addContact,
        () => {
          if (this.validate()) {
            this.props.createContact(
              this.state.nameInput,
              `+${this.state.countryCodeInput}`,
              this.state.cellphoneInput,
            );
            this.props.resetState();
          }
        },
        this.state.nameInput && this.state.cellphoneInput ? "treble" : "grey",
      );
      return (
        <div className="footer-buttons">
          {cancelButton}
          {createButton}
        </div>
      );
    }
  };

  render() {
    return (
      <Modal
        wrappClass="contact-modal"
        show={this.props.show}
        onClose={this.props.resetState}
        header={this.renderTitle()}
        body={this.renderBody()}
        footer={this.renderFooter()}
      />
    );
  }
}

export default ContactModal;
