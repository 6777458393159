module.exports = {
  es: {
    searchByTeam: "Buscar por nombre de equipo",
    allTeams: "Todos los equipos",
    searchByAgentEmail: "Buscar por email del vendedor",
    allAgents: "Todos los Vendedores",
    searchByLabel: "Buscar por nombre de etiqueta",
    allLabels: "Todas las etiquetas",
    filterBy: "Filtrar por",
    teams: "Equipos",
    agents: "Vendedores",
    labels: "Etiquetas",
    searchByCountry: "Buscar por país",
    allCountries: "Todos los países",
    applyFilters: "Aplicar filtros",
    loadingMetrics: "Las métricas se están cargando...",
    operationSummary: "Resumen de la operación",
    mobileNotSupported:
      "La pantalla de métricas no está disponible en versiones móviles por el momento",
    returnToChats: "Volver a la pantalla de chats",
  },
  en: {
    searchByTeam: "Search by team name",
    allTeams: "All teams",
    searchByAgentEmail: "Search by salesperson email",
    allAgents: "All salespeople",
    searchByLabel: "Search by label name",
    allLabels: "All labels",
    filterBy: "Filter by",
    teams: "Teams",
    agents: "Salespeople",
    labels: "Labels",
    searchByCountry: "Search by country",
    allCountries: "All countries",
    applyFilters: "Apply filters",
    loadingMetrics: "Metrics are loading...",
    operationSummary: "Summary of the operation",
    mobileNotSupported:
      "The metrics screen is not available on mobile versions at the moment",
    returnToChats: "Back to the chats view",
  },
  pt: {
    searchByTeam: "Pesquisar pelo nome da equipe",
    allTeams: "Todos os equipes",
    searchByAgentEmail: "Pesquisar por e-mail do vendedor",
    allAgents: "Todos os Vendedores",
    searchByLabel: "Pesquisar pelo nome da etiqueta",
    allLabels: "Todas as etiquetas",
    filterBy: "Filtrar por",
    teams: "Equipes",
    agents: "Vendedores",
    labels: "Etiquetas",
    searchByCountry: "Pesquisar por país",
    allCountries: "Todos os países",
    applyFilters: "Aplicar filtros",
    loadingMetrics: "As métricas estão sendo carregadas...",
    operationSummary: "Resumo da operação",
    mobileNotSupported:
      "A tela de métricas não está disponível em versões para celular no momento",
    returnToChats: "Voltar para a tela de conversas",
  },
};
