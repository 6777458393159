const languages = {
  es: "ES",
  en: "EN",
  pt: "PT",
};

export const scriptUpdateWidget = (language, user) => {
  let nuboScript = document.getElementById("nubo-script");
  if (nuboScript) {
    window.document.body.removeChild(nuboScript);
  }

  let userString = ` 
    user:  {
      id: ${user.agent.id},
      first_name: '${user.agent.first_name}',
      email: '${user.agent.email}',
      role: 'customer',
      label: '${user.company.id}'
    }`;

  let updateScript = document.createElement("script");
  let innerScript = `
      (function (w, d, s, o, f, js, fjs) {
          w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
          js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
          js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', '_nubo', 'https://www.widget.getnubo.co/widget.js'));
        _nubo('init', {
          projectId: '${process.env.REACT_APP_KEY_NUBO}',
          language: '${languages[language]}',
          showNps: false,
          showDefaultbutton: false,
          ${userString}
        })`;
  updateScript.innerText = innerScript;
  updateScript.id = "nubo-script";
  window.document.body.appendChild(updateScript);
};
