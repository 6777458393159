import { store } from "../store.js";
import { agentLogoutSimple } from "./agentAction.js";

const NEW_CONVERSATION = "NEW_CONVERSATION";
export function subscribeConversation() {
  return {
    event: "new_conversation",
    handle: NEW_CONVERSATION,
  };
}

const RECEIVE_MESSAGE_DONE = "RECEIVE_MESSAGE_DONE";
export const receiveMessageDone = () => (dispatch) => {
  return dispatch({
    type: RECEIVE_MESSAGE_DONE,
  });
};

const NEW_MESSAGE = "NEW_MESSAGE";
export const subscribeMessage = () => (dispatch) => {
  return dispatch({
    event: "new_message",
    handle: NEW_MESSAGE,
  });
};

const MESSAGE_STATUS = "MESSAGE_STATUS";
export const subscribeMessageStatus = () => (dispatch) => {
  return dispatch({
    event: "message_status",
    handle: MESSAGE_STATUS,
  });
};

const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";
export const subscribeRemoveConversation = () => (dispatch) => {
  return dispatch({
    event: "remove_conversation",
    handle: REMOVE_CONVERSATION,
  });
};

export const subscribeForceLogout = () => (dispatch) => {
  dispatch({
    event: "logout",
    handle: (data) => {
      return dispatch(
        agentLogoutSimple("Has iniciado sesión en otro dispositivo"),
      );
    },
  });
};

export const socketOnConnect = () => (dispatch) => {
  dispatch({
    event: "connect",
    handle: (data) => {
      const state = store.getState();
      const user = state.mainReducer.user;

      if (user === null) {
        return;
      }
      const agentId = user.agent.id;
      console.log("AUTH SOCKET ON CONNECT", agentId);
      return dispatch(sendMessage("authenticate", { agent_id: agentId }));
    },
  });
};

export function unsubscribeConnect() {
  return {
    event: "connect",
    leave: true,
  };
}

export function sendMessage(name, data) {
  return {
    event: name,
    emit: true,
    payload: data,
  };
}

store.dispatch(socketOnConnect());
store.dispatch(subscribeConversation());
store.dispatch(subscribeMessage());
store.dispatch(subscribeMessageStatus());
store.dispatch(subscribeRemoveConversation());
store.dispatch(subscribeForceLogout());
