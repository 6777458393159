import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import loader from "./Loader";

const Loading = (props) => {
  return (
    <div className="loading" key={props.name}>
      <Player autoplay loop src={loader} />
      {props.text ? <p>{props.text}</p> : null}
    </div>
  );
};

export default Loading;
