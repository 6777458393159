// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// Pipelines

const SET_HUBSPOT_PIPELINE_STAGE = "SET_HUBSPOT_PIPELINE_STAGE";
export const setHubspotPipelineStage = (pipeline, stage) => (dispatch) => {
  dispatch({
    type: SET_HUBSPOT_PIPELINE_STAGE,
    pipeline,
    stage,
  });
};
