module.exports = {
  es: {
    resendLabel: "No se pudo enviar. Haga clic para volver a intentarlo.",
    msgNotFound: "Mensaje no encontrado",
    replyDropdown: "Responder",
    replyMessage: "Responder",
    seeOnGoogleMaps: "Ver en Google maps",
    upToDate: "Estás al día 🙌",
    loadHistory: "Cargar historial",
    conversationEventCreated: "Se creó una conversación para el equipo {{TAG}}",
    conversationEventAssignedAuto:
      "La conversación fue asignada al vendedor {{AGENT}}",
    conversationEventAssignedManual:
      "El usuario {{OWNER_AGENT}} asignó la conversación a {{AGENT}} usando privilegios de administrador",
    conversationEventTagTransferAgent:
      "El usuario {{OWNER_AGENT}} transfirió la conversación al equipo {{TAG}}",
    conversationEventTagTransferAdminFrom:
      "El usuario {{OWNER_AGENT}} transfirió la conversación de {{FROM_AGENT}} al equipo {{TAG}} usando privilegios de administrador",
    conversationEventTagTransferAdmin:
      "El usuario {{OWNER_AGENT}} transfirió la conversación al equipo {{TAG}} usando privilegios de administrador",
    conversationEventAgentTransfer:
      "El usuario {{FROM_AGENT}} transfirió la conversación a {{TO_AGENT}}",
    conversationEventAgentTransferAdmin:
      "El usuario {{OWNER_AGENT}} transfirió la conversación de {{FROM_AGENT}} a {{TO_AGENT}} usando privilegios de administrador",
    conversationEventCloseManual:
      "La conversación fue cerrada por {{AGENT}} manualmente",
    conversationEventCloseAuto:
      "La conversación se cerró automáticamente por tiempo de espera",
    conversationEventHubspotAssigned:
      "La conversación se asignó al propietario de HubSpot",
    conversationEventHubspotTimeout:
      "El tiempo de espera expiró porque el propietario de HubSpot no se conectó",
  },
  en: {
    resendLabel: "Couldn't send. Click to try again.",
    msgNotFound: "Message not found",
    replyDropdown: "Reply",
    replyMessage: "Reply",
    seeOnGoogleMaps: "See on Google maps",
    upToDate: "You're up to date 🙌",
    loadHistory: "Load history",
    conversationEventCreated: "Conversation was created for the team {{TAG}}",
    conversationEventAssignedAuto:
      "The conversation was assigned to the salesperson {{AGENT}}",
    conversationEventAssignedManual:
      "The user {{OWNER_AGENT}} assigned the conversation to {{AGENT}} using admin privileges",
    conversationEventTagTransferAgent:
      "The user {{OWNER_AGENT}} transferred the conversation to the team {{TAG}}",
    conversationEventTagTransferAdminFrom:
      "The user {{OWNER_AGENT}} transferred the conversation from {{FROM_AGENT}} to the team {{TAG}} using admin privileges",
    conversationEventTagTransferAdmin:
      "The user {{OWNER_AGENT}} transferred the conversation to the team {{TAG}} using admin privileges",
    conversationEventAgentTransfer:
      "The user {{FROM_AGENT}} transferred the conversation to {{TO_AGENT}}",
    conversationEventAgentTransferAdmin:
      "The user {{OWNER_AGENT}} transferred the conversation from {{FROM_AGENT}} to {{TO_AGENT}} using admin privileges",
    conversationEventCloseManual:
      "The conversation was closed by {{AGENT}} manually",
    conversationEventCloseAuto:
      "The conversation was closed automatically by timeout",
    conversationEventHubspotAssigned:
      "The conversation was assigned to the HubSpot owner",
    conversationEventHubspotTimeout:
      "Waiting time has expired because HubSpot owner didn't connect",
  },
  pt: {
    resendLabel: "Não foi possível enviar. Clique para tentar novamente.",
    msgNotFound: "Mensagem não encontrada",
    replyDropdown: "Responder",
    replyMessage: "Responder",
    seeOnGoogleMaps: "Veja nos mapas do Google",
    upToDate: "Você está atualizado 🙌",
    loadHistory: "Carregar histórico",
    conversationEventCreated: "A conversa foi criada para a equipe {{TAG}}",
    conversationEventAssignedAuto:
      "A conversa foi atribuída ao vendedor {{AGENT}}",
    conversationEventAssignedManual:
      "O usuário {{OWNER_AGENT}} atribuiu a conversa a {{AGENT}} usando privilégios de administrador",
    conversationEventTagTransferAgent:
      "O usuário {{OWNER_AGENT}} transferiu a conversa para a equipe {{TAG}}",
    conversationEventTagTransferAdminFrom:
      "O usuário {{OWNER_AGENT}} transferiu a conversa de {{FROM_AGENT}} para a equipe {{TAG}} usando privilégios de administrador",
    conversationEventTagTransferAdmin:
      "O usuário {{OWNER_AGENT}} transferiu a conversa para a equipe {{TAG}} usando privilégios de administrador",
    conversationEventAgentTransfer:
      "O usuário {{FROM_AGENT}} transferiu a conversa para {{TO_AGENT}}",
    conversationEventAgentTransferAdmin:
      "O usuário {{OWNER_AGENT}} transferiu a conversa de {{FROM_AGENT}} para {{TO_AGENT}} usando privilégios de administrador",
    conversationEventCloseManual:
      "A conversa foi encerrada por {{AGENT}} manualmente",
    conversationEventCloseAuto:
      "A conversa foi encerrada automaticamente por tempo limite",
    conversationEventHubspotAssigned:
      "A conversa foi atribuída ao proprietário do HubSpot",
    conversationEventHubspotTimeout:
      "O tempo de espera expirou porque o proprietário do HubSpot não se conectou",
  },
};
