module.exports = {
  es: {
    downloadImage: "Descargar imagen",
  },
  en: {
    downloadImage: "Download image",
  },
  pt: {
    downloadImage: "Baixar imagem",
  },
};
