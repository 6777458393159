import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import "./CalendarPicker.scss";
import events from "../../../../../utils/events";
import Calendar from "../Calendar/Calendar";
import languages from "./languages";

/*
	props:
		- options list(obj): options to be displayed
		- noSelectionTitle str: text to be displayed when empty
		- display function(obj) -> str: a map between an object an its display
		- onSelect function([obj]): to be called when an option is clicked, it calls all the clicked objects
*/

const typeToEventName = {
  init: "start",
  end: "end",
};
class CalendarPicker extends Component {
  constructor(props) {
    super(props);

    let options = this.props.options;

    this.state = {
      open: false,
      selectedStartDate: "",
      selectedEndDate: "",
      hoveredDate: "",
      calendarIconClicked: false,
      lastTypeSelected: "",
      referenceStartDate: moment().subtract(1, "months").local(),
      referenceEndDate: moment().local(),
      startDateInput: { day: "", month: "", year: "" },
      endDateInput: { day: "", month: "", year: "" },
      isValidDateInput: { start: true, end: true },
    };

    this.strings = languages[props.language];
    this.handleCalendarEvent = this.handleCalendarEvent.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value["start"] !=
      this.formatDateToProps(this.state.selectedStartDate)
    ) {
      this.setState({
        selectedStartDate: this.formatDateToProps(nextProps.value["start"]),
      });
    }

    if (
      nextProps.value["end"] !=
      this.formatDateToProps(this.state.selectedEndDate)
    ) {
      this.setState({
        selectedEndDate: this.formatDateToProps(nextProps.value["end"]),
      });
    }

    if (
      nextProps.value["end"] !=
        this.formatDateToProps(this.state.selectedEndDate) &&
      nextProps.value["start"] !=
        this.formatDateToProps(this.state.selectedStartDate)
    ) {
      if (nextProps.value["end"] == "" && nextProps.value["start"] == "") {
        this.setState({
          dateItemSelected: null,
          calendarIconClicked: false,
        });
      }
    }
  }

  handleCalendarEvent(date, type) {
    if (this.props.eventPrefix) {
      events.track(this.props.eventPrefix + typeToEventName[type], {
        Date: date,
      });
    }

    let dateType = type.includes("init") ? "start" : "end";
    let newState = {};

    if (type == "init") {
      if (
        moment(this.state.selectedStartDate).isValid() &&
        !moment(this.state.selectedEndDate).isValid()
      ) {
        newState = {
          ...newState,
          selectedEndDate: this.state.selectedStartDate,
          selectedStartDate: date,
          lastTypeSelected: type,
        };
      } else {
        newState = {
          ...newState,
          selectedStartDate: date,
          lastTypeSelected: type,
        };
      }
    } else if (type == "end") {
      newState = {
        ...newState,
        selectedEndDate: date,
        lastTypeSelected: type,
      };
    } else if (type == "hover") {
      newState = {
        ...newState,
        hoveredDate: date,
      };
    }

    this.setState({
      ...newState,
      isValidDateInput: {
        ...this.state.isValidDateInput,
        [dateType]: true,
      },
    });

    this.props.onChange({
      start: this.formatDateToProps(
        type == "init" ? date : this.state.selectedStartDate,
      ),
      end: this.formatDateToProps(
        type == "end" ? date : this.state.selectedEndDate,
      ),
    });
  }

  renderDatesDropdown(type) {
    let { selectedEndDate, selectedStartDate, hoveredDate } = this.state;
    if (!selectedStartDate)
      selectedStartDate = moment.utc(this.props.startDate * 1000).local();
    let onArrowClick = type == "init" ? this.onPrev : this.onNext;
    let dates = {
      init: selectedStartDate != "" ? moment(selectedStartDate) : "",
      end: selectedEndDate != "" ? moment(selectedEndDate) : "",
      referenceDate:
        type == "init"
          ? this.state.referenceStartDate
          : this.state.referenceEndDate,
      hovered: hoveredDate,
    };

    return (
      <div className="dropdown-content-calendar">
        <div className="date-and-calendar">
          <Calendar
            onSelect={this.handleCalendarEvent}
            type={type}
            dates={dates}
            onArrowClick={onArrowClick}
            lastTypeSelected={this.state.lastTypeSelected}
          />
        </div>
      </div>
    );
  }

  handleSaveAndCancelButtons(type) {
    if (type == "close") {
      this.setState({ open: false });
    } else {
      this.setState({ open: false });
    }
  }

  onPrev = () => {
    this.setState({
      referenceStartDate: this.state.referenceStartDate.subtract(1, "month"),
      referenceEndDate: this.state.referenceEndDate.subtract(1, "month"),
    });
  };
  onNext = () => {
    this.setState({
      referenceStartDate: this.state.referenceStartDate.add(1, "month"),
      referenceEndDate: this.state.referenceEndDate.add(1, "month"),
    });
  };

  formatDateToProps = (date) => {
    if (date != "" && date) {
      if (typeof date == "string") {
        return date.replace("/", "-");
      } else {
        return date.format("YYYY-MM-DD");
      }
    } else {
      return "";
    }
  };

  formatDateToReadableText(date) {
    if (date != "" && date) {
      if (typeof date == "string") {
        date = date.includes("-")
          ? moment(date, "YYYY-MM-DD")
          : moment(date, "YYYY/MM/DD");
      }
      return date.format("DD/MM/YYYY");
    } else {
      return "dd/mm/yyyy";
    }
  }
  handleDateOnClick() {
    this.setState({ open: !this.state.open });
  }

  handleBarClick = (i) => {
    let newStateValues = {};
    let today = moment().local().startOf("day");

    newStateValues["selectedEndDate"] = today.clone();

    if (i == 0) {
      newStateValues["selectedStartDate"] = today.clone();
    } else if (i == 1) {
      newStateValues["selectedEndDate"] = today.clone().subtract(1, "days");
      newStateValues["selectedStartDate"] = today.subtract(1, "days");
    } else if (i == 2) {
      newStateValues["selectedStartDate"] = today.clone().subtract(6, "days");
    } else if (i == 3) {
      newStateValues["selectedStartDate"] = today
        .clone()
        .subtract(1, "months")
        .add(1, "days");
    } else if (i == 4) {
      newStateValues["selectedStartDate"] = today
        .clone()
        .subtract(3, "months")
        .add(1, "days");
    }

    this.setState({
      dateItemSelected: i,
      ...newStateValues,
    });

    this.props.onChange({
      start: this.formatDateToProps(newStateValues["selectedStartDate"]),
      end: this.formatDateToProps(newStateValues["selectedEndDate"]),
    });
  };

  renderCalendarBar = () => {
    const dateItems = [
      this.strings.today,
      this.strings.yesterday,
      this.strings.sevenDays,
      this.strings.oneMonth,
      this.strings.threeMonths,
    ];

    return (
      <div className={`calendar-bar`}>
        {dateItems.map((item, i) => (
          <div
            className={this.state.dateItemSelected === i ? "active" : ""}
            onClick={() => this.handleBarClick(i)}
          >
            {item}
          </div>
        ))}
        <div
          className="calendar-icon"
          onClick={() =>
            this.setState({
              calendarIconClicked: !this.state.calendarIconClicked,
              open: true,
            })
          }
        />
      </div>
    );
  };

  getCompleteInputDate = (dateObject, dateName) => {
    if (
      dateObject.day.length >= 1 &&
      dateObject.month.length >= 1 &&
      dateObject.year.length == 4
    ) {
      let completeDate = moment(
        `${dateObject.day}-${dateObject.month}-${dateObject.year}`,
        "DD-MM-YYYY",
      );

      let dateType = dateName.includes("start") ? "start" : "end";

      if (!completeDate.isValid()) {
        this.setState({
          isValidDateInput: {
            ...this.state.isValidDateInput,
            [dateType]: false,
          },
        });
        return false;
      } else {
        this.setState({
          isValidDateInput: {
            ...this.state.isValidDateInput,
            [dateType]: true,
          },
        });
      }

      return completeDate;
    } else {
      return false;
    }
  };

  onChangeInput = (e, datePart, dateName) => {
    let newValue = e.target.value;
    let stateChange = false;
    let newState = {};
    newState[dateName] = this.state[dateName];

    if (isNaN(newValue)) {
      return;
    }

    if (datePart == "day" || datePart == "month") {
      if (newValue.length > 2) {
        return;
      } else if (newValue == "") {
        stateChange = true;
      } else if (
        datePart == "day" &&
        parseInt(newValue) > 0 &&
        parseInt(newValue) <= 31
      ) {
        stateChange = true;
      } else if (
        datePart == "month" &&
        parseInt(newValue) > 0 &&
        parseInt(newValue) <= 12
      ) {
        stateChange = true;
      }
    } else {
      if (newValue.length <= 4) {
        stateChange = true;
      }
    }

    if (stateChange) {
      newState[dateName][datePart] = newValue;
      let completeInputdate = this.getCompleteInputDate(
        newState[dateName],
        dateName,
      );
      if (completeInputdate) {
        if (dateName.includes("start")) {
          newState["selectedStartDate"] = completeInputdate;
        } else {
          newState["selectedEndDate"] = completeInputdate;
        }
      }
    } else {
      newState = {};
    }

    if (!this.getCompleteInputDate(this.state.endDateInput, "end")) {
      newState["selectedEndDate"] = "";
    }

    if (!this.getCompleteInputDate(this.state.startDateInput, "start")) {
      newState["selectedStartDate"] = "";
    }

    this.setState({
      ...newState,
    });

    if (newState != {}) {
      this.props.onChange({
        start: this.formatDateToProps(
          newState["selectedStartDate"]
            ? newState["selectedStartDate"]
            : this.state.selectedStartDate,
        ),
        end: this.formatDateToProps(
          newState["selectedEndDate"]
            ? newState["selectedEndDate"]
            : this.setState.selectedEndDate,
        ),
      });
    }
  };

  deleteSelection = () => {
    this.setState({
      lastTypeSelected: "",
      selectedStartDate: "",
      selectedEndDate: "",
      startDateInput: { day: "", month: "", year: "" },
      endDateInput: { day: "", month: "", year: "" },
      isValidDateInput: { start: true, end: true },
    });

    this.props.onChange({
      start: "",
      end: "",
    });
  };

  renderCalendarInput = (dateType) => {
    let inputTypes = {
      day: "dd",
      month: "mm",
      year: "yyyy",
    };
    let dateKind = dateType.includes("start") ? "start" : "end";
    return (
      <>
        {dateType == "endDateInput" && <span className="separator"> -</span>}
        <div
          className={`calendar-input ${
            !this.state.isValidDateInput[dateKind] ? "error" : ""
          }`}
        >
          {Object.keys(inputTypes).map((key, i) => {
            return (
              <>
                <input
                  placeholder={inputTypes[key]}
                  className={key}
                  onChange={(e) => this.onChangeInput(e, key, dateType)}
                  value={this.state[dateType][key]}
                />
                {i != 2 && <span>/</span>}
              </>
            );
          })}
        </div>
      </>
    );
  };

  renderCalendar = () => {
    let onSelect = this.props.onSelect;
    let showDeleteSelection =
      this.state.startDateInput["day"].length > 0 ||
      moment(this.state.selectedStartDate).isValid() ||
      moment(this.state.selectedEndDate).isValid();

    if (typeof onSelect !== "function") onSelect = () => {};

    return (
      <div className="date-selector-container">
        <div className="date-dropdown" onClick={() => this.handleDateOnClick()}>
          <div className="date-selector date-selector--init">
            <p>{this.formatDateToReadableText(this.state.selectedStartDate)}</p>
          </div>
        </div>
        <i className="icon icon--arrow" />
        <div className="date-dropdown" onClick={() => this.handleDateOnClick()}>
          <div className="date-selector date-selector--end">
            <p>{this.formatDateToReadableText(this.state.selectedEndDate)}</p>
          </div>
        </div>
        <div
          className="calendar-icon"
          onClick={() =>
            this.setState({
              calendarIconClicked: !this.state.calendarIconClicked,
            })
          }
        />
        <div className={`dropdown ${this.state.open ? "is-active" : ""}`}>
          <div className="calendar-head">
            <div className="calendar-title">
              {this.state.startDateInput["day"].length == 0 && (
                <span>{this.strings.selectDate}</span>
              )}
              {this.state.startDateInput["day"].length > 0 && (
                <span>{this.strings.selectedDate}</span>
              )}
            </div>
            {this.renderCalendarInput("startDateInput")}
            {this.state.startDateInput.day != "" &&
              this.renderCalendarInput("endDateInput")}
            {showDeleteSelection && (
              <div
                className="delete-selection"
                onClick={() => this.deleteSelection()}
              >
                <span>{this.strings.deleteSelection}</span>
              </div>
            )}
          </div>
          <div className="calendar-body">
            {this.renderDatesDropdown("init")}
            {this.renderDatesDropdown("end")}
          </div>
          <div className="calendar-footer">
            <button
              className="btn btn--cancel"
              onClick={() => this.handleSaveAndCancelButtons("close")}
            >
              {this.strings.cancel}
            </button>
            <button
              className={`btn btn--save ${
                showDeleteSelection ? "" : "disabled"
              }`}
              onClick={() => {
                if (showDeleteSelection)
                  this.handleSaveAndCancelButtons("save");
              }}
            >
              {this.strings.apply}
            </button>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className="datepicker-container">
        {this.state.calendarIconClicked && this.renderCalendar()}
        {!this.state.calendarIconClicked && this.renderCalendarBar()}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPicker);
