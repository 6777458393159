// UTILS RELATED TO FILES
import { saveAs } from "file-saver";
import events from "./events";

const getFilename = (url) => {
  return url.substring(url.lastIndexOf("/") + 1);
};

export const getFileType = (url) => {
  const tmp = url.split(".");
  return tmp[tmp.length - 1];
};

export const downloadFile = async (
  url,
  eventMessage = null,
  dateFile = null,
) => {
  const filename = getFilename(url);
  const blob = await fetch(url, { cache: "no-cache" }).then((res) =>
    res.blob(),
  );
  saveAs(blob, filename);
  events.track(eventMessage, {
    "file type": getFileType(url),
    "file date": dateFile,
  });
};
