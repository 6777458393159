import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./TableIconButton.scss";

const TableIconButton = ({ iconName, variant, text }) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 50, hide: 50 }}
      overlay={
        <Tooltip className="table-icon-btn-tooltip">
          <p>{text}</p>
        </Tooltip>
      }
      popperConfig={{
        modifiers: {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      }}
    >
      <button className={`table-icon-btn ${variant}`}>
        <div className={`icon icon--${iconName}`} />
      </button>
    </OverlayTrigger>
  );
};

export default TableIconButton;
