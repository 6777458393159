module.exports = {
  es: {
    selectDate: "Seleccione una o varias fechas",
    selectedDate: "Fechas seleccionadas",
    deleteSelection: "Borrar selección",
    today: "Hoy",
    yesterday: "Ayer",
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    May: "Mayo",
    June: "Junio",
    July: "Julio",
    August: "Agosto",
    September: "Septiembre",
    October: "Octubre",
    December: "Diciembre",
    November: "Noviembre",
    cancel: "Cancelar",
    apply: "Aplicar",
    sevenDays: "7D",
    oneMonth: "1M",
    threeMonths: "3M",
  },
  en: {
    selectDate: "Select one or more dates",
    selectedDate: "Selected dates",
    deleteSelection: "Clear selection",
    today: "Today",
    yesterday: "Yesterday",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    December: "December",
    November: "November",
    cancel: "Cancel",
    apply: "Apply",
    sevenDays: "7D",
    oneMonth: "1M",
    threeMonths: "3M",
  },
  pt: {
    selectDate: "Selecione uma ou mais datas",
    selectedDate: "Datas selecionadas",
    deleteSelection: "Seleção clara",
    today: "Hoje",
    yesterday: "Ontem",
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    December: "Dezembro",
    November: "Novembro",
    cancel: "Cancelar",
    apply: "Aplicar",
    sevenDays: "7D",
    oneMonth: "1M",
    threeMonths: "3M",
  },
};
