import LogRocket from "logrocket";
import logoImg from "../assets/images/logo-treble.png";
import notificationSound from "../assets/sounds/mixkit-software-interface-start-2574.wav";
import events from "../utils/events";
import getLanguage from "../getLanguage";
const moment = require("moment-timezone");
var _ = require("lodash");

const SEND_HSM_ERROR = {
  CHARACTER_LIMIT: {
    es: "La longitud de los parámetros y el texto de la plantilla supera la longitud permitida",
    en: "Length of the parameters and the template text exceeds the allowed length",
    pr: "O comprimento dos parâmetros e do texto do modelo excede o comprimento permitido",
  },
  MISSING_PARAMETER: {
    es: "Uno de los parámetros no tiene un valor válido",
    en: "One of the parameters does not have a valid value",
    pr: "Um dos parâmetros não tem um valor válido",
  },
};

/*
 src/reducers/mainReducer.js
*/

const initialState = {
  user: null,
  hsms: [],
  chats: [],
  contacts: {
    offset: 0,
    value: [],
    total: 0,
    visible: 0,
    isFetching: false,
  },
  selectedContactId: null,
  agents: [],
  tags: [],
  chatLabels: [],
  alertState: {
    name: null,
    progress: null,
  },
  selectedChat: null,
  isFetching: false,
  active: true,
  errorVisible: false,
  errorMessage: "",
  currentConversationHistory: [],
  hasMoreHistory: true,
  isFetchingHistory: false,
  currentConversationNotes: [],
  deployments: {},
  language: localStorage["language"] || "es",
  channels: [],
  helpdesks: [],
  snippets: [],
  agentSnippets: [],
  files: {},
  loginHelpdesk: false,
  fullScreen: false,
  hubspot: {},
  contactFetching: false,
  isFetchingChats: false,
  contactEvents: [],
  events: [],
  notes: [],
};

function logout(state, action) {
  // persistor.purge()
  return Object.assign({}, initialState, {
    active: state.active,
  });
}

function receiveLogin(state, action) {
  events.identify(action.user.agent.id, action.user.agent.agent_type);
  LogRocket.identify(action.user.agent.id, {
    name: action.user.agent.first_name + " " + action.user.agent.last_name,
    email: action.user.agent.email,
  });
  localStorage.setItem("set_people", "true");
  try {
    Notification.requestPermission().then(function (result) {});
  } catch (error) {
    console.log("Notification library not supported");
    console.log(error);
  }
  return Object.assign({}, state, {
    isFetching: false,
    user: action.user,
    showModals: true,
  });
}

function orderByLastMessage(conversations) {
  return conversations.sort(function (a, b) {
    return a.last_message.created_at < b.last_message.created_at
      ? 1
      : a.last_message.created_at > b.last_message.created_at
      ? -1
      : 0;
  });
}

function orderConversations(conversations) {
  let unansweredConversations = [];
  let answeredConversations = [];
  let transferredConversations = [];
  conversations.forEach((conversation) => {
    if (
      conversation.last_redirection !== null &&
      conversation.last_redirection.created_at >
        conversation.last_message.created_at
    ) {
      transferredConversations.push(conversation);
    } else if (conversation.last_message.sender !== "AGENT") {
      unansweredConversations.push(conversation);
    } else {
      answeredConversations.push(conversation);
    }
  });
  console.log(
    "SEPARATE CHATS PRE ORDER",
    transferredConversations,
    unansweredConversations,
    answeredConversations,
  );
  transferredConversations.sort((a, b) => {
    return a.last_redirection.created_at - b.last_redirection.created_at;
  });
  unansweredConversations = orderByLastMessage(unansweredConversations);
  answeredConversations = orderByLastMessage(answeredConversations);
  console.log(
    "SEPARATE CHATS POST ORDER",
    transferredConversations,
    unansweredConversations,
    answeredConversations,
  );
  return [
    ...transferredConversations,
    ...unansweredConversations,
    ...answeredConversations,
  ];
}

function requestSelectChat(state, action) {
  const { conversationId } = action;
  console.log("Request select chat", conversationId);
  return {
    ...state,
    isFetching: true,
    currentConversationHistory: [],
    hasMoreHistory: true,
    currentConversationNotes: [],
  };
}

function requestMedia(state, action) {
  const { conversationId, mediaUrl, mediaType, temporalId } = action;
  const { chats, currentConversationHistory } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);
  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === conversationId,
  );

  if (currentChat === undefined) {
    return stateCopy;
  }

  let fileName = mediaUrl.split("_TREBLE_")[1];

  const newMessage = {
    type: mediaType,
    [mediaType]: {
      url: mediaUrl,
      filename: fileName,
    },
    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS[Z]"),
    read_by_agent: true,
    sender: "AGENT",
    provider_msg_id: null,
    temporal_id: temporalId,
  };

  console.log("newMessage", newMessage);

  currentChat.last_agent_message = newMessage;
  currentChat.last_message = newMessage;

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_conversation = null;
    last_conversation = cloneCurrentConversationHistory.find(
      (e) =>
        e.event_type === "event" &&
        e.event.type === "CREATED" &&
        e.event.conversation_id === conversationId,
    );

    if (last_conversation) {
      cloneCurrentConversationHistory.push({
        event_type: "message",
        message: newMessage,
      });
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  return stateCopy;
}

function createNoteSuccess(state, action) {
  const { conversationId, content, noteId, name } = action;

  const { currentConversationNotes, chats } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);
  const clonecurrentConversationNotes = _.cloneDeep(currentConversationNotes);

  const newNote = {
    id: noteId,
    content: content,
    date: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS[Z]"),
    temporal: false,
    name: name,
  };

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversationId),
  );

  if (currentChat !== undefined) {
    clonecurrentConversationNotes.push(newNote);
  }

  stateCopy.currentConversationNotes = clonecurrentConversationNotes;

  return stateCopy;
}

function noteDeleteSuccess(state, action) {
  const { noteId, conversationId } = action;

  const { currentConversationNotes, chats } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);
  const clonecurrentConversationNotes = _.cloneDeep(currentConversationNotes);

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversationId),
  );

  if (currentChat !== undefined) {
    clonecurrentConversationNotes.splice(
      clonecurrentConversationNotes.findIndex((a) => a.id === noteId),
      1,
    );
  }

  stateCopy.currentConversationNotes = clonecurrentConversationNotes;

  return stateCopy;
}

function requestMessage(state, action) {
  const { conversationId, message, temporalId, replyMessage } = action;
  const { chats, currentConversationHistory } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);
  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === conversationId,
  );

  if (currentChat === undefined) {
    return stateCopy;
  }

  const newMessage = {
    type: "text",
    text: {
      body: message,
    },
    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS[Z]"),
    read_by_agent: true,
    sender: "AGENT",
    provider_msg_id: null,
    temporal_id: temporalId,
    reply_message: replyMessage,
  };

  currentChat.last_agent_message = newMessage;
  currentChat.last_message = newMessage;

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_conversation = null;
    last_conversation = cloneCurrentConversationHistory.find(
      (e) =>
        e.event_type === "event" &&
        e.event.type === "CREATED" &&
        e.event.conversation_id === conversationId,
    );

    if (last_conversation) {
      cloneCurrentConversationHistory.push({
        event_type: "message",
        message: newMessage,
      });
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  return stateCopy;
}

function sendMessageSuccess(state, action) {
  const { conversationId, providerMsgId, temporalId } = action;
  const { chats, currentConversationHistory } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);

  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === conversationId,
  );

  if (currentChat === undefined) {
    return stateCopy;
  }

  if (currentChat.last_message.temporal_id === temporalId) {
    currentChat.last_message.provider_msg_id = providerMsgId;
  }

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_conversation = null;
    last_conversation = cloneCurrentConversationHistory.find(
      (e) =>
        e.event_type === "event" &&
        e.event.type === "CREATED" &&
        e.event.conversation_id === conversationId,
    );

    if (last_conversation) {
      const currentHistoryChatMessage = cloneCurrentConversationHistory.find(
        (e) =>
          e.event_type === "message" && e.message.temporal_id === temporalId,
      );
      if (currentHistoryChatMessage !== undefined) {
        currentHistoryChatMessage.message.provider_msg_id = providerMsgId;
      }
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  return stateCopy;
}

function sendMessageError(state, action) {
  const { conversationId, temporalId, error } = action;
  const { chats, currentConversationHistory } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);

  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === conversationId,
  );

  if (currentChat === undefined) {
    return stateCopy;
  }

  if (currentChat.last_message.temporal_id === temporalId) {
    currentChat.last_message.error = true;
  }

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_conversation = null;
    last_conversation = cloneCurrentConversationHistory.find(
      (e) =>
        e.event_type === "event" &&
        e.event.type === "CREATED" &&
        e.event.conversation_id === conversationId,
    );

    if (last_conversation) {
      const currentHistoryChatMessage = cloneCurrentConversationHistory.find(
        (e) =>
          e.event_type === "message" && e.message.temporal_id === temporalId,
      );
      if (currentHistoryChatMessage !== undefined) {
        currentHistoryChatMessage.message.error = true;
        cloneCurrentConversationHistory.splice(
          cloneCurrentConversationHistory.indexOf(currentHistoryChatMessage),
          1,
          currentHistoryChatMessage,
        );
      }
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  stateCopy.errorVisible = true;
  stateCopy.errorMessage = "Ha ocurrido un error al enviar el mensaje";
  if (error.message in SEND_HSM_ERROR) {
    stateCopy.errorMessage = SEND_HSM_ERROR[error.message][getLanguage()];
  }

  return stateCopy;
}

function requestHSM(state, action) {
  const { conversationId, header, content, footer, buttons, temporalId } =
    action;
  const { chats, currentConversationHistory } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);
  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === conversationId,
  );

  if (currentChat === undefined) {
    return stateCopy;
  }

  const newMessage = {
    type: "hsm",
    hsm: {
      header,
      body: content,
      footer,
      buttons,
    },
    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS[Z]"),
    read_by_agent: true,
    sender: "AGENT",
    provider_msg_id: null,
    temporal_id: temporalId,
  };

  currentChat.last_message = newMessage;
  currentChat.last_agent_message = newMessage;

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_conversation = null;
    last_conversation = cloneCurrentConversationHistory.find(
      (e) =>
        e.event_type === "event" &&
        e.event.type === "CREATED" &&
        e.event.conversation_id === conversationId,
    );

    if (last_conversation) {
      cloneCurrentConversationHistory.push({
        event_type: "message",
        message: newMessage,
      });
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  return stateCopy;
}

function sendHSMConversationError(state, action) {
  const { contact, error } = action;
  const { deployments } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneDeployments = _.cloneDeep(deployments);

  cloneDeployments[contact.id] = null;
  stateCopy.deployments = cloneDeployments;

  stateCopy.errorVisible = true;
  stateCopy.errorMessage = "Ha ocurrido un error al enviar el mensaje";
  if (error.message in SEND_HSM_ERROR) {
    stateCopy.errorMessage = SEND_HSM_ERROR[error.message][getLanguage()];
  }

  return stateCopy;
}

function requestHSMConversation(state, action) {
  const { contact, header, content, footer, buttons } = action;
  const { deployments } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneDeployments = _.cloneDeep(deployments);

  const newMessage = {
    type: "hsm",
    hsm: {
      header,
      body: content,
      footer,
      buttons,
    },
    created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS[Z]"),
    read_by_agent: true,
    sender: "AGENT",
    provider_msg_id: null,
  };

  cloneDeployments[contact.id] = newMessage;
  stateCopy.deployments = cloneDeployments;

  return stateCopy;
}

function newConversation(state, action) {
  const conversation = action.result;
  const { chats, contacts, user, deployments } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneContacts = _.cloneDeep(contacts);
  const cloneDeployments = _.cloneDeep(deployments);

  const conversationContact = conversation.contact;
  const newContact = {
    id: conversationContact.id,
    name: conversationContact.name,
    whole_cellphone: conversationContact.whole_cellphone,
    active_conversation: {
      id: conversation.conversation_id,
      agent: user.agent.email,
    },
  };

  console.log("NEW CONVERSATION", conversation);

  cloneChats.push(conversation);
  const existingContact = cloneContacts.value.find(
    (e) => e.id === parseInt(newContact.id),
  );
  if (!existingContact) {
    cloneContacts.value.push(newContact);
    cloneContacts.total += 1;
    if (newContact.name !== null) {
      cloneContacts.visible += 1;
    }
  } else {
    existingContact.active_conversation = {
      id: conversation.conversation_id,
      agent: user.agent.email,
    };
  }

  console.log("CLONE CHATS", cloneChats);
  stateCopy.chats = orderConversations(cloneChats);
  console.log("ORDERED CHATS", stateCopy.chats);
  stateCopy.contacts = cloneContacts;

  cloneDeployments[newContact.id] = null;
  stateCopy.deployments = cloneDeployments;

  return stateCopy;
}

function messageStatus(state, action) {
  const { conversation_id, message } = action.result;
  console.log("REDUCER", message);
  console.log("conversation id", conversation_id);

  const { chats, currentConversationHistory } = state;
  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );
  console.log("chats", cloneChats);

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversation_id),
  );

  const nowTS = new Date();
  const nowDate = nowTS.toUTCString();
  const messageStatus = message.status;

  console.log("Current chat", currentChat);

  if (currentChat !== undefined) {
    if (currentChat.last_message.provider_msg_id === message.provider_msg_id) {
      if (messageStatus === "DELIVERED") {
        currentChat.last_message.delivered_at = nowDate;
      } else if (messageStatus === "READ") {
        currentChat.last_message.read_at = nowDate;
      }
    }

    if (
      currentChat?.last_agent_message?.provider_msg_id ===
      message.provider_msg_id
    ) {
      if (messageStatus === "DELIVERED") {
        currentChat.last_agent_message.delivered_at = nowDate;
      } else if (messageStatus === "READ") {
        currentChat.last_agent_message.read_at = nowDate;
      }
    }
  }

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_created_conversation = null;
    for (let i = 0; i < cloneCurrentConversationHistory.length; i++) {
      let event = cloneCurrentConversationHistory[i];
      if (event.event_type === "event" && event.event.type === "CREATED") {
        last_created_conversation = event;
      }
    }
    if (
      last_created_conversation &&
      last_created_conversation.event.conversation_id ===
        parseInt(conversation_id)
    ) {
      const identifiedEventMessageHistory =
        cloneCurrentConversationHistory.find(
          (e) =>
            e.event_type === "message" &&
            e.message.provider_msg_id === message.provider_msg_id,
        );
      console.log("Identified message history", identifiedEventMessageHistory);
      if (identifiedEventMessageHistory !== undefined) {
        if (messageStatus === "DELIVERED") {
          identifiedEventMessageHistory.message.delivered_at = nowDate;
        } else if (messageStatus === "READ") {
          identifiedEventMessageHistory.message.read_at = nowDate;
        }
      }
    }
  }

  stateCopy.chats = cloneChats;
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;
  return stateCopy;
}

function formatToFile(message) {
  const type = message.type;
  return {
    created_at: message.created_at,
    category: message.type,
    [message.type]: message[type],
    sender: message.sender,
  };
}

function newMessage(state, action) {
  const { conversation_id, message } = action.result;

  console.log(" REDUCER ", message);

  const created_at = message.created_at;
  const moment_created_at = moment(created_at).utc();
  message.created_at = moment_created_at.format(
    "YYYY-MM-DD HH:mm:ss.SSSSSS[Z]",
  );

  console.log("conversation_id", conversation_id);
  console.log("message", message);

  const { chats, currentConversationHistory, files } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneFiles = _.cloneDeep(files);
  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  console.log("chats", cloneChats);

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversation_id),
  );

  if (currentChat !== undefined) {
    currentChat.last_message = message;
    currentChat.unread_messages += 1;
    currentChat.last_user_message = message;
    let type = null;
    if (["image", "video"].includes(message.type)) type = "files";
    if (["audio", "voice", "document"].includes(message.type)) type = "docs";
    if (type !== null) {
      if (!(type in cloneFiles)) cloneFiles[type] = [];
      cloneFiles[type].push(formatToFile(message));
    }
  }

  if (
    cloneCurrentConversationHistory &&
    cloneCurrentConversationHistory.length > 0
  ) {
    let last_created_conversation = null;
    for (let i = 0; i < cloneCurrentConversationHistory.length; i++) {
      let event = cloneCurrentConversationHistory[i];
      if (event.event_type === "event" && event.event.type === "CREATED") {
        last_created_conversation = event;
      }
    }
    if (
      last_created_conversation &&
      last_created_conversation.event.conversation_id == conversation_id
    ) {
      cloneCurrentConversationHistory.push({
        event_type: "message",
        message: message,
      });
    }
  }

  stateCopy.chats = orderConversations(cloneChats);
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;
  stateCopy.files = cloneFiles;
  return stateCopy;
}

function sendSeenSuccess(state, action) {
  const { conversationId } = action;

  const { currentConversationHistory, chats } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);

  const cloneCurrentConversationHistory = _.cloneDeep(
    currentConversationHistory,
  );

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversationId),
  );

  if (currentChat !== undefined) {
    currentChat.unread_messages = 0;
  }

  if (cloneCurrentConversationHistory.length > 0) {
    cloneCurrentConversationHistory.forEach((e) => {
      if (e.event_type === "message") {
        e.message.read_by_agent = true;
      }
    });
  }

  stateCopy.chats = cloneChats;
  stateCopy.currentConversationHistory = cloneCurrentConversationHistory;

  return stateCopy;
}

function deleteContactSuccess(state, action) {
  const { contactId } = action;

  const { chats, contacts } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneContacts = _.cloneDeep(contacts);

  const contactRelatedChat = cloneChats.find(
    (e) => e.contact.id === parseInt(contactId),
  );

  const deletedContact = cloneContacts.value.find(
    (e) => e.id === parseInt(contactId),
  );

  if (contactRelatedChat !== undefined) {
    contactRelatedChat.contact.name = null;
  }

  if (deletedContact) {
    deletedContact.name = null;
  }

  stateCopy.contacts.visible -= 1;

  stateCopy.chats = cloneChats;
  stateCopy.contacts = cloneContacts;
  stateCopy.isFetching = false;

  return stateCopy;
}

function updateContactSuccess(state, action) {
  const { contactId, name } = action;

  const { chats, contacts } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneContacts = _.cloneDeep(contacts);

  const contactRelatedChat = cloneChats.find(
    (e) => e.contact.id === parseInt(contactId),
  );

  const contact = cloneContacts.value.find((e) => e.id === parseInt(contactId));

  if (contactRelatedChat !== undefined) {
    contactRelatedChat.contact.name = name;
  }

  if (contact) {
    contact.name = name;
  }

  stateCopy.contacts.visible += 1;

  stateCopy.chats = cloneChats;
  stateCopy.contacts = cloneContacts;
  stateCopy.isFetching = false;

  return stateCopy;
}

function createContactSuccess(state, action) {
  const {
    contactId,
    contactName,
    contactCellphone,
    contactActiveConversation,
  } = action;

  const { chats, contacts } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const cloneContacts = _.cloneDeep(contacts);

  const contactRelatedChat = cloneChats.find(
    (e) => e.contact.id === parseInt(contactId),
  );

  if (contactRelatedChat) {
    contactRelatedChat.contact.name = contactName;
  }

  cloneContacts.value.push({
    id: contactId,
    name: contactName,
    whole_cellphone: contactCellphone,
    active_conversation: contactActiveConversation,
  });

  cloneContacts.total += 1;
  cloneContacts.visible += 1;

  stateCopy.chats = cloneChats;
  stateCopy.contacts = cloneContacts;
  stateCopy.isFetching = false;

  return stateCopy;
}

function showNotification(state, action) {
  const isMobile = window.innerWidth <= 768;
  let { title, message } = action;
  let options = {
    body: message,
    title,
    icon: logoImg,
    vibrate: [200, 100, 200, 100, 200, 100, 200],
  };
  console.log("showing notification", options);
  console.log("url image", logoImg);
  if (!isMobile && !state.loginHelpdesk) {
    try {
      let notification = new Notification(title, options);
      if (document.hidden) new Audio(notificationSound).play();
    } catch (error) {
      console.log("Error in notification", error);
    }
  } else {
    try {
      Notification.requestPermission(function (result) {
        console.log("Prev");
        if (result === "granted") {
          console.log("Granted");
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification(title, options);
          });
        }
      });
    } catch (error) {
      console.log("Error in service worker notification", error);
    }
  }
  const windowParent = window.parent;
  windowParent.postMessage(`treble--${title}--${message}`, "*");

  return _.cloneDeep(state);
}

function formatSnippet(snippet, tag_names = null, fixed = false) {
  return {
    id: snippet.id,
    name: snippet.name,
    text: snippet.text,
    updated_at: moment.utc(snippet.updated_at).toDate(),
    tags: tag_names ? tag_names : snippet.tags,
    fixed,
  };
}

function formatSnippets(snippets, fixed = false) {
  return snippets.map((snippet) => formatSnippet(snippet, null, fixed));
}

function pushSnippet(state, action) {
  const { snippets } = state;
  const { snippet, tag_names } = action;

  const stateCopy = _.cloneDeep(state);
  let snippetsClone = _.cloneDeep(snippets);

  snippetsClone.push(formatSnippet(snippet, tag_names));

  stateCopy.isFetching = false;
  stateCopy.snippets = snippetsClone;

  return stateCopy;
}

function updateSnippet(state, action) {
  const { snippets } = state;
  const { snippet, tag_names } = action;

  const stateCopy = _.cloneDeep(state);
  let snippetsClone = _.cloneDeep(snippets);

  const idx = snippetsClone.findIndex((elem) => elem.id === snippet.id);
  snippetsClone[idx] = formatSnippet(snippet, tag_names);

  stateCopy.isFetching = false;
  stateCopy.snippets = snippetsClone;

  return stateCopy;
}

function deleteSnippet(state, action) {
  const { snippets } = state;
  const { snippet } = action;

  const stateCopy = _.cloneDeep(state);
  let snippetsClone = _.cloneDeep(snippets);

  snippetsClone = snippetsClone.filter((elem) => elem.id != snippet.id);

  stateCopy.isFetching = false;
  stateCopy.snippets = snippetsClone;

  return stateCopy;
}

function sortAgentSnippets(snippets, agent_snippets) {
  let pos = agent_snippets.reduce((dict, val, idx) => {
    dict[val["snippet_id"]] = idx;
    return dict;
  }, {});

  let fixedSnippets = snippets.filter((snippet) => {
    return pos[snippet.id] !== undefined;
  });

  let otherSnippets = snippets.filter((snippet) => {
    return pos[snippet.id] === undefined;
  });

  fixedSnippets = formatSnippets(fixedSnippets, true);
  otherSnippets = formatSnippets(otherSnippets);

  fixedSnippets = fixedSnippets.sort((a, b) => {
    return pos[a.id] - pos[b.id];
  });
  otherSnippets = otherSnippets.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });

  console.log("agent snippets", [...fixedSnippets, ...otherSnippets]);

  return [...fixedSnippets, ...otherSnippets];
}

function formatAgentSnippets(action) {
  const { snippets, agent_snippets } = action;
  return sortAgentSnippets(snippets, agent_snippets);
}

function agentUpdateSnippets(state, action) {
  const { agentSnippets } = state;
  const { agent_snippets } = action;

  const stateCopy = _.cloneDeep(state);
  let agentSnippetsClone = _.cloneDeep(agentSnippets);

  agentSnippetsClone = sortAgentSnippets(agentSnippetsClone, agent_snippets);

  stateCopy.isFetching = false;
  stateCopy.agentSnippets = agentSnippetsClone;

  return stateCopy;
}

function receiveSelectedChatLabels(state, action) {
  const { chatLabels, conversationId } = action;

  const { chats } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);

  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversationId),
  );

  currentChat.labels = chatLabels;
  stateCopy.chats = cloneChats;

  return stateCopy;
}

function removeLabelAllChats(state, action) {
  const { labelId } = action;

  const { chats } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);

  stateCopy.chats = cloneChats.map((chat) => {
    const index = chat.labels.findIndex((e) => e.id === parseInt(labelId));
    if (index !== -1) chat.labels.splice(index, 1);
    return chat;
  });

  return stateCopy;
}

function updateLabelAllChats(state, action) {
  const { labelId, labelInfo } = action;

  const { chats } = state;

  const stateCopy = _.cloneDeep(state);
  const cloneChats = _.cloneDeep(chats);

  stateCopy.chats = cloneChats.map((chat) => {
    const index = chat.labels.findIndex((e) => e.id === parseInt(labelId));
    if (index !== -1) chat.labels[index] = labelInfo;
    return chat;
  });

  return stateCopy;
}

function removeConversation(state, action) {
  const { conversation_id } = action.result;
  const { chats } = state;

  const stateCopy = _.cloneDeep(state);

  const cloneChats = _.cloneDeep(chats);
  const currentChat = cloneChats.find(
    (e) => e.conversation_id === parseInt(conversation_id),
  );
  var currentChatIndex = cloneChats.indexOf(currentChat);
  cloneChats.splice(currentChatIndex, 1);

  stateCopy.chats = orderConversations(cloneChats);

  return stateCopy;
}

function receiveConversationHistory(state, action) {
  const { history, notes, hasMoreHistory, contactId } = action;
  const { currentConversationHistory, selectedContactId } = state;

  const stateCopy = _.cloneDeep(state);

  if (selectedContactId === contactId) {
    stateCopy.isFetchingHistory = false;
    stateCopy.hasMoreHistory = hasMoreHistory;
    stateCopy.currentConversationNotes = notes;
    if (hasMoreHistory) {
      stateCopy.currentConversationHistory = history;
      stateCopy.isFetching = false;
    } else {
      stateCopy.currentConversationHistory = [
        ...history,
        ...currentConversationHistory,
      ];
    }
  }

  return stateCopy;
}

function requestcontacts(state) {
  const stateCopy = _.cloneDeep(state);
  stateCopy.contacts.isFetching = true;
  return stateCopy;
}

function receiveContacts(state, action) {
  const { contacts, total, visible } = action;

  const stateCopy = _.cloneDeep(state);

  stateCopy.contacts.isFetching = false;

  stateCopy.contacts.offset += contacts.length;
  if (total !== undefined && visible !== undefined) {
    stateCopy.contacts.total = total;
    stateCopy.contacts.visible = visible;
    stateCopy.contacts.value = [...contacts];
  } else {
    stateCopy.contacts.value = [...state.contacts.value, ...contacts];
  }

  return stateCopy;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // LOGIN

    case "REQUEST_LOGIN":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_LOGIN":
      return receiveLogin(state, action);
    case "LOGIN_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
        errorVisible: true,
        errorMessage: "Usuario o contraseña incorrectos",
      });
    case "LOGIN_FROM_HELPDESK":
      return Object.assign({}, state, {
        loginHelpdesk: true,
      });
    case "RESET_SHOW_MODALS":
      return { ...state, showModals: false };

    // SEEN

    case "SEND_SEEN_SUCCESS":
      return sendSeenSuccess(state, action);

    // LANGUAGE

    case "CHANGE_LANGUAGE":
      return Object.assign({}, state, {
        language: action.language,
      });

    // ACTIVATION

    case "CHANGE_ACTIVATION":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "ACTIVATION_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
        active: action.active.active,
      });
    case "ACTIVATION_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    // CLEAN HISTORY
    case "RECEIVE_CLEAN_HISTORY":
      return Object.assign({}, state, {
        currentConversationHistory: [],
        hasMoreHistory: true,
        currentConversationNotes: [],
      });

    // SELECT CHAT
    case "REQUEST_SELECT_CHAT":
      return requestSelectChat(state, action);

    // SEND MESSAGE

    case "REQUEST_SEND_MESSAGE":
      return requestMessage(state, action);

    case "SEND_MESSAGE_SUCCESS":
      return sendMessageSuccess(state, action);

    case "SEND_MESSAGE_ERROR":
      return sendMessageError(state, action);

    // SEND HSM

    case "REQUEST_SEND_HSM":
      return requestHSM(state, action);

    case "SEND_HSM_SUCCESS":
      return sendMessageSuccess(state, action);

    case "SEND_HSM_ERROR":
      return sendMessageError(state, action);

    // SEND HSM CONVERSATION

    case "REQUEST_SEND_HSM_CONVERSATION":
      return requestHSMConversation(state, action);

    case "SEND_HSM_CONVERSATION_ERROR":
      return sendHSMConversationError(state, action);

    // SEND IMAGE

    case "REQUEST_SEND_MEDIA":
      return requestMedia(state, action);

    case "SEND_MEDIA_SUCCESS":
      return sendMessageSuccess(state, action);

    case "SEND_MEDIA_ERROR":
      return sendMessageError(state, action);

    // CREATE NOTE SUCCESS

    case "NOTE_CREATED_SUCCESS":
      return createNoteSuccess(state, action);

    // AGENT CHAT CASES

    case "REQUEST_CHATS":
      return Object.assign({}, state, {
        isFetchingChats: true,
      });
    case "RECEIVE_CHATS":
      return Object.assign({}, state, {
        isFetchingChats: false,
        chats: orderConversations(action.chats),
        errorVisible: false,
        errorMessage: false,
      });
    case "AGENT_CHATS_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    case "REQUEST_AGENTS":
      return Object.assign({}, state, {
        agents: [],
      });

    case "RECEIVE_AGENTS":
      return Object.assign({}, state, {
        agents: action.agents,
      });

    case "SELECT_CONTACT":
      return Object.assign({}, state, {
        selectedContactId: action.contactId,
      });

    case "REQUEST_CONVERSATION_HISTORY_CELLPHONE":
      if (action.firstTime) {
        return Object.assign({}, state, {
          currentConversationHistory: [],
          hasMoreHistory: true,
          isFetching: true,
        });
      } else {
        return Object.assign({}, state, {
          isFetchingHistory: true,
        });
      }

    case "RECEIVE_CONVERSATION_HISTORY_CELLPHONE":
      return receiveConversationHistory(state, action);

    case "REQUEST_CONVERSATION_HISTORY_CELLPHONE_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
      });

    // AGENT CONTACT CASES

    case "REQUEST_CONTACTS":
      return requestcontacts(state);
    case "RECEIVE_CONTACTS":
      return receiveContacts(state, action);

    case "CLEAN_CONTACTS":
      return Object.assign({}, state, {
        contacts: {
          offset: 0,
          value: [],
          total: 0,
          visible: 0,
          isFetching: false,
        },
      });

    case "RECEIVE_CHANNELS":
      return Object.assign({}, state, {
        channels: action.channels,
      });

    case "RECEIVE_HELPDESKS":
      return Object.assign({}, state, {
        helpdesks: action.helpdesks,
      });

    case "AGENT_CONTACTS_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    // AGENT HSMS CASES

    case "REQUEST_HSMS":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_HSMS":
      return Object.assign({}, state, {
        isFetching: false,
        hsms: action.hsms,
      });
    case "AGENT_HSMS_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    // CONTACT

    case "RECEIVE_CONTACT_EVENTS":
      return Object.assign({}, state, {
        contactFetching: false,
        contactEvents: action.events,
      });
    case "REQUEST_CONTACT_EVENTS":
      return Object.assign({}, state, {
        contactFetching: true,
        contactEvents: [],
      });
    case "REQUEST_CONTACT_CONVERSATION_HISTORY":
      return Object.assign({}, state, {
        isFetching: true,
        events: [],
        notes: [],
      });
    case "RECEIVE_CONTACT_CONVERSATION_HISTORY":
      return Object.assign({}, state, {
        isFetching: false,
        events: action.history.events,
        notes: action.history.notes,
      });
    // CREATE CONTACT CASES

    case "REQUEST_CREATE_CONTACT":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "RECEIVE_CREATE_CONTACT":
      return createContactSuccess(state, action);

    case "CREATE_CONTACT_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
        errorVisible: true,
        errorMessage: "Ha ocurrido un error al crear el contacto",
      });

    case "REQUEST_DELETE_CONTACT":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "DELETE_CONTACT_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
        errorVisible: true,
        errorMessage: action.error,
      });

    case "RECEIVE_DELETE_CONTACT":
      return deleteContactSuccess(state, action);

    case "REQUEST_UPDATE_CONTACT":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "UPDATE_CONTACT_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
        errorVisible: true,
        errorMessage: action.error,
      });

    case "RECEIVE_UPDATE_CONTACT":
      return updateContactSuccess(state, action);

    case "REQUEST_UPDATE_CONTACT":
      return Object.assign({}, state, {
        isFetching: true,
      });

    // CHAT LABELS

    case "RECEIVE_CHAT_LABELS":
      return Object.assign({}, state, {
        chatLabels: action.chatLabels,
      });

    case "RECEIVE_SELECTED_CHAT_LABELS":
      return receiveSelectedChatLabels(state, action);

    case "REMOVE_LABEL_ALL_CHATS":
      return removeLabelAllChats(state, action);

    case "UPDATE_LABEL_ALL_CHATS":
      return updateLabelAllChats(state, action);

    case "CHAT_LABELS_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
      });

    // ALERT STATE

    case "UPDATE_ALERT_STATE":
      return Object.assign({}, state, {
        alertState: {
          name: action.name,
          progress: action.progress,
        },
      });

    // END CONVERSATION

    case "REQUEST_END_CONVERSATION":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "END_CONVERSATION_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
        isFetching: false,
      });
    case "END_CONVERSATION_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
      });

    // LOGOUT

    case "AGENT_LOGOUT_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: action.error,
      });
    case "AGENT_LOGOUT":
      return logout(state, action);

    // WEBSOCKETS

    case "NEW_CONVERSATION":
      return newConversation(state, action);
    case "NEW_MESSAGE":
      return newMessage(state, action);
    case "MESSAGE_STATUS":
      return messageStatus(state, action);
    case "REMOVE_CONVERSATION":
      return removeConversation(state, action);
    // NOTIFICATIONS

    case "NOTIFICATION":
      return showNotification(state, action);

    // CHAT TRANSFER

    case "REQUEST_CHAT_TRANSFER":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "CHAT_TRANSFER_SUCCESS":
      return Object.assign({}, state, {
        isFetching: false,
      });
    case "CHAT_TRANSFER_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: "Ha ocurrido un error al transferir el chat",
        isFetching: false,
      });
    case "CHAT_TRANSFER_CONNECTED_AGENT_ERROR":
      return Object.assign({}, state, {
        errorVisible: true,
        errorMessage: "No permitido mientras el vendedor este activo",
        isFetching: false,
      });

    // SNIPPETS

    case "REQUEST_SNIPPETS":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "ADMIN_RECEIVE_SNIPPETS":
      return Object.assign({}, state, {
        isFetching: false,
        snippets: formatSnippets(action.snippets),
      });

    case "SNIPPETS_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    case "CREATE_SNIPPET":
      return pushSnippet(state, action);

    case "UPDATE_SNIPPET":
      return updateSnippet(state, action);

    case "DELETE_SNIPPET":
      return deleteSnippet(state, action);

    case "AGENT_RECEIVE_SNIPPETS":
      return Object.assign({}, state, {
        isFetching: false,
        agentSnippets: formatAgentSnippets(action),
      });

    case "AGENT_UPDATE_SNIPPETS":
      return agentUpdateSnippets(state, action);

    // COMPANY TAGS

    case "AGENT_REQUEST_TAGS":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "AGENT_TAGS_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
        errorVisible: true,
        errorMessage: action.error,
      });

    case "AGENT_RECEIVE_TAGS":
      return Object.assign({}, state, {
        tags: action.tags,
        isFetching: false,
      });

    // FILES

    case "REQUEST_UPLOAD_FILE":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "REQUEST_FILES":
      return Object.assign({}, state, {
        isFetching: true,
      });

    case "FILES_ERROR":
      return Object.assign({}, state, {
        isFetching: false,
      });

    case "FILES_SUCCESS":
      return Object.assign({}, state, {
        files: action.files,
        isFetching: false,
      });

    // FULL SCREEN

    case "SET_FULL_SCREEN":
      return Object.assign({}, state, {
        fullScreen: action.value,
      });

    // HUBSPOT

    case "SET_HUBSPOT_PIPELINE_STAGE":
      return Object.assign({}, state, {
        hubspot: {
          ...state.hubspot,
          pipeline: action.pipeline,
          stage: action.stage,
        },
      });

    // CLEAR ERROR

    case "CLEAR_ERROR":
      return Object.assign({}, state, {
        errorVisible: false,
      });

    default:
      return state;
  }
};
