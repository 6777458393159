import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Platform.scss";
import { registerEvent } from "../../utils/amplitude";
import { logEventServer } from "../../utils/request";

import {
  changeActivation,
  changeLanguage,
  crashUpdateError,
  clearError,
  lastOnline,
} from "../../actions/userAction";
import { setFullScreen } from "../../actions/chatAction";
import { getTeams } from "../../actions/statAction";
import { resetShowModals } from "../../actions/loginAction";
import { agentLogout } from "../../actions/agentAction";
import { endConversation } from "../../actions/chatAction";
import {
  subscribeMessage,
  subscribeConversation,
  socketOnConnect,
  sendMessage,
} from "../../actions/websocket";

import { handleNPSWidget } from "../../utils/NPSWidget";
import { getHubspotAttributes } from "../../utils/getHubspotAttributes";

import Loader from "../../components/Loader/Loader";
import Popup from "../../components/Popup/Popup";
import SelectDropdown from "../../components/SelectDropdown/SelectDropdown";
import Modal from "../../components/Modal/ModalV2";

import Chats from "./Chats/Chats";
import ChatStats from "./ChatStats/ChatStats";
import AgentStats from "./AgentStats/AgentStats";
import Snippets from "./Snippets/Snippets";
import Metrics from "./Metrics/Metrics";

import languages from "./languages.js";
import events from "../../utils/events";
import { scriptUpdateWidget } from "../../utils/updateWidget";
import AnnouncementModal from "../../components/AnnouncementModal/AnnouncementModal";

class Platform extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: "",
      sectionName: "",
      showGeneralModal: false,
      showLoginModal: false,
      showNavbarOnMobile: true,
    };

    this.strings = languages[props.language];

    this.changeActivation = this.changeActivation.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidCatch(error, info) {
    console.log("error", error);
    console.log("info", info);

    registerEvent("logout_component_catch_error");
    logEventServer("component_error", {
      error: error ? error.toString() : "Unknown",
      component: info.componentStack,
    });

    this.props.crashUpdateError("", this.props.loginHelpdesk);
  }

  componentDidMount() {
    setTimeout(() => this.props.changeActivation(this.props.active), 5 * 1000);
    this.props.lastOnline();
    this.intervalLastOnline = setInterval(
      () => this.props.lastOnline(),
      5 * 60 * 1000,
    );

    const user = this.props.user;

    if (
      user.agent.agent_type === "ADMIN" ||
      user.agent.agent_type === "SUPERVISOR"
    ) {
      // request teams
      this.props.getTeams();
    }

    const callbackHandleNPSWidget = handleNPSWidget(user.agent.id);
    const callbackMixpanel = (data) => {
      events.setPeople(user, data);
    };
    const callbackLoginModal = (loginModal) => {
      let loginModalStorage = localStorage["loginModal"];
      if (loginModal && loginModalStorage === undefined) {
        localStorage.setItem("loginModal", "true");
        this.setState({ showLoginModal: true });
      }
    };

    getHubspotAttributes(
      ["nps_widget", "tier", "active_integrations", "treble_login_modal"],
      (data) => {
        callbackHandleNPSWidget(data.nps_widget === "true" ? true : false);
        callbackMixpanel(data);
        callbackLoginModal(data.treble_login_modal === "true" ? true : false);
      },
    );

    this.props.setFullScreen(false);

    scriptUpdateWidget(this.props.language, this.props.user);
  }

  componentWillUpdate(newProps) {
    if (this.props.language !== newProps.language) {
      this.strings = languages[newProps.language];
    }
  }

  componentWillMount() {
    const user = this.props.user;
    console.log("AUTH SOCKET PLATFORM", user.agent.id);
    this.props.sendMessage("authenticate", { agent_id: user.agent.id });

    const loc = this.props.location.pathname;

    if (user.agent.agent_type == "AGENT" && loc != "/admin/chats") {
      window.location.href = `${process.env.PUBLIC_URL}/admin/chats`;
    }

    if (loc === "/admin/chats") {
      const tags = user.agent.tags.join(", ");
      this.setState({
        section: "chats",
        sectionName: `Conversaciones (${tags})`,
      });
    }
    if (loc === "/admin/chat-stats") {
      this.setState({
        section: "chats-stats",
        sectionName: "Panel de Conversaciones",
      });
    }
    if (loc === "/admin/agent-stats") {
      this.setState({
        section: "agent-stats",
        sectionName: "Rendimiendo de Vendedores",
      });
    }
    if (loc === "/admin/metrics") {
      this.setState({ section: "metrics", sectionName: "Métricas" });
    }
    if (loc === "/admin/snippets") {
      this.setState({ section: "snippets", sectionName: "Snippets" });
    }

    this.unlisten = this.props.history.listen((location, action) => {
      const pathname = location.pathname;
      if (pathname === "/admin/chats") {
        const tags = user.agent.tags.join(", ");
        this.setState({
          section: "chats",
          sectionName: `Conversaciones (${tags})`,
          showNavbarOnMobile: true,
        });
      }
      if (pathname === "/admin/chat-stats") {
        this.setState({
          section: "chats-stats",
          sectionName: "Panel de Conversaciones",
          showNavbarOnMobile: true,
        });
      }
      if (pathname === "/admin/agent-stats") {
        this.setState({
          section: "agent-stats",
          sectionName: "Rendimiendo de Vendedores",
          showNavbarOnMobile: true,
        });
      }
      if (pathname === "/admin/metrics") {
        this.setState({
          section: "metrics",
          sectionName: "Métricas",
          showNavbarOnMobile: false,
        });
      }
      if (pathname === "/admin/snippets") {
        this.setState({
          section: "snippets",
          sectionName: "Snippets",
          showNavbarOnMobile: false,
        });
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalLastOnline);
    this.unlisten();
  }

  changeActivation(newState = null) {
    events.track("Agent change status", { Status: newState });
    this.props.changeActivation(newState);
  }

  changeLanguage(newLanguage) {
    events.track("Agent change language", {
      Language: newLanguage,
    });
    this.props.changeLanguage(newLanguage);
  }

  clickOnShowUpdates = () => {
    events.track("Agent click on show updates ");
  };

  logout = () => {
    this.props.agentLogout();
  };

  getUserAcronym = () => {
    const agent = this.props.user.agent;
    const acronym =
      agent.first_name[0] + (agent.last_name ? agent.last_name[0] : "");
    return acronym;
  };

  renderSwitch() {
    const name = this.props.active ? "Activo" : "Inactivo";
    const active = this.props.active ? "active" : "";

    return (
      <div className="user-state" onClick={() => this.changeActivation()}>
        <p>{name}</p>
        <div className={`switch ${active}`}>
          <div className="circle"></div>
        </div>
      </div>
    );
  }

  renderLinks = () => {
    const protectedLinks = [
      <Link
        to="/admin/chat-stats"
        key={1}
        className={
          this.state.section === "chats-stats"
            ? "control-bar-item active"
            : "control-bar-item"
        }
      >
        <div className="control-bar-icon">
          <div className="icon icon--inbox size-18"></div>
        </div>
        <div className="selected-stripe"></div>
      </Link>,
      <Link
        to="/admin/metrics"
        key={2}
        className={
          this.state.section === "metrics"
            ? "control-bar-item active"
            : "control-bar-item"
        }
      >
        <div className="control-bar-icon">
          <div className="icon icon--chart size-18"></div>
        </div>
        <div className="selected-stripe"></div>
      </Link>,
      <Link
        to="/admin/agent-stats"
        key={3}
        className={
          this.state.section === "agent-stats"
            ? "control-bar-item active"
            : "control-bar-item"
        }
      >
        <div className="control-bar-icon">
          <div className="icon icon--support size-18"></div>
        </div>
        <div className="selected-stripe"></div>
      </Link>,
      <Link
        to="/admin/snippets"
        key={4}
        className={`control-bar-item ${
          this.state.section === "snippets" ? "active" : ""
        }`}
      >
        <div className="control-bar-icon">
          <div className="icon icon--snippets size-18"></div>
        </div>
        <div className="selected-stripe"></div>
      </Link>,
    ];

    const user = this.props.user;
    const renderLinks =
      user.agent.agent_type === "ADMIN" ||
      user.agent.agent_type === "SUPERVISOR"
        ? protectedLinks
        : null;

    return (
      <div className="control-bar-items">
        <Link
          to="/admin/chats"
          className={
            this.state.section === "chats"
              ? "control-bar-item active"
              : "control-bar-item"
          }
        >
          <div className="control-bar-icon">
            <div className="icon icon--chat size-18"></div>
          </div>
          <div className="selected-stripe"></div>
        </Link>
        {renderLinks}
      </div>
    );
  };

  renderBodyNotificationModal = () => {
    return (
      <>
        <div className="step step--one">
          <div className="image" />
          <div className="arrow" />
          <div className="text">{this.strings.bodyNotificationStep1}</div>
        </div>
        <div className="step step--two">
          <div className="image" />
          <div className="arrow" />
          <div className="text">{this.strings.bodyNotificationStep2}</div>
        </div>
        <div className="step step--three">
          <div className="image" />
          <div className="arrow" />
          <div className="text">{this.strings.bodyNotificationStep3}</div>
        </div>
        <div className="extra">{this.strings.bodyNotificationExtra}</div>
      </>
    );
  };
  renderFooterNotificationModal = () => {
    return (
      <div
        class="button"
        onClick={() => this.setState({ showGeneralModal: false })}
      >
        {this.strings.footerButtonNotification}
      </div>
    );
  };

  renderHeaderEndAllChatsModal = () => {
    const headerString = this.strings.headerEndAllChats.split("--");
    const emojis = headerString[0];
    const text = headerString[1];
    return (
      <>
        <div className="emojis">{emojis}</div>
        {text}
      </>
    );
  };

  renderBodyEndAllChatsModal = () => {
    return this.strings.bodyEndAllChats;
  };

  renderFooterEndAllChatsModal = () => {
    const conversationIds = this.props.chats.map((chat) => {
      return chat.conversation_id;
    });

    const hubspot = this.props.helpdesks.filter((h) => h.type === "HUBSPOT");

    return (
      <>
        <div
          className="button end-all-chats"
          onClick={() => {
            events.track("Agent click on close all chats");
            this.props.endConversation(
              conversationIds,
              hubspot.length ? { hubspot: {} } : {},
            );
            this.setState({ showGeneralModal: false });
          }}
        >
          {this.strings.footerEndAllChats}
        </div>
        <div
          className="button cancel"
          onClick={() => this.setState({ showGeneralModal: false })}
        >
          {this.strings.footerCancelEndAllChats}
        </div>
      </>
    );
  };

  renderGeneralModal = (className, header, body, footer) => {
    return (
      <div className="general-modal">
        <Modal
          wrappClass={className}
          show={this.state.showGeneralModal}
          header={header}
          onClose={() => this.setState({ showGeneralModal: false })}
          body={body}
          footer={footer}
        />
      </div>
    );
  };

  renderModal() {
    const version = 1; // →→→ increment in one when you release a new modal

    if (
      `modalVersion-${version}` ==
      JSON.parse(localStorage.getItem("lastVisualizaedModals"))
    ) {
      return;
    }

    return (
      <AnnouncementModal
        onClose={this.props.resetShowModals}
        texts={[this.strings.announcementModalText]}
        overTitle={this.strings.announcementModalOverTitle}
        title={this.strings.announcementModalTitle}
        customFooter={<></>}
        imgName={this.strings.announcementModalImgName}
        ctaText={this.strings.announcementModalCtaButton}
        ctaClick={() => {
          this.props.resetShowModals();
          localStorage.setItem(
            "lastVisualizaedModals",
            JSON.stringify(`modalVersion-${version}`),
          );
          events.track("Agent click on know new metrics");
          window.open(this.strings.announcementModalLink, "_blank");
        }}
      />
    );
  }

  selectModal = (selectedModal) => {
    if (selectedModal === "notification-modal") {
      return this.renderGeneralModal(
        "notification-modal",
        this.strings.titleNotification,
        this.renderBodyNotificationModal(),
        this.renderFooterNotificationModal(),
      );
    }
    return this.renderGeneralModal(
      "end-all-chats-modal",
      this.renderHeaderEndAllChatsModal(),
      this.renderBodyEndAllChatsModal(),
      this.renderFooterEndAllChatsModal(),
    );
  };

  render() {
    let active = this.props.active;

    const menuOptions = [
      {
        text: this.strings.notifications,
        icon: "bell-notification",
        onSelect: () => {
          this.setState({
            showGeneralModal: true,
            selectedModal: "notification-modal",
          });
        },
      },
      {
        text: `${this.strings.changeTo} ${
          active ? this.strings.inactive : this.strings.active
        }`,
        icon: active ? "user-inactive" : "user-active",
        onSelect: () => {
          this.changeActivation();
        },
      },
      {
        text: this.strings.logout,
        icon: "exit",
        onSelect: () => {
          this.logout();
        },
      },
    ];

    let endAllChats = false;

    if (this.state.section == "chats" && this.props.chats.length) {
      endAllChats = true;
      menuOptions.splice(1, 0, {
        text: this.strings.endAllChats,
        icon: "end-all-chats",
        onSelect: () => {
          this.setState({
            showGeneralModal: true,
            selectedModal: "end-all-chats-modal",
          });
        },
      });
    }

    const background = this.state.section !== "chats" ? "background" : "";
    const acronymName = this.getUserAcronym();

    const userLogo = (
      <SelectDropdown
        options={menuOptions}
        onSelect={(opt) => opt.onSelect()}
        display={(opt) => {
          return (
            <div className={`platform-options ${opt.icon}`}>
              <div className={`icon icon--${opt.icon}`} />
              <div>{opt.text}</div>
            </div>
          );
        }}
        direction="right"
        triggerComponent={
          <div className="user-logo">
            {acronymName}
            <div className={`state ${active ? "active" : ""}`}></div>
          </div>
        }
      />
    );

    return (
      <div className={`agents-root ${background}`}>
        <Loader visible={this.props.isFetching} />
        <Popup
          visible={this.props.errorVisible}
          error
          errorMessage={this.props.errorMessage}
          handleClick={this.props.clearError}
        />

        {false && this.props.showModals && this.renderModal()}
        <div className={`control-bar ${this.props.fullScreen ? "hide" : ""}`}>
          <div
            className={`agent-options ${endAllChats ? "end-all-chats" : ""}`}
          >
            {userLogo}
          </div>
          {this.renderLinks()}
          <div className="notifications"></div>
          <a
            className={`nubo-trigger`}
            onClick={() => this.clickOnShowUpdates()}
          >
            <div className="bell-icon"></div>
          </a>
          <div className="languages">
            <SelectDropdown
              options={[
                {
                  text: "Español",
                  onSelect: () => this.changeLanguage("es"),
                },
                {
                  text: "English",
                  onSelect: () => this.changeLanguage("en"),
                },
                {
                  text: "Português",
                  onSelect: () => this.changeLanguage("pt"),
                },
              ]}
              onSelect={(opt) => opt.onSelect()}
              display={(opt) => {
                return <div>{opt.text}</div>;
              }}
              direction="right-up"
              triggerComponent={<div className="icon icon--language" />}
            />
          </div>
        </div>
        <div
          className={`main-content ${
            this.props.fullScreen ? "full-screen" : ""
          }`}
        >
          <div
            className={`navbar-mobile ${
              !this.state.showNavbarOnMobile ? "hide" : ""
            }`}
          >
            <div className="icon icon--treble size-28"></div>
            <p className="treble-label">treble.ai</p>
            <div className="agent-options">{userLogo}</div>
          </div>
          {this.selectModal(this.state.selectedModal)}

          <Route path="/admin/chats" render={(props) => <Chats />} />
          <Route path="/admin/chat-stats" render={(props) => <ChatStats />} />
          <Route path="/admin/metrics" render={(props) => <Metrics />} />
          <Route path="/admin/agent-stats" render={(props) => <AgentStats />} />
          <Route path="/admin/snippets" render={(props) => <Snippets />} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.mainReducer.user,
  active: state.mainReducer.active,
  language: state.mainReducer.language,
  isFetching: state.mainReducer.isFetching || state.adminReducer.isFetching,
  errorVisible: state.mainReducer.errorVisible,
  errorMessage: state.mainReducer.errorMessage,
  teams: state.adminReducer.teams,
  loginHelpdesk: state.mainReducer.loginHelpdesk,
  showModals: state.mainReducer.showModals,
  chats: state.mainReducer.chats,
  fullScreen: state.mainReducer.fullScreen,
  helpdesks: state.mainReducer.helpdesks,
});

const mapDispatchToProps = (dispatch) => ({
  changeActivation: (newState) => dispatch(changeActivation(newState)),
  changeLanguage: (newLanguage) => dispatch(changeLanguage(newLanguage)),
  agentLogout: () => dispatch(agentLogout()),
  subscribeMessage: () => dispatch(subscribeMessage()),
  subscribeConversation: () => dispatch(subscribeConversation()),
  socketOnConnect: () => dispatch(socketOnConnect()),
  sendMessage: (name, message) => dispatch(sendMessage(name, message)),
  crashUpdateError: (msg, loginHelpdesk) =>
    dispatch(crashUpdateError(msg, loginHelpdesk)),
  getTeams: () => dispatch(getTeams()),
  clearError: () => dispatch(clearError()),
  lastOnline: () => dispatch(lastOnline()),
  resetShowModals: () => dispatch(resetShowModals()),
  endConversation: (conversationIds, extra) =>
    dispatch(endConversation(conversationIds, extra)),
  setFullScreen: (value) => dispatch(setFullScreen(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
