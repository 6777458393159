module.exports = {
  es: {
    pendingChats: "Chats pendientes por asignar",
    firstInteraction: "Primera interacción (Chat)",
    firstResponse: "Primera respuesta (Vendedor)",
    chatResponse: "Respuesta durante el chat",
    agentCompletion: "Finalización por vendedor",
    chatResolution: "Resolución del chat",
    days: "Días",
    weeks: "Semanas",
    months: "Meses",
    pendingChatsAvg: "Promedio chats pendientes por asignar",
    noInteractionAvg: "Promedio chat asignado (Sin interacción)",
    firstResponseAvg: "Promedio primera respuesta (Vendedor)",
    chatResponseAvg: "Promedio respuesta durante el chat",
    agentCompletionAvg: "Promedio finalización por vendedor",
    chatResolutionAvg: "Promedio resolución del chat",
    date: "Fecha",
  },
  en: {
    pendingChats: "Pending chats to be assigned",
    firstInteraction: "First interaction (Chat)",
    firstResponse: "First response (Salesperson)",
    chatResponse: "Reply during chat",
    agentCompletion: "Salesperson completion",
    chatResolution: "Chat resolution",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    pendingChatsAvg: "Pending chats to be assigned average",
    noInteractionAvg: "Assigned chat (No interaction) average",
    firstResponseAvg: "First response (Salesperson) average",
    chatResponseAvg: "Reply during chat average",
    agentCompletionAvg: "Salesperson completion average",
    chatResolutionAvg: "Chat resolution average",
    date: "Date",
  },
  pt: {
    pendingChats: "Bate-papos pendentes a serem atribuídos",
    firstInteraction: "Primeira interação (Chat)",
    firstResponse: "Primeira resposta (vendedor)",
    chatResponse: "Responder durante o bate-papo",
    agentCompletion: "Conclusão do vendedor",
    chatResolution: "Resolução de bate-papo",
    days: "Dias",
    weeks: "Semanas",
    months: "Meses",
    pendingChatsAvg: "Chats pendentes a serem atribuídos em média",
    noInteractionAvg: "Média de bate-papo atribuído (sem interação)",
    firstResponseAvg: "Média da primeira resposta (vendedor)",
    chatResponseAvg: "Resposta durante a média do chat",
    agentCompletionAvg: "Média de conclusão do vendedor",
    chatResolutionAvg: "Média de resolução de bate-papo",
    date: "Encontro",
  },
};
