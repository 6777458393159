import React, { Component } from "react";
import { connect } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
} from "recharts";

import Loading from "../Loading/Loading";
import Empty from "../Empty/Empty";

import {
  RESPONSE_TIME,
  timeGroupDays,
  timeGroupWeeks,
  timeGroupMonths,
} from "../../../../../../reducers/metricsReducer";

import languages from "./language";
import events from "../../../../../../utils/events";

import Dropdown from "../../../../../../components/Dropdowns/Dropdown";
import MultipleSelectDropdown from "../../../../../Platform/Metrics/Components/MultipleSelectDropdown/MultipleSelectDropdown";
import SelectDropdown from "../../../../../Platform/Metrics/Components/SelectDropdown/SelectDropdown";
import TrebleIcon from "../../../../../../components/TrebleIcon/TrebleIcon";

import { CSVLink } from "react-csv";
import moment from "moment";

import "./ResponseTime.scss";

const mapLanguageToMomentLocale = {
  es: "es",
  en: "en",
  pt: "pt-br",
};

class CustomizedActiveDot extends React.Component {
  render() {
    const { cx, cy, color, currentChart, hoveredChart, handleMouseLeave } =
      this.props;

    if (currentChart == hoveredChart) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r={6}
          stroke={color}
          strokeWidth={6}
          fill={"#FFF"}
          onMouseLeave={(e) => {
            handleMouseLeave();
          }}
        />
      );
    } else {
      return null;
    }
  }
}
class CustomizedDot extends React.Component {
  render() {
    const { cx, cy, renderPoint, color, handleMouseOver, handleMouseLeave } =
      this.props;

    if (renderPoint) {
      return (
        <circle
          cx={cx}
          cy={cy}
          r={4}
          stroke={color}
          fill={color}
          onMouseOver={(e) => {
            handleMouseOver();
          }}
        />
      );
    } else {
      return null;
    }
  }
}
class CustomTooltip extends React.Component {
  parseKeyToIndex = (key, prefix = null, sufix = null) => {
    let parts = key.split("_");
    let parsedString = "";

    parts.map((part, i) => {
      if (i == 0) {
        parsedString = parsedString + part;
      } else {
        parsedString =
          parsedString + part.charAt(0).toUpperCase() + part.slice(1);
      }
    });

    parsedString = prefix ? prefix + parsedString : parsedString;
    parsedString = sufix ? parsedString + sufix : parsedString;

    return parsedString;
  };

  convertToHourMinSec = (mins) => {
    let newValue = "";
    let hours = 0;
    let min = 0;
    let valueSplitted = mins.toString().split(".");
    let secs = valueSplitted[1] ? valueSplitted[1] : 0;
    if (mins > 60) {
      hours = Math.floor(mins / 60);
      min = (mins - hours * 60).toFixed(0);
      newValue = `${hours} hours ${min} mins ${secs} secs`;
    } else {
      newValue = `${valueSplitted[0]} mins ${secs} secs`;
    }

    return newValue;
  };

  render() {
    const { active, payload, label, strings, isHovered } = this.props;

    if (active && payload && payload.length && isHovered) {
      let value = 0;
      let name = "";

      payload.map((item) => {
        if (item.stroke != null) {
          value = item.value;
          name = strings[this.parseKeyToIndex(item.name, null, "Avg")];
        }
      });

      return (
        <div className="custom-tooltip">
          <div className="tooltip-header row">
            <TrebleIcon name="clock" />
            <p className="label">{`${moment(label, "YYYY-MM-DD").format(
              "DD-MM-YYYY",
            )}`}</p>
          </div>
          <div className="tooltip-body">
            <span>{name}</span>
            <span className="value">{this.convertToHourMinSec(value)}</span>
          </div>
        </div>
      );
    }
    return null;
  }
}
class ResponseTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeGroup: timeGroupDays,
      hoveredLine: null,
      isHovered: false,
      allowedCharts: {},
      leftInformationLine: {},
    };
    this.strings = languages[props.language];
    this.headerItems = [
      { text: this.strings["pendingChats"], color: "#FF4668" },
      { text: this.strings["firstInteraction"], color: "#6464FF" },
      { text: this.strings["firstResponse"], color: "#B11EE4" },
      { text: this.strings["agentCompletion"], color: "#C6C936" },
      { text: this.strings["chatResolution"], color: "#5AB978" },
    ];
  }

  componentDidMount = () => {
    let _allowedCharts = {};
    this.headerItems.map((item) => {
      _allowedCharts[item.text] = true;
    });

    this.setState({
      allowedCharts: _allowedCharts,
      leftInformationLine: { text: this.headerItems[0].text, index: 0 },
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.timeGroup !== this.props.timeGroup) {
      this.setState({ timeGroup: this.props.timeGroup });
    }
  }

  getSelectedCharts = () => {
    let selectedCharts = [];

    Object.keys(this.state.allowedCharts).map((key, i) => {
      if (this.state.allowedCharts[key]) {
        selectedCharts.push(i);
      }
    });

    return selectedCharts;
  };

  onSelectChart = (options) => {
    let _allowedCharts = { ...this.state.allowedCharts };

    options.map((opt, i) => {
      let keys = Object.keys(this.state.allowedCharts);
      if (typeof opt == "string") {
        _allowedCharts[keys[parseInt(opt)]] =
          !_allowedCharts[keys[parseInt(opt)]];
      }
    });

    this.setState({
      allowedCharts: _allowedCharts,
    });
  };

  getDownloadData = () => {
    let { data } = this.props.data;

    if (!data) {
      return [];
    }

    let headers = this.headerItems.map((item) => item.text);
    headers = [this.strings.date, ...headers];
    return [headers, ...this.props.downloadData];
  };

  renderHeader = () => {
    let totalHourData = this.props.data.hour_data;

    if (!totalHourData) {
      return null;
    }

    let totalHourDataKeys = Object.keys(totalHourData);

    return (
      <div className="response-time-header">
        <div className="header-container">
          <div className="header-info">
            {this.headerItems.map((header, i) => {
              let isEnabled =
                (!this.state.isHovered ||
                  (this.state.isHovered && this.state.hoveredLine == i)) &&
                this.state.allowedCharts[header.text];
              let totalHour = totalHourData[totalHourDataKeys[i]].split(":");
              let hasLeftMargin = i != 0;
              return (
                <div
                  className={`header-info-item ${
                    hasLeftMargin ? "left-margin" : ""
                  } ${isEnabled ? "" : "disable"}`}
                >
                  <span>{header.text}</span>
                  <p>
                    {totalHour[0]} hrs {totalHour[1] ? totalHour[1] : 0} min
                  </p>
                  <hr
                    style={{
                      backgroundColor: isEnabled ? header.color : "#B2B2B2",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="header-actions">
            <MultipleSelectDropdown
              options={this.headerItems.map((graph, i) => ({
                label: graph.text,
                value: i,
              }))}
              _key="conversation-filter"
              onSelect={(option) => this.onSelectChart(option)}
              value={this.getSelectedCharts()}
            >
              <div className="icon icon--filters" />
            </MultipleSelectDropdown>

            <CSVLink
              data={this.getDownloadData()}
              filename={`${"response-time"}-${moment().format(
                "YYYY-MM-DD",
              )}.csv`}
            >
              <div className="icon icon--download" />
            </CSVLink>
          </div>
        </div>
      </div>
    );
  };

  formatYaxisTick = (tick) => {
    let newTick = "";
    if (tick == 0) {
      newTick = "00 min";
    } else if (tick > 60) {
      newTick = `${Math.floor(tick / 60)} hours`;
    } else {
      newTick = `${tick} min`;
    }
    return newTick;
  };

  renderLines = () => {
    let data = this.props.data.data;
    if (!data) {
      return [];
    }
    let lines = [];
    this.headerItems.map((item, i) => {
      if (this.state.allowedCharts[item.text]) {
        lines.push(
          <Line
            dx={40}
            dataKey={data[0] ? Object.keys(data[0])[i + 1] : ""}
            stroke={
              this.state.hoveredLine != i && this.state.isHovered
                ? null
                : item.color
            }
            strokeWidth={1}
            onMouseOver={(e) => {
              this.setState({ hoveredLine: i, isHovered: true });
            }}
            activeDot={
              <CustomizedActiveDot
                currentChart={i}
                color={item.color}
                handleMouseLeave={() =>
                  this.setState({
                    hoveredLine: null,
                    isHovered: false,
                  })
                }
                hoveredChart={this.state.hoveredLine}
              />
            }
            dot={
              <CustomizedDot
                handleMouseOver={() => {
                  this.setState({ hoveredLine: i, isHovered: true });
                }}
                renderPoint={data.length > 1 ? false : true}
                color={item.color}
                handleMouseLeave={() =>
                  this.setState({
                    hoveredLine: null,
                    isHovered: false,
                  })
                }
              />
            }
          />,
        );
      }
    });
    return lines;
  };

  changeTimeGroup = (timeGroup) => {
    this.setState({ timeGroup });
    this.props.getResponseTimeData(timeGroup);
    events.track("Agent changed metrics time unit", {
      "Metric type": RESPONSE_TIME,
      "Time unit": timeGroup,
    });
  };

  changeLeftInformation = (index) => {
    this.setState({
      leftInformationLine: { text: this.headerItems[index].text, index },
    });
  };

  formatDateTick = (tick) => {
    const timeGroup = this.state.timeGroup;

    let localeMoment = moment();
    localeMoment.locale(mapLanguageToMomentLocale[this.props.language]);

    const parsedDate = moment(tick, "YYYY-MM-DD", true);

    if (!parsedDate.isValid()) {
      return tick;
    }

    if (timeGroup === timeGroupDays || timeGroup === null) {
      return parsedDate.format("MMM DD");
    } else if (timeGroup === timeGroupWeeks) {
      const startOfWeek = parsedDate.clone().startOf("week");
      const endOfWeek = parsedDate.clone().endOf("week");
      return `${startOfWeek.format("MMM DD")} - ${endOfWeek.format("MMM DD")}`;
    } else if (timeGroup === timeGroupMonths) {
      return parsedDate.format("MMMM");
    }
  };

  renderBody = () => {
    const infoItems = [
      "- 15 min",
      "15 - 30 min",
      "30 - 60 min",
      "1 - 2 hrs",
      "2 - 3 hrs",
      "+ 03 hrs",
    ];

    let allData = this.props.data;
    if (!allData.data) {
      return;
    }

    let leftDataKey = Object.keys(allData.percent_data)[
      this.state.leftInformationLine.index
    ];
    let leftData = allData.percent_data[leftDataKey];
    let selectedLeftInformation = this.state.leftInformationLine;
    let data = allData.data.map((dp) => {
      let newDp = {};
      Object.keys(dp).forEach((key) => {
        if (key !== "date") {
          let dpSplit = dp[key].split(":");
          let minutes = parseInt(dpSplit[0]) * 60 + parseInt(dpSplit[1]);
          let seconds = parseInt(dpSplit[2]);
          newDp[key] = parseFloat(`${minutes}.${seconds}`);
        } else {
          newDp[key] = dp[key];
        }
      });
      return newDp;
    });
    let width = data.length <= 8 ? 850 : data.length * 150;

    return (
      <div className="response-time-body">
        <div className="response-time-info">
          <div className="info-card">
            <div className="info-filter-dropdown">
              <Dropdown
                title={
                  <span className="text">{selectedLeftInformation.text}</span>
                }
                iconRow="dropdown-row"
                hideOnClick={true}
              >
                <ul>
                  {this.headerItems.map((option, index) => {
                    return (
                      <li
                        onClick={() => this.changeLeftInformation(index)}
                        className="dropdown-item"
                      >
                        <div className="text-item">{option.text}</div>
                        {index == selectedLeftInformation.index && (
                          <div className="icon icon--sent"></div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Dropdown>
            </div>
            <div className="info-items">
              {infoItems.map((item) => {
                return (
                  <div className="item">
                    <span className="left-item">{item}</span>
                    <span className="margin">
                      {leftData ? leftData[item].percent.toFixed(2) : 0}%
                    </span>
                    <span className="margin">
                      {leftData ? leftData[item].total : 0}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="response-time-chart">
          <div className="chart-options">
            <SelectDropdown
              options={[
                { label: this.strings.days, value: timeGroupDays },
                { label: this.strings.weeks, value: timeGroupWeeks },
                { label: this.strings.months, value: timeGroupMonths },
              ]}
              _key="satisfaction-time-group-filter"
              placement="bottomRight"
              placeholder={this.strings.days}
              onSelect={this.changeTimeGroup}
              value={this.state.timeGroup}
            />
          </div>
          <div className="chart-body">
            <LineChart
              width={width}
              height={400}
              data={data}
              margin={{ top: 30, right: 0, left: 10, bottom: 25 }}
              onMouseMove={(state) => {
                if (!state.isTooltipActive) {
                  this.setState({
                    hoveredLine: null,
                    isHovered: false,
                  });
                }
              }}
            >
              <CartesianGrid strokeDasharray="100 0" vertical={false} />
              <XAxis
                dataKey="date"
                dy={24}
                padding={{ left: 76, right: 76 }}
                axisLine={{ stroke: "#BFBFBF" }}
                tickLine={false}
                tickFormatter={this.formatDateTick}
              />
              <YAxis
                axisLine={false}
                type="number"
                tickFormatter={this.formatYaxisTick}
                tick={{
                  fontSize: "12px",
                  fontWeight: "500",
                  fontFamily: "HK Grotesk",
                  strokeWidth: "50",
                }}
                tickLine={false}
                width={83}
              />
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    hoveredChart={this.state.hoveredChart}
                    strings={this.strings}
                    isHovered={this.state.isHovered}
                  />
                }
              />
              {this.renderLines()}
            </LineChart>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.loading && this.props.data.data?.length == 0) {
      return <Empty name={RESPONSE_TIME} />;
    }
    const content = (
      <>
        {this.renderHeader()} {this.renderBody()}
      </>
    );

    return (
      <div className="graph" id="response-time" key={RESPONSE_TIME}>
        {this.props.loading ? <Loading /> : content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  data: state.metricsReducer.data[RESPONSE_TIME].data,
  extra: state.metricsReducer.data[RESPONSE_TIME].extra,
  timeGroup: state.metricsReducer.data[RESPONSE_TIME].timeGroup,
  loading: state.metricsReducer.data[RESPONSE_TIME].loading,
  downloadData: state.metricsReducer.data[RESPONSE_TIME].downloadData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseTime);
