module.exports = {
  es: {
    satisfaction: "Encuesta de satisfacción",
    clickTooltip:
      "Haz “Click” en cualquier barra para ver información específica de ese periodo de tiempo ⏰",
    csvFilename: "treble-reporte-calificacion",
    days: "Días",
    weeks: "Semanas",
    months: "Meses",
    tags: "Equipo",
    agents: "Vendedor",
    labels: "Etiqueta",
    date: "Fecha",
    rating: "Calificación",
    averageRating: "Calificación promedio",
    inComparison30Days: "En comparación con los últimos 30 días",
    week: "Semana",
    inComparisondays: "En comparación con el día anterior",
    inComparisonweeks: "En comparación con la semana anterior",
    inComparisonmonths: "En comparación con el mes anterior",
    selectionBanner:
      "Actualmente seleccionaste un periodo de tiempo en la gráfica, da clic en la “equis” para volver a visualizar la información general.",
  },
  en: {
    satisfaction: "Satisfaction survey",
    clickTooltip:
      "“Click” on any bar to see specific information for that period of time ⏰",
    csvFilename: "treble-exports-rating",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    tags: "Team",
    agents: "Salesperson",
    labels: "Label",
    date: "Date",
    rating: "Rating",
    averageRating: "Average rating",
    inComparison30Days: "Compared to the last 30 days",
    week: "Week",
    inComparisondays: "Compared to the day before",
    inComparisonweeks: "Compared to the previous week",
    inComparisonmonths: "Compared to the previous month",
    selectionBanner:
      "You currently selected a time period on the graph, click on the “x” to return to display the general information",
  },
  pt: {
    satisfaction: "Pesquisa de satisfação",
    clickTooltip:
      "“Clique” em qualquer barra para ver informações específicas para esse período de tempo ⏰",
    csvFilename: "treble-relatorio-pontuação",
    days: "Dias",
    weeks: "Semanas",
    months: "Meses",
    tags: "Equipe",
    agents: "Vendedor",
    labels: "Etiqueta",
    date: "Data",
    rating: "Avaliação",
    averageRating: "Avaliação média",
    inComparison30Days: "Comparado com os últimos 30 dias",
    week: "Semana",
    inComparisondays: "Em comparação com o dia anterior",
    inComparisonweeks: "Em comparação com a semana anterior",
    inComparisonmonths: "Em comparação com o mês anterior",
    selectionBanner:
      "Você selecionou atualmente um período de tempo no gráfico, clique no “x” para retornar para exibir as informações gerais.",
  },
};
