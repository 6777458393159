import React, { Component } from "react";
import TextInput from "../TextInput/TextInput";

import "./SearchBar.scss";

export class SearchBar extends Component {
  /**
   *
   * @param {*} props
   * placeholder: string (placeholder of text input)
   * value: string (current value)
   * onChange: function
   * trackMessage: string
   * onClick: string
   * cancelIcon
   */

  constructor(props) {
    super(props);
  }
  renderCancelSearch = () => {
    if (
      this.props.showCancel ||
      (!this.props.showCancel && this.props.value.length)
    ) {
      return (
        <div
          className={`icon icon--${
            this.props.cancelIcon ? this.props.cancelIcon : "search-cancel"
          }`}
          onClick={() => this.props.onClick()}
        />
      );
    }
    return "";
  };
  render() {
    return (
      <div className="searchbar">
        <div className="icon icon--search" />
        <TextInput
          className=""
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          trackMessage={this.props.trackMessage}
        />
        {this.renderCancelSearch()}
      </div>
    );
  }
}

export default SearchBar;
