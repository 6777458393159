module.exports = {
  es: {
    emptyTitle: "Métricas relacionadas a “[GRAPH_KEY]” insuficientes",
    emptyDescription:
      "Aún no existe información suficiente para mostrar datos relacionados a estas métricas. Continua conversando con tus clientes para comenzar a medir los resultados obtenidos",
    conversationCount: "Número de conversaciones",
    responseTime: "Tiempos de respuesta",
    satisfaction: "Encuesta de satisfacción",
    conversationHour: "Distribución de conversaciones",
  },
  en: {
    emptyTitle: "Metrics related to “[GRAPH_KEY]” insufficient",
    emptyDescription:
      "There is not yet enough information to show data related to these metrics. Continue talking with your customers to start measuring the results obtained",
    conversationCount: "Number of conversations",
    responseTime: "Response times",
    satisfaction: "Satisfaction survey",
    conversationHour: "Conversation distribution",
  },
  pt: {
    emptyTitle: "Métricas relacionadas a “[GRAPH_KEY]” insuficientes",
    emptyDescription:
      "Ainda não há informações suficientes para mostrar dados relacionados a essas métricas. Continue conversando com seus clientes para começar a medir os resultados obtidos",
    conversationCount: "Número de conversas",
    responseTime: "Tempos de resposta",
    satisfaction: "Pesquisa de satisfação",
    conversationHour: "Distribuição de conversas",
  },
};
