import React from "react";

import "./InlineAlert.scss";

/*
	props:
    - text str: alert text
    - type str: alert type
    - show bool: show or not
*/

const InlineAlert = (props) => {
    if (!props.show){
        return <></>
    }
    return (
    <div className="treble inline-alert">
        <div className={`icon icon--${props.type}`} />
        <p>{props.text}</p>
    </div>
    );
};

export default InlineAlert;