import { request } from "../utils/request";
import events from "../utils/events";

const REQUEST_SNIPPETS = "REQUEST_SNIPPETS";
export const requestSnippets = () => (dispatch) => {
  dispatch({
    type: REQUEST_SNIPPETS,
  });
};

const ADMIN_RECEIVE_SNIPPETS = "ADMIN_RECEIVE_SNIPPETS";
export const adminReceiveSnippets = (snippets) => (dispatch) => {
  dispatch({
    type: ADMIN_RECEIVE_SNIPPETS,
    snippets,
  });
};

const AGENT_RECEIVE_SNIPPETS = "AGENT_RECEIVE_SNIPPETS";
export const agentReceiveSnippets =
  (snippets, agent_snippets) => (dispatch) => {
    dispatch({
      type: AGENT_RECEIVE_SNIPPETS,
      snippets,
      agent_snippets,
    });
  };

const AGENT_UPDATE_SNIPPETS = "AGENT_UPDATE_SNIPPETS";
export const agentUpdateSnippets = (agent_snippets) => (dispatch) => {
  dispatch({
    type: AGENT_UPDATE_SNIPPETS,
    agent_snippets,
  });
};

const CREATE_SNIPPET = "CREATE_SNIPPET";
export const createSnippet = (snippet, tag_names) => (dispatch) => {
  dispatch({
    type: CREATE_SNIPPET,
    snippet,
    tag_names,
  });
};

const UPDATE_SNIPPET = "UPDATE_SNIPPET";
export const updateSnippet = (snippet, tag_names) => (dispatch) => {
  dispatch({
    type: UPDATE_SNIPPET,
    snippet,
    tag_names,
  });
};

const DELETE_SNIPPET = "DELETE_SNIPPET";
export const deleteSnippet = (snippet) => (dispatch) => {
  dispatch({
    type: DELETE_SNIPPET,
    snippet,
  });
};

const SNIPPETS_ERROR = "SNIPPETS_ERROR";
const snippetsError = (error) => (dispatch) => {
  dispatch({
    type: SNIPPETS_ERROR,
    error,
  });
};

export const adminGetSnippets = () => (dispatch) => {
  dispatch(requestSnippets());

  request(
    "GET",
    "/admin/snippet",
    null,
    true,
    (data) => {
      const snippets = data.snippets;
      return dispatch(adminReceiveSnippets(snippets));
    },
    (error) => {
      console.log(error);
      return dispatch(snippetsError("Unable to get snippets"));
    },
  );
};

export const adminCreateSnippet =
  (snippet, snippetToast, duplicate = false) =>
  (dispatch) => {
    dispatch(requestSnippets());

    const params = {
      name: snippet.name,
      text: snippet.text,
      tag_names: snippet.tags,
    };

    request(
      "POST",
      "/admin/snippet",
      params,
      true,
      (data) => {
        snippetToast();
        events.track(`Agent ${duplicate ? "duplicate" : "create"} snippet`, {
          snippet,
        });
        return dispatch(createSnippet(data.snippet, data.tag_names));
      },
      (error) => {
        console.log(error);
        return dispatch(snippetsError("Unable to create snippet"));
      },
    );
  };

export const adminUpdateSnippet =
  (snippet, snippetToast, oldSnippet) => (dispatch) => {
    dispatch(requestSnippets());

    const params = {
      name: snippet.name,
      text: snippet.text,
      tag_names: snippet.tags,
    };

    request(
      "PUT",
      `/admin/snippet/${snippet.id}`,
      params,
      true,
      (data) => {
        snippetToast();
        events.track("Agent Edit snippet", {
          snippet,
          "old snippet": oldSnippet,
        });
        return dispatch(updateSnippet(data.snippet, data.tag_names));
      },
      (error) => {
        console.log(error);
        return dispatch(snippetsError("Unable to udpate snippet"));
      },
    );
  };

export const adminDeleteSnippet = (snippet, snippetToast) => (dispatch) => {
  dispatch(requestSnippets());

  request(
    "DELETE",
    `/admin/snippet/${snippet.id}`,
    null,
    true,
    (data) => {
      snippetToast();
      events.track("Agent deleted a snippet", {
        snippet,
      });
      return dispatch(deleteSnippet(data.snippet));
    },
    (error) => {
      console.log(error);
      return dispatch(snippetsError("Unable to delete snippet"));
    },
  );
};

export const agentGetSnippets = () => (dispatch) => {
  dispatch(requestSnippets());

  request(
    "GET",
    `/agent/snippet`,
    null,
    true,
    (data) => {
      const snippets = data.snippets;
      const agent_snippets = data.agent_snippets;
      return dispatch(agentReceiveSnippets(snippets, agent_snippets));
    },
    (error) => {
      console.log(error);
      return dispatch(snippetsError("Unable to get snippets"));
    },
  );
};

export const agentPushSnippet = (snippet) => (dispatch) => {
  dispatch(requestSnippets());

  const params = {
    snippet_id: snippet.id,
  };

  request(
    "PATCH",
    `/agent/snippet/pinup`,
    params,
    true,
    (data) => {
      events.track("Agent snippet pinned", {
        "Snippet id": snippet.id,
        State: false,
      });
      const agent_snippets = data.agent_snippets;
      return dispatch(agentUpdateSnippets(agent_snippets));
    },
    (error) => {
      console.log(error);
      return dispatch(snippetsError("Unable to push snippet"));
    },
  );
};

export const agentRemoveSnippet = (snippet) => (dispatch) => {
  dispatch(requestSnippets());

  request(
    "DELETE",
    `/agent/snippet/${snippet.id}`,
    null,
    true,
    (data) => {
      events.track("Agent snippet pinned", {
        "Snippet id": snippet.id,
        State: true,
      });
      const agent_snippets = data.agent_snippets;
      return dispatch(agentUpdateSnippets(agent_snippets));
    },
    (error) => {
      console.log(error);
      return dispatch(snippetsError("Unable to remove snippet"));
    },
  );
};
