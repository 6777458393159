module.exports = {
  es: {
    receivedChats: "Chats recibidos",
    distinctContacts: "Contactos Distintos",
    transferredConvs: "Conversaciones Transferidas",
    supportChannel: "Canal soporte",
    salesChannel: "Canal ventas",
    marketingChannel: "Canal marketing",
    totalReceivedChats: "Total chats recibidos",
    totalDistinctContacts: "Total Contactos distintos",
    totalTransferConvs: "Total Conversaciones transferidas",
    days: "Días",
    weeks: "Semanas",
    months: "Meses",
    channel: "Canal",
    total: "Total",
    average: "Promedio",
    week: "Semana",
    date: "Fecha",
  },
  en: {
    receivedChats: "Received chats",
    distinctContacts: "Distinct contacts",
    transferredConvs: "Transferred conversations",
    supportChannel: "Support channel",
    salesChannel: "Sales channel",
    marketingChannel: "Marketing channel",
    totalReceivedChats: "Total chats received",
    totalDistinctContacts: "Total distinct contacts",
    totalTransferConvs: "Total Transferred conversations",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    channel: "Channel",
    total: "Total",
    average: "Average",
    week: "Week",
    date: "Date",
  },
  pt: {
    receivedChats: "Bate-papos recebidos",
    distinctContacts: "Contatos distintos",
    transferredConvs: "Conversas transferidas",
    supportChannel: "Canal de suporte",
    salesChannel: "Canal de vendas",
    marketingChannel: "Canal de marketing",
    totalReceivedChats: "Total de bate-papos recebidos",
    totalDistinctContacts: "Total de contatos distintos",
    totalTransferConvs: "Total de conversas transferidas",
    days: "Dias",
    weeks: "Semanas",
    months: "Meses",
    channel: "Canal",
    total: "Total",
    average: "Média",
    week: "Semana",
    date: "Encontro",
  },
};
