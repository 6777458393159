import React from "react";
import Modal from "../../../../components/Modal/ModalV2";
import ReactPlayer from "react-player";
import SelectDropdown from "../../../../components/SelectDropdown/SelectDropdown";
import TextInput from "../../../../components/TextInput/TextInput";

import { formatText } from "../../../../utils/WhatsappMarkdown";

import "./HSMModal.scss";

class HSMModal extends React.Component {
  constructor(props) {
    super(props);

    let initialState = {
      content: props.hsm.content,
      answers: props.hsm.answers,
      header: props.hsm.header,
      footer: props.hsm.footer,
      buttons: props.hsm.buttons,
      channel: null,
    };
    props.hsm.parameters.map((p) => {
      initialState[`${p}Input`] = "";
    });
    this.state = initialState;
  }

  handleParameterChange = (param, value) => {
    this.setState({
      [`${param}Input`]: value,
    });
  };

  renderVariables = () => {
    return this.props.hsm.parameters.map((p) => (
      <div className="form-group" key={p}>
        <h6>{`{{${p}}}`}</h6>
        <TextInput
          className={`parameter-input`}
          type="text"
          placeholder={this.props.strings.variable}
          value={this.state[`${p}Input`] ? this.state[`${p}Input`] : ""}
          onChange={(e) => {
            this.handleParameterChange(p, e.target.value);
          }}
        />
      </div>
    ));
  };

  renderHeader = () => {
    if (Object.keys(this.state.header).length > 0) {
      switch (this.state.header.type) {
        case "text":
          return <p>{this.formatText(this.state.header.text)}</p>;
        case "image":
          return <img src={this.state.header.url} />;
        case "video":
          return (
            <ReactPlayer
              url={this.state.header.url}
              width={400}
              height={200}
              controls
            />
          );
        case "document":
          return (
            <div className="document">
              <div className="clip-icon" />
              <a href={this.state.header.url} target="_blank">
                {this.state.header.url}
              </a>
            </div>
          );
        default:
          return null;
      }
    }
    return null;
  };

  renderBody = () => {
    if (
      Object.keys(this.state.buttons).length > 0 ||
      this.state.answers.length === 0
    ) {
      return <p>{formatText(this.formatText(this.state.content))}</p>;
    } else if (this.state.answers.length > 0) {
      let text = this.state.content;
      this.state.answers.forEach((a, idx) => (text += `\n${idx + 1}) ${a}`));
      return <p>{formatText(this.formatText(text))}</p>;
    }
  };

  renderFooter = () => {
    if (Object.keys(this.state.footer).length > 0) {
      return <p>{formatText(this.state.footer.text)}</p>;
    }
    return null;
  };

  renderButtons = () => {
    if (Object.keys(this.state.buttons).length > 0) {
      if (this.state.buttons.type === "call_to_action") {
        return (
          <div className="buttons">
            {this.state.buttons.options.map((opt) => {
              if (opt.url) {
                return (
                  <a href={this.formatText(opt.url)} target="_blank">
                    {opt.text}
                  </a>
                );
              } else {
                return (
                  <p>
                    {opt.text}-{opt.phone_number}
                  </p>
                );
              }
            })}
          </div>
        );
      } else if (this.state.buttons.type === "quick_reply") {
        return (
          <div className="buttons">
            {this.state.buttons.options.map((opt) => {
              if (opt.text !== "DEFAULT") {
                return <p>{opt.text}</p>;
              } else {
                return null;
              }
            })}
          </div>
        );
      }
    }
    return null;
  };

  renderContent = () => {
    return (
      <div className="hsm-preview">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
        {this.renderButtons()}
      </div>
    );
  };

  formatText = (text) => {
    for (let index = 0; index < this.props.hsm.parameters.length; index++) {
      const param = this.props.hsm.parameters[index];
      const paramValue = this.state[`${param}Input`];
      if (paramValue != "") {
        text = text.replace(`{{${param}}}`, paramValue);
      }
    }
    return text;
  };

  formatSection = (section, object) => {
    switch (section) {
      case "header":
        if (object.type === "text") {
          object.text = this.formatText(object.text);
        }
        break;
      case "content":
        if (this.state.answers.length > 0) {
          this.state.answers.forEach(
            (a, idx) => (object += `\n${idx + 1}) ${a}`),
          );
        }
        object = this.formatText(object);
        break;
      case "footer":
        if (object.type === "text") {
          object.text = this.formatText(object.text);
        }
        break;
      case "buttons":
        if (object.type === "call_to_action") {
          let options = [];
          object.options.forEach((opt) => {
            if (opt.url) {
              options.push({ url: this.formatText(opt.url), text: opt.text });
            } else {
              options.push(opt);
            }
          });
          object.options = options;
        }
        break;
      default:
        break;
    }
    return object;
  };

  renderChannelSelection() {
    if (!this.props.isNewConversation || this.props.channels.length < 2) return;
    let display = this.state.channel
      ? `${this.props.strings.sentFrom} ${
          this.state.channel.name
        } +${this.props.displayChannels(this.state.channel.number)}`
      : this.props.strings.noNumber;
    return (
      <div className="channel">
        <h6>{this.props.strings.whatsappLine}</h6>
        <SelectDropdown
          options={this.props.channels}
          triggerComponent={
            <div className="channel-selector">
              <div className="icon icon--whatsapp" />
              <h6>{display}</h6>
            </div>
          }
          onSelect={(item) => this.setState({ channel: item })}
          display={(item) => {
            return (
              <div className="channel-option">
                <h6>{item.name}</h6>
                <p>{`+${this.props.displayChannels(item.number)}`}</p>
              </div>
            );
          }}
        />
      </div>
    );
  }

  renderModalBody = () => {
    return (
      <>
        {this.renderChannelSelection()}
        <div className="parameters">{this.renderVariables()}</div>
        <div className="preview">
          <h6>{this.props.strings.preview}</h6>
          <div>{this.renderContent()}</div>
        </div>
      </>
    );
  };

  sendHsm = () => {
    let outParameters = {};
    this.props.hsm.parameters.forEach((p) => {
      outParameters[p] = this.state[`${p}Input`];
    });
    if (this.state.channel) {
      outParameters["deployment_squad"] = `DS_${this.state.channel.number}`;
    }
    this.props.sendHSM(
      outParameters,
      this.formatSection("header", this.state.header),
      this.formatSection("content", this.state.content),
      this.formatSection("footer", this.state.footer),
      this.formatSection("buttons", this.state.buttons),
    );
    this.props.resetState();
  };

  renderModalFooter = () => {
    return (
      <div className="buttons">
        <div className="button cancel" onClick={() => this.props.resetState()}>
          {this.props.strings.cancel}
        </div>
        <div className="button send" onClick={() => this.sendHsm()}>
          {this.props.strings.sendHSM}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Modal
        wrappClass="hsm-modal"
        show={this.props.show}
        onClose={this.props.resetState}
        header={this.props.strings.sendHSM}
        body={this.renderModalBody()}
        footer={this.renderModalFooter()}
      />
    );
  }
}

export default HSMModal;
