import React, { Component } from "react";

import Modal from "../Modal/ModalV2";
import { downloadFile } from "../../utils/file";

import "./FileModal.scss";
import languages from "./languages";

export class FileModal extends Component {
  constructor(props) {
    super(props);
    this.strings = languages[this.props.language];
  }

  renderBody = () => {
    const { type, url, created_at } = this.props.selectedFile;
    let body;
    if (type === "image") {
      body = (
        <>
          <img src={url} />
          <div
            className="download"
            onClick={() => {
              downloadFile(
                url,
                "Agent files panel downloads a file",
                created_at,
              );
            }}
          >
            {this.strings.downloadImage}
            <div className="icon icon--download-image" />
          </div>
        </>
      );
    } else {
      body = <video src={url} controls />;
    }
    return body;
  };

  renderFooter = () => {
    return <button className="button">{this.strings.downloadImage}</button>;
  };

  render() {
    return (
      <Modal
        wrappClass="file-modal"
        body={this.renderBody()}
        footer={this.renderFooter()}
        show={this.props.show}
        closeIcon="modal-close"
        onClose={() => this.props.onClose()}
      />
    );
  }
}

export default FileModal;
