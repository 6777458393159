var parse = require("html-react-parser");

const WHATSAPP_FORMATS = [
  {
    htmlOp: "<b>",
    htmlEnd: "</b>",
    replaceCharacter: "*",
  },
  {
    htmlOp: "<i>",
    htmlEnd: "</i>",
    replaceCharacter: "_",
  },
  {
    htmlOp: "<s>",
    htmlEnd: "</s>",
    replaceCharacter: "~",
  },
  {
    htmlOp: "<tt>",
    htmlEnd: "</tt>",
    replaceCharacter: "```",
  },
];

export const formatText = (text) => {
  if (!text) return "";

  text = escapeHtml(text);

  for (let i = 0; i < WHATSAPP_FORMATS.length; i++) {
    const format = WHATSAPP_FORMATS[i];
    // Format special characters from WhatsApp markdown to HTML tags
    text = whatsappStyles(
      text,
      format.replaceCharacter,
      format.htmlOp,
      format.htmlEnd,
    );
  }

  return parse(text);
};

function escapeHtml(text) {
  var map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };

  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}

function is_aplhanumeric(c) {
  var x = c.charCodeAt();
  return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57)
    ? true
    : false;
}

function whatsappStyles(format, wildcard, opTag, clTag) {
  var indices = [];
  for (var i = 0; i < format.length; i++) {
    if (format[i] === wildcard) {
      if (indices.length % 2) {
        if (!(format[i - 1] == " ")) {
          if (typeof format[i + 1] == "undefined") {
            indices.push(i);
          } else if (!is_aplhanumeric(format[i + 1])) {
            indices.push(i);
          }
        }
      } else {
        if (!(typeof format[i + 1] == "undefined")) {
          if (!(format[i + 1] == " ")) {
            if (typeof format[i - 1] == "undefined") {
              indices.push(i);
            } else if (!is_aplhanumeric(format[i - 1])) {
              indices.push(i);
            }
          }
        }
      }
    } else {
      if (format[i].charCodeAt() == 10 && indices.length % 2) {
        indices.pop();
      }
    }
  }
  if (indices.length % 2) {
    indices.pop();
  }
  var e = 0;
  indices.forEach(function (v, i) {
    var t = i % 2 ? clTag : opTag;
    v += e;
    format = format.substr(0, v) + t + format.substr(v + 1);
    e += t.length - 1;
  });
  return format;
}
