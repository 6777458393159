import React, { Component } from "react";
import "./DetailsPanel.scss";

import {
  ChatHistory,
  CHAT_HISTORY,
  NOTE_HISTORY,
} from "../../../components/ChatHistory/ChatHistory";

import FileModal from "../../../components/FileModal/FileModal";

import events from "../../../utils/events";
class DetailsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: "HISTORY",
      showFileModal: false,
      selectedFile: {},
    };
  }

  changeView = (view) => {
    this.setState({
      activeView: view,
    });
  };

  showFileModal = (file) => {
    const category = file.category || file.type;
    this.setState({
      showFileModal: true,
      selectedFile: {
        url: file[category].url,
        type: category,
        created_at: file.created_at,
      },
    });
  };

  renderFileModal = () => {
    return (
      <FileModal
        language={this.props.language}
        show={this.state.showFileModal}
        selectedFile={this.state.selectedFile}
        onClose={() => this.setState({ showFileModal: false })}
      />
    );
  };

  render = () => (
    <div class="details-panel-container">
      <div class="tabs">
        <ul>
          <li
            onClick={() => this.changeView("HISTORY")}
            class={this.state.activeView == "HISTORY" ? "is-active" : ""}
          >
            <a>{this.props.strings.conversations}</a>
          </li>
          <li
            onClick={() => {
              events.track("Chats stats notes", {});
              this.changeView("NOTES");
            }}
            class={this.state.activeView == "NOTES" ? "is-active" : ""}
          >
            <a>{this.props.strings.notes}</a>
          </li>
        </ul>
      </div>
      <div className="panel-body">
        <div className="chat-main-window">
          <ChatHistory
            language={this.props.language}
            conversationId={
              this.props.conversationIds.length > 0
                ? this.props.conversationIds[0].id
                : null
            }
            chatEvents={this.props.chatEvents}
            notes={this.props.notes}
            getAddressFromCoordinates={this.props.getAddressFromCoordinates}
            sendingFile={false}
            readOnly={true}
            type={
              this.state.activeView === "HISTORY" ? CHAT_HISTORY : NOTE_HISTORY
            }
            showFileModal={this.showFileModal}
          />
        </div>
      </div>
      {this.renderFileModal()}
    </div>
  );
}

export default DetailsPanel;
