import React, { Component } from "react";

import languages from "./languages.js";
import moment from "moment";

import SearchableDropdown from "../../../../components/SearchableDropdown/SearchableDropdown";
import { Tooltip } from "antd";
import { formatText } from "../../../../utils/WhatsappMarkdown";

import "./Contact.scss";
import {
  ChatHistory,
  CHAT_HISTORY,
  NOTE_HISTORY,
} from "../../../../components/ChatHistory/ChatHistory";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      selectedSidePanelOption: null,
      fullScreen: false,
      selectedConversation: null,
      fullScreenView: "conversation",
    };

    this.strings = languages[props.language];
  }

  componentDidUpdate(prevProps) {
    if (this.props.contact.id !== prevProps.contact.id) {
      this.props.getContactEvents(this.props.contact.id);
    }
  }

  componentDidMount() {
    this.props.getContactEvents(this.props.contact.id);
  }
  getEventAtribute(event, type, atribute) {
    let display = this.strings[atribute + event[atribute]];
    let className = atribute;
    if (event[atribute] && event[atribute].toUpperCase().includes("HUBSPOT")) {
      className = "hubspot";
    } else if (atribute == "agent") {
      display = event.sender
        ? `${event.sender.email} ${
            event.sender.role
              ? `- ${this.strings[event.sender.role]}/${event.sender.tags[0]}`
              : ""
          }`
        : this.strings.noInfo;
    } else if (atribute == "extra") {
      if (type != "FAILURE") {
        display = this.strings[type + "des"];
      } else {
        display = this.strings[event.status];
      }
    } else if (atribute == "name") {
      display = event.name;
    }
    return <p className={className}>{display}</p>;
  }

  renderConversationAgents(event) {
    return (
      <>
        <div className="detail">
          <h6>{this.strings.assignedAgent}</h6>
          <p>
            {event.conversation_data?.assigned_to?.email ?? this.strings.noInfo}
          </p>
        </div>
        <div className="detail">
          <h6>{this.strings.nametitle}</h6>
          <p>{event.name}</p>
        </div>
      </>
    );
  }
  renderEventDetails(event, type) {
    if (
      this.state.selectedEvent?.id == event.id ||
      this.isEventSelectedConversation(event)
    ) {
      if (["IN_PROGRESS", "FINISHED"].includes(type)) {
        return (
          <div className="event-details">
            {this.renderConversationAgents(event)}
            <div className="button-container">
              <button
                onClick={() => {
                  this.props.onFullScreenChange(true);
                  this.setState({
                    fullScreen: true,
                    selectedConversation: event.conversation_data.id,
                  });
                  this.props.getContactConversationHistory(
                    event.conversation_data.id,
                  );
                }}
                disabled={this.state.fullScreen}
              >
                {this.strings.viewConvDet}
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="event-details">
          {["origin", "name", "agent", "extra"].map((atribute) => {
            return (
              <div className="detail">
                <h6>
                  {type == "FAILURE" && atribute == "extra"
                    ? this.strings.failureDes
                    : this.strings[atribute + "title"]}
                </h6>
                {this.getEventAtribute(event, type, atribute)}
              </div>
            );
          })}
        </div>
      );
    }
  }
  getEventType(event) {
    if (
      event.status.includes("FAILURE") ||
      [
        "MISSING_PARAMETER",
        "RETRY",
        "COMPANY_RATE_LIMIT",
        "PARAMETER_MISMATCH",
        "INVALID_POLL",
        "CHARACTER_LIMIT_HEADER",
        "CHARACTER_LIMIT_BODY",
      ].includes(event.status)
    )
      return "FAILURE";
    if (event.conversation_data) {
      if (event.conversation_data.finished_at) return "FINISHED";
      return "IN_PROGRESS";
    }
    if (["RECEIVED_BY_WORKER", "RECEIVED", "SUCCESS"].includes(event.status)) {
      return "RECEIVED";
    }
    return event.status;
  }
  getRenderEvents() {
    if (!this.state.selectedConversation)
      return this.props.contactEvents.sort((a, b) => a.date - b.date);
    return this.props.contactEvents.filter((event) =>
      this.isEventSelectedConversation(event),
    );
  }
  isEventSelectedConversation(event) {
    return (
      event.conversation_data &&
      event.conversation_data.id == this.state.selectedConversation
    );
  }
  renderHSMPreview = (hsm) => {
    if (hsm.status == "PAUSED") {
      return (
        <div className="paused-hsm">
          <p>{this.strings.pausedHsmText}</p>
          <a href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines#pausa-de-las-plantillas">
            {this.strings.metaDoc}
          </a>
        </div>
      );
    }
    let headerContent = null;
    let body = <p>{formatText(hsm.content)}</p>;
    let footerContent = null;
    let buttonsContent = null;

    if (
      hsm.answers.length > 0 &&
      !(hsm.buttons && Object.keys(hsm.buttons).length > 0)
    ) {
      let text = hsm.content;
      hsm.answers.forEach((a, idx) => (text += `\n${idx + 1}) ${a}`));
      body = <p>{formatText(text)}</p>;
    }

    if (hsm.header && Object.keys(hsm.header).length > 0) {
      if (hsm.header.type === "text") {
        headerContent = <p>{formatText(hsm.header.text)}</p>;
      } else if (hsm.header.type === "image") {
        headerContent = <img src={hsm.header.url} />;
      } else if (hsm.header.type === "video") {
        headerContent = (
          <video width="300" height="200" controls>
            <source src={hsm.header.url} />
          </video>
        );
      } else if (hsm.header.type === "document") {
        headerContent = <p>{hsm.header.url}</p>;
      }
    }
    if (hsm.footer && Object.keys(hsm.footer).length > 0) {
      footerContent = <p>*{formatText(hsm.footer.text)}</p>;
    }
    if (hsm.buttons && Object.keys(hsm.buttons).length > 0) {
      if (hsm.buttons.type === "quick_reply") {
        buttonsContent = (
          <div className="buttons">
            {hsm.buttons.options.map((opt) => (
              <p>{opt.text}</p>
            ))}
          </div>
        );
      } else if (hsm.buttons.type === "call_to_action") {
        buttonsContent = (
          <div className="buttons">
            {hsm.buttons.options.map((opt) => {
              if (opt.url) {
                return (
                  <a href={opt.url} target="_blank">
                    {opt.text}
                  </a>
                );
              } else if (opt.phone_number) {
                return (
                  <p>
                    {opt.text} - {opt.phone_number}
                  </p>
                );
              }
            })}
          </div>
        );
      }
    }
    return (
      <div className="hsm-preview">
        <div className="header">{headerContent}</div>
        <div className="body">{body}</div>
        <div className="footer">{footerContent}</div>
        {buttonsContent}
      </div>
    );
  };
  renderEventHistory() {
    if (this.props.contactFetching === true) {
      return (
        <div className="loading-events">
          <div className="custom-treble-loader" />
          <h3>{this.strings.loadingEvents}</h3>
        </div>
      );
    } else if (this.props.contactEvents.length == 0) {
      return (
        <div className="no-contact-events">
          <div className="no-events-image" />
          <h6>{this.strings.noEventsTitle}</h6>
          <p>{this.strings.noEventsDes}</p>
          {this.sendHsmButton()}
        </div>
      );
    } else {
      return (
        <div className="events-container">
          <div className="events">
            {this.getRenderEvents().map((event) => {
              let eventType = this.getEventType(event);
              let dateObject = moment.utc(event.date * 1000).local();
              return (
                <div
                  className="event"
                  onClick={() => {
                    let newEvent = event;
                    if (this.state.selectedEvent?.id == event.id) {
                      newEvent = null;
                    }
                    this.setState({ selectedEvent: newEvent });
                  }}
                >
                  <div className="visible-event-items">
                    <div className={`icon icon--${eventType}`} />
                    <h6>{`${this.strings[event.type]} ${
                      this.strings[eventType]
                    }`}</h6>
                    <p>{`${dateObject.format("L")} - ${dateObject.format(
                      "HH:mm",
                    )} hrs`}</p>
                    <div
                      className={`icon icon--cirle-arrow ${
                        this.state.selectedEvent?.id == event.id ||
                        this.isEventSelectedConversation(event)
                          ? "is-active"
                          : ""
                      }`}
                    />
                  </div>
                  {this.renderEventDetails(event, eventType)}
                </div>
              );
            })}
          </div>
          {this.sendHsmButton()}
        </div>
      );
    }
  }
  sendHsmButton() {
    const activeConversation = this.props.contact.active_conversation;
    const sendHSMButton = (
      <button className={`send-hsm ${activeConversation ? "disabled" : ""}`}>
        <p>{this.strings.sendHsm}</p>
        <div className="icon icon--book" />
        {activeConversation && <div className="icon icon--info" />}
      </button>
    );

    return (
      <div className="send-hsm-container">
        <SearchableDropdown
          searchPlaceholder={this.strings.hsmPH}
          options={this.props.hsms}
          closeOnBodyClick={false}
          toSearchStr={(hsm) => hsm.name + hsm.content}
          onSelect={(hsm) => {
            if (hsm.status == "PAUSED") return;
            this.props.toggleHSMModal(hsm);
          }}
          displayItem={(hsm) => (
            <div className={`search-hsm-dropdown-item ${hsm.status}`}>
              {hsm.status == "PAUSED" && (
                <div className="paused-hsm">
                  <p>{this.strings.pausedHsm}</p>
                </div>
              )}
              <h1>{hsm.name}</h1>
              <h2>{formatText(hsm.content)}</h2>
            </div>
          )}
          disabled={activeConversation ? true : false}
          triggerComponent={
            activeConversation ? (
              <Tooltip
                overlayClassName="contact-active-conversation-tooltip"
                placement="topRight"
                align={{ offset: [0, 16] }}
                title={this.strings.activeContactConversation.replace(
                  "{{AGENT}}",
                  activeConversation.agent,
                )}
              >
                {sendHSMButton}
              </Tooltip>
            ) : (
              sendHSMButton
            )
          }
          className="search-hsm-dropdown"
          renderOnNoResults={() => (
            <div className="search-hsm-dropdown__no-results">
              <div className="no-results-image" />
              <div className="no-result-title">{this.strings.noResults}</div>
              <div className="subtitle">{this.strings.noHsm}</div>
            </div>
          )}
          onHoverItem={this.renderHSMPreview}
          menuHeader="HSM"
          trackMessage={"Agent search HSM"}
        />
      </div>
    );
  }
  renderEventPanel() {
    return (
      <div className="contacts-events">
        <div className="title">
          <p>{this.strings.eventsHistory}</p>
        </div>
        {this.renderEventHistory()}
      </div>
    );
  }

  renderConversationDetails() {
    if (!this.state.fullScreen) return;
    return (
      <div className="conversation-details">
        <div className="details-options">
          {["conversation", "notes", "empty-space", "close"].map((option) => {
            if (option != "empty-space") {
              return (
                <button
                  className={`${option} ${
                    this.state.fullScreenView == option ? "active" : ""
                  }`}
                  onClick={() => {
                    if (option != "close") {
                      this.setState({ fullScreenView: option });
                    } else {
                      this.setState({
                        fullScreen: false,
                        fullScreenView: "conversation",
                        selectedConversation: null,
                      });
                      this.props.onFullScreenChange(false);
                    }
                  }}
                >
                  <p>{this.strings[option]}</p>
                  {option == "close" ? (
                    <div className={`icon icon--${option}`} />
                  ) : null}
                </button>
              );
            } else {
              return <div className={option} />;
            }
          })}
        </div>
        <div className="conv-notes-container">
          <ChatHistory
            language={this.props.language}
            conversationId={this.state.selectedConversation}
            chatEvents={this.props.events}
            notes={this.props.notes}
            getAddressFromCoordinates={this.props.getAddressFromCoordinates}
            sendingFile={false}
            readOnly={true}
            type={
              this.state.fullScreenView === "conversation"
                ? CHAT_HISTORY
                : NOTE_HISTORY
            }
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="contact-container">
        {this.renderEventPanel()}
        {this.renderConversationDetails()}
      </div>
    );
  }
}

export default Contact;
