import React, { useState } from "react";

import { validateEmail } from "../../../utils/email";

import "./LabelInputText.scss";

const LabelInputText = ({ onChange, type, value, label, invalidLabel }) => {
  const [internalType, setInternalType] = useState(type);
  const [invalid, setInvalid] = useState(false);
  return (
    <div className="label-input-text">
      <p>{label}</p>
      <div
        className={`label-input-text-input-container ${
          invalid ? "invalid-input" : ""
        }`}
      >
        <form autoComplete="off">
          <input
            autoComplete="off"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value}
            type={internalType}
            onFocus={() => {
              if (invalid) {
                setInvalid(false);
              }
            }}
            onBlur={() => {
              if (type === "email" && !validateEmail(value)) {
                setInvalid(true);
              }
            }}
          />
        </form>
        {type === "password" ? (
          <div className="label-input-text-icon-container">
            <div
              className="icon icon--view"
              onClick={() => {
                internalType == "password"
                  ? setInternalType("text")
                  : setInternalType("password");
              }}
            />
          </div>
        ) : null}
      </div>
      {invalid && invalidLabel && (
        <p className="label-input-text-invalid">{invalidLabel}</p>
      )}
    </div>
  );
};

export default LabelInputText;
