import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EditorState,
  Modifier,
  ContentState,
  getDefaultKeyBinding,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Picker } from "emoji-mart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";
import RecordModal from "../RecordModal/RecordModal";
import MediaRecorder from "opus-media-recorder";

import ReactS3Uploader from "react-s3-uploader";
import config from "../../config";
import { formatText } from "../../utils/WhatsappMarkdown";

import bold_icon from "../../assets/icons/bold.svg";
import italic_icon from "../../assets/icons/italic.svg";
import strikethrough_icon from "../../assets/icons/strikethrough.svg";

import "emoji-mart/css/emoji-mart.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichTextarea.scss";
import events from "../../utils/events";
import { whatsAppFormat } from "../../utils/whatsAppFormat";

// Limit of record time
const RECORDINGTIMELIMIT = 300; // 5 Minutes
const ONESECOND = 1000;

const workerRecordOptions = {
  encoderWorkerFactory: function () {
    return new Worker(
      process.env.PUBLIC_URL + "/opus-media-recorder/encoderWorker.umd.js",
    );
  },
  OggOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/OggOpusEncoder.wasm",
  WebMOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/WebMOpusEncoder.wasm",
};

function myKeyBindingFn(e) {
  if (e.keyCode === 13) {
    return "myeditor-enter";
  }
  if (e.ctrlKey && e.keyCode === 38) {
    return "go-up-chat";
  }
  if (e.ctrlKey && e.keyCode === 40) {
    return "go-down-chat";
  }
  return getDefaultKeyBinding(e);
}

class EmojiPicker extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  addEmoji = (emoji) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji.native,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  render() {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="right"
        overlay={
          <Popover id="popover-emoji">
            <Popover.Content>
              <Picker onSelect={this.addEmoji} autoFocus={true} />
            </Popover.Content>
          </Popover>
        }
      >
        <div
          className="rdw-option-wrapper rdw-option-center icon-svg emoji"
          title="Emoji"
        >
          <div className="icon icon--emoji" />
        </div>
      </OverlayTrigger>
    );
  }
}

class FilePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      sendingFile: false,
    };
  }

  preProcess = (file, next) => {
    this.setState({
      sendingFile: true,
    });
    this.props.setSendingFile(this.props.selectedChat, true);
    setTimeout(this.props.scrollDownChat, 50);
    file.selectedChat = this.props.selectedChat;

    next(file);
  };

  onUploadFinish = (e, file) => {
    console.log("file uploaded", file);
    const { signedUrl } = e;
    this.setState({
      sendingFile: false,
    });
    this.props.setSendingFile(file.selectedChat, false);

    const finalUrl = signedUrl.substring(0, signedUrl.indexOf("?"));

    this.props.sendToTarget("MEDIA", finalUrl, file.selectedChat);
  };

  onUploadError = (e, file) => {
    this.setState({
      sendingFile: false,
    });
    this.props.setSendingFile(file.selectedChat, false);
  };

  render() {
    const headers = {
      Authorization: `Bearer ${this.props.user.token}`,
    };
    return (
      <div
        className="rdw-option-wrapper rdw-option-center icon-svg files"
        title="Attach file"
      >
        <label className="icon icon--clip" htmlFor="media_input">
          {!this.props.blocked ? (
            <ReactS3Uploader
              signingUrl="/agent/message/signed_url"
              signingUrlMethod="GET"
              accept="*"
              s3path="/uploads/"
              preprocess={this.preProcess}
              onProgress={(e) => console.log(e)}
              onError={this.onUploadError}
              onFinish={this.onUploadFinish}
              signingUrlHeaders={headers}
              signingUrlWithCredentials={false} // in case when need to pass authentication credentials via CORS
              uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
              contentDisposition="auto"
              scrubFilename={(filename) =>
                filename.replace(/[^\w\d_\-.]+/gi, "")
              }
              server={config.API_ROOT}
              inputRef={(cmp) => (this.uploadInput = cmp)}
              autoUpload={true}
              style={{ display: "none" }}
              id="media_input"
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          ) : null}
        </label>
      </div>
    );
  }
}

class Record extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onRecord: this.props.onRecord,
      onPlay: false,
      seconds: 0,
    };
    this.mediaRecorder = null;
    this.recordedChunks = null;
    this.fileAudio = null;
    this.sendDirect = false;
    this.totalRecordedSeconds = RECORDINGTIMELIMIT;
    this.previewPlayAudio = false;
    this.timer = 0;
    this.audioRef = React.createRef();
    if (this.props.stream != null) {
      this.onRecord(this.props.stream);
    }
  }

  closeMediaDevice = (stream) => {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  onRecord = (stream) => {
    const options = { mimeType: "audio/ogg" };
    this.recordedChunks = [];
    this.mediaRecorder = new MediaRecorder(
      stream,
      options,
      workerRecordOptions,
    );

    this.mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.data.size > 0) this.recordedChunks.push(e.data);
    });
    this.mediaRecorder.addEventListener("stop", async () => {
      const blob = new Blob(this.recordedChunks);
      const audioUrl = URL.createObjectURL(blob);
      this.fileAudio = new File([blob], `audio_treble_agent.ogg`, {
        type: "audio/ogg",
      });
      if (this.audioRef.current != null) this.audioRef.current.src = audioUrl;
      this.closeMediaDevice(stream);
      if (this.sendDirect) {
        await this.prepareUpload();
        this.deleteRecord();
        this.sendDirect = false;
      }
    });

    this.mediaRecorder.start();
    console.log("recording");
  };

  updateTime = (timeLimit) => {
    const secondUpdated = this.state.seconds + 1;
    if (secondUpdated <= timeLimit) {
      this.setState({ seconds: secondUpdated });
    } else {
      if (this.state.onRecord) {
        this.stopRecord();
      }
      if (this.state.onPlay) {
        this.playAudio();
      }
    }
  };

  secondsToTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return (
      <>
        {minutes}:{Math.floor(seconds / 10)}
        {seconds % 10}
      </>
    );
  };

  renderTime = () => {
    let timeLimit = this.state.seconds == RECORDINGTIMELIMIT;
    return (
      <div className={timeLimit ? "time-limit" : ""}>
        {this.secondsToTime(this.state.seconds)}
        {timeLimit ? " max" : ""}
      </div>
    );
  };

  stopRecord = () => {
    this.mediaRecorder.stop();
    this.setState({ onRecord: false });
    clearInterval(this.timer);
    this.totalRecordedSeconds = this.state.seconds;
    this.timer = 0;
  };

  startRecord = () => {
    if (this.timer == 0 && this.state.onRecord) {
      this.timer = setInterval(this.updateTime, ONESECOND, RECORDINGTIMELIMIT);
    }
    return (
      <div className="item">
        <div className="audio-box">{this.renderTime()}</div>
      </div>
    );
  };

  onLoad = () => {
    this.props.setSendingFile(this.props.selectedChat, true);
    setTimeout(this.props.scrollDownChat, 50);
  };

  uploadFinish = () => {
    this.props.setSendingFile(this.props.selectedChat, false);
  };

  sendToTarget = (signedUrl) => {
    const finalUrl = signedUrl.split("?")[0];
    console.log("finalUrl", finalUrl);
    events.track("Agent send audio");
    this.props.sendToTarget("MEDIA", finalUrl, this.props.selectedChat);
  };

  prepareUpload = () => {
    this.props.uploadRecord(
      this.fileAudio,
      this.onLoad,
      this.uploadFinish,
      this.sendToTarget,
    );
  };

  uploadRecord = async () => {
    if (this.state.onRecord) {
      this.sendDirect = true;
      this.stopRecord();
    } else {
      this.prepareUpload();
      this.deleteRecord();
    }
  };

  playAudio = () => {
    if (this.state.onPlay) {
      this.setState({ onPlay: false });
      clearInterval(this.timer);
      this.audioRef.current.pause();
    } else {
      if (!this.previewPlayAudio) {
        this.previewPlayAudio = true;
        events.track("Agent play preview audio");
      }
      const startSecond =
        this.state.seconds == this.totalRecordedSeconds
          ? 0
          : this.state.seconds;
      this.setState({ onPlay: true, seconds: startSecond });
      this.timer = setInterval(
        this.updateTime,
        ONESECOND,
        this.totalRecordedSeconds,
      );
      if (startSecond == 0) {
        this.audioRef.current.currentTime = 0;
      }
      this.audioRef.current.play();
    }
  };

  renderAudio = () => {
    return (
      <div className="item">
        <div className="audio-box audio-box--on-play">
          <div className="item" onClick={() => this.playAudio()}>
            <div
              className={`icon ${
                this.state.onPlay
                  ? "icon--stop icon--stop-audio"
                  : "icon--play icon--play-audio"
              }`}
            ></div>
            <audio ref={this.audioRef}></audio>
          </div>
          <div>{this.renderTime()}</div>
        </div>
      </div>
    );
  };

  renderStopButton = () => {
    return (
      <div className="item">
        <label
          className="icon icon--stop-record"
          onClick={() => this.stopRecord()}
        ></label>
      </div>
    );
  };

  deleteRecord = (canceled = false) => {
    if (this.state.onRecord) {
      this.stopRecord();
    } else {
      this.closeMediaDevice(this.props.stream);
    }
    this.props.changeRecord();
    if (canceled) {
      events.track("Agent cancel audio");
    }
  };

  renderOnRecord = () => {
    return (
      <div className="record-items record">
        <div className="item" onClick={() => this.deleteRecord(true)}>
          <label className="icon icon--trash"></label>
        </div>
        {this.state.onRecord ? this.startRecord() : this.renderAudio()}
        {this.state.onRecord ? this.renderStopButton() : ""}
        <div className="item">
          <button
            className="icon icon--send-record"
            onClick={() => this.uploadRecord()}
          ></button>
        </div>
      </div>
    );
  };

  renderVoice = () => {
    return (
      <div
        className="rdw-option-wrapper rdw-option-center icon-svg voice"
        onClick={() => this.props.changeRecord()}
      >
        <div className="icon icon--record"></div>
      </div>
    );
  };

  renderSendMessage = () => {
    return (
      <div className="rdw-option-wrapper icon-svg send">
        <div
          className="icon icon--send"
          onClick={() => this.props.sendMessage(this.props.message)}
        />
      </div>
    );
  };

  render() {
    if (this.props.message.length > 0 || this.props.blocked) {
      return this.renderSendMessage();
    }
    return !this.props.onRecord ? this.renderVoice() : this.renderOnRecord();
  }
}

class Templates extends Component {
  constructor(props) {
    super(props);
  }

  renderPreview = (hsm) => {
    if (hsm.status == "PAUSED") {
      return (
        <div className="paused-hsm">
          <p>{this.props.strings.pausedHsmText}</p>
          <a
            href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines#template-pausing"
            target="_blank"
          >
            {this.props.strings.metaDoc}
          </a>
        </div>
      );
    }
    let headerContent = null;
    let body = <p>{formatText(hsm.content)}</p>;
    let footerContent = null;
    let buttonsContent = null;

    if (
      hsm.answers.length > 0 &&
      !(hsm.buttons && Object.keys(hsm.buttons).length > 0)
    ) {
      let text = hsm.content;
      hsm.answers.forEach((a, idx) => (text += `\n${idx + 1}) ${a}`));
      body = <p>{formatText(text)}</p>;
    }

    if (hsm.header && Object.keys(hsm.header).length > 0) {
      if (hsm.header.type === "text") {
        headerContent = <p>{formatText(hsm.header.text)}</p>;
      } else if (hsm.header.type === "image") {
        headerContent = <img src={hsm.header.url} />;
      } else if (hsm.header.type === "video") {
        headerContent = (
          <video width="300" height="200" controls>
            <source src={hsm.header.url} />
          </video>
        );
      } else if (hsm.header.type === "document") {
        headerContent = <p>{hsm.header.url}</p>;
      }
    }
    if (hsm.footer && Object.keys(hsm.footer).length > 0) {
      footerContent = <p>*{formatText(hsm.footer.text)}</p>;
    }
    if (hsm.buttons && Object.keys(hsm.buttons).length > 0) {
      if (hsm.buttons.type === "quick_reply") {
        buttonsContent = (
          <div className="buttons">
            {hsm.buttons.options.map((opt) => (
              <p>{opt.text}</p>
            ))}
          </div>
        );
      } else if (hsm.buttons.type === "call_to_action") {
        buttonsContent = (
          <div className="buttons">
            {hsm.buttons.options.map((opt) => {
              if (opt.url) {
                return (
                  <a href={opt.url} target="_blank">
                    {opt.text}
                  </a>
                );
              } else if (opt.phone_number) {
                return (
                  <p>
                    {opt.text} - {opt.phone_number}
                  </p>
                );
              }
            })}
          </div>
        );
      }
    }
    return (
      <div className="hsm-preview">
        <div className="header">{headerContent}</div>
        <div className="body">{body}</div>
        <div className="footer">{footerContent}</div>
        {buttonsContent}
      </div>
    );
  };

  renderHsmIcon = () => {
    return (
      <div
        className="rdw-option-wrapper rdw-option-center icon-svg hsm"
        title="HSM"
        id={"HSM-picker"}
      >
        <div className="icon icon--templates" />
        <span>HSMs</span>
      </div>
    );
  };

  render() {
    if (this.props.blocked)
      return <div className="search-hsm-dropdown">{this.renderHsmIcon()}</div>;
    return (
      <SearchableDropdown
        searchPlaceholder={this.props.strings.hsmPlaceholder}
        options={this.props.hsms}
        closeOnBodyClick={false}
        toSearchStr={(hsm) => hsm.name + hsm.content}
        onSelect={(hsm) => {
          if (hsm.status == "PAUSED") return;
          this.props.toggleHSMModal(hsm);
        }}
        displayItem={(hsm) => (
          <div className={`search-hsm-dropdown-item ${hsm.status}`}>
            {hsm.status == "PAUSED" && (
              <div className="paused-hsm">
                <p>{this.props.strings.pausedHsm}</p>
              </div>
            )}
            <h1>{hsm.name}</h1>
            <h2>{formatText(hsm.content)}</h2>
          </div>
        )}
        triggerComponent={this.renderHsmIcon()}
        className="search-hsm-dropdown"
        renderOnNoResults={() => (
          <div className="search-hsm-dropdown__no-results">
            <div className="no-results-image" />
            <div className="no-result-title">
              {this.props.strings.noResults}
            </div>
            <div className="subtitle">{this.props.strings.noHsm}</div>
          </div>
        )}
        onHoverItem={this.renderPreview}
        menuHeader="HSM"
        trackMessage={"Agent search HSM"}
      />
    );
  }
}
class BracketVars extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  onChange = (elem) => {
    const { editorState, onChange } = this.props;
    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      elem,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  render() {
    const bracketVars = this.props.BracketVars;
    return (
      <div className="rdw-option-wrapper bracket-vars">
        {bracketVars.map((elem) => (
          <div
            className="var"
            onClick={() => this.onChange(`{{${elem}}}`)}
          >{`{{${elem}}}`}</div>
        ))}
      </div>
    );
  }
}

class Notes extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        className="rdw-option-wrapper icon-svg notes"
        onClick={() => this.props.selectTabNotes()}
      >
        <div className="icon icon--notes" />
        <span>{this.props.strings.notes}</span>
      </div>
    );
  }
}

class Jumps extends Component {
  constructor(props) {
    super(props);
  }

  jumpChat = (inc) => {
    if (this.props.blocked) return;
    this.props.jumpChat(inc);
  };

  renderTooltip = (number, text) => {
    return (
      <ReactTooltip
        id={`jump-tooltip-${number}`}
        effect="solid"
        className="tooltip"
        delayShow={1000}
      >
        {text}
      </ReactTooltip>
    );
  };

  render() {
    return (
      <div className="jumps">
        {this.renderTooltip(1, "CTRL + ↑")}
        <div
          className="rdw-option-wrapper icon-svg jump"
          data-tip
          data-for="jump-tooltip-1"
        >
          <div
            className="icon icon--jump-chat-arrow"
            onClick={() => this.jumpChat(-1)}
          />
        </div>
        {this.renderTooltip(2, "CTRL + ↓")}
        <div
          className="rdw-option-wrapper icon-svg jump"
          data-tip
          data-for="jump-tooltip-2"
        >
          <div
            className="icon icon--jump-chat-arrow left"
            onClick={() => this.jumpChat(1)}
          />
        </div>
      </div>
    );
  }
}

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      placeholder: props.placeholder,
      renderExtras: null,
      onRecord: false,
      showRecordModal: false,
      recordModal: null,
    };
  }

  componentDidMount() {
    this.setState({ renderExtras: this.renderExtras(this.props.extras) });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.placeholder !== prevProps.placeholder ||
      this.state.placeholder !== prevState.placeholder
    ) {
      this.setState({ placeholder: this.props.placeholder });
    }
    if (this.props.selectedChat !== prevProps.selectedChat) {
      this.props.storeCurrentValue(
        whatsAppFormat(prevState.editorState.getCurrentContent()),
        prevProps.selectedChat,
      );
      const editorState = EditorState.push(
        this.state.editorState,
        ContentState.createFromText(this.props.initValue),
      );
      this.setState({
        editorState: EditorState.moveFocusToEnd(editorState),
        renderExtras: this.renderExtras(this.props.extras),
        onRecord: false,
      });
    }
    if (this.props.initValue !== prevProps.initValue) {
      this.changeInitValue(this.props.initValue);
    }
    if (
      this.props.copy !== prevProps.copy &&
      this.props.copy &&
      !this.props.deniedPaste
    ) {
      this.copyTextOnEditor(this.props.copy);
    }
    if (
      this.props.chatTab !== prevProps.chatTab ||
      this.state.editorState !== prevState.editorState
    ) {
      this.setState({ renderExtras: this.renderExtras(this.props.extras) });
    }
  };

  copyTextOnEditor = (text) => {
    const contentState = Modifier.replaceText(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      text,
      this.state.editorState.getCurrentInlineStyle(),
    );
    this.copyContent(contentState);
  };

  changeInitValue = (value) => {
    const editorState = EditorState.push(
      this.state.editorState,
      ContentState.createFromText(value),
    );
    this.setState({
      editorState: EditorState.moveFocusToEnd(editorState),
    });
  };

  copyContent = (content) => {
    const editorState = EditorState.push(this.state.editorState, content);
    this.setState({ editorState });
  };

  renderMicrophoneAccept = () => {
    return (
      <>
        {this.props.strings.allowTrebleMicrophoneAccess}
        <div className="icon icon--allow-microphone-denied"> </div>
        {this.props.strings.getTrebleMicrophoneAccess}
      </>
    );
  };

  renderRecordModal = () => {
    if (!this.state.showRecordModal) {
      return;
    }
    const modal = this.state.recordModal;
    return (
      <RecordModal
        show={this.state.showRecordModal}
        onClose={() =>
          this.setState({ showRecordModal: false, recordModal: null })
        }
        iconArrow={modal == "prompt" ? "icon icon--allow-microphone-arrow" : ""}
        title={this.props.strings.allowMicrophoneTitle}
        body={
          modal == "prompt"
            ? this.props.strings.allowMicrophoneBodyAccept
            : this.renderMicrophoneAccept()
        }
      />
    );
  };

  renderExtras = (extras, onRecord = false, stream = null) => {
    return extras.map((extra) => this.getExtraHandles(extra, onRecord, stream));
  };

  changeExtras = (type = null, stream = null) => {
    this.setState({
      onRecord: !this.state.onRecord,
      renderExtras: type
        ? this.renderExtras([type], true, stream)
        : this.renderExtras(this.props.extras),
    });
  };

  startRecord = (stream) => {
    this.changeExtras("Record", stream);
  };

  changeRecord = () => {
    if (this.state.onRecord) this.changeExtras();
    else {
      navigator.permissions.query({ name: "microphone" }).then((result) => {
        if (result.state == "granted") {
          navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(this.startRecord);
        } else if (result.state == "prompt") {
          this.setState({ showRecordModal: true, recordModal: "prompt" });
          navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(this.startRecord);
        } else if (result.state == "denied") {
          this.setState({ showRecordModal: true, recordModal: "denied" });
        }
        result.onchange = () => {
          if (result.state == "granted") {
            this.setState({ showRecordModal: false });
          }
        };
      });
    }
  };

  jumpChat = (inc) => {
    const chats = this.props.chats;
    const length = chats.length;
    const idx = chats.findIndex((chat) => {
      return chat.conversation_id === this.props.selectedChat;
    });
    const nextIdx = (idx + inc + length) % length;
    const nextChat = chats[nextIdx];
    this.props.onSelectChat(nextChat);
    events.track(
      `Agent click on ${inc === 1 ? "next" : "previous"} conversation shortcut`,
    );
  };

  getExtraHandles = (type, onRecord = false, stream = null) => {
    const blocked = this.props.chatTab !== "CHAT_TAB_CHATS";
    switch (type) {
      case "Emojis":
        return <EmojiPicker />;
      case "BracketVars":
        return <BracketVars BracketVars={this.props.BracketVars} />;
      case "Templates":
        return (
          <Templates
            id={"hsm-picker"}
            ref={(r) => (this.hsm = r)}
            hsms={this.props.hsms}
            strings={this.props.strings}
            toggleHSMModal={this.props.toggleHSMModal}
            blocked={blocked}
          />
        );
      case "Notes":
        return (
          <Notes
            strings={this.props.strings}
            selectTabNotes={this.props.selectTabNotes}
            blocked={blocked}
          />
        );
      case "Jumps":
        return (
          <Jumps
            chats={this.props.chats}
            selectedChat={this.props.selectedChat}
            onSelectChat={this.props.onSelectChat}
            blocked={blocked}
            jumpChat={this.jumpChat}
          />
        );
      case "Files":
        return (
          <FilePicker
            selectedChat={this.props.selectedChat}
            user={this.props.user}
            sendToTarget={this.props.sendToTarget}
            setSendingFile={this.props.setSendingFile}
            scrollDownChat={this.props.scrollDownChat}
            blocked={blocked}
          />
        );
      case "Record":
        return (
          <Record
            changeRecord={this.changeRecord}
            onRecord={onRecord}
            uploadRecord={this.props.uploadToS3}
            stream={stream}
            selectedChat={this.props.selectedChat}
            user={this.props.user}
            sendToTarget={this.props.sendToTarget}
            setSendingFile={this.props.setSendingFile}
            scrollDownChat={this.props.scrollDownChat}
            message={whatsAppFormat(this.state.editorState.getCurrentContent())}
            sendMessage={this.sendToTarget}
            blocked={blocked}
          />
        );

      default:
        return;
    }
  };

  clearState = () => {
    const editorState = EditorState.push(
      this.state.editorState,
      ContentState.createFromText(""),
    );
    this.setState({ editorState: EditorState.moveFocusToEnd(editorState) });
  };

  sendToTarget = (message) => {
    if (message === "") return;
    this.setState({ editorState: EditorState.createEmpty() });
    this.props.sendToTarget("TEXT", message, this.props.selectedChat);
  };

  handleKeyCommand = (command) => {
    if (command === "myeditor-enter") {
      // Perform a request to save your contents, set
      // a new `editorState`, etc.
      let message = whatsAppFormat(this.state.editorState.getCurrentContent());
      this.sendToTarget(message);
      this.clearState();
    }
    if (command === "go-up-chat") this.jumpChat(-1);
    if (command === "go-down-chat") this.jumpChat(1);
    return "not-handled";
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    if (this.props.onChange && this.props.onChange instanceof Function) {
      this.props.onChange(editorState.getCurrentContent());
    }
  };

  renderMobileToolbar = (currentContent) => {
    if (!this.props.mobileToolbar) return;
    return (
      <div
        className={`textarea-wrapper
        ${this.props.readOnly === true ? "read-only" : ""}
        ${this.props.chatTab === "CHAT_TAB_CHATS" ? "chat-tab" : "note-tab"}
        ${currentContent.length > 0 ? "writing" : ""}
      `}
      >
        <div className="rdw-editor-toolbar mobile-toolbar toolbar-class">
          {this.renderExtras(["Notes", "Templates", "Jumps"])}
        </div>
      </div>
    );
  };

  render() {
    const { editorState } = this.state;
    const currentContent = whatsAppFormat(
      this.state.editorState.getCurrentContent(),
    );

    return (
      <div
        className={`editor-container ${
          this.state.onRecord ? "is-inactive" : ""
        }`}
      >
        {this.renderMobileToolbar(currentContent)}
        <Editor
          readOnly={
            this.props.readOnly !== undefined
              ? this.state.onRecord || this.props.readOnly
              : false
          }
          placeholder={this.state.placeholder}
          editorState={editorState}
          spellCheck={true}
          handleKeyCommand={this.handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          wrapperClassName={`textarea-wrapper ${
            this.props.readOnly === true ? "read-only" : ""
          } ${
            this.props.chatTab === "CHAT_TAB_CHATS" ? "chat-tab" : "note-tab"
          } ${currentContent.length > 0 ? "writing" : ""}`}
          editorClassName={`text-editor`}
          onEditorStateChange={this.onEditorStateChange}
          toolbarClassName="toolbar-class"
          toolbarCustomButtons={this.state.renderExtras}
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "italic", "strikethrough"],
              bold: {
                className: "icon-svg",
                icon: bold_icon,
              },
              italic: {
                className: "icon-svg",
                icon: italic_icon,
              },
              strikethrough: {
                className: "icon-svg",
                icon: strikethrough_icon,
              },
            },
          }}
          stripPastedStyles={true}
        />
        {this.renderRecordModal()}
      </div>
    );
  }
}
export default Textarea;
