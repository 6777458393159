import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt-br";
import _ from "lodash";
import { CSVLink } from "react-csv";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from "recharts";

import TrebleIcon from "../../../../../../components/TrebleIcon/TrebleIcon";
import Empty from "../../../../../Platform/Metrics/Components/Graphs/Empty/Empty";
import Loading from "../../../../../Platform/Metrics/Components/Graphs/Loading/Loading";
import SelectDropdown from "../../../../../Platform/Metrics/Components/SelectDropdown/SelectDropdown";

import {
  SATISFACTION,
  timeGroupDays,
  timeGroupWeeks,
  timeGroupMonths,
} from "../../../../../../reducers/metricsReducer";

import events from "../../../../../../utils/events";
import languages from "./languages";
import "./Satisfaction.scss";

const mapLanguageToMomentLocale = {
  es: "es",
  en: "en",
  pt: "pt-br",
};

const mapRatingToEmoji = {
  1: "😡",
  2: "☹️",
  3: "😐",
  4: "🙂",
  5: "😃",
};

class CustomBarLabel extends React.Component {
  render() {
    const { x, y, value, barWidth } = this.props;
    return (
      <text
        x={x + barWidth / 2}
        y={y}
        dy={36}
        fontSize="17"
        fontFamily="HKGrotesk"
        fontWeight={600}
        fill="#FFFFFF"
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

class CustomTooltip extends React.Component {
  renderTrend = (difference) => {
    let trendContent = null;
    if (difference === 0 || difference === null) {
      return;
    } else if (difference > 0) {
      trendContent = (
        <div className="trend positive">
          <div className="icon icon--trend-up" />+{difference}%
        </div>
      );
    } else {
      trendContent = (
        <div className="trend negative">
          <div className="icon icon--trend-down" />
          {difference}%
        </div>
      );
    }

    return (
      <div className="satisfaction-trend">
        {trendContent}
        <p>
          {
            this.props.strings[
              `inComparison${this.props.timeGroup.toLowerCase()}`
            ]
          }
        </p>
      </div>
    );
  };

  render() {
    const { active, payload, label } = this.props;
    if (active && payload && payload.length) {
      const data = payload[0];
      return (
        <div className="satisfaction-tooltip">
          <div className="satisfaction-tooltip-header">
            <TrebleIcon name="clock" />
            {this.props.timeGroup !== timeGroupDays
              ? this.props.formatLabel(label)
              : label}
          </div>
          <div className="satisfaction-tooltip-body">
            <div className="satisfaction-average">
              <p>{this.props.strings.rating}</p>
              <p>{data.payload.average}</p>
            </div>
            {this.renderTrend(data.payload.difference)}
          </div>
        </div>
      );
    }
    return null;
  }
}

class Satisfaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredBar: null,
      hovering: false,
      timeGroup: props.timeGroup,
      selectedBar: null,
    };

    this.strings = languages[props.language];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timeGroup !== this.props.timeGroup) {
      this.setState({ timeGroup: this.props.timeGroup, selectedBar: null });
    }
    if (
      !_.isEqual(prevProps.data, this.props.data) &&
      (this.state.hoveredBar !== null ||
        this.state.hovering === true ||
        this.state.selectedBar !== null)
    ) {
      this.setState({ hoveredBar: null, hovering: false, selectedBar: null });
    }
  }

  changeTimeGroup = (timeGroup) => {
    this.setState({ timeGroup });
    this.props.getSatisfactionData(timeGroup);
    events.track("Agent changed metrics time unit", {
      "Metric type": SATISFACTION,
      "Time unit": timeGroup,
    });
  };

  renderSidePanel = () => {
    if (this.props.dateData.date !== null && this.state.selectedBar !== null) {
      if (this.props.dateData.loading) {
        return (
          <div className="satisfaction-side-panel">
            <Loading />
          </div>
        );
      }

      return (
        <div className="satisfaction-side-panel">
          <p className="satisfaction-side-panel-title">
            {this.strings.satisfaction}
          </p>
          <div className="ratings">
            {Object.entries(this.props.dateData.ratings).map(
              ([rating, data]) => (
                <div className="rating date-rating" key={`rating-${rating}`}>
                  <span>
                    <p>{mapRatingToEmoji[rating]}</p>
                    <p>{rating}</p>
                  </span>

                  <p>{data.percentage}%</p>
                  <p>{data.count}</p>
                </div>
              ),
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="satisfaction-side-panel">
          <p className="satisfaction-side-panel-title">
            {this.strings.satisfaction}
          </p>
          <div className="ratings">
            {Object.entries(this.props.summary.ratings).map(
              ([rating, data]) => (
                <div className="rating" key={`rating-${rating}`}>
                  <span>
                    <p>{mapRatingToEmoji[rating]}</p>
                    <p>{rating}</p>
                  </span>
                  <p>{data.percentage}%</p>
                  <p>{data.count}</p>
                </div>
              ),
            )}
          </div>
          <div className="click-tooltip">{this.strings.clickTooltip}</div>
        </div>
      );
    }
  };

  getDownloadData = () => {
    const headers = [
      this.strings[this.props.objectFilter.toLowerCase()],
      this.strings.date,
      this.strings.rating,
    ];

    return [headers, ...this.props.downloadData];
  };

  renderActions = () => {
    return (
      <div className="graph-actions">
        <CSVLink
          data={this.getDownloadData()}
          filename={`${this.strings.csvFilename}-${moment().format(
            "YYYY-MM-DD",
          )}.csv`}
        >
          <div
            className="icon icon--download"
            onClick={() => this.props.trackDownload(SATISFACTION)}
          />
        </CSVLink>
      </div>
    );
  };

  renderDifference = () => {
    const difference = this.props.summary.difference;
    let trendContent = null;
    if (difference === 0 || difference === null) {
      return;
    } else if (difference > 0) {
      trendContent = (
        <div className="trend positive">
          <div className="icon icon--trend-up" />+{difference}%
        </div>
      );
    } else {
      trendContent = (
        <div className="trend negative">
          <div className="icon icon--trend-down" />
          {difference}%
        </div>
      );
    }

    return (
      <div className="satisfaction-trend">
        {trendContent}
        <p>{this.strings.inComparison30Days}</p>
      </div>
    );
  };

  formatDateTick = (tick) => {
    const timeGroup = this.state.timeGroup;

    let localeMoment = moment();
    localeMoment.locale(mapLanguageToMomentLocale[this.props.language]);

    const parsedDate = moment(tick, "YYYY-MM-DD", true);

    if (!parsedDate.isValid()) {
      return tick;
    }

    if (timeGroup === timeGroupDays || timeGroup === null) {
      return parsedDate.format("MMM DD");
    } else if (timeGroup === timeGroupWeeks) {
      const startOfWeek = parsedDate.clone().startOf("week");
      const endOfWeek = parsedDate.clone().endOf("week");
      return `${startOfWeek.format("MMM DD")} - ${endOfWeek.format("MMM DD")}`;
    } else if (timeGroup === timeGroupMonths) {
      return parsedDate.format("MMMM");
    }
  };

  onBarClick = (bar) => {
    if (
      bar.date !== this.props.dateData.date ||
      this.state.selectedBar === null
    ) {
      this.setState({ selectedBar: bar.date });
      events.track("Agent hovered on metrics to get info", {
        "Metric type": SATISFACTION,
      });
      this.props.getSatisfactionDateData(this.state.timeGroup, bar.date);
    } else {
      this.setState({ selectedBar: null });
    }
  };

  renderGraph = () => {
    let width = this.props.data.length * 120;
    let barWidth = this.props.data.length >= 5 ? 50 : 120;

    let w = window.innerWidth;
    if (width < w - 580) {
      width = w - 580;
    }

    if (this.props.timeGroup === timeGroupWeeks && barWidth === 50) {
      width *= 1.5;
    }

    return (
      <BarChart
        width={width}
        height={400}
        data={this.props.data}
        isAnimationActiveBoolean={false}
        barSize={barWidth}
        onMouseMove={(state) => {
          if (
            state.isTooltipActive &&
            state.activePayload[0].payload.average !== null
          ) {
            this.setState({
              hoveredBar: state.activeLabel,
              hovering: true,
            });
          } else {
            this.setState({ hoveredBar: null, hovering: false });
          }
        }}
      >
        <CartesianGrid vertical={false} stroke="#E8E8E8" />
        <XAxis
          dataKey="date"
          axisLine={{ stroke: "#393939" }}
          tickLine={false}
          tickFormatter={this.formatDateTick}
          minTickGap={-200}
        />
        <YAxis
          type="number"
          domain={[0, 5]}
          tickCount={6}
          axisLine={false}
          tickLine={false}
          tick={({ x, y, payload }) => {
            if (payload.value === 5) {
              return (
                <g transform={`translate(${x},${y})`}>
                  <text x={-10} y={-1} textAnchor="start" fill="#666">
                    {payload.value}
                  </text>
                </g>
              );
            } else {
              if (payload.value === 0) {
                return null;
              }
              return (
                <g transform={`translate(${x},${y})`}>
                  <text x={-10} y={5} textAnchor="start" fill="#666">
                    {payload.value}
                  </text>
                </g>
              );
            }
          }}
        />
        <Tooltip
          content={
            <CustomTooltip
              strings={this.strings}
              formatLabel={this.formatDateTick}
              timeGroup={this.state.timeGroup ?? timeGroupDays}
            />
          }
          cursor={{ fill: "transparent" }}
        />
        {this.props.data
          .filter((d) => d.isYear === true)
          .map((year) => (
            <ReferenceLine x={year.date} stroke="#E8E8E8" strokeWidth={2} />
          ))}
        <Bar
          dataKey="average"
          fill="#8884d8"
          label={<CustomBarLabel barWidth={barWidth} />}
          onClick={this.onBarClick}
        >
          {this.props.data.map((entry, index) => {
            if (
              (this.state.hoveredBar === null || !this.state.hovering) &&
              this.state.selectedBar === null
            ) {
              return <Cell fill={index % 2 === 0 ? "#00003D" : "#7575E9"} />;
            } else {
              if (
                this.state.hoveredBar === entry.date ||
                this.state.selectedBar === entry.date
              ) {
                return (
                  <Cell
                    fill={index % 2 === 0 ? "#00003D" : "#7575E9"}
                    cursor="pointer"
                  />
                );
              } else {
                return <Cell fill={"#F5F5F5"} />;
              }
            }
          })}
        </Bar>
      </BarChart>
    );
  };

  renderSelectionBanner = () => {
    if (this.state.selectedBar !== null) {
      return (
        <div className="selection-banner">
          <div
            className="icon icon--close"
            onClick={() => this.setState({ selectedBar: null })}
          />
          {this.strings.selectionBanner}
        </div>
      );
    }
  };

  render() {
    const totalRatings = Object.values(this.props.summary.ratings).reduce(
      (total, rating) => (total += rating.count),
      0,
    );
    if (totalRatings === 0 && !this.props.loading) {
      return <Empty name={SATISFACTION} />;
    }

    const content = (
      <div className="satisfaction-wrapper">
        {this.renderSelectionBanner()}
        <div className="satisfaction-data">
          {this.renderActions()}
          {this.renderSidePanel()}
          <div className="graph-wrapper">
            <div className="graph-extra">
              <div
                className={`satisfaction-header ${
                  this.state.hoveredBar !== null ? "is-hovering" : ""
                }`}
              >
                <p>{this.strings.averageRating}:</p>
                <p>{this.props.summary.average}</p>
                {this.renderDifference()}
              </div>
              <SelectDropdown
                options={[
                  { label: this.strings.days, value: timeGroupDays },
                  { label: this.strings.weeks, value: timeGroupWeeks },
                  { label: this.strings.months, value: timeGroupMonths },
                ]}
                _key="satisfaction-time-group-filter"
                placement="bottomRight"
                placeholder={this.strings.days}
                onSelect={this.changeTimeGroup}
                value={this.state.timeGroup}
              />
            </div>
            <div
              className="recharts-graph"
              onMouseLeave={() =>
                this.setState({ hoveredBar: null, hovering: false })
              }
            >
              {this.renderGraph()}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="graph" id="satisfaction" key={SATISFACTION}>
        {this.props.loading ? <Loading /> : content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  data: state.metricsReducer.data[SATISFACTION].data,
  downloadData: state.metricsReducer.data[SATISFACTION].downloadData,
  summary: state.metricsReducer.data[SATISFACTION].summary,
  loading: state.metricsReducer.data[SATISFACTION].loading,
  dateData: state.metricsReducer.data[SATISFACTION].dateData,
  timeGroup: state.metricsReducer.data[SATISFACTION].timeGroup,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Satisfaction);
