import io from "socket.io-client";

import {
  notifyNewConversation,
  notifyNewMessage,
} from "./actions/notificationAction";
const uri = process.env.REACT_APP_SOCKET_URL;

export default function socketMiddleware() {
  const socket = io(uri);
  console.log("INIT SOCKET", socket);

  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === "function") {
        return next(action);
      }

      const { event, leave, handle, emit, payload, ...rest } = action;

      if (!event) {
        return next(action);
      }

      if (leave) {
        socket.removeListener(event);
      }

      if (emit) {
        socket.emit(event, payload);
        return;
      }

      let handleEvent = handle;
      if (typeof handleEvent === "string") {
        handleEvent = (result) => {
          let notification_type = "";
          console.log("result", result);
          switch (event) {
            case "new_message":
              const { chats } = getState().mainReducer;
              const conversation = chats.find(
                (chat) => chat.conversation_id == result.conversation_id,
              );
              if (conversation !== undefined) {
                dispatch(notifyNewMessage(conversation, result.message));
              }
              break;
            case "new_conversation":
              dispatch(notifyNewConversation(result));
              break;
            case "message_status":
              console.log(result);
              break;
            case "remove_conversation":
              console.log(result);
              break;
          }

          dispatch({ type: handle, result, ...rest });
        };
      }
      return socket.on(event, handleEvent);
    };
}
