module.exports = {
  es: {
    changeTo: "Cambiar a",
    active: "activo",
    inactive: "inactivo",
    logout: "Cerrar sesión",
    notifications: "Notificaciones",
    titleNotification: "Permitir notificaciones",
    bodyNotificationStep1: "1. Haz clic en el ícono con forma de candado",
    bodyNotificationStep2:
      "2. Haz clic en “configuración del sitio” Permite las notificaciones",
    bodyNotificationStep3: "3. Vuelve a cargar la página",
    bodyNotificationExtra:
      "Adicionalmente: Asegúrate de tener las notificaciones activas en tu computador",
    footerButtonNotification: "Entendido",
    endAllChats: "Finalizar todos los chats",
    headerEndAllChats: "😀 🚀 ✨--¿Estás segur@ de finalizar todos los chats?",
    bodyEndAllChats:
      "Estás a punto de finalizar todos los chats que se encuentran abiertos, recuerda que al finalizar los chats automáticamente se envía la encuesta de satisfacción",
    footerEndAllChats: "Finalizar todos los chats",
    footerCancelEndAllChats: "No finalizar los chats",
    informationModalText:
      "👋 ¡Hola! Has sido seleccionad@ para ayudarnos a hacer mejoras 😎 en nuestra plataforma",
    informationModalCtaButton: "Compartir mejoras",
    informationModalLink: "https://airtable.com/shrz7VlkcCdGzt8GC",
    announcementModalCtaButton: "Conocer nuevas métricas",
    announcementModalText:
      "Añadiremos unos cuantos ingredientes adicionales a nuestra receta original para que tengas una mejor visibilidad de lo que ocurre en tu operación ¿Estás listo/a?",
    announcementModalOverTitle: "Nuevos Lanzamientos",
    announcementModalTitle: "¡Conoce las nuevas métricas!",
    announcementModalLink:
      "https://t.maze.co/88454181?email=daniel%40treble.ai",
    announcementModalImgName: "metrics-charts.svg",
    yes: "Si",
    no: "No",
    cancel: "Cancelar",
    everything: "Todo",
  },
  en: {
    changeTo: "Change to",
    active: "active",
    inactive: "inactive",
    logout: "Logout",
    notifications: "Notifications",
    titleNotification: "Enable notifications",
    bodyNotificationStep1: "1. Click on the padlock icon",
    bodyNotificationStep2: "2. Click on “site settings” Allow notifications",
    bodyNotificationStep3: "3. reload the page",
    bodyNotificationExtra:
      "Additionally: Make sure you have active notifications on your computer",
    footerButtonNotification: "Entendido",
    endAllChats: "End all chats",
    headerEndAllChats: "😀 🚀 ✨--¿Are you sure to end all chats?",
    bodyEndAllChats:
      "You are about to finish all the chats that are open, remember that when you finish the chats, the satisfaction survey is automatically sent",
    footerEndAllChats: "End all chats",
    footerCancelEndAllChats: "Don't end chats",
    informationModalText:
      "👋 Hello! You have been selected to help us make improvements 😎 to our platform",
    informationModalCtaButton: "Share improvements",
    informationModalLink: "https://airtable.com/shrz7VlkcCdGzt8GC",
    announcementModalCtaButton: "Learn about new metrics",
    announcementModalText:
      "We will add a few additional ingredients to our original recipe to give you better visibility of what is going on in your operation. Are you ready?",
    announcementModalOverTitle: "New Releases",
    announcementModalTitle: "Get to know the new metrics!",
    announcementModalLink:
      "https://t.maze.co/88454181?email=daniel%40treble.ai",
    announcementModalImgName: "metrics-charts.svg",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    everything: "All",
  },
  pt: {
    changeTo: "Mudar para",
    active: "ativo",
    inactive: "inativo",
    logout: "Fechar Sessão",
    notifications: "Notificações",
    titleNotification: "Ativar notificações",
    bodyNotificationStep1: "1. Clique no ícone do cadeado",
    bodyNotificationStep2:
      "2. Clique em “configurações do site” Permitir notificações",
    bodyNotificationStep3: "3. recarregue a página",
    bodyNotificationExtra:
      "Além disso: verifique se você tem notificações ativas no seu computador",
    footerButtonNotification: "Entendido",
    endAllChats: "Encerrar todos os bate-papos",
    headerEndAllChats:
      "😀 🚀 ✨--¿Tem certeza de encerrar todos os bate-papos?",
    bodyEndAllChats:
      "Você está prestes a finalizar todos os chats que estão abertos, lembre-se que ao finalizar os chats, a pesquisa de satisfação é enviada automaticamente",
    footerEndAllChats: "Encerrar todos os bate-papos",
    footerCancelEndAllChats: "Não termine os bate-papos",
    informationModalText:
      "👋 Olá! Você foi selecionado para nos ajudar a fazer melhorias 😎 em nossa plataforma",
    informationModalCtaButton: "Compartilhar melhorias",
    informationModalLink: "https://airtable.com/shrepM6CcXanx1ku8",
    announcementModalCtaButton: "Conhecer novas métricas",
    announcementModalText:
      "Acrescentaremos alguns ingredientes adicionais à nossa receita original para lhe dar melhor visibilidade do que está acontecendo em sua operação. Você está pronto?",
    announcementModalOverTitle: "Novos Lançamentos",
    announcementModalTitle: "Conheça as novas métricas!",
    announcementModalLink:
      "https://t.maze.co/88454181?email=daniel%40treble.ai",
    announcementModalImgName: "metrics-charts.svg",
    yes: "Sim",
    no: "Não",
    cancel: "Cancelar",
    everything: "Tudo",
  },
};
