module.exports = {
  es: {
    moreThan: "Más de",
    withoutResponse: "sin respuesta",
    minutes: "minutos",
    hours: "horas",
    days: "días",
    erase: "Borrar",
    save: "Guardar",
  },
  en: {
    moreThan: "More than",
    withoutResponse: "without response",
    minutes: "minutes",
    hours: "hours",
    days: "days",
    erase: "Clean",
    save: "Save",
  },
  pt: {
    moreThan: "Mais de",
    withoutResponse: "sem resposta",
    minutes: "minutos",
    hours: "horas",
    days: "días",
    erase: "Limpar",
    save: "Salve",
  },
};
