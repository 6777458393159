import { request } from "../utils/request";
import { agentLogoutSimple } from "./agentAction.js";

const CHANGE_ACTIVATION = "CHANGE_ACTIVATION";
export const requestChangeActivation = (email, password) => (dispatch) => {
  dispatch({
    type: CHANGE_ACTIVATION,
  });
};

const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const activationSuccess = (active) => (dispatch) => {
  dispatch({
    type: ACTIVATION_SUCCESS,
    active,
  });
};

const ACTIVATION_ERROR = "ACTIVATION_ERROR";
export const activationError = () => (dispatch) => {
  dispatch({
    type: ACTIVATION_ERROR,
  });
};

export const changeActivation =
  (newState = null, logout = false) =>
  (dispatch) => {
    dispatch(requestChangeActivation());

    const body = newState !== null ? { new_state: newState } : {};

    request(
      "PUT",
      "/agent/state",
      body,
      true,
      (data) => {
        if (!logout) dispatch(activationSuccess({ active: data.active }));
      },
      (error) => {
        return dispatch(activationError());
      },
    );
  };

export const changeLanguage = (newLanguage) => (dispatch) => {
  dispatch({
    type: "CHANGE_LANGUAGE",
    language: newLanguage,
  });
  setTimeout(() => window.location.reload(), 100);
  localStorage["language"] = newLanguage;
};

export const lastOnline = () => (_) => {
  request(
    "PUT",
    "/agent/last_online",
    {},
    true,
    (data) => {
      console.log("[INFO] Successful log");
      console.log(data);
    },
    (error) => {
      console.log("[ERROR] Problem doing the log on the server");
      console.log(error);
    },
    false,
    false,
  );
};

const CLEAR_ERROR = "CLEAR_ERROR";
export const clearError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};

export const crashUpdateError =
  (msg = "", loginHelpdesk = false) =>
  (dispatch) => {
    if (msg) dispatch(agentLogoutSimple(msg, false, loginHelpdesk));
    return dispatch(
      agentLogoutSimple(
        "Por favor inicia de nuevo sesión",
        true,
        loginHelpdesk,
      ),
    );
  };
