import React from "react";
import "./Pill.scss";

function Pill(props) {
  return (
    <div className={`treble-pill ${props.styleClass}`} {...props}>
      {props.icon && <div className={`icon icon--${props.icon}`} />}
      {props.text}
    </div>
  );
}

export default Pill;
