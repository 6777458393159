export const theme = {
  header: {
    fontSize: "15px",
    fontColor: "rgba(0, 0, 0, 0.85)",
    backgroundColor: "#FAFAFA",
    height: "57px",
  },
  rows: {
    height: "64px",
  },
};
