import React, { Component } from "react";
import { Transition } from "react-transition-group";
import "./Popup.scss";

export default class Popup extends Component {
  toggle(visible) {
    this.setState({ visible });
  }

  render() {
    const logo = this.props.error ? "error" : "success";
    const errorMessage = this.props.errorMessage
      ? this.props.errorMessage
      : "Ha ocurrido un error";

    const duration = 500;

    const defaultStyle = {
      transition: `top ${duration}ms ease-in-out`,
      top: "-20%",
    };

    const transitionStyles = {
      entering: { top: "-20%" },
      entered: { top: "-20%" },
      exiting: { top: "10%" },
      exited: { top: "10%" },
    };

    return (
      <Transition in={!this.props.visible} timeout={0}>
        {(state) => (
          <div
            onClick={this.props.handleClick}
            className="treble-popup"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className={`logo-popup ${logo}`}></div>
            <p>{errorMessage}</p>
          </div>
        )}
      </Transition>
    );
  }
}
