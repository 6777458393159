import React from "react";
import Modal from "../Modal/ModalV2";
import InlineAlert from "../InlineAlert/InlineAlert";

import languages from "./languages";

import "antd/lib/radio/style/index.css";
import "antd/lib/space/style/index.css";

import "./TransferModal.scss";
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";

const AGENT = "AGENT";
const TAG = "TAG";

class TransferModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAgent: null,
      selectedCategory: null,
      sendAttempt: false,
      selectedOption: TAG,
    };

    this.strings = languages[props.language];
  }

  renderAlert = (property, message) => {
    if (!this.state.sendAttempt) {
      return;
    }
    let stateProperty = `${property}`;
    if (this.state[stateProperty] === null) {
      return (
        <InlineAlert
          show={this.state.sendAttempt}
          type="error"
          text={message}
        />
      );
    }
    return;
  };

  renderDropdown = (selectedOption) => {
    let dropdownState;
    if (selectedOption === AGENT) {
      dropdownState = this.state.selectedAgent
        ? this.agentName(this.state.selectedAgent)
        : this.strings.agentPlaceholder;
    } else {
      dropdownState = this.state.selectedCategory
        ? this.state.selectedCategory.name
        : this.strings.tagPlaceholder;
    }
    return (
      <div className="button">
        <p>{dropdownState}</p>
        <div className="icon icon--dropdown-row" />
      </div>
    );
  };

  agentName = (agent) => {
    return `${agent.first_name} ${agent.last_name ? agent.last_name : ""}`;
  };

  renderDropdownOption = (selectedOption, option) => {
    let content;
    let check = false;
    if (selectedOption === AGENT) {
      content = this.agentName(option);
      check = this.state.selectedAgent
        ? this.state.selectedAgent.id === option.id
        : false;
    } else {
      content = option.name;
      check = this.state.selectedCategory
        ? this.state.selectedCategory.id === option.id
        : false;
    }

    return (
      <>
        {content}
        {check ? <div className="icon icon--check" /> : ""}
      </>
    );
  };

  resetOption = (option) => {
    if (option === this.state.selectedOption) return;
    this.setState({
      selectedOption: option,
      selectedAgent: null,
      selectedCategory: null,
    });
  };

  renderOptions = () => {
    const selectedOption = this.state.selectedOption;
    return (
      <div className="options">
        <div
          className={`option ${selectedOption !== AGENT ? "is-active" : ""}`}
          onClick={() => this.resetOption("TAG")}
        >
          {this.strings.transferToTeam}
        </div>
        <div
          className={`option ${selectedOption === AGENT ? "is-active" : ""}`}
          onClick={() => this.resetOption("AGENT")}
        >
          {this.strings.transferToAgent}
        </div>
      </div>
    );
  };

  renderFilters = () => {
    const agents = this.props.agents;
    const categories = [];

    agents.map((agent) =>
      agent.tags.map((tag) => {
        if (!categories.find((c) => c.id === tag.id)) {
          categories.push(tag);
        }
      }),
    );

    let dropdownFilter = this.props.tags;
    if (this.state.selectedOption === AGENT) {
      dropdownFilter = agents;
      if (this.state.selectedCategory) {
        dropdownFilter = agents.filter((agent) => {
          return agent.tags.find(
            (tag) => tag.id === this.state.selectedCategory.id,
          );
        });
      }
    }

    const toSearch = (option) => {
      if (this.state.selectedOption === AGENT) {
        return option.first_name + (option.last_name ? option.last_name : "");
      }
      return option.name;
    };

    return (
      <div
        className={`filters ${
          this.state.selectedOption === AGENT ? "has-tags" : ""
        }`}
      >
        <SearchableDropdown
          searchPlaceholder={this.strings.searchName}
          options={dropdownFilter}
          closeOnBodyClick={false}
          toSearchStr={toSearch}
          onSelect={(option) => {
            if (this.state.selectedOption === AGENT) {
              this.setState({ selectedAgent: option });
            } else {
              this.setState({ selectedCategory: option });
            }
          }}
          displayItem={(option) => {
            let selectedOption;
            if (this.state.selectedOption === AGENT) selectedOption = AGENT;
            else selectedOption = TAG;
            return this.renderDropdownOption(selectedOption, option);
          }}
          triggerComponent={this.renderDropdown(this.state.selectedOption)}
          className="search-dropdown"
          renderOnNoResults={() => (
            <div className="not-found">{this.strings.noResults}</div>
          )}
          trackMessage={
            this.state.selectedOption === AGENT
              ? "Agent transfer conversation to agent search by name"
              : "Agent transfer conversation to team search by team name"
          }
        />
        {this.state.selectedOption === AGENT ? (
          <SearchableDropdown
            searchPlaceholder={this.strings.searchName}
            options={categories}
            closeOnBodyClick={false}
            toSearchStr={(category) => category.name}
            onSelect={(category) =>
              this.setState({ selectedCategory: category })
            }
            displayItem={(option) => this.renderDropdownOption(TAG, option)}
            triggerComponent={this.renderDropdown(TAG)}
            className="tag-dropdown"
            renderOnNoResults={() => (
              <div className="not-found">{this.strings.noResults}</div>
            )}
            trackMessage="Agent transfer conversation to filter agent by team name"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  validate = () => {
    this.setState({ sendAttempt: true });
    return this.isAbleToTransfer() ? true : false;
  };

  transferAgent = () => {
    const selectedAgentId = this.state.selectedAgent
      ? this.state.selectedAgent.id
      : null;
    const categoryId = this.state.selectedCategory.id;

    const { chats } = this.props;

    let transferConversationIds = chats
      .filter(
        (chat) =>
          chat.status === "assigned" ||
          (chat.status == "unassigned" && selectedAgentId === null),
      )
      .map((chat) =>
        chat.conversation_id !== undefined ? chat.conversation_id : chat.id,
      );
    let assignedConversationIds = chats
      .filter(
        (chat) => chat.status === "unassigned" && selectedAgentId !== null,
      )
      .map((chat) =>
        chat.conversation_id !== undefined ? chat.conversation_id : chat.id,
      );

    if (transferConversationIds.length >= 1) {
      this.props.transferChats(
        transferConversationIds,
        selectedAgentId,
        categoryId,
      );
    }

    if (assignedConversationIds.length >= 1) {
      this.props.assignChats(
        assignedConversationIds,
        selectedAgentId,
        categoryId,
      );
    }
  };

  isAbleToTransfer = () => {
    return (
      (this.state.selectedOption === AGENT &&
        this.state.selectedAgent &&
        this.state.selectedCategory) ||
      (this.state.selectedOption === TAG && this.state.selectedCategory)
    );
  };

  renderFooter = () => {
    return (
      <div className="buttons">
        <div className="button cancel" onClick={() => this.props.resetState()}>
          {this.strings.cancel}
        </div>
        <div
          className={`button send ${this.isAbleToTransfer() ? "able" : ""}`}
          onClick={() => {
            if (this.validate() === true) {
              this.transferAgent();
              this.props.hardResetState();
            }
          }}
        >
          {this.strings.transfer}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Modal
        wrappClass="transfer-modal"
        show={this.props.show}
        onClose={this.props.resetState}
        header={this.strings.transferChat}
        body={
          <>
            {this.renderOptions()}
            {this.renderFilters()}
          </>
        }
        footer={this.renderFooter()}
      />
    );
  }
}

export default TransferModal;
