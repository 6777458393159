import React, { Component } from "react";
import "./AlertLoader.scss";

import { SUCCESS, ERROR } from "../../actions/chatAction";

export class AlertLoader extends Component {
  /**
   * alertLoader: the state on redux
   * loading: string
   * success: string
   * error: string
   * udpateAlertSate
   */

  changeAlertLoaderToNull = () => {
    setTimeout(() => {
      this.props.updateAlertState(null, null);
    }, 3000);
  };

  render() {
    let { progress } = this.props.alertState;
    if (!progress) return;
    if (progress === SUCCESS || progress === ERROR) {
      this.changeAlertLoaderToNull();
    }
    progress = progress.toLowerCase();
    return (
      <div className="alert-loader">
        <p>{this.props[progress]}</p>
        <div className={`icon ${progress}`} />
      </div>
    );
  }
}

export default AlertLoader;
