import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";
import "moment/locale/pt";
import "moment/locale/es";

import "react-dates/lib/css/_datepicker.css";

import HoverableButton from "../../../components/HoverableButton/HoverableButton";

import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import MultipleSelectDropdown from "../../../components/Dropdowns/MultipleSelectDropdown";
import CountriesDropdown from "../../../components/Dropdowns/CountriesDropdown";

import TransferModal from "../../../components/TransferModal/TransferModal";
import DetailsPanel from "./DetailsPanel";

import Panel from "../../../components/Panel/Panel";
import DataTable from "react-data-table-component";

import {
  getAgents,
  transferChats,
  assignChats,
  agentGetTags,
} from "../../../actions/chatAction";
import {
  getStats,
  getAdminConversations,
  getConversationHistory,
} from "../../../actions/statAction";
import { getAddressFromCoordinates } from "../../../actions/agentAction";

import { DateRangePicker } from "react-dates";
import { CSVLink } from "react-csv";
import "./ChatStats.scss";

import { theme } from "./theme.js";
import COUNTRIES from "../../../data/countries.json";

import languages from "./languages";
import events from "../../../utils/events";
import TimeDropdown from "../../../components/TimeDropdown/TimeDropdown";

const TWENTY_FOUR_HOUR_FORMAT = "YYYY-MM-DD HH:mm:ss";

let countryCodeToDialCode = {};
COUNTRIES.forEach((country) => {
  countryCodeToDialCode[country.code] = country.dial_code;
});

const displayTimeMetric = (metric) => {
  if (!metric) return "-";
  const seconds = Math.floor(metric);
  if (seconds < 60) return `${seconds} s`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} m ${seconds % 60} s`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} h ${minutes % 60} m`;
  const days = Math.floor(hours / 24);
  return `${days} d ${hours % 24} h`;
};

const formatTimeDifference = (start, end) => {
  const diff = end.diff(start);
  const duration = moment.duration(diff);
  const hours = Math.floor(duration.asHours());
  const remainingMs = diff - hours * 3600000; // subtract the hours in milliseconds
  return hours + moment.utc(remainingMs).format(":mm:ss");
};

class ChatStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        actor: "customer",
        searchTerm: "",
        tags: [],
        states: [],
        labels: [],
        responseTime: {
          quantity: null,
          units: null,
        },
      },
      startDate: null,
      endDate: null,
      selectedConversations: [],

      focusedDatePickerInput: null,
      language: props.language,
      showTransferModal: false,

      csv: {
        loading: false,
        data: [],
      },
    };

    this.strings = languages[props.language];
    moment.locale(props.language);
  }

  mapperChatStatus(chatStatus) {
    if (chatStatus === "assigned") return this.strings.inProgress;
    if (chatStatus === "finished") return this.strings.finished;
    if (chatStatus === "unassigned") return this.strings.unassigned;
    return "";
  }

  fetchConversations = () => {
    const nowUtc = moment()
      .startOf("day")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    this.props.getStats(nowUtc);
    this.props.getAdminConversations();
    this.props.getAgents();
    this.props.agentGetTags(this.props.user.company.id);
  };

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.setState({ language: this.props.language });
      moment.locale(this.props.language);
      this.strings = languages[this.props.language];
    }
  }

  componentDidMount() {
    events.page("Chat Stats");
  }

  componentWillMount() {
    this.fetchConversations();
  }

  changeFilter = (nValue, key) => {
    const { filter } = this.state;
    filter[key] = nValue;
    console.log(filter);
    this.setState({ filter });
  };

  dateChange = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  cleanFilter = () => {
    this.setState({
      filter: {
        actor: "customer",
        searchTerm: "",
        tags: [],
        states: [],
        labels: [],
        responseTime: {
          quantity: null,
          units: null,
        },
      },
    });
    events.track("Clean filters", {});
  };

  getCSVRepresentation = (event, done) => {
    if (!this.state.csv.loading) {
      this.setState({
        csv: { ...this.state.csv, loading: true },
      });

      const filteredChats = this.filterChats(this.props.chats);
      let finalChats = filteredChats
        .sort((a, b) => {
          if (a.created_at > b.created_at) return -1;
          if (a.created_at < b.created_at) return +1;
          return 0;
        })
        .map((chat) => {
          const createdAt = chat.created_at
            ? moment(chat.created_at + "Z")
            : null;
          const assignedAt = chat.assigned_at
            ? moment(chat.assigned_at + "Z")
            : null;
          const finishedAt = chat.finished_at
            ? moment(chat.finished_at + "Z")
            : null;
          const agentFirstMessage = chat.agent_first_message
            ? moment(chat.agent_first_message + "Z")
            : null;
          const lastMessage = chat.last_message
            ? moment(chat.last_message + "Z")
            : null;
          const lastTransfer =
            chat.transfers && chat.transfers.length > 0
              ? chat.transfers[chat.transfers.length - 1]
              : null;
          const lastTransferTime = lastTransfer
            ? moment(lastTransfer.timestamp + "Z")
            : null;
          let allLabels = chat.labels.map((label) => label.name);
          let labels = allLabels.join(", ");
          return {
            phone: `${chat.country_code} ${chat.cellphone}`,
            contact: chat.contact ? chat.contact : "",
            tag: chat.tag ? chat.tag.toLowerCase() : "-",
            agent: chat.agent,
            created_at: createdAt
              ? createdAt.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            assigned_at: assignedAt
              ? assignedAt.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            finished_at: finishedAt
              ? finishedAt.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            last_transfer_time: lastTransferTime
              ? lastTransferTime.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            last_transfer_from: lastTransfer ? lastTransfer.from : "",
            agent_first_message: agentFirstMessage
              ? agentFirstMessage.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            last_message: lastMessage
              ? lastMessage.local().format(TWENTY_FOUR_HOUR_FORMAT)
              : "",
            last_message_sender: chat.last_message_sender,
            duration: finishedAt
              ? formatTimeDifference(createdAt, finishedAt)
              : formatTimeDifference(createdAt, moment().utc()),
            rating: chat.rating ? chat.rating : "-",
            status: this.mapperChatStatus(chat.status),
            finish_type: chat.status === "finished" ? chat.finish_type : "",
            agent_first_message_from_allocation:
              assignedAt && agentFirstMessage
                ? formatTimeDifference(assignedAt, agentFirstMessage)
                : "-",
            agent_first_message_from_creation:
              agentFirstMessage && createdAt
                ? formatTimeDifference(createdAt, agentFirstMessage)
                : "-",
            labels: labels,
          };
        });
      this.setState({
        csv: {
          data: finalChats,
          loading: false,
        },
      });
      done(true);
    }
  };

  onSelectedTagsChange = (tags) => {
    const { filter } = this.state;
    filter["tags"] = tags;
    this.setState({ filter });
    events.track("Chats stats select tag", { Tags: tags });
  };

  onSelectedLabelsChange = (labels) => {
    const { filter } = this.state;
    filter["labels"] = labels;
    this.setState({ filter });
    events.track("Chats stats select label", { Labels: labels });
  };

  onSelectedStatesChange = (states) => {
    const { filter } = this.state;
    filter["states"] = states;
    this.setState({ filter });
    events.track("Chats stats select statuses", { Statuses: states });
  };

  onSaveResponseTimeFilterChange = (units, quantity) => {
    const { filter } = this.state;
    filter["responseTime"] = {
      units,
      quantity,
    };
    this.setState({ filter });
    events.track("Chats stats save since response time", {
      ResponseTime: { units, quantity },
    });
  };

  onViewDetails = (row) => {
    events.track("Chats stats details", { "Conversation ID": row.id });
    this.props.getConversationHistory(row.id);
    this.setState({ selectedConversations: [row] });
    this.chatHistoryPanel.toggle(true);
  };

  openTransferPanel = (row) => {
    events.track("Chats stats transfer", { "Conversation ID": row.id });
    this.props.getAgents();
    this.props.agentGetTags(this.props.user.company.id);
    this.setState({ selectedConversations: [row], showTransferModal: true });
  };

  trackingTransferChats = (conversationIds, agentId, categoryId) => {
    let { startDate, endDate, filter } = this.state;

    let startDateStr = startDate ? startDate.format("YYYY/MM/DD") : "";
    let endDateStr = endDate ? endDate.format("YYYY/MM/DD") : "";
    events.track("Chat stats batch transfer", {
      "Search Text": filter.searchTerm,
      Tags: filter.tags,
      Statuses: filter.states,
      Labels: filter.labels,
      "Response Time": `${filter.responseTime.quantity} ${filter.responseTime.units}`,
      "Start Date": startDateStr,
      "End Date": endDateStr,
      "Country Code": filter.country_code,
      "Agent Transfer": agentId,
      "Team Transfer": categoryId,
      "Number of Chats": conversationIds.length,
    });
    this.props.transferChats(conversationIds, agentId, categoryId);
  };

  trackingAssignChats = (conversationIds, agentId, categoryId) => {
    let { startDate, endDate, filter } = this.state;

    let startDateStr = startDate ? startDate.format("YYYY/MM/DD") : "";
    let endDateStr = endDate ? endDate.format("YYYY/MM/DD") : "";
    events.track("Chat stats batch assign", {
      "Search Text": filter.searchTerm,
      Tags: filter.tags,
      Statuses: filter.states,
      Labels: filter.labels,
      "Response Time": `${filter.responseTime.quantity} ${filter.responseTime.units}`,
      "Start Date": startDateStr,
      "End Date": endDateStr,
      "Country Code": filter.country_code,
      "Agent Transfer": agentId,
      "Team Transfer": categoryId,
      "Number of Chats": conversationIds.length,
    });
    this.props.assignChats(conversationIds, agentId, categoryId);
  };

  filterChats = (chats) => {
    const { filter, startDate, endDate } = this.state;
    const { tags, states, country_code, labels, responseTime } = filter;

    let filteredChats = chats;

    if (filter.searchTerm !== "") {
      const { searchTerm, actor } = filter;
      filteredChats = filteredChats.filter((e) => {
        if (e[actor]) {
          return (
            e[actor].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
        } else if (e.country_code && Number.isInteger(parseInt(searchTerm))) {
          return (
            `${e.country_code} ${e.cellphone}`.indexOf(
              searchTerm.toLowerCase(),
            ) !== -1
          );
        } else if (e.contact) {
          return (
            String(e.contact)
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
          );
        } else {
          return false;
        }
      });
    }
    if (startDate !== null && endDate !== null) {
      filteredChats = filteredChats.filter((e) => {
        const actual = moment.utc(e.created_at).local().format();
        return (
          startDate.startOf("day").format() <= actual &&
          actual <= endDate.endOf("day").format()
        );
      });
    }

    if (tags.length > 0) {
      filteredChats = filteredChats.filter((e) => tags.indexOf(e.tag) !== -1);
    }

    if (states.length > 0) {
      filteredChats = filteredChats.filter(
        (e) => states.indexOf(e.status) !== -1,
      );
    }

    if (country_code) {
      filteredChats = filteredChats.filter(
        (e) => e.country_code == country_code,
      );
    }

    if (labels.length > 0) {
      filteredChats = filteredChats.filter((chat) => {
        let hasLabel = false;
        chat.labels.forEach((label) => {
          if (labels.indexOf(label.name) !== -1) {
            hasLabel = true;
          }
        });
        return hasLabel;
      });
    }

    if (responseTime.quantity !== null && responseTime.units !== null) {
      filteredChats = filteredChats.filter((chat) => {
        if (chat.last_message_sender === "USER") {
          const actual = moment().utc();
          const actualModified = moment(chat.last_message + "Z").add(
            responseTime.quantity,
            responseTime.units.toLowerCase(),
          );
          return actual > actualModified;
        }
        return false;
      });
    }

    return filteredChats;
  };

  isEmptyFilter = () => {
    const { filter } = this.state;
    if (
      filter.actor !== "customer" ||
      filter.searchTerm !== "" ||
      filter.tags.length > 0 ||
      filter.states.length > 0 ||
      filter.labels.length > 0 ||
      (filter.responseTime.units !== null &&
        filter.responseTime.quantity !== null)
    ) {
      return false;
    }
    return true;
  };

  renderConversationsSubheader = (filteredChats) => {
    if (this.state.selectedConversations.length > 0) {
      return (
        <div className="selected-chats">
          <h2>
            {this.state.selectedConversations.length}{" "}
            {this.strings.conversationsSelected.toUpperCase()}
          </h2>
          <HoverableButton
            icon="user-circle"
            iconSize="16"
            tooltip={this.strings.transfer}
            onClick={() => {
              this.props.getAgents();
              this.props.agentGetTags(this.props.user.company.id);
              this.setState({ showTransferModal: true });
            }}
            place="top"
          />
        </div>
      );
    } else {
      return (
        <h2>
          {filteredChats.length} {this.strings.conversations.toUpperCase()}
        </h2>
      );
    }
  };

  getTimeDiferrence(firstTimestamp, secondTimestamp, interval) {
    const diff = firstTimestamp.diff(secondTimestamp, interval);
    return displayTimeMetric(diff);
  }

  render() {
    let columns = [
      {
        name: this.strings.client,
        selector: (row) => row.customer,
        sortable: true,
        sortFunction: (a, b) => {
          const nameA = a.contact;
          const nameB = b.contact;
          if (nameA && nameB) {
            return nameA > nameB;
          } else if (nameA && !nameB) {
            return 1;
          } else if (!nameA && nameB) {
            return -1;
          } else {
            return 0;
          }
        },
        minWidth: "180px",
        format: (row) => {
          const cellphone = `${row.country_code} ${row.cellphone}`;
          const contactName = row.contact;
          return (
            <div>
              <p className="mb-1">
                <b>{cellphone}</b>
              </p>
              <p className="mb-2">{contactName}</p>
              {row.labels && (
                <div className="chat-labels">
                  {row.labels.map((chatLabel) => (
                    <div
                      key={chatLabel.id}
                      className={`label-item label-${chatLabel.color}`}
                    >
                      {chatLabel.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        },
      },
      {
        name: this.strings.agent,
        selector: (row) => row.agent,
        minWidth: "180px",
        sortable: true,
        cell: (row) => {
          return (
            <div>
              <p className="mb-1">{row.agent}</p>
              <div className="transfers">
                {row.transfers && row.transfers.length > 0 && (
                  <div className="dropdown is-right is-hoverable">
                    <div className="dropdown-trigger">
                      <button
                        className="button"
                        aria-haspopup="true"
                        aria-controls={`conversation-row-${row.id}-transfers`}
                      >
                        <small>{this.strings.transfers}</small>
                      </button>
                    </div>
                    <div
                      className="dropdown-menu"
                      role="menu"
                      id={`conversation-row-${row.id}-transfers`}
                    >
                      <div className="dropdown-content">
                        {row.transfers.map((transfer) => (
                          <div className="transfer-item">
                            <p>
                              <small>
                                {moment(moment.utc(row.created_at).toDate())
                                  .local()
                                  .format("DD/MM/YY - hh:mm a")}
                              </small>
                            </p>
                            <p>
                              <b>{this.strings.from}</b>: {transfer.from}
                            </p>
                            <p>
                              <b>{this.strings.to}</b>: {transfer.to}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        name: this.strings.tag,
        selector: (row) => row.tag,
        sortable: true,
        format: (row) => {
          return row.tag ? row.tag.toLowerCase() : "-";
        },
      },
      {
        name: this.strings.creationDate,
        selector: (row) => row.created_at,
        sortable: true,
        minWidth: "180px",
        format: (row) =>
          moment(moment.utc(row.created_at).toDate())
            .local()
            .format("DD/MM/YY - hh:mm a"),
      },
      {
        maxWidth: "180px",
        name: this.strings.duration,
        selector: (row) => row.finished_at,
        sortable: true,
        sortFunction: (a, b) => {
          const createdAtA = moment(a.created_at + "Z"); // z is added to force utc time
          const endTimeA = a.finished_at
            ? moment(a.finished_at + "Z")
            : moment().utc();
          const diffA = endTimeA.diff(createdAtA, "seconds");
          const createdAtB = moment(b.created_at + "Z"); // z is added to force utc time
          const endTimeB = b.finished_at
            ? moment(b.finished_at + "Z")
            : moment().utc();
          const diffB = endTimeB.diff(createdAtB, "seconds");
          return diffA - diffB;
        },
        format: (row) => {
          const createdAt = moment(row.created_at + "Z"); // z is added to force utc time
          const endTime = row.finished_at
            ? moment(row.finished_at + "Z")
            : moment().utc();
          const diff = endTime.diff(createdAt, "seconds");
          return displayTimeMetric(diff);
        },
      },
      {
        name: this.strings.rating,
        selector: (row) => row.rating,
        sortable: true,
        minWidth: "140px",
        format: (row) => {
          return row.rating ? row.rating : "-";
        },
      },
      {
        name: this.strings.status,
        selector: (row) => row.status,
        sortable: true,
        format: (row) => {
          return (
            <div>
              <p>{this.mapperChatStatus(row.status)}</p>
              {row.status === "finished" && row.finish_type !== "MANUAL" && (
                <b>TIMEOUT</b>
              )}
            </div>
          );
        },
      },
      {
        name: this.strings.agent_first_message,
        selector: (row) => row.agent_first_message,
        sortable: true,
        sortFunction: (a, b) => {
          const messageA = a.agent_first_message;
          const messageB = b.agent_first_message;
          if (messageA && messageB) {
            const createdAtA = moment(a.created_at + "Z");
            const agentFirstMessageA = moment(messageA + "Z");
            const diffA = agentFirstMessageA.diff(createdAtA, "seconds");

            const createdAtB = moment(b.created_at + "Z");
            const agentFirstMessageB = moment(messageB + "Z");
            const diffB = agentFirstMessageB.diff(createdAtB, "seconds");
            return diffA - diffB;
          } else if (messageA && !messageB) {
            return 1;
          } else if (!messageA && messageB) {
            return -1;
          } else {
            return 0;
          }
        },
        format: (row) => {
          if (row.agent_first_message) {
            const createdAt = moment(row.created_at + "Z");
            const agentFirstMessage = moment(row.agent_first_message + "Z");
            return this.getTimeDiferrence(
              agentFirstMessage,
              createdAt,
              "seconds",
            );
          } else {
            return "-";
          }
        },
      },
      {
        name: this.strings.last_message,
        selector: (row) => row.last_message,
        sortable: true,
        sortFunction: (a, b) => {
          const lastUnfinishedA = a.status !== "finished" && a.last_message;
          const lastUnfinishedB = b.status !== "finished" && b.last_message;
          if (lastUnfinishedA && lastUnfinishedB) {
            const nowDateTime = moment().utc();

            const lastMessageA = moment(a.last_message + "Z");
            const diffA = nowDateTime.diff(lastMessageA, "seconds");

            const lastMessageB = moment(b.last_message + "Z");
            const diffB = nowDateTime.diff(lastMessageB, "seconds");

            return diffA - diffB;
          } else if (lastUnfinishedA && !lastUnfinishedB) {
            return 1;
          } else if (!lastUnfinishedA && lastUnfinishedB) {
            return -1;
          } else {
            return 0;
          }
        },
        minWidth: "180px",
        format: (row) => {
          if (row.status !== "finished" && row.last_message) {
            const nowDateTime = moment().utc();
            const lastMessage = moment(row.last_message + "Z");
            const diff = nowDateTime.diff(lastMessage, "seconds");
            return (
              <>
                <p>{displayTimeMetric(diff)}</p>
                <p>
                  <b>{this.strings.last_message_sender}</b>{" "}
                  {row.last_message_sender == "AGENT"
                    ? this.strings.agent
                    : this.strings.client}
                </p>
              </>
            );
          } else {
            return "-";
          }
        },
      },
      {
        name: this.strings.p_assigned_time,
        selector: (row) => row.last_message,
        sortable: true,
        sortFunction: (a, b) => {
          const assignedResponseA = a.agent_first_message && a.assigned_at;
          const assignedResponseB = b.agent_first_message && b.assigned_at;
          if (assignedResponseA && assignedResponseB) {
            const assignedAtA = moment(a.assigned_at + "Z");
            const agentFirstMessageA = moment(a.agent_first_message + "Z");
            const diffA = agentFirstMessageA.diff(assignedAtA, "seconds");

            const assignedAtB = moment(b.assigned_at + "Z");
            const agentFirstMessageB = moment(b.agent_first_message + "Z");
            const diffB = agentFirstMessageB.diff(assignedAtB, "seconds");
            return diffA - diffB;
          } else if (assignedResponseA && !assignedResponseB) {
            return 1;
          } else if (!assignedResponseA && assignedResponseB) {
            return -1;
          } else {
            return 0;
          }
        },
        minWidth: "180px",
        format: (row) => {
          if (row.agent_first_message && row.assigned_at) {
            const assignedAt = moment(row.assigned_at + "Z");
            const agentFirstMessage = moment(row.agent_first_message + "Z");
            return this.getTimeDiferrence(
              agentFirstMessage,
              assignedAt,
              "seconds",
            );
          } else {
            return "-";
          }
        },
      },
      {
        maxWidth: "20px",
        sortable: false,
        cell: (row) => (
          <div className="dropdown is-right is-hoverable">
            <div className="dropdown-trigger">
              <button
                className="button"
                aria-haspopup="true"
                aria-controls={`conversation-row-${row.id}-options`}
              >
                <span className="icon is-small">
                  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div
              className="dropdown-menu"
              id={`conversation-row-${row.id}-options`}
              role="menu"
            >
              <div className="dropdown-content">
                <div
                  className="dropdown-item"
                  onClick={() => this.onViewDetails(row)}
                >
                  {this.strings.details}
                </div>
                {row.status != "finished" ? (
                  <div
                    className={"dropdown-item"}
                    onClick={() => this.openTransferPanel(row)}
                  >
                    {row.status == "unassigned"
                      ? this.strings.assign
                      : this.strings.transfer}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ),
      },
    ];

    const filteredChats = this.filterChats(this.props.chats);
    const stats = this.props.stats;
    const selectedChats = this.state.selectedConversations;
    let selectedChat = {};
    if (selectedChats.length >= 1) {
      selectedChat = selectedChats[0];
      columns.pop();
    }

    let allTags = [];
    let allStates = [];
    let allLabels = [];
    let allCountryCodes = [""];
    console.log(this.props.chats);
    for (var chat of this.props.chats) {
      if (allTags.indexOf(chat["tag"]) === -1) {
        allTags.push(chat["tag"]);
      }
      if (allStates.indexOf(chat["status"]) === -1) {
        allStates.push(chat["status"]);
      }
      if (allCountryCodes.indexOf(chat["country_code"]) == -1) {
        allCountryCodes.push(chat["country_code"]);
      }

      chat["labels"].forEach((label) => {
        const labelName = label.name;
        if (allLabels.indexOf(labelName) === -1) {
          allLabels.push(labelName);
        }
      });
    }

    const nameActorMap = {};
    nameActorMap[this.strings.client] = "customer";
    nameActorMap[this.strings.agent] = "agent";

    const resetState = () => {
      this.setState({
        showTransferModal: false,
        selectedConversations: [],
      });
    };

    return (
      <div className="chat-stats">
        <TransferModal
          language={this.props.language}
          chats={selectedChats}
          show={this.state.showTransferModal}
          resetState={resetState}
          hardResetState={resetState}
          agents={this.props.agents}
          tags={this.props.tags}
          transferChats={this.trackingTransferChats}
          assignChats={this.trackingAssignChats}
        />

        <Panel
          title={
            selectedChat.contact ||
            `${selectedChat.country_code} ${selectedChat.cellphone}`
          }
          ref={(chatHistoryPanel) => (this.chatHistoryPanel = chatHistoryPanel)}
          onClose={() => this.setState({ selectedConversations: [] })}
        >
          <DetailsPanel
            language={this.props.language}
            strings={this.strings}
            chatEvents={this.props.events}
            conversationIds={this.state.selectedConversations}
            notes={this.props.notes}
            title={
              selectedChat.contact ||
              `${selectedChat.country_code} ${selectedChat.cellphone}`
            }
            getAddressFromCoordinates={this.props.getAddressFromCoordinates}
          />
        </Panel>

        <div className="container is-fluid general-stats-container">
          <div className="columns">
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.unassignedChats}</p>
                <h1>{stats.unassigned}</h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.activeChats}</p>
                <h1>{stats.assigned}</h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.chatsEndedToday}</p>
                <h1>{stats.finished}</h1>
              </div>
            </div>
            <div className="column">
              <div className="stat-box">
                <p>{this.strings.topicHighestLoadToday}</p>
                <h1>
                  {stats.most_used_tag
                    ? stats.most_used_tag.toLowerCase()
                    : "-"}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="flex" id="conversation-stats-navbar">
          <DateRangePicker
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            startDateId="startingId" // PropTypes.string.isRequired,
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            endDateId="endId" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              let startDateStr = startDate
                ? startDate.format("YYYY/MM/DD")
                : "";
              let endDateStr = endDate ? endDate.format("YYYY/MM/DD") : "";

              events.track("Chat stats change date", {
                "Start Date": startDateStr,
                "End Date": endDateStr,
              });

              this.setState({ startDate, endDate });
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedDatePickerInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedDatePickerInput) =>
              this.setState({ focusedDatePickerInput })
            } // PropTypes.func.isRequired,
            showDefaultInputIcon={true}
            startDatePlaceholderText={this.strings.initialDate}
            endDatePlaceholderText={this.strings.finalDate}
            isOutsideRange={() => false}
            showClearDates={true}
          />
          <CountriesDropdown
            noSelected={this.strings.allCountries}
            countries={allCountryCodes
              .filter(
                (dial_code) =>
                  COUNTRIES.find(
                    (country) => country.dial_code == dial_code,
                  ) !== undefined,
              )
              .map(
                (dial_code) =>
                  COUNTRIES.find((country) => country.dial_code == dial_code)
                    .code,
              )}
            onClick={(country) => {
              this.changeFilter(
                countryCodeToDialCode[country.code],
                "country_code",
              );
              events.track("Chat stats select countries", {
                Country: country.code,
              });
            }}
          />
          <div class="fill"></div>
          <div
            className="btn dark small"
            onClick={() => {
              let { startDate, endDate } = this.state;

              let startDateStr = startDate
                ? startDate.format("YYYY/MM/DD")
                : "";
              let endDateStr = endDate ? endDate.format("YYYY/MM/DD") : "";

              events.track("Chats stats download", {
                "Search Text": this.state.filter.searchTerm,
                Tags: this.state.filter.tags,
                Statuses: this.state.filter.states,
                Labels: this.state.filter.labels,
                "Response Time": `${this.state.filter.responseTime.quantity} ${this.state.filter.responseTime.units}`,
                "Start Date": startDateStr,
                "End Date": endDateStr,
              });
            }}
          >
            <span className="icon download"></span>
            <CSVLink
              data={this.state.csv.data}
              filename={"treble.csv"}
              asyncOnClick={true}
              onClick={this.getCSVRepresentation}
            >
              {this.state.csv.loading ? "..." : this.strings.download}
            </CSVLink>
          </div>
        </div>

        <div className="flex" id="conversation-stats-header">
          <div>
            <h1>{this.strings.conversations}</h1>
            {this.renderConversationsSubheader(filteredChats)}
          </div>
        </div>
        <div className="flex" id="conversation-stats-filters">
          <SearchDropdown
            placeholder={this.strings.search}
            options={[this.strings.client, this.strings.agent]}
            onSelect={(actor) =>
              this.changeFilter(nameActorMap[actor], "actor")
            }
            onSearch={(text) => this.changeFilter(text, "searchTerm")}
            selectedOption={nameActorMap[this.state.filter.actor]}
            searchValue={this.state.filter.searchTerm}
          />
          {!this.isEmptyFilter() && (
            <a onClick={this.cleanFilter}>{this.strings.cleanFilters}</a>
          )}

          <TimeDropdown
            units={this.state.filter.responseTime.units}
            quantity={this.state.filter.responseTime.quantity}
            noSelectionTitle={this.strings.noResponseTime}
            saveChanges={this.onSaveResponseTimeFilterChange}
            language={this.props.language}
          />
          <MultipleSelectDropdown
            options={allStates}
            noSelectionTitle={this.strings.allStatus}
            display={(state) => state}
            onSelect={this.onSelectedStatesChange}
            selectedOptions={this.state.filter.states}
          />
          <MultipleSelectDropdown
            options={allTags}
            noSelectionTitle={this.strings.allTags}
            display={(tag) => tag}
            onSelect={this.onSelectedTagsChange}
            selectedOptions={this.state.filter.tags}
          />
          {allLabels.length > 0 && (
            <MultipleSelectDropdown
              options={allLabels}
              noSelectionTitle={this.strings.allLabels}
              display={(label) => label}
              onSelect={this.onSelectedLabelsChange}
              selectedOptions={this.state.filter.labels}
            />
          )}
        </div>
        <hr />
        <div className="table-container">
          <DataTable
            striped
            columns={columns}
            data={filteredChats}
            customTheme={theme}
            pagination
            paginationComponentOptions={{
              rowsPerPageText: this.strings.rowsPerPage,
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: this.strings.allRows,
            }}
            defaultSortField="created_at"
            defaultSortAsc={false}
            minimumNights={0}
            responsive
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => {
              this.setState({ selectedConversations: selectedRows });
            }}
            selectableRowDisabled={(row) => row.status === "finished"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chats: state.adminReducer.chats,
  stats: state.adminReducer.stats,
  events: state.adminReducer.events,
  notes: state.adminReducer.notes,
  agents: state.mainReducer.agents,
  tags: state.mainReducer.tags,
  user: state.mainReducer.user,
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({
  getAgents: () => dispatch(getAgents()),
  getStats: (date) => dispatch(getStats(date)),
  getAdminConversations: () => dispatch(getAdminConversations()),
  getConversationHistory: (conversationId) =>
    dispatch(getConversationHistory(conversationId)),
  transferChats: (conversationIds, agentId, categoryId) =>
    dispatch(transferChats(conversationIds, agentId, categoryId)),
  assignChats: (conversationIds, agentId, categoryId) =>
    dispatch(assignChats(conversationIds, agentId, categoryId)),
  getAddressFromCoordinates: (latitude, longitude, key, msgId, callback) =>
    dispatch(
      getAddressFromCoordinates(latitude, longitude, key, msgId, callback),
    ),
  agentGetTags: (companyId) => dispatch(agentGetTags(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatStats);
