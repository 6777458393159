module.exports = {
  es: {
    login: "Iniciar sesión",
    email: "Correo",
    password: "Contraseña",
    forgotPassword: "¿Olvidaste tu contraseña?",
    noAgent: "No tienes un vendedor activo en treble",
    noAgentD:
      "Es necesario que tengas un vendedor con tu correo para que puedas ingresar a la plataforma de treble sales. Porfavor contacta a tu Onboarding Specialist para obtener tus credenciales",
    errorLogout: "Sucedió un error, por favor intenta lo siguiente:",
  },
  en: {
    login: "Log in",
    email: "Email",
    password: "Password",
    forgotPassword: "Forgot your password?",
    noAgent: "You don't have an active agent inside treble",
    noAgentD:
      "It is necessary that you have an salesperson with your email so that you can enter the treble sales platform. Please contact your Onboarding Specialist to obtain your credentials",
    errorLogout: "An error occurred, please try the following:",
  },
  pt: {
    login: "Iniciar sessão",
    email: "Email",
    password: "Senha",
    forgotPassword: "Você esqueceu sua senha?",
    noAgent: "Você não tem um vendedor ativo em treble",
    noAgentD:
      "É necessário que você tenha um vendedor com seu email para que possa entrar na plataforma de treble sales. Entre em contato com seu especialista de Onboarding para obter suas credenciais",
    errorLogout: "Ocorreu um erro, tente o seguinte:",
  },
};
