import events from "./events";

const NPSWidgetID = "script-nps";

function setURLQueryStringToDisplayWidget(userId) {
  let lang = localStorage["language"] || "es";
  window.history.replaceState(null, null, `?nps_${lang}=` + userId);
}

function checkIfWidgetAdded() {
  let scriptElem = document.getElementById(NPSWidgetID);

  if (scriptElem) return true;
  return false;
}

function appendNPSWidget(enable) {
  let NPSScript = document.createElement("script");
  NPSScript.id = NPSWidgetID;

  let innerScript = document.createTextNode(`
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1062409,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`);

  if (enable) {
    NPSScript.appendChild(innerScript);
  }

  document.head.appendChild(NPSScript);
}

export function handleNPSWidget(userId) {
  let isWidgetAdded = checkIfWidgetAdded();

  return (isUserNPSTarget) => {
    if (isWidgetAdded) return;

    if (isUserNPSTarget) {
      setURLQueryStringToDisplayWidget(userId);
      events.track("NPS Widget display", { userId });
    }

    appendNPSWidget(isUserNPSTarget);
  };
}
