import moment from "moment";
var _ = require("lodash");

export const CONVERSATION_COUNT = "conversationCount";
export const RESPONSE_TIME = "responseTime";
export const SATISFACTION = "satisfaction";
export const CONVERSATION_HOUR = "conversationHour";
export const CONVERSATION_DAY_HOUR = "conversationDayHour";

export const timeGroupDays = "DAYS";
export const timeGroupWeeks = "WEEKS";
export const timeGroupMonths = "MONTHS";

const initialState = {
  loading: false,
  filters: {
    agents: [],
    tags: [],
    labels: [],
    countries: [],
  },

  data: {
    [CONVERSATION_COUNT]: {
      data: [],
      loading: false,
      timeGroup: null,
      downloadData: [],
    },
    [RESPONSE_TIME]: {
      data: [],
      loading: false,
      timeGroup: null,
      downloadData: [],
    },
    [SATISFACTION]: {
      data: [],
      downloadData: [],
      loading: false,
      summary: {
        average: 0,
        difference: 0,
        ratings: [...Array(5).keys()].reduce(
          (o, i) => ({ ...o, [i + 1]: { count: 0, percentage: 0 } }),
          {},
        ),
      },
      timeGroup: null,
      dateData: {
        date: null,
        loading: false,
        ratings: [...Array(5).keys()].reduce(
          (o, i) => ({ ...o, [i + 1]: { count: 0, percentage: 0 } }),
          {},
        ),
      },
    },
    [CONVERSATION_HOUR]: {
      data: [],
      loading: false,
      extra: {
        maxDow: {
          dows: [],
          count: 0,
        },
        minDow: {
          dows: [],
          count: 0,
        },
        maxHour: {
          hours: [],
          count: 0,
        },
      },
    },
    [CONVERSATION_DAY_HOUR]: {
      data: [],
      loading: false,
    },
  },
};

function receiveFilterData(state, action) {
  const { agents, tags, labels, countries } = action.data;

  const stateCopy = _.cloneDeep(state);

  stateCopy.filters = {
    agents: agents
      .sort((a, b) => a.email.localeCompare(b.email))
      .map((a) => {
        return { label: a.email, value: a.id };
      }),
    tags: tags
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((t) => {
        return { label: t.name, value: t.id };
      }),
    labels: labels
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((l) => {
        return { label: l.name, value: l.id };
      }),
    countries: countries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((c) => {
        return { label: c.name, value: c.id };
      }),
  };
  stateCopy.loading = false;

  return stateCopy;
}

function receiveConversationCountData(state, action) {
  const { data, timeGroup, dates } = action;

  const stateCopy = _.cloneDeep(state);

  let downloadData = [];

  if (data.length != 0) {
    const channels = Object.keys(data.channels_total);
    let row = [];
    data.data.map((item) => {
      row.push(item.date, item.redirected, item.contacts);
      channels.map((channel) => {
        row.push(item[`channel ${channel}`]);
      });
      row.push(item.convs);
      downloadData.push(row);
      row = [];
    });
  }

  stateCopy.data[CONVERSATION_COUNT] = {
    data,
    loading: false,
    timeGroup,
    downloadData,
  };
  return stateCopy;
}

function receiveResponseTimeData(state, action) {
  const { data, timeGroup } = action;

  const stateCopy = _.cloneDeep(state);
  let downloadData = [];

  if (data.length != 0) {
    data.data.map((item) => {
      let row = [];
      row.push(
        item.date,
        item.pending_chats,
        item.chat_resolution,
        item.agent_completion,
      );
      downloadData.push(row);
    });
  }

  stateCopy.data[RESPONSE_TIME] = {
    data,
    loading: false,
    timeGroup,
    downloadData,
  };
  return stateCopy;
}

function receiveSatisfactionData(state, action) {
  const { data, timeGroup } = action;

  const stateCopy = _.cloneDeep(state);

  let formattedData = [];
  let years = [];

  let startDate = moment().startOf("month");
  let endDate = moment();

  if (data.data.length > 0) {
    startDate = moment(data.data[0].date, "YYYY-MM-DD");
    endDate = moment(data.data[data.data.length - 1].date, "YYYY-MM-DD");
  }

  let datesData = [];

  if (timeGroup === timeGroupWeeks) {
    startDate = startDate.startOf("week");
    endDate = endDate.startOf("week");
  } else if (timeGroup === timeGroupMonths) {
    startDate = startDate.startOf("month");
    endDate = endDate.startOf("month");
  }

  while (startDate <= endDate) {
    datesData.push(startDate.format("YYYY-MM-DD"));
    let nextDay = startDate
      .clone()
      .add(1, (timeGroup === null ? timeGroupDays : timeGroup).toLowerCase());
    startDate = nextDay.clone();
  }

  datesData.forEach((date) => {
    const year = date.split("-")[0];
    if (!years.includes(year)) {
      years.push(year);
    }

    const dateData = data.data.filter((db) => db.date === date);

    if (dateData.length === 0) {
      formattedData.push({ date, average: null, difference: null });
      return;
    }

    let total = 0;
    let sum = 0;
    dateData.forEach((dateObj) => {
      if (dateObj.rating !== null) {
        total += dateObj.count;
        sum += dateObj.count * dateObj.rating;
      }
    });

    const average = total > 0 ? Number((sum / total).toFixed(2)) : null;
    if (formattedData.length > 0 && average) {
      const prevAverage = formattedData[formattedData.length - 1].average;
      let difference = null;
      if (prevAverage) {
        difference = Number(
          (((average - prevAverage) / prevAverage) * 100).toFixed(2),
        );
      }
      formattedData.push({
        date,
        average,
        difference,
      });
    } else {
      formattedData.push({ date, average, difference: null });
    }
  });

  if (years.length > 1) {
    years.slice(1).forEach((year) => {
      const lastYearDate = formattedData.find((d) => {
        const splitDate = d.date.split("-");
        if (splitDate.length === 3) {
          const dateYear = splitDate[0];
          if (dateYear === year) {
            return d;
          }
        }
      });
      formattedData.splice(formattedData.indexOf(lastYearDate), 0, {
        date: year,
        average: null,
        difference: null,
        isYear: true,
      });
    });
  }

  const rawData = data.download;

  let downloadData = [];
  let usedDates = [];

  rawData.forEach((d) => {
    const date = d.date;
    if (!usedDates.includes(date)) {
      usedDates.push(date);
      const dateData = rawData.filter((dd) => dd.date === date);
      const objects = [...new Set(dateData.map((d) => d.object))];
      objects.forEach((o) => {
        const dateObjects = dateData.filter((dd) => dd.object === o);
        let dTotal = 0;
        let dSum = 0;
        dateObjects.forEach((dateObj) => {
          dTotal += dateObj.count;
          dSum += dateObj.count * dateObj.rating;
        });
        downloadData.push([o, date, (dSum / dTotal).toFixed(2)]);
      });
    }
  });

  stateCopy.data[SATISFACTION] = {
    ...state.data[SATISFACTION],
    data: formattedData,
    downloadData,
    summary: {
      ...state.data[SATISFACTION].summary,
      ratings: data.ratings,
      average: data.average,
      difference: data.difference,
    },
    loading: false,
  };
  return stateCopy;
}

function receiveSatisfactionDateData(state, action) {
  const { data, date } = action;

  const stateCopy = _.cloneDeep(state);

  if (stateCopy.data[SATISFACTION].dateData.date !== date) {
    stateCopy.data[SATISFACTION] = {
      ...state.data[SATISFACTION],
      dateData: {
        ...state.data[SATISFACTION].dateData,
        loading: false,
      },
    };
    return stateCopy;
  }

  stateCopy.data[SATISFACTION] = {
    ...state.data[SATISFACTION],
    dateData: {
      ...state.data[SATISFACTION].dateData,
      loading: false,
      ratings: data.ratings,
    },
  };
  return stateCopy;
}

function receiveConversationHourData(state, action) {
  const { data } = action;

  const stateCopy = _.cloneDeep(state);

  let formattedData = [];
  Object.entries(data.data).forEach(([dow, hours_count]) => {
    formattedData.push(hours_count);
  });

  stateCopy.data[CONVERSATION_HOUR] = {
    data: formattedData,
    extra: {
      maxDow: data.max_dow,
      minDow: data.min_dow,
      maxHour: data.max_hour,
    },
    loading: false,
  };
  return stateCopy;
}

function receiveConversationDayHourData(state, action) {
  const { data } = action;

  const stateCopy = _.cloneDeep(state);

  stateCopy.data[CONVERSATION_DAY_HOUR] = {
    data: data,
    loading: false,
  };
  return stateCopy;
}

export default (state = initialState, action) => {
  console.log("ACTION", action);
  switch (action.type) {
    // FILTER DATA
    case "RECEIVE_FILTER_DATA":
      return receiveFilterData(state, action);

    case "REQUEST_FILTER_DATA":
      return Object.assign({}, state, {
        loading: true,
        filters: {
          agents: [],
          tags: [],
          labels: [],
          countries: [],
        },
      });

    // CONVERSATION COUNT DATA
    case "RECEIVE_CONVERSATION_COUNT_DATA":
      return receiveConversationCountData(state, action);

    case "REQUEST_CONVERSATION_COUNT_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [CONVERSATION_COUNT]: {
            ...state.data[CONVERSATION_COUNT],
            data: [],
            loading: true,
            timeGroup: action.timeGroup,
          },
        },
      });

    // RESPONSE TIME DATA
    case "RECEIVE_RESPONSE_TIME_DATA":
      return receiveResponseTimeData(state, action);

    case "REQUEST_RESPONSE_TIME_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [RESPONSE_TIME]: {
            data: [],
            loading: true,
            downloadData: [],
            timeGroup: action.timeGroup,
          },
        },
      });

    // SATISFACTION DATA
    case "RECEIVE_SATISFACTION_DATA":
      return receiveSatisfactionData(state, action);

    case "REQUEST_SATISFACTION_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [SATISFACTION]: {
            ...state.data[SATISFACTION],
            data: [],
            downloadData: [],
            loading: true,
            timeGroup: action.timeGroup,
            summary: {
              average: 0,
              difference: 0,
              ratings: [...Array(5).keys()].reduce(
                (o, i) => ({ ...o, [i + 1]: { count: 0, percentage: 0 } }),
                {},
              ),
            },
            dateData: {
              date: null,
              loading: false,
              ratings: [...Array(5).keys()].reduce(
                (o, i) => ({ ...o, [i + 1]: { count: 0, percentage: 0 } }),
                {},
              ),
            },
          },
        },
      });

    // SATISFACTION DATE DATA
    case "RECEIVE_SATISFACTION_DATE_DATA":
      return receiveSatisfactionDateData(state, action);

    case "REQUEST_SATISFACTION_DATE_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [SATISFACTION]: {
            ...state.data[SATISFACTION],
            dateData: {
              date: action.date,
              loading: true,
              ratings: [...Array(5).keys()].reduce(
                (o, i) => ({ ...o, [i + 1]: { count: 0, percentage: 0 } }),
                {},
              ),
            },
          },
        },
      });

    // CONVERSATION HOUR DATA
    case "RECEIVE_CONVERSATION_HOUR_DATA":
      return receiveConversationHourData(state, action);

    case "REQUEST_CONVERSATION_HOUR_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [CONVERSATION_HOUR]: {
            data: [],
            loading: true,
            extra: {
              maxDow: {
                dows: [],
                count: 0,
              },
              minDow: {
                dows: [],
                count: 0,
              },
              maxHour: {
                hours: [],
                count: 0,
              },
            },
          },
        },
      });

    // DAY HOUR DATA
    case "RECEIVE_CONVERSATION_DAY_HOUR_DATA":
      return receiveConversationDayHourData(state, action);

    case "REQUEST_CONVERSATION_DAY_HOUR_DATA":
      return Object.assign({}, state, {
        data: {
          ...state.data,
          [CONVERSATION_DAY_HOUR]: {
            data: [],
            loading: true,
          },
        },
      });

    default:
      return state;
  }
};
