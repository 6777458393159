import React from "react";

import languages from "./languages";
import onClickOutside from "react-onclickoutside";

import "./TimeDropdown.scss";
import SelectDropdown from "../SelectDropdown/SelectDropdown";

const MINUTES = "MINUTES";
const HOURS = "HOURS";
const DAYS = "DAYS";

const DEFAULT_QUANTITY = 30;

class TimeDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      units: props.units,
      quantity: props.quantity,
      open: false,
    };

    this.strings = languages[props.language];
  }

  handleClickOutside = (evt) => {
    if (this.props.hideOnClickOutside === false) return;
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.units !== this.props.units) {
      this.setState({ units: this.props.units });
    }
    if (prevProps.quantity !== this.props.quantity) {
      this.setState({ quantity: this.props.quantity });
    }
  }

  saveChanges = () => {
    let { units, quantity } = this.state;
    if (units === null) {
      units = MINUTES;
    }
    if (quantity === null) {
      quantity = DEFAULT_QUANTITY;
    }
    this.props.saveChanges(units, quantity);
    this.setState({ open: false });
  };

  reset = () => {
    this.props.saveChanges(null, null);
    this.setState({ open: false });
  };

  renderTitle = () => {
    const { units, quantity } = this.state;
    if (units !== null && quantity !== null) {
      return `${this.strings.moreThan} ${quantity} ${
        this.strings[units.toLowerCase()]
      } ${this.strings.withoutResponse}`;
    } else {
      return this.props.noSelectionTitle;
    }
  };

  renderBody = () => {
    const timeUnits = [
      {
        value: MINUTES,
        text: this.strings.minutes,
      },
      {
        value: HOURS,
        text: this.strings.hours,
      },
      {
        value: DAYS,
        text: this.strings.days,
      },
    ];
    return (
      <div>
        <p>{this.strings.moreThan}</p>
        <div className="flex">
          <input
            type="number"
            placeholder="30"
            onChange={(e) => {
              this.setState({ quantity: e.target.value });
            }}
            value={this.state.quantity}
          />
          <SelectDropdown
            noSelectionTitle={this.strings.minutes}
            className={`time-dropdown-units`}
            options={timeUnits}
            display={(unit) => <p className="r lh-22">{unit.text}</p>}
            onSelect={(unit) => {
              this.setState({ units: unit.value });
            }}
            value={
              this.state.units !== null
                ? timeUnits.filter((unit) => unit.value === this.state.units)[0]
                : null
            }
          />
        </div>
        <p>{this.strings.withoutResponse}</p>
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className="actions">
        <a onClick={this.reset}>{this.strings.erase}</a>
        <button onClick={this.saveChanges}>{this.strings.save}</button>
      </div>
    );
  };

  render() {
    return (
      <div
        className={`dropdown ${
          this.state.open ? "is-active" : ""
        } time-dropdown`}
      >
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => this.setState({ open: !this.state.open })}
          >
            <p>{this.renderTitle()}</p>
            <span className="icon is-small">
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {this.renderBody()}
            <hr />
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(TimeDropdown);
