import moment from "moment";
import { request } from "../utils/request";

const formatFiltersQueryString = (
  objectType,
  objectFilter,
  countriesFilter,
  dates,
  timeGroup = null,
) => {
  let baseQS = `?tz=${moment.tz.guess()}`;

  const objectTypeQS = objectType.toLowerCase();

  // Object filter
  baseQS += `&object=${objectTypeQS}`;
  if (objectFilter !== null && !Array.isArray(objectFilter)) {
    baseQS += `&${objectTypeQS}=${objectFilter}`;
  } else if (Array.isArray(objectFilter) && objectFilter.length > 0) {
    baseQS += `&${objectTypeQS}=${objectFilter.join()}`;
  }

  // Countries filter
  if (countriesFilter.length > 0) {
    baseQS += `&countries=${countriesFilter
      .map((cc) => cc.replace("+", ""))
      .join()}`;
  }

  // Dates filter
  if (dates["start"] != "" && dates["end"] != "") {
    baseQS += `&start=${dates["start"]}&end=${dates["end"]}`;
  } else if (dates["start"] != "") {
    baseQS += `&start=${dates["start"]}&end=${dates["start"]}`;
  }

  if (timeGroup !== null) {
    baseQS += `&timeGroup=${timeGroup.toLowerCase()}`;
  }

  return baseQS;
};

// Retrieve filter data

const REQUEST_FILTER_DATA = "REQUEST_FILTERS_DATA";
export const requestFilterData = () => (dispatch) => {
  dispatch({
    type: REQUEST_FILTER_DATA,
  });
};

const RECEIVE_FILTER_DATA = "RECEIVE_FILTER_DATA";
export const receiveFilterData = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_FILTER_DATA,
    data,
  });
};

const REQUEST_FILTER_ERROR = "REQUEST_FILTERS_ERROR";
export const requestFilterDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_FILTER_ERROR,
    error,
  });
};

export const getFilterData = (language) => (dispatch) => {
  dispatch(requestFilterData());

  request(
    "GET",
    `/admin/metrics/filters?lang=${language}`,
    null,
    true,
    (data) => {
      return dispatch(receiveFilterData(data));
    },
    (error) => {
      console.log(error);
      return dispatch(requestFilterDataError("Error retrieving filter data"));
    },
  );
};

// Retrieve conversation count data

const REQUEST_CONVERSATION_COUNT_DATA = "REQUEST_CONVERSATION_COUNT_DATA";
export const requestConversationCountData = (timeGroup) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_COUNT_DATA,
    timeGroup,
  });
};

const RECEIVE_CONVERSATION_COUNT_DATA = "RECEIVE_CONVERSATION_COUNT_DATA";
export const receiveConversationCountData = (data, timeGroup) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONVERSATION_COUNT_DATA,
    data,
    timeGroup,
  });
};

const REQUEST_CONVERSATION_COUNT_ERROR = "REQUEST_CONVERSATION_COUNT_ERROR";
export const requestConversationCountDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_COUNT_ERROR,
    error,
  });
};

export const getConversationCountData =
  (objectType, objectFilter, countriesFilter, dates, timeGroup) =>
  (dispatch) => {
    dispatch(requestConversationCountData(timeGroup));

    request(
      "GET",
      `/admin/metrics/conversations${formatFiltersQueryString(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      )}`,
      null,
      true,
      (data) => {
        return dispatch(receiveConversationCountData(data, timeGroup));
      },
      (error) => {
        console.log(error);
        return dispatch(
          receiveConversationCountData(
            "Error retrieving conversation hour data",
          ),
        );
      },
    );
  };

// Retrieve response time data

const REQUEST_RESPONSE_TIME_DATA = "REQUEST_RESPONSE_TIME_DATA";
export const requestResponseTimeData = () => (dispatch) => {
  dispatch({
    type: REQUEST_RESPONSE_TIME_DATA,
  });
};

const RECEIVE_RESPONSE_TIME_DATA = "RECEIVE_RESPONSE_TIME_DATA";
export const receiveResponseTimeData = (data, timeGroup) => (dispatch) => {
  dispatch({
    type: RECEIVE_RESPONSE_TIME_DATA,
    data,
    timeGroup,
  });
};

const REQUEST_RESPONSE_TIME_ERROR = "REQUEST_RESPONSE_TIME_ERROR";
export const requestResponseTimeDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_RESPONSE_TIME_ERROR,
    error,
  });
};

export const getResponseTimeData =
  (objectType, objectFilter, countriesFilter, dates, timeGroup) =>
  (dispatch) => {
    dispatch(requestResponseTimeData());

    request(
      "GET",
      `/admin/metrics/response-times${formatFiltersQueryString(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      )}`,
      null,
      true,
      (data) => {
        return dispatch(receiveResponseTimeData(data, timeGroup));
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestResponseTimeDataError("Error retrieving response time data"),
        );
      },
    );
  };

// Retrieve satisfaction data

const REQUEST_SATISFACTION_DATA = "REQUEST_SATISFACTION_DATA";
export const requestSatisfactionData = (timeGroup) => (dispatch) => {
  dispatch({
    type: REQUEST_SATISFACTION_DATA,
    timeGroup,
  });
};

const RECEIVE_SATISFACTION_DATA = "RECEIVE_SATISFACTION_DATA";
export const receiveSatisfactionData = (data, timeGroup) => (dispatch) => {
  dispatch({
    type: RECEIVE_SATISFACTION_DATA,
    data,
    timeGroup,
  });
};

const REQUEST_SATISFACTION_ERROR = "REQUEST_SATISFACTION_ERROR";
export const requestSatisfactionDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_SATISFACTION_ERROR,
    error,
  });
};

export const getSatisfactionData =
  (objectType, objectFilter, countriesFilter, dates, timeGroup) =>
  (dispatch) => {
    dispatch(requestSatisfactionData(timeGroup));

    request(
      "GET",
      `/admin/metrics/satisfaction${formatFiltersQueryString(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      )}`,
      null,
      true,
      (data) => {
        return dispatch(receiveSatisfactionData(data, timeGroup));
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestSatisfactionDataError("Error retrieving satisfaction data"),
        );
      },
    );
  };

// Retrieve satisfaction date data

const REQUEST_SATISFACTION_DATE_DATA = "REQUEST_SATISFACTION_DATE_DATA";
export const requestSatisfactionDateData = (date) => (dispatch) => {
  dispatch({
    type: REQUEST_SATISFACTION_DATE_DATA,
    date,
  });
};

const RECEIVE_SATISFACTION_DATE_DATA = "RECEIVE_SATISFACTION_DATE_DATA";
export const receiveSatisfactionDateData = (date, data) => (dispatch) => {
  dispatch({
    type: RECEIVE_SATISFACTION_DATE_DATA,
    data,
    date,
  });
};

const REQUEST_SATISFACTION_DATE_ERROR = "REQUEST_SATISFACTION_DATE_ERROR";
export const requestSatisfactionDateDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_SATISFACTION_DATE_ERROR,
    error,
  });
};

export const getSatisfactionDateData =
  (objectType, objectFilter, countriesFilter, dates, timeGroup, date) =>
  (dispatch) => {
    dispatch(requestSatisfactionDateData(date));

    request(
      "GET",
      `/admin/metrics/satisfaction/date${formatFiltersQueryString(
        objectType,
        objectFilter,
        countriesFilter,
        dates,
        timeGroup,
      )}&date=${date}`,
      null,
      true,
      (data) => {
        return dispatch(receiveSatisfactionDateData(date, data));
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestSatisfactionDateDataError(
            "Error retrieving satisfaction date data",
          ),
        );
      },
    );
  };

// Retrieve conversations hour data

const REQUEST_CONVERSATION_HOUR_DATA = "REQUEST_CONVERSATION_HOUR_DATA";
export const requestConversationHourData = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_HOUR_DATA,
  });
};

const RECEIVE_CONVERSATION_HOUR_DATA = "RECEIVE_CONVERSATION_HOUR_DATA";
export const receiveConversationHourData = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONVERSATION_HOUR_DATA,
    data,
  });
};

const REQUEST_CONVERSATION_HOUR_ERROR = "REQUEST_CONVERSATION_HOUR_ERROR";
export const requestConversationHourDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_HOUR_ERROR,
    error,
  });
};

export const getConversationHourData =
  (objectType, objectFilter, countriesFilter, dates) => (dispatch) => {
    dispatch(requestConversationHourData());

    const url = `/admin/metrics/conversation-hours${formatFiltersQueryString(
      objectType,
      objectFilter,
      countriesFilter,
      dates,
    )}`;

    request(
      "GET",
      url,
      null,
      true,
      (data) => {
        return dispatch(receiveConversationHourData(data));
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestConversationHourDataError(
            "Error retrieving conversation hour data",
          ),
        );
      },
    );
  };

// Retrieve day hour data

const REQUEST_CONVERSATION_DAY_HOUR_DATA = "REQUEST_CONVERSATION_DAY_HOUR_DATA";
export const requestConversationDayHourData = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_DAY_HOUR_DATA,
  });
};

const RECEIVE_CONVERSATION_DAY_HOUR_DATA = "RECEIVE_CONVERSATION_DAY_HOUR_DATA";
export const receiveConversationDayHourData = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONVERSATION_DAY_HOUR_DATA,
    data,
  });
};

const REQUEST_CONVERSATION_DAY_HOUR_ERROR =
  "REQUEST_CONVERSATION_DAY_HOUR_ERROR";
export const requestConversationDayHourDataError = (error) => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_DAY_HOUR_ERROR,
    error,
  });
};

export const getConversationDayHourData =
  (objectType, objectFilter, countriesFilter, dow, hour, dates) =>
  (dispatch) => {
    dispatch(requestConversationDayHourData());

    const url = `/admin/metrics/conversation-hours/day${formatFiltersQueryString(
      objectType,
      objectFilter,
      countriesFilter,
      dates,
    )}&dow=${dow}&hour=${hour}`;

    request(
      "GET",
      url,
      null,
      true,
      (data) => {
        return dispatch(receiveConversationDayHourData(data));
      },
      (error) => {
        console.log(error);
        return dispatch(
          requestConversationDayHourDataError(
            "Error retrieving conversation day hour data",
          ),
        );
      },
    );
  };
