import React from "react";
import withTrackOnChange from "./withTrackOnChange";

/*
  props:
    All props are taken by the withTrackOnChange HOC and passed to this component
    - placeholder str: text to be displayed when empty
    - type str: type of input text (e.g text, email, password, tel)
    - onChange function([obj]): to be called when something is written in the input
    - value str: value to be written
    - Any other
    Due to withTrackOnChange, this componenet receives two special props to track the onChange event
    - trackMessage str: event name to be sent to events.track (if it's undefined the tracking will not be done)
    - trackData obj: extra data to add to events.track (text value is always tracked)
*/

const TextInput = (props) => {
  return <input {...props} />;
};

export default withTrackOnChange(TextInput);
