import React from "react";
import { connect } from "react-redux";

import { setHubspotPipelineStage } from "../../../../../actions/hubspotAction";

import TrebleIcon from "../../../../../components/TrebleIcon/TrebleIcon";

import { Button, Select, Radio } from "antd";
import "./HubspotCloseModal.scss";
import events from "../../../../../utils/events";

const { Option } = Select;

const CLOSED = "CLOSED";
const OPEN = "OPEN";

class HubspotCloseModal extends React.Component {
  constructor(props) {
    super(props);

    const defaultPipelines = props.pipelines.filter((p) => p.value === "0");

    if (props.hubspot.pipeline && props.hubspot.stage) {
      let pipeline = props.hubspot.pipeline;
      let stage = props.hubspot.stage;

      let selectedPipeline =
        defaultPipelines.length > 0
          ? defaultPipelines[0].label
          : props.pipelines[0].label;
      let closed = CLOSED;
      let selectedStage = null;

      const valuePipelines = props.pipelines.filter(
        (p) => p.value === pipeline.value,
      );
      if (valuePipelines.length) {
        selectedPipeline = valuePipelines[0].label;
        const valueStages = valuePipelines[0].stages.filter(
          (s) => s.value === stage.value,
        );
        if (valueStages.length) {
          const valueStage = valueStages[0];
          closed = valueStage.open === true ? OPEN : CLOSED;
          selectedStage = valueStage.label;
        }
      }

      this.state = {
        stageType: closed,
        selectedPipeline: selectedPipeline,
        selectedStage: selectedStage,
      };
    } else {
      this.state = {
        stageType: CLOSED,
        selectedPipeline:
          defaultPipelines.length > 0
            ? defaultPipelines[0].label
            : props.pipelines[0].label,
        selectedStage: null,
      };
    }
  }

  getPipeline = () => {
    const valuePipeline = this.props.pipelines.filter(
      (p) => p.label === this.state.selectedPipeline,
    );
    return valuePipeline.length > 0 ? valuePipeline[0] : null;
  };

  getStage = () => {
    const valuePipeline = this.props.pipelines.filter(
      (p) => p.label === this.state.selectedPipeline,
    );
    const valueStage = valuePipeline[0].stages.filter(
      (s) => s.label === this.state.selectedStage,
    );

    return valueStage.length > 0 ? valueStage[0] : null;
  };

  render() {
    const pipeline = this.getPipeline();

    const stages = pipeline.stages.filter((stage) => {
      if (this.state.stageType === CLOSED) {
        return stage.open === false;
      } else {
        return stage.open === true;
      }
    });

    return (
      <div className="hubspot-close-conversation">
        <p>{this.props.strings.hubspotCloseModalD}</p>
        <div className="controls">
          <div className="flex-row">
            <Select
              suffixIcon={<div className="icon icon--select-arrow" />}
              className="stage-status"
              onChange={(status) =>
                this.setState({ stageType: status, selectedStage: null })
              }
              value={this.state.stageType}
              dropdownClassName="stage-status"
            >
              <Option value={CLOSED}>
                {this.props.strings.closed}
                {this.state.stageType === CLOSED && <TrebleIcon name="check" />}
              </Option>
              <Option value={OPEN}>
                {this.props.strings.open}
                {this.state.stageType === OPEN && <TrebleIcon name="check" />}
              </Option>
            </Select>
            <Select
              suffixIcon={<div className="icon icon--select-arrow" />}
              className="pipeline"
              onChange={(pipeline) => {
                this.setState({
                  selectedPipeline: pipeline,
                  selectedStage: null,
                });
                events.track("Agent change close ticket stage and pipeline", {
                  Pipeline: pipeline,
                  Stage: null,
                });
              }}
              value={this.state.selectedPipeline}
              dropdownClassName="pipeline"
            >
              {this.props.pipelines.map((pipeline) => (
                <Option value={pipeline.label}>
                  {pipeline.label}
                  {this.state.selectedPipeline === pipeline.label && (
                    <TrebleIcon name="check" />
                  )}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex-col">
            <Radio.Group
              onChange={(stage) => {
                this.setState({ selectedStage: stage.target.value });
                events.track("Agent change close ticket stage and pipeline", {
                  Pipeline: this.state.selectedPipeline,
                  Stage: stage.target.value,
                });
              }}
              value={this.state.selectedStage}
            >
              {stages.map((stage) => (
                <Radio value={stage.label}>{stage.label}</Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
        <div className="buttons">
          <Button
            className="close"
            disabled={
              this.state.selectedPipeline === null ||
              this.state.selectedStage === null
            }
            onClick={() => {
              const pipeline = this.getPipeline();
              const stage = this.getStage();
              if (pipeline && stage) {
                this.props.finishConversation(pipeline.value, stage.value);
                this.props.setHubspotPipelineStage(pipeline, stage);
              }
            }}
          >
            {this.props.strings.footerEndChat}
          </Button>
          <Button className="cancel" onClick={this.props.close}>
            {this.props.strings.footerCancelEndChat}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hubspot: state.mainReducer.hubspot,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setHubspotPipelineStage: (pipeline, stage) =>
    dispatch(setHubspotPipelineStage(pipeline, stage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubspotCloseModal);
