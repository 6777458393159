export const colorOptions = [
  "default",
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "lavender",
  "classic",
];
