import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
  ReferenceLine,
} from "recharts";

import Loading from "../Loading/Loading";
import Empty from "../Empty/Empty";

import MultipleSelectDropdown from "../../../../../Platform/Metrics/Components/MultipleSelectDropdown/MultipleSelectDropdown";
import SelectDropdown from "../../../../../Platform/Metrics/Components/SelectDropdown/SelectDropdown";
import TrebleIcon from "../../../../../../components/TrebleIcon/TrebleIcon";

import {
  CONVERSATION_COUNT,
  timeGroupDays,
  timeGroupWeeks,
  timeGroupMonths,
} from "../../../../../../reducers/metricsReducer";

import events from "../../../../../../utils/events";

import { CSVLink } from "react-csv";

import moment from "moment";

import languages from "./language";

import "./ConversationCount.scss";

const mapLanguageToMomentLocale = {
  es: "es",
  en: "en",
  pt: "pt-br",
};

const colors = [
  "#6464FF",
  "#ADADFF",
  "#D6D6FF",
  "#3333FF",
  "#0000F5",
  "#0000B8",
  "#00007A",
  "#000054",
  "#000008",
  "#FFE39C",
  "#FFBF1F",
  "#E0A100",
  "#B88400",
  "#8F6600",
  "#664900",
];

class CustomizedDot extends React.Component {
  render() {
    const {
      cx,
      cy,
      chart,
      handleMouseOver,
      hoveredChart,
      payload,
      dataLength,
      dataKey,
    } = this.props;
    let isInHoveredChart = chart == hoveredChart;
    let color = "";

    if (payload.date.length == 4) {
      return null;
    }

    if (dataLength && dataLength == 1) {
      if (dataKey.includes("contacts")) {
        color = "#FF4668";
      } else if (dataKey.includes("redirected")) {
        color = "#00003D";
      }
    } else {
      return null;
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        stroke={isInHoveredChart ? "#FF4668" : "transparent"}
        strokeWidth={6}
        fill={color}
        onMouseOver={(e) => {
          handleMouseOver(chart, e);
        }}
      />
    );
  }
}

class CustomizedActiveDot extends React.Component {
  render() {
    const { cx, cy, chart, handleMouseOver, hoveredChart, payload, color } =
      this.props;
    let isInHoveredChart = chart == hoveredChart;

    if (payload.date.length == 4) {
      return null;
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        stroke={isInHoveredChart ? color : "transparent"}
        strokeWidth={6}
        fill={isInHoveredChart ? "#FFF" : "transparent"}
        onMouseOver={(e) => {
          handleMouseOver(chart, e);
        }}
      />
    );
  }
}

class CustomTooltip extends React.Component {
  render() {
    const { active, payload, label, hoveredChart, strings } = this.props;

    if (active && payload && payload.length) {
      let totalConvs = 0;
      let isLineHovered = hoveredChart ? hoveredChart.includes("Line") : null;
      let isBarHovered = hoveredChart ? hoveredChart.includes("Bar") : null;
      let mainText = strings["totalReceivedChats"];

      if (!isBarHovered && !isLineHovered) {
        return null;
      }

      if (hoveredChart) {
        if (hoveredChart.includes("Line")) {
          mainText = hoveredChart.includes("1")
            ? strings["totalDistinctContacts"]
            : strings["totalTransferConvs"];
        }
      }

      payload.map((item) => {
        if (isBarHovered && item.name.includes("channel")) {
          totalConvs += item.value;
        } else if (isLineHovered && item.stroke != "transparent") {
          totalConvs = item.value;
        }
      });

      return (
        <div className="custom-tooltip">
          <div className="tooltip-header row">
            <TrebleIcon name="clock" />
            <p className="label">{`${moment(label, "YYYY-MM-DD").format(
              "DD-MM-YYYY",
            )}`}</p>
          </div>
          <div className="tooltip-body col">
            <div className="row">
              <p>{mainText}</p>
              <p className="value">{totalConvs}</p>
            </div>
            {!isLineHovered &&
              payload.length > 3 &&
              payload.map((item) => {
                if (item.name.includes("channel") && item.value != 0) {
                  return (
                    <div className="item row">
                      <span
                        className="item-color"
                        style={{ backgroundColor: item.fill }}
                      ></span>
                      <p>{item.name.replace("channel", strings["channel"])}</p>
                      <p className="value">{item.value}</p>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      );
    }
    return null;
  }
}
class ConversationCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredChart: null,
      hoveredBar: null,
      hoveredLine: null,
      timeGroup: timeGroupDays,
      allowedCharts: {},
    };

    this.strings = languages[props.language];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timeGroup !== this.props.timeGroup) {
      this.setState({ timeGroup: this.props.timeGroup });
    }

    if (this.props.data.channels_total && prevProps.loading == true) {
      let _allowedCharts = {};
      _allowedCharts[this.strings["distinctContacts"]] = true;
      _allowedCharts[this.strings["transferredConvs"]] = true;
      Object.keys(this.props.data.channels_total).map((channel) => {
        _allowedCharts[`Channel ${channel}`] = true;
      });
      this.setState({ allowedCharts: _allowedCharts });
    }
  }

  getDownloadData = () => {
    let { channels_total } = this.props.data;
    let channels = [];

    if (!channels_total) {
      return [];
    }

    Object.keys(channels_total).map((channel) => {
      channels.push(`${this.strings.channel} ${channel}`);
    });

    const headers = [
      this.strings.date,
      this.strings.transferredConvs,
      this.strings.distinctContacts,
      ...channels,
      this.strings.totalReceivedChats,
    ];
    return [headers, ...this.props.downloadData];
  };

  changeTimeGroup = (timeGroup) => {
    this.setState({ timeGroup });
    this.props.getConversationCountData(timeGroup);
    events.track("Agent changed metrics time unit", {
      "Metric type": CONVERSATION_COUNT,
      "Time unit": timeGroup,
    });
  };

  handleMouseOver = (chart, e) => {
    let date = e.date ? e.date : null;

    this.setState({
      hoveredChart: chart,
      hoveredBar: chart.includes("Bar") ? date : null,
      hoveredLine: chart.includes("Line") ? date : null,
    });
  };

  handleMouseMove = (e) => {
    this.setState({
      hoveredLabel: e.activeLabel,
    });
  };

  renderBarCharts = (data) => {
    let barList = [];
    let _i = 0;

    const barWidth = data.length >= 5 ? 50 : 100;

    Object.keys(data[0]).map((key, i) => {
      if (
        key.includes("channel") &&
        this.state.allowedCharts[key.replace("channel", "Channel")]
      ) {
        barList.push(
          <Bar
            yAxisId="left"
            dataKey={key}
            stackId="a"
            fill={colors[_i]}
            onMouseOver={(e) => this.handleMouseOver("Bar" + _i, e)}
            onMouseLeave={(e) =>
              this.setState({
                hoveredChart: null,
                hoveredBar: null,
                hoveredLine: null,
              })
            }
            barSize={barWidth}
          >
            {data.map((entry, index) => {
              if (this.state.hoveredChart != null) {
                if (
                  !this.state.hoveredChart.includes("Bar") ||
                  !this.state.hoveredBar ||
                  this.state.hoveredBar != entry.date
                ) {
                  return <Cell fill={"#F5F5F5"} />;
                }
              }
              return <Cell fill={colors[_i]} />;
            })}
          </Bar>,
        );
        _i += 1;
      }
    });

    return barList;
  };

  renderLineCharts = (data) => {
    let lineList = [];
    let _i = 0;

    Object.keys(data[0]).map((key, i) => {
      let color = "";
      if (
        this.state.hoveredChart === "Line" + _i ||
        this.state.hoveredChart == null
      ) {
        color = key == "redirected" ? "#00003D" : "#FF4668";
      } else {
        color = "transparent";
      }

      if (
        !key.includes("channel") &&
        ((key == "redirected" &&
          this.state.allowedCharts[this.strings["transferredConvs"]]) ||
          (key == "contacts" &&
            this.state.allowedCharts[this.strings["distinctContacts"]]))
      ) {
        lineList.push(
          <Line
            yAxisId="left"
            dataKey={key}
            stroke={color}
            strokeWidth={2}
            strokeDasharray={key == "redirected" ? "5 5" : null}
            dot={
              <CustomizedDot
                chart={"Line" + _i}
                handleMouseOver={this.handleMouseOver}
                hoveredChart={this.state.hoveredChart}
                dataLength={data?.length}
              />
            }
            activeDot={
              <CustomizedActiveDot
                color={color}
                chart={"Line" + _i}
                handleMouseOver={this.handleMouseOver}
                hoveredChart={this.state.hoveredChart}
                dataLength={data?.length}
              />
            }
            onMouseOver={(e) => this.handleMouseOver("Line" + _i, e)}
          />,
        );
        _i += 1;
      }
    });

    return lineList;
  };

  formatDateTick = (tick) => {
    const timeGroup = this.state.timeGroup;

    let localeMoment = moment();
    localeMoment.locale(mapLanguageToMomentLocale[this.props.language]);

    const parsedDate = moment(tick, "YYYY-MM-DD", true);

    if (!parsedDate.isValid()) {
      return tick;
    }

    if (timeGroup === timeGroupDays || timeGroup === null) {
      return parsedDate.format("MMM DD");
    } else if (timeGroup === timeGroupWeeks) {
      const startOfWeek = parsedDate.clone().startOf("week");
      const endOfWeek = parsedDate.clone().endOf("week");
      return `${startOfWeek.format("MMM DD")} - ${endOfWeek.format("MMM DD")}`;
    } else if (timeGroup === timeGroupMonths) {
      return parsedDate.format("MMMM");
    }
  };

  renderChart = () => {
    const { data } = this.props.data;
    let { channels_total } = this.props.data;

    if (!data || data.length == 0) {
      return;
    }

    let width = data.length * 120;
    let barWidth = this.props.data.data.length >= 5 ? 50 : 120;

    let w = window.innerWidth;
    if (width < w - 580) {
      width = w - 580;
    }

    if (this.state.timeGroup === timeGroupWeeks && barWidth === 50) {
      width *= 1.5;
    }

    let chartBodyWidth =
      Object.keys(channels_total).length <= 1 ? "100%" : "1000px";

    return (
      <div className="conversation-chart">
        <div className="chart-options">
          <SelectDropdown
            options={[
              { label: this.strings.days, value: timeGroupDays },
              { label: this.strings.weeks, value: timeGroupWeeks },
              { label: this.strings.months, value: timeGroupMonths },
            ]}
            _key="satisfaction-time-group-filter"
            placement="bottomRight"
            placeholder={this.strings.days}
            onSelect={this.changeTimeGroup}
            value={this.state.timeGroup}
          />
        </div>
        <div className={`chart-body`} style={{ width: chartBodyWidth }}>
          <ComposedChart
            width={width}
            height={340}
            data={data}
            margin={{
              top: 15,
              right: 0,
              bottom: 10,
              left: 0,
            }}
            onMouseMove={(state) => {
              if (!state.isTooltipActive) {
                this.setState({
                  hoveredChart: null,
                  hoveredBar: null,
                  hoveredLine: null,
                });
              }
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="100 0" />
            <XAxis
              dataKey="date"
              dy={15}
              axisLine={{ stroke: "#000" }}
              tick={{
                fill: "#262626",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "HK Grotesk",
              }}
              tickFormatter={this.formatDateTick}
              tickLine={false}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
            />
            <Tooltip
              content={
                <CustomTooltip
                  hoveredChart={this.state.hoveredChart}
                  strings={this.strings}
                />
              }
            />
            <Legend />
            {this.props.data.data
              .filter((d) => d.is_year === true)
              .map((year) => (
                <ReferenceLine
                  x={year.date}
                  stroke="#E8E8E8"
                  strokeWidth={2}
                  yAxisId="left"
                />
              ))}
            {this.renderBarCharts(data)}
            {this.renderLineCharts(data)}
          </ComposedChart>
        </div>
      </div>
    );
  };

  renderSideBar = () => {
    let { channels_total } = this.props.data;
    let isLineHovered = this.state.hoveredChart?.includes("Line");

    if (!channels_total || Object.keys(channels_total).length <= 1) {
      return;
    }

    return (
      <div
        className={`conversation-sidebar ${isLineHovered ? "disabled" : ""}`}
      >
        {Object.keys(channels_total).map((key, i) => {
          return (
            <div className={`sidebar-item ${i != 0 ? "middle" : ""}`}>
              <div
                className="label-color"
                style={{
                  backgroundColor: `${!isLineHovered ? colors[i] : "#B2B2B2"}`,
                }}
              ></div>
              <div className="info">
                <p>{`${this.strings["channel"]} ${key}`}</p>
                <div className="small-info">
                  <span>
                    <b>{this.strings["total"]}: </b>
                    {channels_total[key].value}
                  </span>
                  <span className="end">
                    <b>{this.strings["average"].slice(0, 4)}: </b>
                    {channels_total[key].avg.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  getSelectedCharts = () => {
    let selectedCharts = [];

    Object.keys(this.state.allowedCharts).map((key, i) => {
      if (this.state.allowedCharts[key]) {
        selectedCharts.push(i);
      }
    });

    return selectedCharts;
  };

  onSelectChart = (options) => {
    let _allowedCharts = { ...this.state.allowedCharts };

    options.map((opt, i) => {
      let keys = Object.keys(this.state.allowedCharts);
      if (typeof opt == "string") {
        _allowedCharts[keys[parseInt(opt)]] =
          !_allowedCharts[keys[parseInt(opt)]];
      }
    });

    this.setState({
      allowedCharts: _allowedCharts,
    });
  };

  renderHeader = () => {
    const headerItems = [
      this.strings["receivedChats"],
      this.strings["distinctContacts"],
      this.strings["transferredConvs"],
    ];

    let {
      total_convs,
      total_contacts,
      total_redirected,
      convs_average,
      contacts_average,
      redirected_average,
    } = this.props.data;

    return (
      <div className="conversation-header">
        {headerItems.map((value, i) => {
          let total = 0;
          let average = 0;
          let isDisabled = this.state.hoveredChart?.includes("Bar");
          if (i == 0) {
            total = total_convs;
            average = convs_average;
          } else if (i == 1) {
            total = total_contacts;
            average = contacts_average;
            if (!isDisabled && this.state.hoveredChart?.includes("0")) {
              isDisabled = true;
            }
          } else {
            total = total_redirected;
            average = redirected_average;
            if (!isDisabled && this.state.hoveredChart?.includes("1")) {
              isDisabled = true;
            }
          }
          return (
            <div
              className={`header-item ${i != 0 ? "middle" : ""} ${
                isDisabled ? "disabled" : ""
              }`}
            >
              <p>
                {value}
                {(i == 1 || i == 2) && (
                  <hr
                    className={`line ${
                      i == 1 ? "line-red" : i == 2 ? "line-dashed" : ""
                    } ${isDisabled ? "disabled" : ""}`}
                  />
                )}
              </p>
              <div className="small-info">
                <span>
                  <b>{this.strings["total"]}: </b>
                  {total}
                </span>
                <span>
                  <b className="end">{this.strings["average"].slice(0, 4)}: </b>
                  {average?.toFixed(2)}
                </span>
              </div>
            </div>
          );
        })}
        <div className="header-item end">
          <div className="actions">
            <MultipleSelectDropdown
              options={Object.keys(this.state.allowedCharts).map((key, i) => {
                return { label: key, value: i };
              })}
              _key="conversation-filter"
              onSelect={(option) => this.onSelectChart(option)}
              value={this.getSelectedCharts()}
            >
              <div className="icon icon--filters" />
            </MultipleSelectDropdown>

            <CSVLink
              data={this.getDownloadData()}
              filename={`${"conversations"}-${moment().format(
                "YYYY-MM-DD",
              )}.csv`}
            >
              <div className="icon icon--download" />
            </CSVLink>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.loading && this.props.data.data?.length == 0) {
      return <Empty name={CONVERSATION_COUNT} />;
    }

    const content = (
      <div className="conversation-count">
        {this.renderHeader()}
        <div className="conversation-body">
          {this.renderSideBar()}
          {this.renderChart()}
        </div>
      </div>
    );

    return (
      <div className="graph" id="conversation-count" key={CONVERSATION_COUNT}>
        {this.props.loading ? <Loading /> : content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
  data: state.metricsReducer.data[CONVERSATION_COUNT].data,
  extra: state.metricsReducer.data[CONVERSATION_COUNT].extra,
  loading: state.metricsReducer.data[CONVERSATION_COUNT].loading,
  timeGroup: state.metricsReducer.data[CONVERSATION_COUNT].timeGroup,
  downloadData: state.metricsReducer.data[CONVERSATION_COUNT].downloadData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCount);
