import React, { Component } from "react";

import "./BubbleMessage.scss";

import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

import { formatText } from "../../../utils/WhatsappMarkdown";
import TrebleMapPointer from "components/Maps/TrebleMapPointer";
import GoogleMapReact from "google-map-react";
import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';

import events from "../../../utils/events";
import { contentReplyMessage } from "../../../utils/messages";

import { downloadFile } from "../../../utils/file";

const AGENT = "AGENT";
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_MAPS_LANGUAGE = process.env.REACT_APP_GOOGLE_MAPS_LANGUAGE;

export class BubbleMessage extends Component {
  /**
   *
   * @param {*} props
   * this components receives
   * id: id of messsage
   * type: message | note
   * message
   * strings: string languges
   * sendToTarget: function
   * selectedChat
   * removeMessageFromHistory: function (this is in case a message fail you can resend)
   * selectProviderMsgId: function (this is to reply works)
   * showFileModal: function (this trigger filemodal with media selected)
   * reply: boolean (it show the reply button)
   * locationAddresses:
   * selectProviderMsgId: function (this is to reply works)
   * findReplyMessage: function (find and scroll to the message)
   */

  constructor(props) {
    super(props);
    this.strings = this.props.strings;
  }

  getSender = (message) => {
    return message.sender.toLowerCase().replace("ai", "agent");
  };

  renderReplyInfo = (message) => {
    if (!message) return;
    if (message === "not-found") {
      message = {
        type: "not-found",
        text: this.strings.msgNotFound,
        sender: "USER",
      };
    }
    const { text, icon, img } = contentReplyMessage(message);
    const sender = this.getSender(message);
    return (
      <div
        className="reply-info"
        onClick={() =>
          this.props.findReplyMessage(message.provider_msg_id, true)
        }
      >
        <div className={`flag flag--${sender}`}>
          <div className="icon icon--scroll-to-message" />
        </div>
        <div className="reply-content">
          {icon}
          <div className="text">{text}</div>
          {img ? <div className="cut-image">{img}</div> : ""}
        </div>
      </div>
    );
  };

  renderButtons = (buttons) => {
    if (!buttons || !Array.isArray(buttons)) return;
    return (
      <div className="buttons">
        {buttons.map((button) => (
          <p>{button.text}</p>
        ))}
      </div>
    );
  };

  renderHsm = (hsm, date) => {
    let header, footer, buttons;
    const body = <p>{formatText(hsm.body)}</p>;
    if (hsm.header) {
      const type = hsm.header.type;
      if (type === "text") header = <p>{formatText(hsm.header.text)}</p>;
      if (["image", "video"].includes(type))
        header = this.renderMedia(hsm.header);
      if (type === "document") header = this.renderDocument(hsm.header, date);
    }
    if (hsm.footer && hsm.footer.text && hsm.footer.text.length > 0) {
      footer = <p>*{formatText(hsm.footer.text)}</p>;
    }
    if (hsm.buttons && Object.keys(hsm.buttons).length > 0) {
      let content = null;
      if (hsm.buttons.type === "quick_reply") {
        content = hsm.buttons.options.map((opt) => {
          return <p>{formatText(opt.text)}</p>;
        });
      }
      if (hsm.buttons.type === "call_to_action") {
        content = hsm.buttons.options.map((opt) => {
          if (opt.url) {
            return (
              <a href={opt.url} target="_blank">
                {opt.text}
              </a>
            );
          } else if (opt.phone_number) {
            return (
              <p>
                {opt.text} - {opt.phone_number}
              </p>
            );
          }
        });
      }
      if (content) {
        buttons = <div className="buttons">{content}</div>;
      }
    }
    return (
      <div className="hsm">
        <div className="header">{header}</div>
        <div className="body">{body}</div>
        <div className="footer">{footer}</div>
        {buttons}
      </div>
    );
  };

  renderText = (text) => {
    const body = text.body;
    return <p>{formatText(body)}</p>;
  };

  renderMedia = (message) => {
    const type = message.type;
    const url = message[type] ? message[type].url : message.url;
    const caption = formatText(message[type] ? message[type].caption : "");
    return (
      <div className={caption ? "has-caption" : ""}>
        {type === "image" ? (
          <img src={url} onClick={() => this.props.showFileModal(message)} />
        ) : (
          <video src={url} controls />
        )}
        <p>{caption}</p>
      </div>
    );
  };

  renderAudio = (audio, date) => {
    return (
      <div className="audio">
        <AudioPlayer src={audio.url} layout="horizontal" showSkipControls={false} showJumpControls={false}/>
        <div
          className="icon icon--download-arrow"
          onClick={() => {
            downloadFile(audio.url, "Agent download file from chat", date);
          }}
        />
      </div>
    );
  };

  renderDocument = (document, date = null) => {
    const url = document.url;
    const filename = document.filename
      ? document.filename
      : document.caption
      ? document.caption
      : url.substring(url.lastIndexOf("/") + 1);
    return (
      <div className="document">
        <div className="icon icon--file-message" />
        <div className="text">{filename}</div>
        <div
          className="icon icon--download-file"
          onClick={() => {
            downloadFile(url, "Agent download file from chat", date);
          }}
        />
      </div>
    );
  };

  renderMarker = (latitude, longitude) => {
    return <TrebleMapPointer lat={latitude} lng={longitude} />;
  };

  renderLocation = (location, providerMsgId) => {
    let latitude, longitude;
    let currentLocationAddresses = this.props.locationAddresses;
    if (location.latitude && location.longitude) {
      latitude = location.latitude;
      longitude = location.longitude;
    } else {
      let coordinates = location.caption.split(" ");
      latitude = Number(coordinates[1]);
      longitude = Number(coordinates[4]);
      this.props.getAddressFromCoordinates(
        latitude,
        longitude,
        GOOGLE_API_KEY,
        providerMsgId,
        (data, msgId) => {
          this.props.handleIncomingAddress(
            currentLocationAddresses,
            data,
            msgId,
          );
        },
      );
    }
    return (
      <div className="location">
        <div className="maps-container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: GOOGLE_API_KEY,
              language: GOOGLE_MAPS_LANGUAGE,
              libraries: ["places"],
            }}
            center={{
              lat: latitude,
              lng: longitude,
            }}
            zoom={15}
          >
            {this.renderMarker(latitude, longitude)}
          </GoogleMapReact>
          <div
            className="address-container"
            onClick={() =>
              window.open(
                `https://maps.google.com/?q=${latitude},${longitude}`,
                "_blank",
              )
            }
          >
            <div className="address-information">
              <span>{this.strings.seeOnGoogleMaps}</span>
            </div>
            <div className="icon icon--arrow-map" />
          </div>
        </div>
      </div>
    );
  };

  renderContacts = (contacts) => {
    return (
      <div className="contacts">
        {contacts.map((contact) => (
          <div className="contact">
            <div className="icon icon--contact-message"></div>
            {contact.name}
            {contact.phones.map((phone) => (
              <div>{phone.phone}</div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  renderSticker = (sticker) => {
    return (
      <div className="sticker">
        <img src={sticker.url} />
      </div>
    );
  };

  renderUploadFile = () => {
    return (
      <div className="upload-file">
        <div className="ico-spinner"></div>{" "}
      </div>
    );
  };

  renderBodyByType = (message) => {
    const type = message.type;
    const date = message.created_at;
    const messageContent = message[type];
    const providerMsgId = message.provider_msg_id;
    const hasReplyMessage = message.reply_message ? true : false;
    let body = "";
    let contentType = "content";
    if (type === "upload-file") {
      body = this.renderUploadFile();
    } else if (type === "hsm") {
      body = this.renderHsm(messageContent, date);
    } else if (type === "text") {
      body = this.renderText(messageContent);
    } else if (["image", "video"].includes(type)) {
      contentType = "media-content";
      body = this.renderMedia(message);
    } else if (["audio", "voice"].includes(type)) {
      body = this.renderAudio(messageContent, date);
    } else if (type === "document") {
      body = this.renderDocument(messageContent, date);
    } else if (type === "location") {
      contentType = "media-content";
      body = this.renderLocation(messageContent, providerMsgId);
    } else if (type === "contacts") {
      body = this.renderContacts(messageContent);
    } else if (type === "sticker") {
      body = this.renderSticker(messageContent);
    }
    return (
      <div className="bubble-body">
        <div className={`${contentType} ${hasReplyMessage ? "has-reply" : ""}`}>
          {this.renderReplyInfo(message.reply_message)}
          {body}
          {messageContent && this.renderButtons(messageContent.buttons)}
        </div>
        {this.props.reply && type !== "load" ? (
          <div
            className="reply"
            onClick={() =>
              this.props.selectProviderMsgId(message.provider_msg_id)
            }
          >
            {this.strings.replyMessage}
          </div>
        ) : null}
      </div>
    );
  };

  resendMessage = (message, id) => {
    events.track("Click on resend message");
    this.props.removeMessageFromHistory(id);
    let type, messageToResend;
    if (message.type === "text") {
      type = "TEXT";
      messageToResend = message.text.body;
    } else {
      type = "MEDIA";
      messageToResend = message[message.type].url;
    }
    let resendData = {};
    if (message.reply_message) {
      resendData.providerMsgId = message.reply_message.provider_msg_id;
    }
    this.props.sendToTarget(
      type,
      messageToResend,
      this.props.selectedChat,
      resendData,
    );
  };

  renderDateAndStatus = (message) => {
    const id = this.props.id;
    const type = message.type;
    let messageDate = moment(moment.utc(message.created_at).toDate())
      .local()
      .format("hh:mm A");
    let messageStatus = null;
    if (message.sender === AGENT) {
      const hasError = message.error;
      const providerMsgId = message.provider_msg_id;
      if (providerMsgId) {
        const deliveredAt = message.delivered_at;
        const readAt = message.read_at;
        if (!deliveredAt && !readAt) {
          messageStatus = <div className="icon icon--sent size-16"></div>;
        }
        if (deliveredAt) {
          messageStatus = <div className="icon icon--delivered size-16"></div>;
        }
        if (readAt) {
          messageStatus = <div className="icon icon--read size-16"></div>;
        }
      } else if (hasError && type !== "hsm") {
        messageDate = null;
        messageStatus = (
          <>
            <p
              className="resend-link"
              onClick={() => this.resendMessage(message, id)}
            >
              {this.strings.resendLabel}
            </p>
            <div className="icon icon--error size-16"></div>
          </>
        );
      }
    }
    return (
      <div className="date-and-status">
        {messageDate}
        {messageStatus}
      </div>
    );
  };

  render() {
    const message = this.props.message;
    const type = this.props.type;
    const sender = this.getSender(message);
    return (
      <div
        className={`bubble ${type}`}
        id={`msg-id-${message.provider_msg_id}`}
      >
        <div className={sender}>
          {this.renderBodyByType(message)}
          {this.renderDateAndStatus(message)}
        </div>
      </div>
    );
  }
}

export default BubbleMessage;
