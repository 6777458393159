import React, { Component } from "react";
import "./Button.scss";
/*
	props:
		- title str|ReactComponent: title to be displayed
		- hideOnClick bool: Whether to hide body on click
*/

class Button extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <button
        className={`button btn treble-button ${
          this.props.className ? this.props.className : ""
        }`}
        onClick={this.props.onClick}
      >
        <div className={`icon icon--${this.props.icon} size-16`}></div>
        <p>{this.props.text}</p>
      </button>
    );
  };
}

export default Button;
