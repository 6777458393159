import React, { Component } from "react";

import "./Loader.scss";

export default class Loader extends Component {
  render() {
    const visible = this.props.visible ? this.props.visible : false;

    if (!visible) return null;

    return (
      <div className="treble-loader">
        <div
          className={"treble-logo"}
          style={{ display: "inline-block" }}
        ></div>
      </div>
    );
  }
}
