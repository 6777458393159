import { request } from "../utils/request";
import { toast } from "react-toastify";

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// REQUEST GENERAL STATS

const REQUEST_GENERAL_STATS = "REQUEST_GENERAL_STATS";
export const requestGeneralStats = () => (dispatch) => {
  dispatch({
    type: REQUEST_GENERAL_STATS,
  });
};

const RECEIVE_GENERAL_STATS = "RECEIVE_GENERAL_STATS";
export const receiveStats = (stats) => (dispatch) => {
  dispatch({
    type: RECEIVE_GENERAL_STATS,
    stats,
  });
};

const GENERAL_STATS_ERROR = "GENERAL_STATS_ERROR";
export const generalStatsError = (error) => (dispatch) => {
  dispatch({
    type: GENERAL_STATS_ERROR,
    error,
  });
};

export const getStats = (date) => (dispatch) => {
  dispatch(requestGeneralStats());

  request(
    "GET",
    `/admin/stats/conversation?from_date=${date}`,
    null,
    true,
    (data) => {
      return dispatch(receiveStats(data));
    },
    (error) => {
      console.log(error);
      return dispatch(generalStatsError("Unable to get chats"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// ADMIN CONVERSATIONS

const REQUEST_ADMIN_CONVERSATIONS = "REQUEST_ADMIN_CONVERSATIONS";
export const requestAdminConversations = () => (dispatch) => {
  dispatch({
    type: REQUEST_ADMIN_CONVERSATIONS,
  });
};

const RECEIVE_ADMIN_CONVERSATIONS = "RECEIVE_ADMIN_CONVERSATIONS";
export const receiveAdminConversations = (chats, resetChats) => (dispatch) => {
  dispatch({
    type: RECEIVE_ADMIN_CONVERSATIONS,
    resetChats,
    chats,
  });
};

const RECEIVE_ADMIN_CONVERSATIONS_ERROR = "RECEIVE_ADMIN_CONVERSATIONS_ERROR";
export const adminConversationsError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_ADMIN_CONVERSATIONS_ERROR,
    error,
  });
};

export const getAdminConversations =
  (offset = 0, limit = 5000) =>
  (dispatch) => {
    dispatch(requestAdminConversations());

    request(
      "GET",
      `/admin/conversations?offset=${offset}&limit=${limit}`,
      null,
      true,
      (data) => {
        if (data.length === limit)
          dispatch(getAdminConversations(offset + limit, limit));
        return dispatch(receiveAdminConversations(data, offset === 0));
      },
      (error) => {
        console.log(error);
        return dispatch(adminConversationsError("Unable to get chats"));
      },
    );
  };

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// GET CONVERSATION HISTORY

const REQUEST_CONVERSATION_HISTORY = "REQUEST_CONVERSATION_HISTORY";
export const requestConversationHistory = () => (dispatch) => {
  dispatch({
    type: REQUEST_CONVERSATION_HISTORY,
  });
};

const RECEIVE_CONVERSATION_HISTORY = "RECEIVE_CONVERSATION_HISTORY";
export const receiveConversationHistory = (history) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONVERSATION_HISTORY,
    history,
  });
};

const RECEIVE_CONVERSATION_HISTORY_ERROR = "RECEIVE_CONVERSATION_HISTORY_ERROR";
export const receiveConversationError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_CONVERSATION_HISTORY_ERROR,
    error,
  });
};

export const getConversationHistory = (conversationId) => (dispatch) => {
  dispatch(requestConversationHistory());

  request(
    "GET",
    `/agent/conversation/${conversationId}`,
    null,
    true,
    (data) => {
      return dispatch(receiveConversationHistory(data));
    },
    (error) => {
      console.log(error);
      return dispatch(receiveConversationError("Unable to get chats"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// REQUEST AGENT STATS

const REQUEST_AGENT_STATS = "REQUEST_AGENT_STATS";
export const requestAgentStats = () => (dispatch) => {
  dispatch({
    type: REQUEST_AGENT_STATS,
  });
};

const RECEIVE_AGENT_STATS = "RECEIVE_AGENT_STATS";
export const receiveAgentStats = (stats) => (dispatch) => {
  dispatch({
    type: RECEIVE_AGENT_STATS,
    stats,
  });
};

const AGENT_STATS_ERROR = "AGENT_STATS_ERROR";
export const agentStatsError = (error) => (dispatch) => {
  dispatch({
    type: AGENT_STATS_ERROR,
    error,
  });
};

export const getAgentStats = (date) => (dispatch) => {
  dispatch(requestGeneralStats());

  request(
    "GET",
    `/admin/stats/agent`,
    null,
    true,
    (data) => {
      return dispatch(receiveAgentStats(data));
    },
    (error) => {
      console.log(error);
      return dispatch(agentStatsError("Unable to get chats"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// ADMIN CONVERSATIONS

const REQUEST_ADMIN_AGENTS = "REQUEST_ADMIN_AGENTS";
export const requestAdminAgents = () => (dispatch) => {
  dispatch({
    type: REQUEST_ADMIN_AGENTS,
  });
};

const RECEIVE_ADMIN_AGENTS = "RECEIVE_ADMIN_AGENTS";
export const receiveAdminAgents = (agents) => (dispatch) => {
  dispatch({
    type: RECEIVE_ADMIN_AGENTS,
    agents,
  });
};

const RECEIVE_ADMIN_AGENTS_ERROR = "RECEIVE_ADMIN_AGENTS_ERROR";
export const adminAgentsError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_ADMIN_AGENTS_ERROR,
    error,
  });
};

export const getAdminAgents = () => (dispatch) => {
  dispatch(requestAdminAgents());

  request(
    "GET",
    "/admin/agents",
    null,
    true,
    (data) => {
      return dispatch(receiveAdminAgents(data));
    },
    (error) => {
      console.log(error);
      return dispatch(adminAgentsError("Unable to get agents"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

// TEAMS DATA

const REQUEST_TEAMS = "REQUEST_TEAMS";
export const requestTeams = () => (dispatch) => {
  dispatch({
    type: REQUEST_TEAMS,
  });
};

const RECEIVE_TEAMS = "RECEIVE_TEAMS";
export const receiveTeams = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_TEAMS,
    data,
  });
};

const RECEIVE_TEAMS_ERROR = "RECEIVE_TEAMS_ERROR";
export const receiveTeamsError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_TEAMS_ERROR,
    error,
  });
};

export const getTeams = () => (dispatch) => {
  dispatch(requestTeams());

  request(
    "GET",
    "/admin/teams",
    null,
    true,
    (data) => {
      return dispatch(receiveTeams(data));
    },
    (error) => {
      console.log(error);
      return dispatch(receiveTeamsError("Unable to get chats"));
    },
  );
};

// ****************************************************************************
// ****************************************************************************
// ****************************************************************************

export const createAgent = (agent) => (dispatch) => {
  toast("Creating agent", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  const params = {
    first_name: agent.first_name,
    last_name: agent.last_name,
    email: agent.email.replace(/\s/g, ""),
    password: agent.password,
    tags: agent.tags,
    agent_type: agent.agent_type,
    company_id: agent.company_id,
  };
  request(
    "POST",
    "/admin/create",
    params,
    true,
    (data) => {
      toast("Agent created!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    (error) => {
      toast.error("Error while creating agent: " + error.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    false,
    false,
  );
};

export const editAgent = (agent) => (dispatch) => {
  toast("Processing", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  const params = {
    id: agent.id,
    first_name: agent.first_name,
    last_name: agent.last_name,
    email: agent.email.replace(/\s/g, ""),
    password: agent.password,
    tags: agent.tags,
    agent_type: agent.agent_type.toUpperCase(),
    company_id: agent.company_id,
  };
  request(
    "PUT",
    "/admin/edit",
    params,
    true,
    (data) => {
      dispatch(getAdminAgents());
      toast(data.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    (error) => {
      toast.error("Error while editing agent", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    false,
    false,
  );
};

export const deleteAgent = (agent) => (dispatch) => {
  toast("Processing", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  request(
    "DELETE",
    "/admin/remove/" + agent["id"],
    null,
    true,
    (data) => {
      dispatch(getAdminAgents());
      dispatch(getAgentStats());
      toast(data.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    (error) => {
      toast.error(error.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    false,
    false,
  );
};

const REQUEST_TAGS = "REQUEST_TAGS";
export const requestTags = () => (dispatch) => {
  dispatch({
    type: REQUEST_TAGS,
  });
};

const REQUEST_ALL_TAGS = "REQUEST_ALL_TAGS";
export const requestAllTags = () => (dispatch) => {
  dispatch({
    type: REQUEST_ALL_TAGS,
  });
};

const RECEIVE_TAGS = "RECEIVE_TAGS";
export const receiveTags = (agents) => (dispatch) => {
  dispatch({
    type: RECEIVE_TAGS,
    agents,
  });
};

const RECEIVE_ALL_TAGS = "RECEIVE_ALL_TAGS";
export const receiveAllTags = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_ALL_TAGS,
    data,
  });
};

const RECEIVE_NEW_TAG = "RECEIVE_NEW_TAG";
export const receiveNewTag = (tag) => (dispatch) => {
  dispatch({
    type: RECEIVE_NEW_TAG,
    tag,
  });
};

const RECEIVE_TAGS_ERROR = "RECEIVE_TAGS_ERROR";
export const tagError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_TAGS_ERROR,
    error,
  });
};

export const getAllTagsByTeam = (company_id) => (dispatch) => {
  dispatch(requestTags());
  request(
    "GET",
    `/admin/company/${company_id}/tags`,
    null,
    true,
    (data) => {
      return dispatch(receiveTags(data));
    },
    (error) => {
      console.log(error);
      return dispatch(tagError("Unable to get tags"));
    },
  );
};

export const getAllTags = (company_id) => (dispatch) => {
  dispatch(requestAllTags());
  request(
    "GET",
    `/admin/company/${company_id}/tags/all`,
    null,
    true,
    (data) => {
      return dispatch(receiveAllTags(data));
    },
    (error) => {
      console.log(error);
      return dispatch(tagError("Unable to get tags"));
    },
  );
};

export const createNewTag = (company_id, name) => (dispatch) => {
  toast("Creating tag", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
  });
  const params = {
    name: name,
  };
  request(
    "POST",
    `/admin/company/${company_id}/tag`,
    params,
    true,
    (data) => {
      toast("Tag created!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      dispatch(receiveNewTag(data));
      request(
        "GET",
        `/admin/company/${company_id}/tags`,
        null,
        true,
        (data) => {
          return dispatch(receiveTags(data));
        },
        (error) => {
          console.log(error);
          return dispatch(tagError("Unable to get tags"));
        },
      );
      return;
    },
    (error) => {
      toast.error("Error while creating tag: " + error.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    },
    false,
    false,
  );
};

const REQUEST_ASSIGNMENT_METHOD = "REQUEST_ASSIGNMENT_METHOD";
export const requestAssignmentMethod = () => (dispatch) => {
  dispatch({
    type: REQUEST_ASSIGNMENT_METHOD,
  });
};

const RECEIVE_ASSIGNMENT_METHOD = "RECEIVE_ASSIGNMENT_METHOD";
export const receiveAssignmentMethod =
  (assignmentMethod, waitingTimeLimit) => (dispatch) => {
    dispatch({
      type: RECEIVE_ASSIGNMENT_METHOD,
      assignmentMethod,
      waitingTimeLimit,
    });
  };

const RECEIVE_ASSIGNMENT_METHOD_ERROR = "RECEIVE_ASSIGNMENT_METHOD_ERROR";
export const assignmentMethodError = (error) => (dispatch) => {
  dispatch({
    type: RECEIVE_ASSIGNMENT_METHOD_ERROR,
    error,
  });
};

export const getAssignmentMethod = () => (dispatch) => {
  dispatch(requestAssignmentMethod());
  request(
    "GET",
    `/admin/assignment_method`,
    null,
    true,
    (data) => {
      return dispatch(
        receiveAssignmentMethod(
          data.assignment_method,
          data.waiting_time_limit,
        ),
      );
    },
    (error) => {
      console.log(error);
      return dispatch(
        assignmentMethodError("Unable to get agent assigment method"),
      );
    },
  );
};

export const editAssignmentMethod =
  (assignmentMethod, waitingTimeLimit = null) =>
  (dispatch) => {
    toast("Updating agent assignment method", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
    });
    const params = {
      assignment_method: assignmentMethod,
      waiting_time_limit: waitingTimeLimit,
    };
    request(
      "PUT",
      `/admin/assignment_method`,
      params,
      true,
      (data) => {
        dispatch(getAssignmentMethod());
        toast("Agent assignment method updated!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
        return;
      },
      (error) => {
        toast.error("Error while updating agent assignment method: " + error.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
        });
        return;
      },
      false,
      false,
    );
  };
