module.exports = {
  es: {
    labelOrderByAZ: "Ordenar por A - Z",
    labelOrderByTimeAsc: "Ordenar por último tag creado",
    labelOrderByTimeDesc: "Ordenar por tag más antiguo",
    searchChatLabel: "Buscar tag o crear uno...",
    confirmDeleteChatLabel:
      "Vas a eliminar permanentemente el tag de todos los chats, ¿estás seguro de que deseas continuar?",
    createTag: "Crear tag",
    tagName: "Nombre del tag",
    createTagPlaceHolder: "Ej. Casos de soporte",
    chooseColor: "Elige un color",
    cancel: "Cancelar",
    saveChanges: "Guardar cambios",
    backToTags: "Regresar a los tags",
    tagsCreated: "Tags creados",
    createNewTag: "Crear nuevo tag",
    deleting: "El tag se está eliminando, espere un momento por favor🗑️ ",
    deleted: "El tag “Ventas Colombia” fue eliminado exitosamente",
    cantDeleted:
      "Oops! Hubo un error al eliminar el tag, revisa tu conexión a internet",
  },
  en: {
    labelOrderByAZ: "Sort by A - Z",
    labelOrderByTimeAsc: "Sort by last created tag",
    labelOrderByTimeDesc: "Sort by oldest tag",
    searchChatLabel: "Search for an tag or create one...",
    confirmDeleteChatLabel:
      "You are going to permanently remove the tag from all chats, are you sure you want to continue?",
    createTag: "Create tag",
    tagName: "Tag name",
    createTagPlaceHolder: "Eg Support Cases",
    chooseColor: "Choose a color",
    cancel: "Cancel",
    saveChanges: "Save Changes",
    backToTags: "Back to tags",
    tagsCreated: "Tags created",
    createNewTag: "Create new tag",
    deleting: "El tag se está eliminando, espere un momento por favor 🗑️ ",
    deleted: "El tag “Ventas Colombia” fue eliminado exitosamente",
    cantDeleted:
      "Oops! Hubo un error al eliminar el tag, revisa tu conexión a internet",
  },
  pt: {
    labelOrderByAZ: "Classificar por A - Z",
    labelOrderByTimeAsc: "Classificar pela última tag criada",
    labelOrderByTimeDesc: "Classificar por tag mais antiga",
    searchChatLabel: "Encontre a tag ou crie uma...",
    confirmDeleteChatLabel:
      "Você removerá permanentemente a tag de todos os bate-papos. Tem certeza de que deseja continuar?",
    createTag: "Criar etiqueta",
    tagName: "nome da etiqueta",
    createTagPlaceHolder: "Por exemplo, casos de suporte",
    chooseColor: "Escolha uma cor",
    cancel: "Cancelar",
    saveChanges: "Guardar mudanças",
    backToTags: "voltar às etiquetas",
    tagsCreated: "Tags criadas",
    createNewTag: "Criar nova etiqueta",
    deleting: "El tag se está eliminando, espere un momento por favor 🗑️ ",
    deleted: "El tag “Ventas Colombia” fue eliminado exitosamente",
    cantDeleted:
      "Oops! Hubo un error al eliminar el tag, revisa tu conexión a internet",
  },
};
