import React, { Component } from "react";
import Dropdown from "./Dropdown";

import COUNTRIES from "../../data/countries.json";
import "./CountriesDropdown.scss";
/*
	props:
		- countries list(str): list of country codes to show, if emty show all
		- onClick function(country_code): to be called on click
		- display function(counry_code) -> str: map betweem a country code and it displayed str
*/

class CountriesDropdown extends Component {
  constructor(props) {
    super(props);

    this.countries = {};
    COUNTRIES.forEach((country) => (this.countries[country.code] = country));

    this.state = {
      selectedCountry: { code: "EARTH" },
    };
  }

  filteredCountries = () =>
    COUNTRIES.filter((country) =>
      !this.props.countries || this.props.countries.length == 0
        ? true
        : this.props.countries.indexOf(country.code) !== -1,
    );

  defaultDisplay = (country) => {
    if (country.code === "EARTH") return this.props.noSelected;
    return this.countries[country.code].name;
  };

  onSelect = (country) => {
    this.setState({
      selectedCountry: country,
    });

    if (typeof this.props.onClick === "function") this.props.onClick(country);
  };

  getCountryClassName = (country) =>
    "tr-icon flag " + country.code.toLowerCase();

  getTitleComponent = (countryName) => (
    <div className="flex">
      <span className="tr-icon earth"></span>
      <p className="dropdown-title">{countryName}</p>
    </div>
  );

  render = () => {
    let display =
      typeof this.props.display === "function"
        ? this.props.display
        : this.defaultDisplay;
    return (
      <div className="country-dropdown">
        <Dropdown
          title={this.getTitleComponent(display(this.state.selectedCountry))}
          hideOnClick={true}
        >
          <div className="country-dropdown-body">
            <ul className="list-unstyled">
              {this.filteredCountries().map((country) => (
                <li onClick={() => this.onSelect(country)}>
                  <span className={this.getCountryClassName(country)}></span>
                  {display(country)}
                  <span className="country-code">{country.dial_code}</span>
                </li>
              ))}
            </ul>
          </div>
        </Dropdown>
      </div>
    );
  };
}

export default CountriesDropdown;
