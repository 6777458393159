import React, { Component } from "react";
import { connect } from "react-redux";
import "./Login.scss";

import Loader from "../../components/Loader/Loader";

import { doLogin, loginFromHelpdesk } from "../../actions/loginAction";
import queryString from "query-string";

import languages from "./languages.js";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isHubspot: false,
    };

    this.login = this.login.bind(this);

    this.strings = languages[props.language];
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params) {
      const email = params["email"];
      const apiKey = params["api_key"];
      const helpdesk = params["helpdesk"];
      if (email && apiKey) {
        this.props.doLogin(email, null, apiKey);
        this.setState({ isHubspot: true });
      }
      if (helpdesk) {
        this.props.loginFromHelpdesk();
      }
    }
  }

  login() {
    const { email, password } = this.state;
    this.props.doLogin(email, password, null);
  }
  renderLogin() {
    if (!this.state.isHubspot) {
      return (
        <>
          <div className="input-container">
            <p className="label-input">{this.strings.email}</p>
            <input
              className="input-text email"
              type="email"
              placeholder="name@company.com"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div className="input-container">
            <p className="label-input">{this.strings.password}</p>
            <input
              className="input-text pass"
              type="password"
              placeholder="treble123"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </div>
          <div className="msg-error">
            {this.props.errorVisible && this.props.errorMessage}
          </div>
          <div className="btn btn-dark" onClick={() => this.login()}>
            <p>{this.strings.login}</p>
          </div>
          <a
            href="https://support-treble.atlassian.net/servicedesk/customer/portal/1/group/1/create/19"
            className="link"
          >
            {this.strings.forgotPassword}
          </a>
        </>
      );
    } else {
      return (
        <div className="invalid-hubspot-agent">
          <h6>{this.strings.noAgent}</h6>
          <p className="r">{this.strings.noAgentD}</p>
        </div>
      );
    }
  }

  render() {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const errorLogout = parameters.get("error");

    return (
      <div className="login-screen">
        <Loader visible={this.props.isFetching} />
        <div className="login">
          <div className="treble-logo"></div>
          <p className="login">SALES</p>
          {this.renderLogin()}
          {errorLogout === "true" && (
            <div className="error-logout">
              <p className="error-message">{this.strings.errorLogout}</p>
              <p>Mac: Cmd + Shift + R</p>
              <p>Windows & Linux: Ctrl + Shift + R</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.mainReducer.isFetching,
  errorVisible: state.mainReducer.errorVisible,
  errorMessage: state.mainReducer.errorMessage,
  language: state.mainReducer.language,
  loginHelpdesk: state.mainReducer.loginHelpdesk,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (email, password, apiKey) =>
    dispatch(doLogin(email, password, apiKey)),
  loginFromHelpdesk: () => dispatch(loginFromHelpdesk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
