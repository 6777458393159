import React, { Component } from "react";
import { Input, Button, Dropdown, Menu } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import _ from "lodash";

import TrebleIcon from "../../../../../components/TrebleIcon/TrebleIcon";

import languages from "./languages";
import "./MultipleSelectDropdown.scss";

class MultipleSelectDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      value: props.value ?? [],
      open: false,
    };

    this.strings = languages[props.language];
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      this.setState({ value: this.props.value });
    }
  }

  filterOptions = () => {
    let searchValue = this.state.search.toLowerCase();
    return this.props.options.filter((option) =>
      option.label.toLowerCase().includes(searchValue),
    );
  };

  onSelect = (value) => {
    let newValue;
    if (Array.isArray(value) && value.length === 0) {
      newValue = [];
    } else {
      const key = value.key;
      newValue = this.state.value;
      if (newValue.includes(key)) {
        newValue.splice(newValue.indexOf(key), 1);
      } else {
        newValue.push(key);
      }
    }

    this.setState({ value: newValue });
    if (this.props.onSelect) {
      this.props.onSelect(newValue);
    }
  };

  renderSelectAll = () => {
    return (
      <div className="select-all">
        <div className="label-text">{this.props.placeholder}</div>
        <TrebleIcon
          name={`${
            Array.isArray(this.state.value) && this.state.value.length === 0
              ? "checked-radio"
              : "unchecked-radio"
          }`}
          size={18}
        />
      </div>
    );
  };

  formatOptions = (options) => {
    let newOptions = options.map((option) => ({
      key: option.value,
      label: (
        <div className="treble-multi-select-option">
          <div className="label-text">{option.label}</div>
          <TrebleIcon
            name={`${
              this.state.value.includes(option.value)
                ? "checked-checkbox"
                : "unchecked-checkbox"
            }`}
            size={18}
          />
        </div>
      ),
    }));

    return newOptions;
  };

  render = () => {
    let options = this.props.options;
    if (!options || !this.state.value) {
      return null;
    }
    if (this.search !== "") {
      options = this.filterOptions();
    }
    let classNames = `${this.props.className ?? ""} ${
      this.state.value.length > 0 ? "has-value" : ""
    } ${this.state.search !== "" ? "has-search" : ""}`;

    return (
      <Dropdown
        dropdownClassName={`treble-multiple-select-dropdown ${classNames}`}
        overlay={
          <div className="treble-multiple-select-dropdown-container">
            {this.props.searchable && (
              <div className="treble-multiple-select-searchbar">
                <Input
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  placeholder={this.props.searchPlaceholder}
                  prefix={<SearchOutlined />}
                  allowClear
                />
              </div>
            )}
            {this.props.enableAll && (
              <div className="select-all" onClick={() => this.onSelect([])}>
                <div className="label-text">{this.props.placeholder}</div>
                <TrebleIcon
                  name={`${
                    Array.isArray(this.state.value) &&
                    this.state.value.length === 0
                      ? "checked-radio"
                      : "unchecked-radio"
                  }`}
                  size={18}
                />
              </div>
            )}
            <Menu onClick={this.onSelect} items={this.formatOptions(options)} />
            {options.length === 0 && (
              <div className="treble-empty">
                <p>{this.strings.noResults}</p>
              </div>
            )}
            {this.props.enableAll && this.state.value.length > 0 && (
              <div className="clear-all">
                <div className="clear" onClick={() => this.onSelect([])}>
                  {this.strings.clearSelection}
                </div>
              </div>
            )}
          </div>
        }
        onVisibleChange={(open) => this.setState({ open })}
        visible={this.state.open}
        getPopupContainer={(trigger) => trigger.parentNode}
        trigger={["click"]}
        key={this.props._key}
        placement={this.props.placement ? this.props.placement : "bottomLeft"}
      >
        {this.props.children ? (
          this.props.children
        ) : (
          <div
            onClick={(e) => e.preventDefault()}
            className={`treble-multiple-select-dropdown ${classNames}`}
          >
            <Button>
              {this.state.value.length === 0
                ? this.props.placeholder
                : `${this.state.value.length} seleccionados`}
              <div className="icon icon--dropdown-arrow" />
            </Button>
          </div>
        )}
      </Dropdown>
    );
  };
}

const mapStateToProps = (state) => ({
  language: state.mainReducer.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleSelectDropdown);
